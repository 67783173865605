import {
    TOGGLE_NAVIGATION,
    TOGGLE_MOBILE_NAVIGATION,
    TOGGLE_BUSINESS_TRIP_BANNER_SUCCESS,
    TOGGLE_OVERTIME_BANNER_SUCCESS,
    TOGGLE_WORKSPACE_MIGRATION_DIALOG_SUCCESS,
    TOGGLE_INCOMPLETE_PROFILE_DIALOG_SUCCESS,
    TOGGLE_JOURNEY_COMPLETED_SUCCESS,
    TOGGLE_RECEIVED_HIRED_NOTIFICATION,
    SET_IS_CANDIDATE_HIRED,
    SET_IS_CANDIDATE_REJECTED,
} from 'actions/actionTypes';

const toggleNavigationSuccess = toggleValue => ({
    type: TOGGLE_NAVIGATION,
    payload: toggleValue
});

const toggleMobileNavigationSuccess = toggleValue => ({
    type: TOGGLE_MOBILE_NAVIGATION,
    payload: toggleValue
});

const toggleWorkspaceMigrationDialogSuccess = toggleValue => ({
    type: TOGGLE_WORKSPACE_MIGRATION_DIALOG_SUCCESS,
    payload: toggleValue,
});

const toggleIncompleteProfileDialogSucess = toggleValue => ({
    type: TOGGLE_INCOMPLETE_PROFILE_DIALOG_SUCCESS,
    payload: toggleValue,
});

const toggleJourneyCompletedSuccess = toggleValue => ({
    type: TOGGLE_JOURNEY_COMPLETED_SUCCESS,
    payload: toggleValue
});

export const toggleBusinessTripBannerSuccess = toggleValue => ({
    type: TOGGLE_BUSINESS_TRIP_BANNER_SUCCESS,
    payload: toggleValue,
})

export const toggleOvertimeBannerSuccess = toggleValue => ({
    type: TOGGLE_OVERTIME_BANNER_SUCCESS,
    payload: toggleValue,
})

export const toggleReceivedHiredNotificationSuccess = toggleValue => ({
    type: TOGGLE_RECEIVED_HIRED_NOTIFICATION,
    payload: toggleValue,
});

export const setIsCandidateHiredSuccess = (value) => ({
    type: SET_IS_CANDIDATE_HIRED,
    payload: value,
});

export const setIsCandidateRejectedSuccess = (value) => ({
    type: SET_IS_CANDIDATE_REJECTED,
    payload: value,
});

export const toggleNavigation = (toggleNavigation) => dispatch => {
    dispatch(toggleNavigationSuccess({ toggleNavigation }));
};

export const toggleMobileNavigation = (toggleMobileNavigation) => dispatch => {
    dispatch(toggleMobileNavigationSuccess({ toggleMobileNavigation }));
};

export const toggleBusinessTripBanner = (toggleValue) => dispatch => {
    dispatch(toggleBusinessTripBannerSuccess(toggleValue));
};

export const toggleOvertimeBanner = (toggleValue) => dispatch => {
    dispatch(toggleOvertimeBannerSuccess(toggleValue));
};

export const toggleWorkspaceMigrationDialog = (toggleValue) => dispatch => {
    dispatch(toggleWorkspaceMigrationDialogSuccess(toggleValue));
};

export const toggleIncompleteProfileDialog = (toggleValue) => dispatch => {
    dispatch(toggleIncompleteProfileDialogSucess(toggleValue));
};

export const toggleJourneyCompleted = (toggleValue) => dispatch => {
    dispatch(toggleJourneyCompletedSuccess(toggleValue));
};

export const toggleReceivedHiredNotification = (toggleValue) => dispatch => {
    dispatch(toggleReceivedHiredNotificationSuccess(toggleValue));
};

export const setIsCandidateHired = (value) => dispatch => {
    dispatch(toggleReceivedHiredNotificationSuccess(value));
};
