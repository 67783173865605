import React from 'react';
import { useIntl } from 'react-intl';

import DialogContent from '@material-ui/core/DialogContent';
import { Formik } from 'formik';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { APPLICATION_STATUSES } from 'constants/applicationConstants';

import ApproveApplicationFormView from './ApproveApplicationFormView';
import CommonFormikDialog from '../CommonFormikDialog';

import { addUtcOffset } from 'utils/formatTimes';

import {
    validateRequiredFields,
    validateDateFields,
} from 'utils/formValidations';

import './ApproveApplicationDialog.scss';

const ApproveApplicationDialog = ({
    agencyId,
    applicationId,
    handleCloseDialog,
    openDialog,
    handleHireCandidate,
    isContractJob,
    accountManager,
    isClientMode,
    isAgencyJob,
    handleStartDateChange,
}) => {
    const intl = useIntl();

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                enableReinitialize={true}
                initialValues={isContractJob
                    ? { startDate: null, comment: '' }
                    : { salaryCurrency: '', salaryValue: '', startDate: null, comment: '' }
                }
                validate={values => {
                    const requiredFields = isContractJob
                        ? ['startDate', 'comment']
                        : ['salaryCurrency', 'salaryValue', 'startDate', 'comment'];

                    return {
                        ...validateRequiredFields(values, requiredFields),
                        ...validateDateFields(values, ['startDate']),
                    }
                }}
                onSubmit={async (values) => {
                    const { comment, ...rest } = values;
                    await handleHireCandidate(agencyId, applicationId, { ...rest, feedback: { comment }, status: APPLICATION_STATUSES.APPROVED, startDate: addUtcOffset(values.startDate) });
                    handleCloseDialog();
                }}
            >
                {(props) =>
                    <CommonFormikDialog
                        className="approve-application-dialog"
                        onSave={props.handleSubmit}
                        dialogTitle={isContractJob ?  intl.formatMessage({ id: "hiring-details" }) : intl.formatMessage({ id: "contract-details" })}
                        open={openDialog}
                        onClose={handleCloseDialog}
                        saveBtnText="Save"
                        discardBtnText="Discard"
                        size="sm"
                    >
                        <DialogContent className="dialog-content">
                            <ApproveApplicationFormView
                                {...props}
                                isContractJob={isContractJob}
                                accountManager={accountManager}
                                isClientMode={isClientMode}
                                isAgencyJob={isAgencyJob}
                                handleStartDateChange={handleStartDateChange}
                            />
                        </DialogContent>
                    </CommonFormikDialog>

                }
            </Formik>
        </MuiPickersUtilsProvider >
    )
};

export default ApproveApplicationDialog;
