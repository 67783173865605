import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import './CvProfileInformation.scss';

const CvProfileInformation = ({
    profileInformation
}) => {
    const intl = useIntl();

    return (
        <>
            <div className="cv-profile-information">
                <div className="intro-container">
                    <h1 className="heading-m color-dark-grey">{profileInformation.firstName} {profileInformation.lastName}</h1>
                    <h3 className="heading-s color-grey">{profileInformation.position}</h3>
                    {profileInformation.experienceSince && profileInformation.experienceSince !== ''
                        && <p className="subheading-xl color-grey">{moment().diff(moment(profileInformation.experienceSince), 'years')} {intl.formatMessage({ id: "years-of-experience" })}</p>
                    }
                </div>
            </div>
        </>
    )
}

export default CvProfileInformation;
