import React, { Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
	fetchEmploymentInformation,
	editEmployeeActiveCompensation,
	editBankDetails
} from 'actions/employeeActions';
import { showNotification } from 'actions/notificationActions';
import { downloadContractFiles } from 'actions/contractActions';
import {
	getEmploymentActiveCompensation,
	getEmploymentBankDetails,
	getDisplayMode,
	getEmployeesEmploymentInformationDataFetchState,
} from 'reducers';

import EmploymentContractDetails from './EmploymentContractDetails';
import BankDetails from './BankDetails';
import Contracts from 'components/Contracts';
import ContractInformation from './ContractInformation';
import EmployeeEvrotrustStatus from './EmployeeEvrotrustStatus';
import CompanyInformation from './CompanyInformation';

import CircularProgress from '@material-ui/core/CircularProgress';

import { CONTRACT_TYPES } from 'constants/contractsConstants';
import { ROLES } from 'constants/userConstants';

import './EmploymentInformation.scss';

const EmploymentInformation = ({
	fetchEmploymentInformation,
	editBankDetails,
	bankDetails,
	displayMode,
	userId,
	agencyId,
	departmentId,
	dataFetchState,
	isDataAvailable,
}) => {
    const contractsTableRef = useRef();
    const history = useHistory();

    useEffect(() => {
        if (contractsTableRef.current && history.location.search?.includes('scrollIntoContracts') && dataFetchState.isDataFetched) {
            contractsTableRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            const searchParams = new URLSearchParams(history.location.search);
            searchParams.delete('scrollIntoContracts');
            history.replace({
                pathname: history.location.pathname,
                search: searchParams.toString(),
            });
        }
    }, [contractsTableRef, dataFetchState]);

	useEffect(() => {
		if (!isDataAvailable) {
			fetchEmploymentInformation(agencyId, userId, departmentId)
		}
	}, []);

	return (
		<Fragment>
			{dataFetchState?.isDataFetching && !dataFetchState.isDataFetched
				? <CircularProgress thickness={5} size={50} disableShrink className="page-loader" />
				: <div>
					{(displayMode === ROLES.ADMIN || departmentId) &&
						<Fragment>
							<CompanyInformation
								agencyId={agencyId}
								employeeId={userId}
							/>

							<EmploymentContractDetails
								agencyId={agencyId}
								departmentId={departmentId}
								employeeId={userId}
							/>

							<ContractInformation
								employeeId={userId}
								agencyId={agencyId}
								departmentId={departmentId}
							/>

							<EmployeeEvrotrustStatus />
						</Fragment>
					}
					{(displayMode === ROLES.ADMIN || displayMode === ROLES.SUPPLIER_ADMIN || departmentId) &&
						<div ref={contractsTableRef}>
							{(displayMode === ROLES.ADMIN || departmentId) && <Contracts
								agencyId={agencyId}
								userId={userId}
								isAdmin={true}
								departmentId={departmentId}
								contractTypes={CONTRACT_TYPES.EMPLOYEE}
								receiverRole={ROLES.EMPLOYEE}
							/>}
						</div>
					}
					{(displayMode === ROLES.ADMIN || departmentId) &&
						<BankDetails
							employeeId={userId}
							agencyId={agencyId}
							departmentId={departmentId}
							bankDetails={bankDetails}
							editBankDetails={editBankDetails}
						/>
					}
				</div>
			}
		</Fragment>
	)
}

const mapStateToProps = state => ({
	activeCompensation: getEmploymentActiveCompensation(state),
	bankDetails: getEmploymentBankDetails(state),
	displayMode: getDisplayMode(state),
	dataFetchState: getEmployeesEmploymentInformationDataFetchState(state),
});

const mapDispatchtoProps = {
	fetchEmploymentInformation,
	editEmployeeActiveCompensation,
	editBankDetails,
	downloadContractFiles,
	showNotification,
};

export default connect(
	mapStateToProps,
	mapDispatchtoProps
)(EmploymentInformation);
