import React from 'react';

import { getStateAgencyName } from 'utils/helpers';
import AdminMenu from '../Menus/AdminMenu';
import EmployeeMenu from '../Menus/EmployeeMenu';
import ClientMenu from '../Menus/ClientMenu';
import SupplierMenu from '../Menus/SupplierMenu';
import SupplierEmployeeMenu from '../Menus/SupplierEmployeeMenu';
import RecruiterMenu from '../Menus/RecruiterMenu';
import CandidateMenu from '../Menus/CandidateMenu';

import { ROLES } from 'constants/userConstants';

export const getMenuForRole = (role, accessAsManager = null, userPermissions = [], hiddenSections, agencyRouteName, agencyId, userId) => {
    const rolesMenusMap = {
        [ROLES.ADMIN]: <AdminMenu accessAsManager={accessAsManager} userPermissions={userPermissions} hiddenSections={hiddenSections} agencyRouteName={agencyRouteName} />,
        [ROLES.EMPLOYEE]: <EmployeeMenu accessAsManager={accessAsManager} hiddenSections={hiddenSections} agencyRouteName={agencyRouteName} agencyId={agencyId} userId={userId} />,
        [ROLES.CLIENT]: <ClientMenu hiddenSections={hiddenSections} agencyRouteName={agencyRouteName} />,
        [ROLES.SUPPLIER_ADMIN]: <SupplierMenu hiddenSections={hiddenSections} agencyRouteName={agencyRouteName} />,
        [ROLES.SUPPLIER_EMPLOYEE]: <SupplierEmployeeMenu agencyRouteName={agencyRouteName} />,
        [ROLES.RECRUITER]: <RecruiterMenu agencyRouteName={agencyRouteName} />,
        [ROLES.CANDIDATE]: <CandidateMenu agencyRouteName={agencyRouteName} />,
    };

    return rolesMenusMap[role];
};

export const getDepartmentSubItemEndpoint = departmentId => {
    return `/${getStateAgencyName()}/departments/${departmentId}/members`;
};
