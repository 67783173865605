import searchService from 'services/searchService';
import { authorizedShowNotification } from './actionHelpers';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { SEARCH_MESSAGES } from 'constants/messageConstants';

import {
    SEARCH_RESULTS_PENDING,
    SEARCH_RESULTS_FETCH_SUCCESS,
    SEARCH_RESULTS_BY_NAME_FETCH_SUCCESS,
    SEARCH_DATA_FETCH_SUCCESS,
    SEARCH_DATA_FETCHING_SUCCESS
} from 'actions/actionTypes';

const fetchSearchResultsSuccess = searchResults => ({
    type: SEARCH_RESULTS_FETCH_SUCCESS,
    payload: searchResults
});

const fetchSearchResultsByNamesSuccess = searchResults => ({
    type: SEARCH_RESULTS_BY_NAME_FETCH_SUCCESS,
    payload: searchResults
});

export const fetchSearchResultsPending = () => ({
    type: SEARCH_RESULTS_PENDING,
});

const searchResultsFetchSuccess = () => ({
    type: SEARCH_DATA_FETCH_SUCCESS,
}); 

const searchResultsFetchingSuccess = () => ({
    type: SEARCH_DATA_FETCHING_SUCCESS,
});

export const fetchSearchResults = (agencyId, searchTerm, roles, detailedInformation) => async dispatch => {
    dispatch(fetchSearchResultsSuccess([]));
    dispatch(searchResultsFetchingSuccess());
    try {
        const searchResults = await searchService.performSearch(agencyId, searchTerm, roles, detailedInformation);

        dispatch(fetchSearchResultsSuccess(searchResults));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SEARCH_MESSAGES.SEARCH_RESULTS_FETCH_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(searchResultsFetchSuccess());
    }
};

export const fetchSearchUsersByNameResults = (agencyId, searchTerm, roles) => async dispatch => {
    dispatch(fetchSearchResultsByNamesSuccess([]));
    try {
        const searchResults = await searchService.fetchSearchUsersByNames(agencyId, searchTerm, roles);

        dispatch(fetchSearchResultsByNamesSuccess(searchResults));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SEARCH_MESSAGES.SEARCH_RESULTS_FETCH_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchSearchUsersResponses = (agencyId, searchTerm) => async dispatch => {
    dispatch(fetchSearchResultsPending());

    try {
        const searchResults = await searchService.fetchSearchUsersResponses(agencyId, searchTerm);

        dispatch(fetchSearchResultsSuccess(searchResults));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SEARCH_MESSAGES.SEARCH_RESULTS_FETCH_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};
