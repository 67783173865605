import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import SearchEmployee from './SearchEmployee';
import CustomCheckbox from 'components/Shared/CustomCheckbox';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import { ADMIN_USERS_PERMISSIONS_LIST, ADMIN_USERS_PERMISSIONS_TEXT_MAP } from 'constants/userPermissionsConstants';

import './AssignAdminFormView.scss';

const AssignAdminFormView = ({
    handleSelectedEmployees,
    selectedEmployees,
    values,
    handleChange,
    isEditMode,
}) => {
    const intl = useIntl();
    const [selectedPermissions, setSelectedPermissions] = useState(selectedEmployees[0]?.permissions || ADMIN_USERS_PERMISSIONS_LIST);

    const handleSelectedEmployeesPermissionsChange = (permission) => {
        selectedEmployees.forEach(employee => {
            if (!employee.permissions) {
                employee.permissions = selectedPermissions;
            }
            if (employee.permissions.includes(permission)) {
                employee.permissions = employee.permissions.filter(x => x !== permission);
            } else {
                employee.permissions = [...employee.permissions, permission];
            }
        });
        setSelectedPermissions(selectedEmployees[0].permissions)
    };

    return (
        <form className="assign-admin-form" autoComplete="off">
            {!isEditMode && <SearchEmployee handleChange={handleChange} searchTerm={values.searchTerm} handleSelectedEmployees={handleSelectedEmployees} />}
            <List className="people-results-list">
                {selectedEmployees.map((employee, index) => (
                    <div key={employee._id} className="result-list-item">
                        <ListItem
                            alignItems="flex-start"
                        >
                            <ListItemAvatar>
                                <Avatar alt={`${employee.firstName} ${employee.lastName}`} src={employee.profilePictureImgUrl} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={employee.firstName ? `${employee.firstName} ${employee.lastName}` : employee.name}
                                secondary={employee.position}
                            />
                        </ListItem>
                        {selectedEmployees.length !== index + 1 && <Divider component="li" />}
                    </div>
                ))}
            </List >
            <div className="permissions-container">
                <Typography className="permissions-title" variant='h3'>{intl.formatMessage({ id: 'permissions' })}</Typography>
                <div className="checkboxes-container">
                    {ADMIN_USERS_PERMISSIONS_LIST.map((permission, idx) =>
                        <div className="checkbox-container" onClick={() => handleSelectedEmployeesPermissionsChange(permission)}>
                            <CustomCheckbox
                                disabled={selectedEmployees.length === 0 || idx === 0}
                                isChecked={selectedPermissions.includes(permission)}
                                size="small"
                                className="checkbox"
                            />
                            <p className="checkbox-text">{intl.formatMessage({ id: convertStringToLanguageKey(ADMIN_USERS_PERMISSIONS_TEXT_MAP[permission]) })}</p>
                        </div>
                    )}
                </div>
            </div>
        </form>
    );
};

export default AssignAdminFormView;
