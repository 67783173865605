import React, { useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { isAuthenticated } from 'reducers';
import { getHomePath } from 'utils/navigationUtils';
import { setGCLID, setReferrer } from 'utils/queryUtils';

const isGuest = Component => {
    const Guest = ({
        isAuthenticated,
    }) => {
        const history = useHistory();
       
        const gotoUrl = history.location.state?.gotoUrl;
        
        setReferrer(history.location.search);
        setGCLID(history.location.search);

        const isFromCandidateOrClientRegistration = history.location.state?.isFromCandidateOrClientRegistration;
        const isFromAgencyRegistration = history.location.state?.isFromAgencyRegistration;
        const isSSOLogin = history.location.state?.isSSOLogin;
        const isSSORegisterClient = history.location.state?.isSSORegisterClient;

        useEffect(() => {
            if (isFromCandidateOrClientRegistration || isFromAgencyRegistration || isSSOLogin || isSSORegisterClient) {
                return;
            }

            if (isAuthenticated) {
                const homePath = getHomePath(gotoUrl);
                history.push({ pathname: homePath.pathname, state: { ...history.location.state, ...homePath.state }});
            }
        }, [isAuthenticated]);

        return (
            <Fragment>
                {(!isAuthenticated || isFromCandidateOrClientRegistration || isFromAgencyRegistration || isSSOLogin || isSSORegisterClient) ? <Component /> : null}
            </Fragment>
        );
    }

    const mapStateToProps = state => ({
        isAuthenticated: isAuthenticated(state),
    });

    return connect(mapStateToProps)(Guest);
}

export default isGuest;
