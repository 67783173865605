import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import FormInputField from 'components/Shared/FormInputField';
import FormControlCheckbox from 'components/Shared/FormControlCheckbox';
// import LabeledSwitcher from 'components/Shared/LabeledSwitcher';
import ActionButton from 'components/Shared/Buttons/ActionButton';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';

// import { UI_VIEWS } from 'constants/authConstants';
import { ROLES } from 'constants/userConstants';

import { ReactComponent as GoogleIcon } from 'assets/google.svg';

const LoginFormView = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    loginLoading,
    ssoLoginLoading,
    handleSSOLogin,
    selectedView,
    setSelectedView,
    agencyInfo,
}) => {
    const isEmailRequirementNotMet = errors.email && touched.email;
    const isPasswordRequirementNotMet = errors.password && touched.password;
    const isRememberMeChecked = values.rememberMe;
    const hasRememberMeError = errors.rememberMe;
    const isRememberMeFieldTouched = touched.rememberMe;

    return (
        <form autoComplete="off">
            {/* <LabeledSwitcher
                options={Object.values(UI_VIEWS)}
                selectedOption={selectedView}
                setSelectedOption={setSelectedView}
            /> */}

            <FormInputField
                className="smaller"
                label="Email"
                placeholder="Email"
                name="email"
                value={values.email}
                showValidationRequirement={isEmailRequirementNotMet}
                requirementText={errors.email}
                isRequirementNotMet={isEmailRequirementNotMet}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />

            <FormInputField
                className="smaller"
                withPasswordVisibility
                label="Password"
                placeholder="Password"
                name="password"
                value={values.password}
                showValidationRequirement={isPasswordRequirementNotMet}
                requirementText={errors.password}
                isRequirementNotMet={isPasswordRequirementNotMet}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />

            <div className="form-fields-wrapper">
                <FormControlCheckbox
                    className="smaller"
                    isTouched={isRememberMeFieldTouched}
                    hasError={hasRememberMeError}
                    name="rememberMe"
                    label="Remember me"
                    checked={isRememberMeChecked}
                    handleChange={() => setFieldValue('rememberMe', !isRememberMeChecked)}
                />

                <Link className={`forgotten-pwd-link ${selectedView.ROLE}`} component={RouterLink} to="/forgottenpassword">
                    Forgot Password?
                </Link>
            </div>

            <div className="buttons-wrapper">
                <ActionButton
                    text={(!loginLoading && ssoLoginLoading) ? <CircularProgress thickness={5} className="white" /> : "Log In"}
                    handleClick={handleSubmit}
                    className={selectedView.ROLE === ROLES.CANDIDATE ? "purple" : "orange"}
                />

                <ActionButton
                    className="inverted with-icon"
                    text={ssoLoginLoading ? "Sign in with Google" : <CircularProgress thickness={5} className="deep-purple" />}
                    icon={ssoLoginLoading && <GoogleIcon />}
                    handleClick={handleSSOLogin}
                />
            </div>
            <div className="sign-text-wrapper">Don't have an account? <Link className={selectedView.ROLE} component={RouterLink} to={`./register/${agencyInfo}`}>Sign up</Link></div>
        </form>
    )
};

export default LoginFormView;
