import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { ErrorMessage } from 'formik';

import { KeyboardDatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import isHoliday from 'hoc/isHoliday';

import { EMPLOYMENT_TYPES } from 'constants/employeeConstants';
import { DATE_PICKER_FORMAT } from 'constants/timesFormat';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './AddTeamMemberFormView.scss';

const AddTeamMemberFormView = ({
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    isAddingEmployee,
    isEmailRequired,
    nameFieldLabelText,
}) => {
    const intl = useIntl();

    return (
        <form className="add-team-member-form" autoComplete="off">
            <div>
                <TextField
                    required
                    className="add-member-field"
                    name="name"
                    value={values.firstName}
                    onChange={handleChange}
                    id="add-team-member-name"
                    label={intl.formatMessage({ id: nameFieldLabelText ? convertStringToLanguageKey(nameFieldLabelText) : "name-text" }) }
                    error={errors.name && touched.name}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="name" component="div" className="invalid-field-message" />
            </div>
            <div>
                <TextField
                    required={isEmailRequired}
                    className="add-member-field"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    id="add-member-email"
                    label={intl.formatMessage({ id: "email" })}
                    error={errors.email && touched.email}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="email" component="div" className="invalid-field-message" />
            </div>

            {isAddingEmployee &&
                <>
                    <div>
                        <TextField
                            className="add-member-field"
                            required
                            select
                            name="employmentType"
                            value={values.employmentType}
                            onChange={e => { setFieldValue('employmentType', e.target.value) }}
                            label={intl.formatMessage({ id: "employment-type" })}
                            error={errors.employmentType && touched.employmentType}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                        >
                            {Object.values(EMPLOYMENT_TYPES).map((type, i) => (
                                <MenuItem
                                    native="true"
                                    key={i}
                                    value={type}
                                >
                                    {intl.formatMessage({ id: convertStringToLanguageKey(type) })}
                                </MenuItem>
                            ))}
                        </TextField>
                        <ErrorMessage name="employmentType" component="div" className="invalid-field-message" />
                    </div>

                    <div>
                        <div className="helper-text-wrapper">
                            <span>
                                {intl.formatMessage({ id: "add-member-helper-text" })}
                            </span>
                        </div>
                        <KeyboardDatePicker
                            className="add-member-field"
                            autoOk
                            required
                            label={intl.formatMessage({ id: "start-date" })}
                            name="startDate"
                            variant="inline"
                            disableToolbar
                            format={DATE_PICKER_FORMAT}
                            openTo="date"
                            inputVariant="outlined"
                            value={values.startDate}
                            onChange={value => {
                                setFieldValue('startDate', moment(value));
                            }}
                            fullWidth
                            renderDay={isHoliday}
                        />
                        <ErrorMessage name="startDate" component="div" className="invalid-field-message" />
                    </div>
                </>
            }
        </form>
    )
};

export default AddTeamMemberFormView;
