import React from 'react';
import { isMobile } from 'react-device-detect';

import { ErrorMessage } from 'formik';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import PaperHeader from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperHeader';
import PaperContent from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperContent';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

import { ReactComponent as RadioButtonIcon } from 'assets/radio-button.svg';
import { ReactComponent as RadioButtonCheckedIcon } from 'assets/radio-button-checked.svg';

import './SelectionCard.scss';

const SelectionCard = ({
    title,
    subTitle,
    options,
    name,
    errorClassName,
    handleChange,
    values,
    errors,
    touched,
    primaryButtonText,
    primaryButtonClickHandler,
    additionalOptionsComponent,
}) => (
    <PaperElement
        classes={['options-card-wrapper', isMobile ? 'max' : 'sm']}
        elevation={0}
    >
        <PaperHeader title={title} />
        <PaperContent classes={["paper-content-flex"]}>
            <Typography className="options-card-sub-title" variant="h4">
                {subTitle}
            </Typography>

            <RadioGroup
                value={values[name]}
                name={name}
                className={`options-card-labels-wrapper ${(errors[name] && touched[name] ? errorClassName || 'error' : '')}`}
                onChange={handleChange}
            >
                {options.map(option => (
                    <FormControlLabel
                        className={`options-card-label ${values[name] === option.value ? 'selected' : ''}`}
                        key={option.value}
                        value={option.value}
                        control={<Radio disableRipple className="radio-btn" icon={<RadioButtonIcon />} checkedIcon={<RadioButtonCheckedIcon />} />}
                        label={option.title}
                        labelPlacement="start"
                    />
                ))}
                <ErrorMessage
                    name={name}
                    component={() => (
                        <div className="invalid-field-wrapper">
                            <ErrorRoundedIcon className="error-icon" fontSize="small" />
                            <span>The field is required. Please select one option.</span>
                        </div>
                    )}
                />
            </RadioGroup>
            {additionalOptionsComponent ? additionalOptionsComponent : null}
            <PrimaryButton className="purple" text={primaryButtonText} onClick={primaryButtonClickHandler} />
        </PaperContent>
    </PaperElement>
);

export default SelectionCard;
