import React, { useCallback, useState } from 'react';

import MainMenu from './MainMenu';
import { filterMenuItems, filterPermittedMenuItems } from 'utils/navigationUtils';

import { getAdminMenuItems, getAdminMenuItemsSaas, HIDDEN_MENU_ITEMS } from '../constants/adminMenuConstants';

const AdminMenu = (props) => {
    const [adminMenuItems] = useState(filterPermittedMenuItems(getAdminMenuItems(props.agencyRouteName), props.userPermissions));
    const [adminMenuItemsSaas] = useState(filterPermittedMenuItems(getAdminMenuItemsSaas(props.agencyRouteName), props.userPermissions));
    const filterMenuItemCallback = useCallback(filterMenuItems(adminMenuItemsSaas, HIDDEN_MENU_ITEMS), [])

    return (
        <MainMenu
            menuItems={props.hiddenSections
                ? filterMenuItemCallback
                : adminMenuItems}
        />
    )
};

export default AdminMenu;
