import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { fetchEmployeeDepartmentDetails } from 'actions/departmentActions';

import { getAgencyName, getDepartmentDetails } from 'reducers';

import { Paper, TextField } from '@material-ui/core';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { getStateAgencyName } from 'utils/helpers';

import './CompanyInformation.scss';

const CompanyInformation = ({
    agencyId,
    employeeId,
    fetchEmployeeDepartmentDetails,
    departmentDetails,
    companyName,
}) => {
    const intl = useIntl();
    const history = useHistory();

    useEffect(() => {
        fetchEmployeeDepartmentDetails(agencyId, employeeId);
    }, []);

    return (
        <div className="company-information-wrapper">
            <div className="company-information-header">
                <p className="heading-xs color-grey">Company</p>
                <PrimaryButton text="Change" handleClick={() => history.push(`/${getStateAgencyName()}/settings/user-management`)} />
            </div>
            <Paper className="company-information-content-wrapper">
                <div className="form-field">
                    <TextField
                        fullWidth
                        name="companyName"
                        label={intl.formatMessage({ id: "company-name" })}
                        type="text"
                        variant="outlined"
                        value={departmentDetails.name || companyName}
                        disabled
                    />
                </div>
            </Paper>
        </div>
    )
};

const mapStateToProps = (state) => ({
    companyName: getAgencyName(state),
    departmentDetails: getDepartmentDetails(state),
});

const mapDispatchToProps = {
    fetchEmployeeDepartmentDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInformation);
