import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import moment from 'moment';

import { getCountries, getJobOpportunitiesTechnologies } from 'reducers';

import { updateCandidatePreferences } from 'actions/candidateActions';
import { updateAvatar, deleteAvatar } from 'actions/employeeActions';
import { fetchJobOpportunitiesTechnologies } from 'actions/jobOpportunityActions';

import CandidateProfileInfoFormik from './CandidateProfileInfoFormik';
import ExperienceDialog from './Dialogs/ExperienceDialog';
import TaxResidencyDialog from './Dialogs/TaxResidencyDialog';
import AvailableFromDialog from './Dialogs/AvailableFromDialog';
import ExpectedSalaryDialog from './Dialogs/ExpectedSalaryDialog';
import ImageEditDialog from 'components/Shared/ImageEditDialog';
import JobTypeDialog from './Dialogs/JobTypeDialog';
import TechStackDialog from './Dialogs/TechStackDialog';

import Avatar from '@material-ui/core/Avatar';
import { Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import CandidateStatusIcon from './CandidateStatusIcon';

import { ReactComponent as InfoIcon } from 'assets/info-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/x-icon-purple.svg';
import { ReactComponent as EditIconBordered } from 'assets/icons-edit-bordered.svg';
import { ReactComponent as EditIcon } from 'assets/edit-candidate-cv-icon.svg';

import { DATE_FORMAT } from 'constants/commonConstants';
import { ROWS_MAPPER, REQUIRED_FIELDS, POSITIVE_FIELDS } from './CandidateProfileInfoConstants';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import defaultAvatar from 'assets/default-avatar.svg';

import './CandidateProfileInfo.scss';
import { JOB_TYPES } from 'components/CandidateProfileJourney/Steps/YourProfileWrapper/YourProfileConstants';

const CandidateProfileInfo = ({
    profileInfo,
    personalInfo,
    updateCandidatePreferences,
    agencyId,
    userId,
    candidateId,
    setEditMode,
    isCandidateMode,
    isAdminMode,
    countries,
    deleteAvatar,
    updateAvatar,
    fetchJobOpportunitiesTechnologies,
}) => {
    const intl = useIntl();

    const classes = ["candidate-preferences", "background-white"].join(' ');
    const [dialog, setDialog] = useState('');

    const [currentImgSelected, setCurrentImgSelected] = useState(personalInfo.profilePictureImgUrl);
    const [isEditImgDialogOpen, setIsEditImgDialogOpen] = useState(false);

    const profileImgRef = useRef();

    useEffect(() => {
        fetchJobOpportunitiesTechnologies(agencyId);
    }, [])

    const currentlyOpenedDialog = (props) => {
        switch (dialog) {
            case 'experienceSince':
                return <ExperienceDialog {...props} open={true} onClose={handleCloseDialog} />
            case 'countryOfTax':
                return <TaxResidencyDialog {...props} open={true} onClose={handleCloseDialog} isCandidateMode={isCandidateMode} />
            case 'earliestDate':
                return <AvailableFromDialog {...props} open={true} onClose={handleCloseDialog} />
            case 'salaryValue':
                return <ExpectedSalaryDialog {...props} open={true} onClose={handleCloseDialog} />
            case 'jobType':
                return <JobTypeDialog {...props} open={true} onClose={handleCloseDialog} />
            case 'techStack':
                return <TechStackDialog {...props} open={true} onClose={handleCloseDialog} handleAddTechnology={handleAddTechnology} handleRemoveTechnology={handleRemoveTechnology} />
            default:
                break;
        }
    }

    const handleAddTechnology = (values, setFieldValue, newValue) => {
        setFieldValue('technologies', [...values.technologies, newValue.title]);
    }

    const handleRemoveTechnology = (values, setFieldValue, indexToRemove) => {
        const fieldValueFiltered = values.technologies.filter((_, index) => index !== indexToRemove);

        setFieldValue('technologies', [...fieldValueFiltered]);
    }

    const updateProfileInfo = async (preferencesData, resetForm) => {
        await updateCandidatePreferences(agencyId, candidateId, preferencesData);
        resetForm();
        handleCloseDialog();
        setEditMode(false);
    };

    const handleOpenDialog = (dialogName) => {
        setDialog(dialogName)
    }

    const handleCloseDialog = () => setDialog('');

    const adjustProfileInfo = (profileInfo) => {

        return {
            experienceSince: (<p>{profileInfo?.experienceSince ? intl.formatMessage({ id: convertStringToLanguageKey(profileInfo?.experienceSince) }) : intl.formatMessage({ id: "not-selected-yet" })}</p>),
            countryOfTax: (<p>{profileInfo?.countryOfTax ? intl.formatMessage({ id: convertStringToLanguageKey(profileInfo?.countryOfTax) }) : intl.formatMessage({ id: "not-selected-yet" })}</p>),
            earliestDate: (
                profileInfo.earliestDate == null
                    ? <p>{intl.formatMessage({ id: "not-selected-yet" })}</p>
                    : <p>{moment(profileInfo.earliestDate).format(DATE_FORMAT)}</p>
            ),
            salaryValue: <div>
                <p>{+profileInfo.netSalaryValue ? `${intl.formatMessage({ id: "net" })}: ${+profileInfo.netSalaryValue.toFixed(2)} ${profileInfo.salaryCurrency}` : intl.formatMessage({ id: "not-sure-yet" })}</p>
            </div>,
            jobType: <p>
                {
                    profileInfo.jobType
                        ? intl.formatMessage({ id: convertStringToLanguageKey(profileInfo.jobType) })
                        : intl.formatMessage({ id: "not-selected-yet" })
                }
            </p>,
            techStack: <div className="tech-stack-container">
                {profileInfo.technologies.map((skill) =>
                    <div className="tech-stack-item">
                        <p className="subheading-m color-grey">{skill.title}</p>
                    </div>
                )}
            </div>
        }
    }

    const imageSelectedHandler = (e) => {
        const currentFile = e.target.files[0]
        const imgReader = new FileReader()

        imgReader.addEventListener("load", () => {
            const resultImg = imgReader.result
            setCurrentImgSelected(resultImg);
        }, false)

        imgReader.readAsDataURL(currentFile)

        setIsEditImgDialogOpen(true);
    };

    const handleCloseEditImgDialog = () => {
        setIsEditImgDialogOpen(false);
        setCurrentImgSelected(null);
        profileImgRef.current.value = "";
    }

    const handleUpdateAvatar = (imgData) => {
        updateAvatar(imgData, personalInfo._id, agencyId, null, userId);
    };

    const handleDeleteAvatar = () => {
        if (personalInfo?.profilePictureImgUrl && personalInfo?.profilePictureImgUrl !== '') {
            deleteAvatar(personalInfo._id, agencyId, null, userId);
            setCurrentImgSelected('');
        }
    }

    return (
        <div className={classes}>
            <div className="header-wrapper-info">
                <p className="profile-status-text subheading-xl color-grey">Profile status <InfoIcon /></p>
                <CandidateStatusIcon isAdminMode={isAdminMode} status={personalInfo.status} feedback={personalInfo?.publicCandidateFeedback} />
            </div>
            <div className="header-wrapper">
                <div className="header">
                    <div className="candidate-avatar-wrapper">
                        <Avatar className={`candidate-avatar ${isCandidateMode && 'darker'}`} src={personalInfo.profilePictureImgUrl || defaultAvatar} />
                        {(isCandidateMode || isAdminMode) &&
                            <div className="avatar-actions">
                                <IconButton onClick={handleDeleteAvatar}>
                                    <Icon>
                                        <CloseIcon />
                                    </Icon>
                                </IconButton>
                                <input
                                    accept="image/*"
                                    className="upload-input"
                                    id="upload-file-input"
                                    type="file"
                                    onChange={imageSelectedHandler}
                                    ref={profileImgRef}
                                />
                                <IconButton className="edit-icon" onClick={() => { profileImgRef.current.click(); }}>
                                    <Icon>
                                        <EditIconBordered />
                                    </Icon>
                                </IconButton>
                            </div>
                        }
                    </div>
                    <div className="header-info">
                        <Typography variant="h4" className="heading-m color-dark-grey">
                            {`${personalInfo.firstName} ${personalInfo.lastName}`}
                        </Typography>
                        <div className="candidate-email">
                            <Typography variant="h4" className="heading-xs color-grey">
                                {personalInfo.email}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
            {
                ROWS_MAPPER.filter(rowAttributes => rowAttributes.VALUE !== 'techStack' || [JOB_TYPES.FRONT_END_DEVELOPER, JOB_TYPES.BACK_END_DEVELOPER, JOB_TYPES.FULL_STACK_DEVELOPER, JOB_TYPES.MOBILE_DEVELOPER].includes(profileInfo.jobType)).map((rowAttributes, i) => (
                    <div key={i} className={`preferences-row-wrapper ${i === ROWS_MAPPER.length - 1 ? 'last' : ''}`}>
                        <div className="preferences-row">
                            <div className="row-title">
                                <Typography variant="h5" className="preferences-title subheading-xxl color-dark-grey">
                                    {intl.formatMessage({ id: convertStringToLanguageKey(rowAttributes.TITLE) })}
                                </Typography>
                            </div>
                            <div className="row-value">
                                {adjustProfileInfo(profileInfo)[rowAttributes.VALUE]}
                            </div>
                            <div className="job-edit-icon-container">
                                {(isCandidateMode || isAdminMode) &&
                                    <EditIcon onClick={() => handleOpenDialog(rowAttributes.VALUE)} />
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
            <CandidateProfileInfoFormik
                profileInfo={profileInfo}
                updateProfileInfo={updateProfileInfo}
                requiredField={REQUIRED_FIELDS[dialog]}
                positiveFields={POSITIVE_FIELDS[dialog]}>
                {(props) => (
                    currentlyOpenedDialog({ ...props, countries })
                )}
            </CandidateProfileInfoFormik>

            <ImageEditDialog
                dialogTitle={intl.formatMessage({ id: "edit-profile-picture" })}
                isDialogOpen={isEditImgDialogOpen}
                handleCloseDialog={handleCloseEditImgDialog}
                currentImgSelected={currentImgSelected}
                handleUpdateAvatar={handleUpdateAvatar}
                handleDeleteAvatar={handleDeleteAvatar}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    countries: getCountries(state),
    jobsTechnologies: getJobOpportunitiesTechnologies(state),
});

const mapDispatchToProps = {
    updateCandidatePreferences,
    deleteAvatar,
    updateAvatar,
    fetchJobOpportunitiesTechnologies,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfileInfo);
