import React from 'react';

import { ReactComponent as BackIcon } from 'assets/back-icon.svg';

import './PreviousButton.scss'

const PreviousButton = React.forwardRef(({
    text,
    className,
    handleClick,
    ...props
}, ref) => {
    return (
        <div
            className={className ? `previous-button ${className}` : 'previous-button'}
            onClick={handleClick}
            {...props}
        >
            <BackIcon />
            {text}
        </div>
    );
});

export default PreviousButton;
