import { debounce } from '@material-ui/core';
import { ROLES } from 'constants/userConstants';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { parseQueryString, queryObjToString } from 'utils/helpers';
import { FIVE_YEARS_PLUS_EXPERIENCE, initialFilters, VETTED_SINCE_ITEMS, VETTED_SINCE_ANY_TIME, DEFAULT_SEARCH_QUERY } from './ExploreTalentsConstants';

export const adjustFilters = (filters) => {
    const { availableOnly, vettedOnly, vettedSince, ...rest } = filters;
    const adjustedFilters = {
        ...rest,
        mandatorySkills: filters.mandatorySkills.map((skill) => skill.name),
        preferredTechStack: filters.preferredTechStack.map((skill) => skill.name),
        profileType: mapProfileType(filters.profileType),
        experience: filters.experience.map((experience) => mapExperience(experience)),
    };

    if (availableOnly) {
        adjustedFilters.availableOnly = [availableOnly];
    }
    if (vettedOnly) {
        adjustedFilters.vettedOnly = [vettedOnly];

        if (vettedSince.value === VETTED_SINCE_ANY_TIME) {
            adjustedFilters.vettedSince = [];
        } else {
            adjustedFilters.vettedSince = [vettedSince.value]
        }
    }

    return adjustedFilters;
};

export const updateQuery = (queryString, entry) => {
    const parsedQuery = parseQueryString(queryString);
    Object.keys(entry).forEach((key) => (parsedQuery[key] = entry[key]));
    return queryObjToString(parsedQuery);
};

const mapProfileType = (profileType) => {
    const profileTypes = [];

    profileType.forEach(x => {
        switch (x) {
            case 'Candidate':
                profileTypes.push(ROLES.CANDIDATE);
                break;
            case 'Employee':
                profileTypes.push(ROLES.EMPLOYEE);
                profileTypes.push(ROLES.ADMIN);
                break;
            case 'Partner':
                profileTypes.push(ROLES.SUPPLIER_EMPLOYEE);
                profileTypes.push(ROLES.SUPPLIER_ADMIN);
                break
            default:
                break;
        }
    })

    return profileTypes
};

const mapExperience = (experience) => {
    switch (experience) {
        case '< 1 year':
            return '1';
        case '1 - 3 years':
            return '1-3';
        case '3 - 5 years':
            return '3-5';
        case '5 + years':
            return '5';
        default:
            break;
    }
};

const mapQueryToFilters = (parsedQueryString, initialFilters) => {
    const newFilters = {
        ...initialFilters,
    };
    Object.keys(parsedQueryString).forEach((key) => {
        switch (key) {
            case 'searchTerm':
                newFilters[key] = parsedQueryString[key];
                break;
            case 'experience':
                // after the query is parsed the "+" in the 5 + years is replaced with a space
                // here we check if this string is present and if it is, replace it with the correct one
                if (Array.isArray(parsedQueryString[key])) {
                    const index = parsedQueryString[key].indexOf('5   years');
                    if (index !== -1) {
                        parsedQueryString[key][index] = FIVE_YEARS_PLUS_EXPERIENCE;
                    }
                    newFilters[key] = parsedQueryString[key];
                } else {
                    parsedQueryString[key] === '5   years'
                        ? (newFilters[key] = [FIVE_YEARS_PLUS_EXPERIENCE])
                        : (newFilters[key] = [...newFilters[key], parsedQueryString[key]]);
                }
                break;
            case 'availableOnly':
            case 'vettedOnly':
                newFilters[key] = parsedQueryString[key] === 'true';
                break;
            case 'mandatorySkills':
            case 'preferredTechStack':
                Array.isArray(parsedQueryString[key])
                    ? (newFilters[key] = parsedQueryString[key].map((skill) => ({ name: skill })))
                    : (newFilters[key] = [{ name: parsedQueryString[key] }]);
                break;
            case 'vettedSince':
                newFilters[key] = VETTED_SINCE_ITEMS.find((x) => x.name === parsedQueryString[key]);
                break;
            default:
                Array.isArray(parsedQueryString[key])
                    ? (newFilters[key] = [...parsedQueryString[key]])
                    : (newFilters[key] = [parsedQueryString[key]]);
                break;
        }
    });
    const hasParsedUrl = true;
    return [newFilters, hasParsedUrl];
};

const getNewFilters = (filterObj) => {
    const newFilters = {};

    Object.keys(filterObj).forEach((key) => {
        switch (key) {
            case 'searchTerm':
            case 'availableOnly':
            case 'searchInFullCV':
                newFilters[key] = filterObj[key] ? filterObj[key].toString() : '';
                break;
            case 'vettedOnly':
                newFilters[key] = filterObj[key] ? filterObj[key].toString() : '';
                if (!newFilters[key]) {
                    newFilters['vettedSince'] = '';
                }
                break;
            case 'mandatorySkills':
            case 'preferredTechStack':
                newFilters[key] = filterObj[key].map((x) => x.name);
                break;
            case 'vettedSince':
                newFilters[key] = filterObj[key].name;
                break;
            case 'languages':
            case 'location':
            case 'maximumSalary':
                newFilters[key] = filterObj[key][filterObj[key].length - 1];
                break;
            default:
                newFilters[key] = [...filterObj[key]];
                break;
        }
    });

    return newFilters;
};

const useExploreTalentsFilters = () => {
    const history = useHistory();
    const location = useLocation();

    const [hasParsedUrl, setHasParsedUrl] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState(initialFilters);

    const handleReplaceHistoryWithNewFilters = (filterObj) => {
        const newFilters = getNewFilters(filterObj);
        const newQueryString = updateQuery(location.search, newFilters);
        history.replace({ pathname: location.pathname, search: newQueryString });
    };

    const handleReplaceHistoryDebouncedVersion = useCallback(
        debounce((value, name) => {
            const filterObj = {};
            filterObj[name] = value;
            handleReplaceHistoryWithNewFilters(filterObj);
        }, 500),
        [filters]
    );

    const resetFilters = () => history.replace({ pathname: location.pathname, search: DEFAULT_SEARCH_QUERY });

    const handleVettedSinceChange = (e) => {
        const filterObj = {};
        filterObj.vettedSince = VETTED_SINCE_ITEMS.find((x) => x.value === e.target.value);
        handleReplaceHistoryWithNewFilters(filterObj);
    };

    const handleAddSkill = (name) => (skill) => {
        const filterObj = {};
        filterObj[name] = [...filters[name], skill];
        handleReplaceHistoryWithNewFilters(filterObj);
    };

    const handleRemoveSkill = (name) => (skill) => {
        const filterObj = {};
        filterObj[name] = filters[name].filter((x) => x.name !== skill.title);
        handleReplaceHistoryWithNewFilters(filterObj);
    };

    const handleSearchTermChange = (value) => {
        setSearchTerm(value);
        handleReplaceHistoryDebouncedVersion(value, 'searchTerm');
    };

    const handleSwitchChange = (name) => () => {
        const filterObj = {};
        filterObj[name] = !filters[name];
        handleReplaceHistoryWithNewFilters(filterObj);
    };

    const handleFilterChange = (name) => (value) => {
        const currentIndex = filters[name].indexOf(value);
        const filterObj = {};

        if (currentIndex === -1) {
            filterObj[name] = [...filters[name], value];
        } else {
            filterObj[name] = filters[name].filter((x) => x !== value);
        }

        handleReplaceHistoryWithNewFilters(filterObj);
    };

    useEffect(() => {
        const queryString = location.search;
        const parsedQueryString = parseQueryString(queryString);
        const [newFilters, hasParsedUrl] = mapQueryToFilters(parsedQueryString, initialFilters);
        setFilters({ ...newFilters });
        setSearchTerm(newFilters.searchTerm);
        setHasParsedUrl(hasParsedUrl);
    }, [location.search]);

    return [
        hasParsedUrl,
        filters,
        searchTerm,
        handleAddSkill,
        handleRemoveSkill,
        handleSwitchChange,
        handleFilterChange,
        handleSearchTermChange,
        resetFilters,
        handleVettedSinceChange,
    ];
};

const getTalentRequestResetFiltersLink = (agencyRouteName) => {
    return `/${agencyRouteName}/explore-talents${DEFAULT_SEARCH_QUERY}`
}

export { useExploreTalentsFilters, mapProfileType, mapExperience, getTalentRequestResetFiltersLink };
