import React, { createContext, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { fetchSupplierEmployeeProfileInfo } from 'actions/supplierActions';
import { getCurrentSupplierEmployee, getIsRawCvPdfGenerated, getDisplayMode, getSupplierEmployeeOverviewPageLoadingState } from 'reducers';

import HistoricalNotes from 'components/Shared/HistoricalNotes';
import Switcher from 'components/Shared/Switcher';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import PersonalInfoBasicForm from 'components/Person/PersonalInfo/PersonalInfoBasicForm';
import PersonalInfoContactForm from 'components/Person/PersonalInfo/PersonalInfoContactForm';
import TimeTracking from 'components/Person/TimeTracking';
import ProfileCvSection from 'components/Person/Profile/ProfileCvSection';
import Holidays from 'components/Person/Holidays';

import './SupplierEmployee.scss';
import { CircularProgress } from '@material-ui/core';

export const SupplierEmployeeContext = createContext();

const { Provider } = SupplierEmployeeContext;

const SupplierEmployeeWrapper = ({
    agencyId,
    supplierId,
    employeeId,
    fetchSupplierEmployeeProfileInfo,
    isRawCvPdfGenerated,
    removeEmployeeClickHandler,
    openEditStatusDialog,
    selectedEmployee,
    departmentId,
    employee,
    role,
    changeVettedStatusHandler,
    isVetted,
    changeAvailableStatusHandler,
    isAvailable,
    handleTransferToAgencyClick,
    dataFetchState,
    children,
    isDeleted,
    pageLoadingState
}) => {
    const context = {
        agencyId,
        supplierId,
        employeeId,
        departmentId,
        isRawCvPdfGenerated,
        role,
        employee,
        removeEmployeeClickHandler,
        openEditStatusDialog,
        selectedEmployee,
        changeVettedStatusHandler,
        isVetted,
        changeAvailableStatusHandler,
        isAvailable,
        handleTransferToAgencyClick,
        dataFetchState,
        isDeleted,
        pageLoadingState
    };

    useEffect(() => {
        fetchSupplierEmployeeProfileInfo(agencyId, supplierId, employeeId);
    }, []);

    return (
        <Provider value={context}>
            <div className="supplier-employee-profile-content-wrapper">
                {pageLoadingState.isDataLoading || !pageLoadingState.isDataFetched ? <CircularProgress thickness={5} size={50} disableShrink className="page-loader" /> : children}
            </div>
        </Provider>
    )
};

const Actions = () => {
    const intl = useIntl();

    const {
        removeEmployeeClickHandler,
        changeVettedStatusHandler,
        isVetted,
        changeAvailableStatusHandler,
        isAvailable,
        isDeleted,
    } = useContext(SupplierEmployeeContext);

    return (
        <div className="additional-actions-wrapper">
            <div className="switch-block">
                <div className="switch profile-vetted">
                    <Switcher
                        isChecked={isVetted}
                        changeStatusHandler={changeVettedStatusHandler}
                        label={intl.formatMessage({ id: "profile-vetted" })}
                    />
                </div>

                <div className="switch currently-available">
                    <Switcher
                        isChecked={isAvailable}
                        changeStatusHandler={changeAvailableStatusHandler}
                        label={intl.formatMessage({ id: "currently-available" })}
                    />
                </div>
            </div>

            {!isDeleted &&
                <div className="buttons-wrapper">
                    <SecondaryButton buttonStyle="delete" text={intl.formatMessage({ id: "delete" })} className="btn" handleClick={removeEmployeeClickHandler} />
                </div>
            }
        </div>
    )
};

const BasicForm = () => {
    const { employeeId, agencyId } = useContext(SupplierEmployeeContext);

    return (
        <PersonalInfoBasicForm employeeId={employeeId} agencyId={agencyId} />
    );
};

const ContactInfo = () => {
    const { employeeId, agencyId, departmentId } = useContext(SupplierEmployeeContext);

    return (
        <PersonalInfoContactForm
            agencyId={agencyId}
            employeeId={employeeId}
            isSupplierEmployee={true}
            departmentId={departmentId}
        />
    )
};

const TimeTrackingWrapper = () => {
    const { employeeId, agencyId, departmentId } = useContext(SupplierEmployeeContext);

    return <TimeTracking userId={employeeId} agencyId={agencyId} departmentId={departmentId} />;
};

const CvSection = () => {
    const { employeeId, agencyId, dataFetchState } = useContext(SupplierEmployeeContext);

    return (
        <ProfileCvSection employeeId={employeeId} agencyId={agencyId} dataFetchState={dataFetchState} isEmployee={true} />
    )
};

const HolidaysWrapper = () => {
    const { employeeId, departmentId, ...props } = useContext(SupplierEmployeeContext);

    return (
        <Holidays {...props} userId={employeeId} departmentId={departmentId} isSupplierEmployee={true} />
    )
}


SupplierEmployeeWrapper.Actions = Actions;
SupplierEmployeeWrapper.HistoricalNotes = HistoricalNotes;
SupplierEmployeeWrapper.BasicForm = BasicForm;
SupplierEmployeeWrapper.ContactForm = ContactInfo;
SupplierEmployeeWrapper.TimeTracking = TimeTrackingWrapper;
SupplierEmployeeWrapper.Holidays = HolidaysWrapper;

SupplierEmployeeWrapper.CvSection = CvSection;

const mapStateToProps = (state) => ({
    employee: getCurrentSupplierEmployee(state),
    isRawCvPdfGenerated: getIsRawCvPdfGenerated(state),
    role: getDisplayMode(state),
    pageLoadingState: getSupplierEmployeeOverviewPageLoadingState(state),
});

const mapDispatchToProps = {
    fetchSupplierEmployeeProfileInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierEmployeeWrapper);
