// Auth
export const LOGIN_FETCH_SUCCESS = 'LOGIN_FETCH_SUCCESS';
export const AUTH_BASIC_INFO_UPDATE_SUCCESS = 'AUTH_BASIC_INFO_UPDATE_SUCCESS';
export const REGISTER_FETCH_SUCCESS = 'REGISTER_FETCH_SUCCESS';
export const LOGOUT_FETCH_SUCCESS = 'LOGOUT_FETCH_SUCCESS';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
export const PASSWORD_RESET_EMAIL_SEND_SUCCESS = 'PASSWORD_RESET_EMAIL_SEND_SUCCESS';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const ONBOARDING_FETCH_SUCCESS = 'ONBOARDING_FETCH_SUCCESS';
export const ONBOARDING_UPDATE_SUCCESS = 'ONBOARDING_UPDATE_SUCCESS';
export const CLIENT_INV_STATUS_DONE_FETCH_SUCCESS = 'CLIENT_INV_STATUS_DONE_FETCH_SUCCESS';
export const AUTH_USER_AVATAR_EDIT_SUCCESS = 'AUTH_USER_AVATAR_EDIT_SUCCESS';
export const AUTH_USER_POSITION_EDIT_SUCCESS = 'AUTH_USER_POSITION_EDIT_SUCCESS';
export const TERMS_AND_CONDITIONS_FETCH_SUCCESS = 'TERMS_AND_CONDITIONS_FETCH_SUCCESS';
export const TERMS_AND_CONDITIONS_UPDATE_SUCCESS = 'TERMS_AND_CONDITIONS_UPDATE_SUCCESS';
export const REGISTER_CLIENT_FETCH_SUCCESS = 'REGISTER_CLIENT_FETCH_SUCCESS';
export const CANDIDATE_JOURNEY_START_INFORMATION_FETCH_SUCCESS = 'CANDIDATE_JOURNEY_START_INFORMATION_FETCH_SUCCESS';
export const CANDIDATE_JOURNEY_START_INFORMATION_UPDATE_SUCCESS = 'CANDIDATE_JOURNEY_START_INFORMATION_UPDATE_SUCCESS';
export const CANDIDATE_REGISTER_FETCH_SUCCESS = 'CANDIDATE_REGISTER_FETCH_SUCCESS';
export const ACCESS_AS_MANAGER_SET_SUCCESS = 'ACCESS_AS_MANAGER_SET_SUCCESS';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_INFORMATION_FETCH_SUCCESS = 'USER_INFORMATION_FETCH_SUCCESS';
export const USER_HAS_NEEDED_EVROTRUST_INFO_SUCCESS = 'USER_HAS_NEEDED_EVROTRUST_INFO_SUCCESS';

// Employee
export const EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS = 'EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS';
export const EMPLOYEE_REGISTER_FETCH_SUCCESS = 'EMPLOYEE_REGISTER_FETCH_SUCCESS';
export const EMPLOYEES_FETCH_SUCCESS = 'EMPLOYEES_FETCH_SUCCESS';
export const EMPLOYEES_FETCH_PENDING = 'EMPLOYEES_FETCH_PENDING';
export const TRANSFERRED_CANDIDATES_FETCH_SUCCESS = 'TRANSFERRED_CANDIDATES_FETCH_SUCCESS';
export const TRANSFERRED_CANDIDATES_FETCH_PENDING = 'TRANSFERRED_CANDIDATES_FETCH_PENDING';
export const CHECKLISTED_CANDIDATES_FETCH_SUCCESS = 'CHECKLISTED_CANDIDATES_FETCH_SUCCESS';
export const CANDIDATES_FETCH_SUCCESS = 'CANDIDATES_FETCH_SUCCESS';
export const PROFILE_FETCH_SUCCESS = 'PROFILE_FETCH_SUCCESS';
export const PROFILE_DETAILS_FETCH_SUCCESS = 'PROFILE_DETAILS_FETCH_SUCCESS';
export const SKILL_SUGGESTIONS_FETCH_SUCCESS = 'SKILL_SUGGESTIONS_FETCH_SUCCESS';
export const SKILL_SUGGESTIONS_FETCH_PENDING = 'SKILL_SUGGESTIONS_FETCH_PENDING';
export const SKILL_SUGGESTIONS_INITIAL_STATE_SET = 'SKILL_SUGGESTIONS_INITIAL_STATE_SET';
export const PERSONAL_INFO_FETCH_SUCCESS = 'PERSONAL_INFO_FETCH_SUCCESS';
export const PERSONAL_INFO_INITIAL_STATE_SET = 'PERSONAL_INFO_INITIAL_STATE_SET';
export const CONTACT_INFO_UPDATE_SUCCESS = 'CONTACT_INFO_UPDATE_SUCCESS';
export const PERSONAL_INFO_UPDATE_SUCCESS = 'PERSONAL_INFO_UPDATE_SUCCESS';
export const BASIC_INFO_UPDATE_SUCCESS = 'BASIC_INFO_UPDATE_SUCCESS';
export const EMPLOYMENT_INFO_GET_SUCCESS = 'EMPLOYMENT_INFO_GET_SUCCESS';
export const EMPLOYMENT_INFO_UPDATE_SUCCESS = 'EMPLOYMENT_INFO_UPDATE_SUCCESS';
export const EMPLOYMENT_ACTIVE_COMPENSETION_EDIT_SUCCESS = 'EMPLOYMENT_ACTIVE_COMPENSETION_EDIT_SUCCESS';
export const EMPLOYMENT_BANK_DETAILS_EDIT_SUCCESS = 'EMPLOYMENT_BANK_DETAILS_EDIT_SUCCESS';
export const EMPLOYEE_CONTRACT_INFO_EDIT_SUCCESS = 'EMPLOYEE_CONTRACT_INFO_EDIT_SUCCESS';
export const EMPLOYEE_CONTRACT_INFO_FETCH_SUCCESS = 'EMPLOYEE_CONTRACT_INFO_FETCH_SUCCESS';
export const EMPLOYEES_PAGES_EDIT_SUCCESS = 'EMPLOYEES_PAGES_EDIT_SUCCESS';
export const GENERAL_INFO_EDIT_SUCCESS = 'GENERAL_INFO_EDIT_SUCCESS';
export const PERSONAL_AVATAR_EDIT_SUCCESS = 'PERSONAL_AVATAR_EDIT_SUCCESS';
export const PERSONAL_AVATAR_DELETE_SUCCESS = 'PERSONAL_AVATAR_DELETE_SUCCESS';
export const HOLIDAY_ALLOWANCE_EDIT_SUCCESS = 'HOLIDAY_ALLOWANCE_EDIT_SUCCESS';
export const EMPLOYMENT_INFO_GET_PENDING = 'EMPLOYMENT_INFO_GET_PENDING';
export const HOLIDAYS_APPROVE_REQUESTS_SUCCESS = 'HOLIDAYS_APPROVE_REQUESTS_SUCCESS';
export const HOLIDAY_ALLOWANCE_FETCH_SUCCESS = 'HOLIDAY_ALLOWANCE_FETCH_SUCCESS';
export const EMPLYOEE_HOLIDAYS_DELETE_REQUESTS_SUCCESS = 'EMPLYOEE_HOLIDAYS_DELETE_REQUESTS_SUCCESS';
export const EMPLYOEE_HOLIDAYS_DELETE_PENDING_REQUESTS_SUCCESS = 'EMPLYOEE_HOLIDAYS_DELETE_PENDING_REQUESTS_SUCCESS';
export const AGENCY_EMPLOYEE_HOME_INFORMATION_FETCH_SUCCESS = 'AGENCY_EMPLOYEE_HOME_INFORMATION_FETCH_SUCCESS';
export const EMPLOYEE_ROLE_UPDATE_SUCCESS = 'EMPLOYEE_ROLE_UPDATE_SUCCESS';
export const KNOW_YOUR_TEAM_SAMPLE_REFRESH_FETCH_SUCCESS = 'KNOW_YOUR_TEAM_SAMPLE_REFRESH_FETCH_SUCCESS';
export const EMPLOYEE_TEAM_FETCH_SUCCESS = 'EMPLOYEE_TEAM_FETCH_SUCCESS';
export const KNOW_YOUR_TEAM_SAMPLE_FETCH_SUCCESS = 'KNOW_YOUR_TEAM_SAMPLE_FETCH_SUCCESS';
export const ASSIGNEE_INFO_FETCH_SUCCESS = 'ASSIGNEE_INFO_FETCH_SUCCESS';
export const EMPLOYEE_EXPERIENCE_SINCE_UPDATE_SUCCESS = 'EMPLOYEE_EXPERIENCE_SINCE_UPDATE_SUCCESS';
export const CANDIDATE_PROFILE_PAGE_UNMOUNTED_SUCCESS = 'CANDIDATE_PROFILE_PAGE_UNMOUNTED_SUCCESS';
export const CANDIDATE_PROFILE_PAGE_FETCH_SUCCESS = 'CANDIDATE_PROFILE_PAGE_FETCH_SUCCESS';
export const CANDIDATE_JOURNEY_INFO_FETCH_SUCCESS = 'CANDIDATE_JOURNEY_INFO_FETCH_SUCCESS';
export const CANDIDATE_VIEWED_JOB_UPDATE_SUCCESS = 'CANDIDATE_VIEWED_JOB_UPDATE_SUCCESS';
export const CANDIDATE_VIEWED_JOB_FETCH_SUCCESS = 'CANDIDATE_VIEWED_JOB_FETCH_SUCCESS';
export const CANDIDATE_PROFILE_STEPS_FETCH_SUCCESS = 'CANDIDATE_PROFILE_STEPS_FETCH_SUCCESS';
export const CANDIDATE_PROFILE_STEPS_UPDATE_SUCCESS = 'CANDIDATE_PROFILE_STEPS_UPDATE_SUCCESS';
export const CANDIDATE_STATUS_HISTORY_FETCH_SUCCESS = 'CANDIDATE_STATUS_HISTORY_FETCH_SUCCESS';
export const CANDIDATE_APPLICATION_PROCESS_INFORMAITON_FETCH_SUCCESS = 'CANDIDATE_APPLICATION_PROCESS_INFORMAITON_FETCH_SUCCESS';
export const CANDIDATE_APPLICATION_PROCESS_INFORMAITON_UPDATE_SUCCESS = 'CANDIDATE_APPLICATION_PROCESS_INFORMAITON_UPDATE_SUCCESS';
export const EMPLOYEE_GENERAL_INFO_FETCH_SUCCESS = 'EMPLOYEE_GENERAL_INFO_FETCH_SUCCESS';
export const EMPLOYEES_TOTAL_COUNT_FETCH_SUCCESS = 'EMPLOYEES_TOTAL_COUNT_FETCH_SUCCESS';
export const EMPLOYEES_TOTAL_COUNT_FETCH_PENDING = 'EMPLOYEES_TOTAL_COUNT_FETCH_PENDING';
export const EMPLOYMENT_START_DATE_UPDATE_SUCCESS = 'EMPLOYMENT_START_DATE_UPDATE_SUCCESS';
export const USER_TERMINATION_DATE_UPDATE_SUCCESS = 'USER_TERMINATION_DATE_UPDATE_SUCCESS';
export const QUERIED_TEAM_RESET_FETCH_SUCCESS = 'QUERIED_TEAM_RESET_FETCH_SUCCESS';
export const TEAM_PAGE_DATA_FETCH_SUCCESS = 'TEAM_PAGE_DATA_FETCH_SUCCESS';

export const EDUCATION_ENTRY_ADD_SUCCESS = 'EDUCATION_ENTRY_ADD_SUCCESS';
export const EDUCATION_ENTRY_EDIT_SUCCESS = 'EDUCATION_ENTRY_EDIT_SUCCESS';
export const EDUCATION_ENTRIES_UPDATE_SUCCESS = 'EDUCATION_ENTRIES_UPDATE_SUCCESS';
export const EDUCATION_ENTRY_REMOVE_SUCCESS = 'EDUCATION_ENTRY_REMOVE_SUCCESS';

export const REVIEW_CREATE_SUCCESS = 'REVIEW_CREATE_SUCCESS';
export const REVIEW_EDIT_SUCCESS = 'REVIEW_EDIT_SUCCESS';
export const REVIEW_DELETE_SUCCESS = 'REVIEW_DELETE_SUCCESS';
export const REVIEW_GET_ALL_SUCCESS = 'REVIEW_GET_ALL_SUCCESS';

export const PROFILE_SKILLS_UPDATE_SUCCESS = 'PROFILE_SKILLS_UPDATE_SUCCESS';
export const PROFILE_SKILL_DELETE_SUCCESS = 'PROFILE_SKILL_DELETE_SUCCESS';
export const PROFILE_SKILL_CREATE_SUCCESS = 'PROFILE_SKILL_CREATE_SUCCESS';

export const LANGUAGE_ADD_SUCCESS = 'LANGUAGE_ADD_SUCCESS';
export const LANGUAGE_EDIT_SUCCESS = 'LANGUAGE_EDIT_SUCCESS';
export const LANGUAGE_REMOVE_SUCCESS = 'LANGUAGE_REMOVE_SUCCESS';

export const DAYS_TRACK_SUCCESS = 'DAYS_TRACK_SUCCESS';
export const TRACKED_DAYS_FETCH_SUCCESS = 'TRACKED_DAYS_FETCH_SUCCESS';
export const TIMESHEET_EXPORT_SUCCESS = 'TIMESHEET_EXPORT_SUCCESS';

export const EMPLOYEE_PROJECT_ENTRY_GET_SUCCESS = 'EMPLOYEE_PROJECT_ENTRY_GET_SUCCESS';
export const EMPLOYEE_PROJECT_ENTRY_ADD_SUCCESS = 'EMPLOYEE_PROJECT_ENTRY_ADD_SUCCESS';
export const EMPLOYEE_PROJECT_ENTRY_EDIT_SUCCESS = 'EMPLOYEE_PROJECT_ENTRY_EDIT_SUCCESS';
export const EMPLOYEE_PROJECT_ENTRY_REMOVE_SUCCESS = 'EMPLOYEE_PROJECT_ENTRY_REMOVE_SUCCESS';
export const EMPLOYEE_PROJECTS_UPDATE_SUCCESS = 'EMPLOYEE_PROJECTS_UPDATE_SUCCESS';
export const EMPLOYEE_CURRENT_PROJECT_ENTRY_CLEAR = 'EMPLOYEE_CURRENT_PROJECT_ENTRY_CLEAR';
export const PROJECT_TEMPLATE_ADD_SUCCESS = 'PROJECT_TEMPLATE_ADD_SUCCESS';
export const PROJECT_TEMPLATE_EDIT_SUCCESS = 'PROJECT_TEMPLATE_EDIT_SUCCESS';
export const PROJECT_TEMPLATES_FETCH_SUCCESS = 'PROJECT_TEMPLATES_FETCH_SUCCESS';
export const PROJECT_TEMPLATE_GET_SUCCESS = 'PROJECT_TEMPLATE_GET_SUCCESS';
export const EMPLOYEE_UPLOADED_CV_FETCH_SUCCESS = 'EMPLOYEE_UPLOADED_CV_FETCH_SUCCESS';

export const CERTIFICATE_ADD_SUCCESS = 'CERTIFICATE_ADD_SUCCESS';
export const CERTIFICATE_EDIT_SUCCESS = 'CERTIFICATE_EDIT_SUCCESS';
export const CERTIFICATES_UPDATE_SUCCESS = 'CERTIFICATES_UPDATE_SUCCESS';

export const CERTIFICATE_REMOVE_SUCCESS = 'CERTIFICATE_REMOVE_SUCCESS';

export const CV_EXPORT_SUCCESS = 'CV_EXPORT_SUCCESS';

export const TASK_ADD_SUCCESS = 'TASK_ADD_SUCCESS';
export const TASK_EDIT_SUCCESS = 'TASK_EDIT_SUCCESS';
export const TASK_SAVE_SUCCESS = 'TASK_SAVE_SUCCESS';
export const TASKS_FETCH_SUCCESS = 'TASKS_FETCH_SUCCESS';

export const SWITCH_MODE = 'SWITCH_MODE';

export const NOTIFICATION_TRIGGER = 'NOTIFICATION_TRIGGER';
export const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';

export const CANDIDATE_PREFERENCES_FETCH_SUCCESS = 'CANDIDATE_PREFERENCES_FETCH_SUCCESS';
export const CANDIDATE_PREFERENCES_UPDATE_SUCCESS = 'CANDIDATE_PREFERENCES_UPDATE_SUCCESS';
export const RAW_CV_GENERATED_UPDATE_SUCCESS = 'RAW_CV_GENERATED_UPDATE_SUCCESS';
export const RAW_CV_PROGRESS_DATA_FETCH_SUCCESS = 'RAW_CV_PROGRESS_DATA_FETCH_SUCCESS';
export const RAW_CV_PROGRESS_DATA_FETCHING_SUCCESS = 'RAW_CV_PROGRESS_DATA_FETCHING_SUCCESS';

export const CANDIDATE_APPLICATIONS_FETCH_SUCCESS = 'CANDIDATE_APPLICATIONS_FETCH_SUCCESS';
export const CANDIDATE_ASSIGNEE_UPDATE_SUCCESS = 'CANDIDATE_ASSIGNEE_UPDATE_SUCCESS';

export const CANDIDATE_LINKEDIN_URL_ADD_SUCCESS = 'CANDIDATE_LINKEDIN_URL_ADD_SUCCESS';

export const CANDIDATE_CRITERIA_FETCH_SUCCESS = 'CANDIDATE_CRITERIA_FETCH_SUCCESS';
export const CANDIDATE_CRITERIA_UPDATE_SUCCESS = 'CANDIDATE_CRITERIA_UPDATE_SUCCESS';

export const CANDIDATE_BOOK_INTERVIEW_SUCCESS = 'CANDIDATE_BOOK_INTERVIEW_SUCCESS';

export const ADMIN_DASHBOARD_EMPLOYEES_BY_COUNTRY_FETCH_SUCCESS = 'ADMIN_DASHBOARD_EMPLOYEES_BY_COUNTRY_FETCH_SUCCESS';
export const ADMIN_DASHBOARD_EMPLOYEES_BY_UTILISATION_FETCH_SUCCESS = 'ADMIN_DASHBOARD_EMPLOYEES_BY_UTILISATION_FETCH_SUCCESS';
export const ADMIN_DASHBOARD_RECENT_STARTERS_FETCH_SUCCESS = 'ADMIN_DASHBOARD_RECENT_STARTERS_FETCH_SUCCESS';
export const ADMIN_DASHBOARD_RECENT_LEAVERS_FETCH_SUCCESS = 'ADMIN_DASHBOARD_RECENT_LEAVERS_FETCH_SUCCESS';
export const USER_TRANSFER_SUCCESS = 'USER_TRANSFER_SUCCESS';
export const OCCUPATION_STATUS_UPDATE_SUCCESS = 'OCCUPATION_STATUS_UPDATE_SUCCESS';
export const RECRUITER_UPDATE_SUCCESS = 'RECRUITER_UPDATE_SUCCESS';
export const CANDIDATE_CONTRACTOR_FILTERS = 'CANDIDATE_CONTRACTOR_FILTERS';
export const CV_PAGE_UPDATE_SUCCESS = 'CV_PAGE_UPDATE_SUCCESS';
export const CV_PAGE_UPDATING_SUCCESS = 'CV_PAGE_UPDATING_SUCCESS';

export const EMPLOYEE_OVERVIEW_INFORMATION_FETCH_SUCCESS = 'EMPLOYEE_OVERVIEW_INFORMATION_FETCH_SUCCESS';

export const PROFILE_CREATION_STEPS_FETCH_SUCCESS = 'PROFILE_CREATION_STEPS_FETCH_SUCCESS';

// Video Interview
export const VIDEO_INTERVIEW_QUESTIONS_FETCH_SUCCESS = 'VIDEO_INTERVIEW_QUESTIONS_FETCH_SUCCESS';

export const VIDEO_INTERVIEW_ANSWER_FETCH_SUCCESS = 'VIDEO_INTERVIEW_ANSWER_FETCH_SUCCESS';

// Holidays
export const EMPLOYEE_HOLIDAYS_FETCH_SUCCESS = 'EMPLOYEE_HOLIDAYS_FETCH_SUCCESS';
export const EMPLOYEE_HOLIDAYS_REJECT_REQUEST_SUCCESS = 'EMPLOYEE_HOLIDAYS_REJECT_REQUEST_SUCCESS';
export const EMPLOYEE_HOLIDAYS_APPROVE_REQUESTS_SUCCESS = 'EMPLOYEE_HOLIDAYS_APPROVE_REQUESTS_SUCCESS';
export const EMPLOYEE_HOLIDAYS_ALLOWANCE_FETCH_SUCCESS = 'EMPLOYEE_HOLIDAYS_ALLOWANCE_FETCH_SUCCESS';
export const EMPLOYEE_HOME_OFFICE_ALLOWANCE_FETCH_SUCCESS = 'EMPLOYEE_HOME_OFFICE_ALLOWANCE_FETCH_SUCCESS';
export const EMPLOYEE_HOME_OFFICE_REMAINING_REQUESTS_FETCH_SUCCESS = 'EMPLOYEE_HOME_OFFICE_REMAINING_REQUESTS_FETCH_SUCCESS';
export const EMPLOYEE_HOME_OFFICE_ALLOWANCE_UPDATE_SUCCESS = 'EMPLOYEE_HOME_OFFICE_ALLOWANCE_UPDATE_SUCCESS';
export const EMPLOYEE_HOME_OFFICE_REMAINING_REQUESTS_UPDATE_SUCCESS = 'EMPLOYEE_HOME_OFFICE_REMAINING_REQUESTS_UPDATE_SUCCESS';
export const HOLIDAY_REQUEST_CREATE_SUCCESS = 'HOLIDAY_REQUEST_CREATE_SUCCESS';
export const AGENCY_PENDING_HOLIDAYS_FETCH_SUCCESS = 'AGENCY_PENDING_HOLIDAYS_FETCH_SUCCESS';
export const AGENCY_PENDING_HOLIDAYS_REPLACE_SUCCESS = 'AGENCY_PENDING_HOLIDAYS_REPLACE_SUCCESS';
export const AGENCY_HOLIDAYS_REJECT_REQUEST_SUCCESS = ' AGENCY_HOLIDAYS_REJECT_REQUEST_SUCCESS';
export const AGENCY_HOLIDAYS_APPROVE_REQUESTS_SUCCESS = 'AGENCY_HOLIDAYS_APPROVE_REQUESTS_SUCCESS';
export const AGENCY_APPROVED_HOLIDAYS_FETCH_SUCCESS = 'AGENCY_APPROVED_HOLIDAYS_FETCH_SUCCESS';
export const AGENCY_REVIEWED_HOLIDAYS_FETCH_SUCCESS = 'AGENCY_REVIEWED_HOLIDAYS_FETCH_SUCCESS';
export const AGENCY_REVIEWED_HOLIDAYS_REPLACE_SUCCESS = 'AGENCY_REVIEWED_HOLIDAYS_REPLACE_SUCCESS';
export const AGENCY_SICK_LEAVES_FETCH_SUCCESS = 'AGENCY_SICK_LEAVES_FETCH_SUCCESS';
export const AGENCY_WORK_LEAVES_FETCH_SUCCESS = 'AGENCY_WORK_LEAVES_FETCH_SUCCESS';
export const AGENCY_HOLIDAYS_REQUESTS_UPDATE_SUCCESS = 'AGENCY_HOLIDAYS_REQUESTS_UPDATE_SUCCESS';
export const EMPLOYEE_PENDING_HOLIDAYS_COUNT_FETCH_SUCCESS = 'EMPLOYEE_PENDING_HOLIDAYS_COUNT_FETCH_SUCCESS';
export const AGENCY_PENDING_HOLIDAYS_COUNT_FETCH_SUCCESS = 'AGENCY_PENDING_HOLIDAYS_COUNT_FETCH_SUCCESS';

// EMPLOYEE RELATED OPPORTUNITIES
export const EMPLOYEE_RELATED_OPPORTUNITIES_FETCH_SUCCESS = 'EMPLOYEE_RELATED_OPPORTUNITIES_FETCH_SUCCESS';
export const EMPLOYEE_RELATED_OPPORTUNITIES_UPDATE_SUCCESS = 'EMPLOYEE_RELATED_OPPORTUNITIES_UPDATE_SUCCESS';
export const JOBS_PAGE_ALL_OPPORTUNITIES_FETCH_SUCCESS = 'JOBS_PAGE_ALL_OPPORTUNITIES_FETCH_SUCCESS';
export const JOBS_PAGE_MATCHING_OPPORTUNITIES_FETCH_SUCCESS = 'JOBS_PAGE_MATCHING_OPPORTUNITIES_FETCH_SUCCESS';

// Agency
export const BASIC_AGENCY_INFO_UPDATE_SUCCESS = 'BASIC_AGENCY_INFO_UPDATE_SUCCESS';
export const EVROTRUST_AGENCY_INFO_UPDATE_SUCCESS = 'EVROTRUST_AGENCY_INFO_UPDATE_SUCCESS';
export const EVROTRUST_AGENCY_INFO_FETCH_SUCCESS = 'EVROTRUST_AGENCY_INFO_FETCH_SUCCESS';
export const EVROTRUST_AGENCY_USAGE_UPDATE_SUCCESS = 'EVROTRUST_AGENCY_USAGE_UPDATE_SUCCESS';
export const EVROTRUST_AGENCY_INTEGRATION_FETCH_SUCCESS = 'EVROTRUST_AGENCY_INTEGRATION_FETCH_SUCCESS';
export const CALENDLY_AGENCY_INFO_UPDATE_SUCCESS = 'CALENDLY_AGENCY_INFO_UPDATE_SUCCESS';
export const CALENDLY_AGENCY_INFO_FETCH_SUCCESS = 'CALENDLY_AGENCY_INFO_FETCH_SUCCESS';
export const AGENCY_INFO_FETCH_SUCCESS = 'AGENCY_INFO_FETCH_SUCCESS';
export const AGENCY_LOGO_UPLOAD_SUCCESS = 'AGENCY_LOGO_UPLOAD_SUCCESS';
export const AGENCY_LOGO_DELETE_SUCCESS = 'AGENCY_LOGO_DELETE_SUCCESS';
export const AGENCY_EMPLOYEE_SETTINGS_FETCH_SUCCESS = 'AGENCY_EMPLOYEE_SETTINGS_FETCH_SUCCESS';
export const AGENCY_HOLIDAY_ALLOWANCE_UPDATE_SUCCESS = 'AGENCY_HOLIDAY_ALLOWANCE_UPDATE_SUCCESS';
export const AGENCY_PERFORMANCE_CRITERIAS_UPDATE_SUCCESS = 'AGENCY_PERFORMANCE_CRITERIAS_UPDATE_SUCCESS';
export const AGENCY_PERFORMANCE_CRITERIAS_FETCH_SUCCESS = 'AGENCY_PERFORMANCE_CRITERIAS_FETCH_SUCCESS';
export const AGENCY_HOME_INFORMATION_FETCH_SUCCESS = 'AGENCY_HOME_INFORMATION_FETCH_SUCCESS';
export const AGENCY_ADMINS_FETCH_SUCCESS = 'AGENCY_ADMINS_FETCH_SUCCESS';
export const AGENCY_IMPORTANT_DOCUMENTS_UPDATE_SUCCESS = 'AGENCY_IMPORTANT_DOCUMENTS_UPDATE_SUCCESS';
export const AGENCY_IMPORTANT_DOCUMENTS_FETCH_SUCCESS = 'AGENCY_IMPORTANT_DOCUMENTS_FETCH_SUCCESS';
export const AGENCY_TASK_EDIT_SUCCESS = 'AGENCY_TASK_EDIT_SUCCESS';
export const AGENCY_TASK_DELETE_SUCCESS = 'AGENCY_TASK_DELETE_SUCCESS';
export const ALL_TASKS_FETCH_SUCCESS = 'ALL_TASKS_FETCH_SUCCESS';
export const AGENCY_INTERVIEW_INTRO_URL_FETCH_SUCCESS = 'AGENCY_INTERVIEW_INTRO_URL_FETCH_SUCCESS';
export const DELETED_USERS_REPLACE_SUCCESS = 'DELETED_USERS_REPLACE_SUCCESS';
export const DELETED_USERS_FETCH_SUCCESS = 'DELETED_USERS_FETCH_SUCCESS';
export const AGENCY_NAME_FETCH_SUCCESS = 'AGENCY_NAME_FETCH_SUCCESS';
export const AGENCY_MAIN_ADMIN_FETCH_SUCCESS = 'AGENCY_MAIN_ADMIN_FETCH_SUCCESS';
export const USER_PERMANENTLY_DELETE_SUCCESS = 'USER_PERMANENTLY_DELETE_SUCCESS';
export const UI_DEPENDENCIES_FETCH_SUCCESS = 'UI_DEPENDENCIES_FETCH_SUCCESS';
export const AGENCY_SETTINGS_FETCH_SUCCESS = 'AGENCY_SETTINGS_FETCH_SUCCESS';
export const AGENCY_SETTINGS_UPDATE_SUCCESS = 'AGENCY_SETTINGS_UPDATE_SUCCESS';
export const AGENCY_UPCOMING_BIRTHDAYS_FETCH_SUCCESS = 'AGENCY_UPCOMING_BIRTHDAYS_FETCH_SUCCESS';

//Agency Documents
export const AGENCY_DOCUMENTS_FETCH_SUCCESS = 'AGENCY_DOCUMENTS_FETCH_SUCCESS';
export const AGENCY_DOCUMENTS_REPLACE_SUCCESS = 'AGENCY_DOCUMENTS_REPLACE_SUCCESS';

// Trainings
export const AGENCY_TRAININGS_FETCH_SUCCESS = 'AGENCY_TRAININGS_FETCH_SUCCESS';
export const AGENCY_TRAININGS_ADD_SUCCESS = 'AGENCY_TRAININGS_ADD_SUCCESS';
export const AGENCY_TRAINING_REMOVE_SUCCESS = 'AGENCY_TRAINING_REMOVE_SUCCESS';
export const AGENCY_TRAINING_UPDATE_SUCCESS = 'AGENCY_TRAINING_UPDATE_SUCCESS';
export const TRAINING_TOPIC_ADD_SUCCESS = 'TRAINING_TOPIC_ADD_SUCCESS';
export const TRAINING_TOPIC_REMOVE_SUCCESS = 'TRAINING_TOPIC_REMOVE_SUCCESS';
export const TRAINING_FETCH_SUCCESS = 'TRAINING_FETCH_SUCCESS';
export const TRAINING_TOPIC_UPDATE_SUCCESS = 'TRAINING_TOPIC_UPDATE_SUCCESS';
export const AGENCY_PROJECTS_FETCH_SUCCESS = 'AGENCY_PROJECTS_FETCH_SUCCESS';

// Trainings Topic Videos 
export const AGENCY_TRAINING_TOPIC_VIDEOS_FETCH_SUCCESS = 'AGENCY_TRAINING_TOPIC_VIDEOS_FETCH_SUCCESS';
export const AGENCY_TRAINING_TOPIC_VIDEO_ADD_SUCCESS = 'AGENCY_TRAINING_TOPIC_VIDEO_ADD_SUCCESS';
export const AGENCY_TRAINING_TOPIC_VIDEO_REMOVE_SUCCESS = 'AGENCY_TRAINING_TOPIC_VIDEO_REMOVE_SUCCESS';

// Clients
export const CLIENT_REGISTER_FETCH_SUCCESS = 'CLIENT_REGISTER_FETCH_SUCCESS';
export const CLIENT_FETCH_ALL_SUCCESS = 'CLIENT_FETCH_ALL_SUCCESS';
export const CLIENT_PAGE_FETCH_SUCCESS = 'CLIENT_PAGE_FETCH_SUCCESS';
export const CLIENTS_DELETE_SUCCESS = 'CLIENTS_DELETE_SUCCESS';

// Client
export const CLIENT_INFORMATION_FETCH_SUCCESS = 'CLIENT_INFORMATION_FETCH_SUCCESS';
export const CLIENT_AVATAR_UPDATE_SUCCESS = 'CLIENT_AVATAR_UPDATE_SUCCESS';
export const CLIENT_AVATAR_DELETE_SUCCESS = 'CLIENT_AVATAR_DELETE_SUCCESS';
export const CLIENT_BASIC_INFORMATION_UPDATE_SUCCESS = 'CLIENT_BASIC_INFORMATION_UPDATE_SUCCESS';
export const CLIENT_CONTACT_INFORMATION_UPDATE_SUCCESS = 'CLIENT_CONTACT_INFORMATION_UPDATE_SUCCESS';
export const CLIENT_INVOICE_INFORMATION_UPDATE_SUCCESS = 'CLIENT_INVOICE_INFORMATION_UPDATE_SUCCESS';
export const CLIENT_PROJECT_ADD_SUCCESS = 'CLIENT_PROJECT_ADD_SUCCESS';
export const CLIENT_PROJECT_UPDATE_SUCCESS = 'CLIENT_PROJECT_UPDATE_SUCCESS';
export const CLIENT_PROJECTS_FETCH_SUCCESS = 'CLIENT_PROJECTS_FETCH_SUCCESS';
export const CLIENT_INVOICING_FETCH_SUCCESS = 'CLIENT_INVOICING_FETCH_SUCCESS';
export const CLIENT_TOTAL_REVENUE_FETCH_SUCCESS = 'CLIENT_TOTAL_REVENUE_FETCH_SUCCESS';
export const EMPLOYEE_TO_PROJECT_ADD_SUCCESS = 'EMPLOYEE_TO_PROJECT_ADD_SUCCESS';
export const DEDICATED_EMPLOYEE_TO_PROJECT_ADD_SUCCESS = 'DEDICATED_EMPLOYEE_TO_PROJECT_ADD_SUCCESS';
export const PROJECT_EMPLOYEE_UPDATE_SUCCESS = 'PROJECT_EMPLOYEE_UPDATE_SUCCESS';
export const DEDICATED_EMPLOYEE_TO_PROJECT_UPDATE_SUCCESS = 'DEDICATED_EMPLOYEE_TO_PROJECT_UPDATE_SUCCESS'
export const PROJECT_EMPLOYEE_DELETE_SUCCESS = 'PROJECT_EMPLOYEE_DELETE_SUCCESS';
export const PROJECT_DEDICATED_EMPLOYEE_DELETE_SUCCESS = 'PROJECT_DEDICATED_EMPLOYEE_DELETE_SUCCESS'
export const AGENCY_CLIENT_HOME_INFORMATION_FETCH_SUCCESS = 'AGENCY_CLIENT_HOME_INFORMATION_FETCH_SUCCESS';
export const CLIENT_ACCOUNT_MANAGEMENT_INFORMATION_UPDATE_SUCCESS = 'CLIENT_ACCOUNT_MANAGEMENT_INFORMATION_UPDATE_SUCCESS';
export const CLIENT_TASKS_FETCH_SUCCESS = 'CLIENT_TASKS_FETCH_SUCCESS';
export const CLIENT_TASK_CREATE_SUCCESS = 'CLIENT_TASK_CREATE_SUCCESS';
export const CLIENT_TASK_EDIT_SUCCESS = 'CLIENT_TASK_EDIT_SUCCESS';
export const CLIENT_PROJECT_FILES_ADD_SUCCESS = 'CLIENT_PROJECT_FILES_ADD_SUCCESS';
export const CLIENT_PROJECT_DOCUMENTS_FETCH_SUCCESS = 'CLIENT_PROJECT_DOCUMENTS_FETCH_SUCCESS';
export const CLIENT_PROJECT_LINK_ADD_SUCCESS = 'CLIENT_PROJECT_LINK_ADD_SUCCESS';
export const CLIENT_PROJECT_FILE_DELETE_SUCCESS = 'CLIENT_PROJECT_FILE_DELETE_SUCCESS';
export const CLIENT_PROJECT_LINK_DELETE_SUCCESS = 'CLIENT_PROJECT_LINK_DELETE_SUCCESS';
export const CLIENT_PROJECTS_FEEDBACK_FETCH_SUCCESS = 'CLIENT_PROJECTS_FEEDBACK_FETCH_SUCCESS';
export const CLIENT_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS = 'CLIENT_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS';
export const CLIENT_PROJECT_FETCH_PENDING = 'CLIENT_PROJECT_FETCH_PENDING';
export const CLIENT_PROJECT_FETCH_SUCCESS = 'CLIENT_PROJECT_FETCH_SUCCESS';
export const CLIENT_PROJECT_EMPLOYEE_HISTORY_FETCH_SUCCESS = 'CLIENT_PROJECT_EMPLOYEE_HISTORY_FETCH_SUCCESS';
export const CLIENT_UPDATE_FLAG_SUCCESS = 'CLIENT_UPDATE_FLAG_SUCCESS';
export const CLIENT_PROFILE_PAGE_UNMOUNTED_SUCCESS = 'CLIENT_PROFILE_PAGE_UNMOUNTED_SUCCESS';
export const CLIENT_INTERVIEW_DATES_FETCH_SUCCESS = 'CLIENT_INTERVIEW_DATES_FETCH_SUCCESS';
export const CLIENT_JOBS_DASHBOARD_FETCH_SUCCESS = 'CLIENT_JOBS_DASHBOARD_FETCH_SUCCESS';
export const CLIENT_NOTIFICATIONS_FETCH_SUCCESS = 'CLIENT_NOTIFICATIONS_FETCH_SUCCESS';
export const CLIENT_ADMINS_FETCH_SUCCESS = 'CLIENT_ADMINS_FETCH_SUCCESS';
export const CLIENT_ADMINS_REPLACE_SUCCESS = 'CLIENT_ADMINS_REPLACE_SUCCESS';
export const CLIENT_ADMIN_ADD_SUCCESS = 'CLIENT_ADMIN_ADD_SUCCESS';
export const CLIENT_ADMIN_DELETE_SUCCESS = 'CLIENT_ADMIN_DELETE_SUCCESS';
export const CLIENT_USERS_FETCH_SUCCESS = 'CLIENT_USERS_FETCH_SUCCESS';
export const CLIENT_USERS_REPLACE_SUCCESS = 'CLIENT_USERS_REPLACE_SUCCESS';
export const CLIENT_PROJECTS_COUNT_FETCH_SUCCESS = 'CLIENT_PROJECTS_COUNT_FETCH_SUCCESS';
export const CLIENT_TEAM_MEMBERS_COUNT_FETCH_SUCCESS = 'CLIENT_TEAM_MEMBERS_COUNT_FETCH_SUCCESS';
export const CLIENT_JOURNEY_INFORMATION_FETCH_SUCCESS = 'CLIENT_JOURNEY_INFORMATION_FETCH_SUCCESS';

//Invoices
export const INVOICE_ADD_SUCCESS = 'INVOICE_ADD_SUCCESS';
export const INVOICE_EDIT_SUCCESS = 'INVOICE_EDIT_SUCCESS';
export const INVOICES_FETCH_SUCCESS = 'INVOICES_FETCH_SUCCESS';
export const INVOICE_STATUS_UPDATE_SUCCESS = 'INVOICE_STATUS_UPDATE_SUCCESS';
export const INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS';
export const INVOICES_RESET_SUCCESS = 'INVOICES_RESET_SUCCESS';

//Contracts
export const CONTRACTS_FETCH_SUCCESS = 'CONTRACTS_FETCH_SUCCESS';
export const CONTRACTS_REPLACE_SUCCESS = 'CONTRACTS_REPLACE_SUCCESS';
export const CONTRACT_ADD_SUCCESS = 'CONTRACT_ADD_SUCCESS';
export const CONTRACT_DELETE_SUCCESS = 'CONTRACT_DELETE_SUCCESS';
export const CONTRACT_UPDATE_SUCCESS = 'CONTRACT_UPDATE_SUCCESS';
export const UPDATE_PENDING_CONTRACT_STATUS_SUCCESS = 'UPDATE_PENDING_CONTRACT_STATUS_SUCCESS';

// Supplier
export const SUPPLIER_REGISTER_FETCH_SUCCESS = 'SUPPLIER_REGISTER_FETCH_SUCCESS';
export const SUPPLIER_FETCH_ALL_SUCCESS = 'SUPPLIER_FETCH_ALL_SUCCESS';
export const SUPPLIER_REPLACE_ALL_SUCCESS = 'SUPPLIER_REPLACE_ALL_SUCCESS';
export const SUPPLIER_INFORMATION_FETCH_SUCCESS = 'SUPPLIER_INFORMATION_FETCH_SUCCESS';
export const SUPPLIER_AVATAR_UPDATE_SUCCESS = 'SUPPLIER_AVATAR_UPDATE_SUCCESS';
export const SUPPLIER_AVATAR_DELETE_SUCCESS = 'SUPPLIER_AVATAR_DELETE_SUCCESS';
export const SUPPLIER_BASIC_INFORMATION_UPDATE_SUCCESS = 'SUPPLIER_BASIC_INFORMATION_UPDATE_SUCCESS';
export const SUPPLIER_CONTACT_INFORMATION_UPDATE_SUCCESS = 'SUPPLIER_CONTACT_INFORMATION_UPDATE_SUCCESS';
export const SUPPLIER_INVOICE_INFORMATION_UPDATE_SUCCESS = 'SUPPLIER_INVOICE_INFORMATION_UPDATE_SUCCESS';
export const AGENCY_SUPPLIER_HOME_INFORMATION_FETCH_SUCCESS = 'AGENCY_SUPPLIER_HOME_INFORMATION_FETCH_SUCCESS';
export const SUPPLIER_ACCOUNT_MANAGER_INFORMATION_UPDATE_SUCCESS = 'SUPPLIER_ACCOUNT_MANAGER_INFORMATION_UPDATE_SUCCESS';
export const SUPPLIER_EMPLOYEES_PROJECTS_FETCH_SUCCESS = 'SUPPLIER_EMPLOYEES_PROJECTS_FETCH_SUCCESS';
export const SUPPLIER_EMPLOYEES_EXPORT_CV_SUCCESS = 'SUPPLIER_EMPLOYEES_EXPORT_CV_SUCCESS';
export const SUPPLIER_TASKS_FETCH_SUCCESS = 'SUPPLIER_TASKS_FETCH_SUCCESS';
export const SUPPLIER_TASK_CREATE_SUCCESS = 'SUPPLIER_TASK_CREATE_SUCCESS';
export const SUPPLIER_TASK_EDIT_SUCCESS = 'SUPPLIER_TASK_EDIT_SUCCESS';
export const SUPPLIER_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS = 'SUPPLIER_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS';
export const SUPPLIER_EMPLOYEE_FETCH_SUCCESS = 'SUPPLIER_EMPLOYEE_FETCH_SUCCESS';
export const SUPPLIER_UPDATE_FLAG_SUCCESS = 'SUPPLIER_UPDATE_FLAG_SUCCESS';
export const SUPPLIER_PROFILE_PAGE_UNMOUNTED_SUCCESS = 'SUPPLIER_PROFILE_PAGE_UNMOUNTED_SUCCESS';
export const SUPPLIERS_ACCOUNT_MANAGERS_FETCH_SUCCESS = 'SUPPLIERS_ACCOUNT_MANAGERS_FETCH_SUCCESS';
export const CANDIDATE_APPLICATION_PAGE_UNMOUNTED_SUCCESS = 'CANDIDATE_APPLICATION_PAGE_UNMOUNTED_SUCCESS';

// StaticData
export const COUNTRIES_FETCH_ALL_SUCCESS = 'COUNTRIES_FETCH_ALL_SUCCESS';
export const STATIC_LANGUAGES_FETCH_ALL_SUCCESS = 'STATIC_LANGUAGES_FETCH_ALL_SUCCESS';
export const STATIC_LANGUAGES_FETCH_SUGGESTIONS_SUCCESS = 'STATIC_LANGUAGES_FETCH_SUGGESTIONS_SUCCESS';

// Time Management
export const AGENCY_TIMESHEETS_FETCH_SUCCESS = 'AGENCY_TIMESHEETS_FETCH_SUCCESS';
export const AGENCY_TIMESHEETS_EXPORT_SUCCESS = 'AGENCY_TIMESHEETS_EXPORT_SUCCESS';

export const SEARCH_RESULTS_PENDING = 'SEARCH_RESULTS_PENDING';
export const SEARCH_RESULTS_FETCH_SUCCESS = 'SEARCH_RESULTS_FETCH_SUCCESS';
export const SEARCH_RESULTS_BY_NAME_FETCH_SUCCESS = 'SEARCH_RESULTS_BY_NAME_FETCH_SUCCESS';

// Application Loader
export const APPLICATION_LOADER_SHOW_SUCCESS = 'APPLICATION_LOADER_SHOW_SUCCESS';
export const APPLICATION_LOADER_HIDE_SUCCESS = 'APPLICATION_LOADER_HIDE_SUCCESS';
export const HOME_INFORMATION_INITIAL_STATE_SET = 'HOME_INFORMATION_INITIAL_STATE_SET';

// Job Opportunities
export const JOB_OPPORTUNITY_ADD_SUCCESS = 'JOB_OPPORTUNITY_ADD_SUCCESS';
export const JOB_OPPORTUNITIES_FETCH_SUCCESS = 'JOB_OPPORTUNITIES_FETCH_SUCCESS';
export const JOB_OPPORTUNITIES_COUNT_FETCH_SUCCESS = 'JOB_OPPORTUNITIES_COUNT_FETCH_SUCCESS';
export const JOB_OPPORTUNITY_UPDATE_SUCCESS = 'JOB_OPPORTUNITY_UPDATE_SUCCESS';
export const JOB_OPPORTUNITY_DELETE_SUCCESS = 'JOB_OPPORTUNITY_DELETE_SUCCESS';
export const JOB_OPPORTUNITY_DETAILS_FETCH_SUCCESS = 'JOB_OPPORTUNITY_DETAILS_FETCH_SUCCESS';
export const JOB_OPPORTUNITY_DETAILS_UPDATE_SUCCESS = 'JOB_OPPORTUNITY_DETAILS_UPDATE_SUCCESS';
export const CANDIDATE_APPLICATION_FETCH_SUCCESS = 'CANDIDATE_APPLICATION_FETCH_SUCCESS';
export const APPLICATION_UPDATE_STATUS_SUCCESS = 'APPLICATION_UPDATE_STATUS_SUCCESS';
export const APPLICATION_UPDATE_ASSIGNEE_SUCCESS = 'APPLICATION_UPDATE_ASSIGNEE_SUCCESS';
export const APPLICATION_UPDATE_STATUS_REQUEST_DATE = 'APPLICATION_UPDATE_STATUS_REQUEST_DATE';
export const CLIENT_JOBS_FETCH_SUCCESS = 'CLIENT_JOBS_FETCH_SUCCESS';
export const CLIENT_JOBS_RESET_STATE_SUCCESS = 'CLIENT_JOBS_RESET_STATE_SUCCESS';
export const JOBS_RESET_STATE_SUCCESS = 'JOBS_RESET_STATE_SUCCESS';
export const JOB_OPPORTUNITIES_TECHNOLOGIES_FETCH_SUCCESS = 'JOB_OPPORTUNITIES_TECHNOLOGIES_FETCH_SUCCESS';
export const JOB_APPLICATION_DELETE_SUCCESS = 'JOB_APPLICATION_DELETE_SUCCESS';
export const APPLIED_CONSULTANT_APPLICATION_UPDATE_SUCCESS = 'APPLIED_CONSULTANT_APPLICATION_UPDATE_SUCCESS';
export const APPLIED_CONSULTANTS_APPLICATION_UPDATE_SUCCESS = 'APPLIED_CONSULTANTS_APPLICATION_UPDATE_SUCCESS';
export const JOB_OPPORTUNITY_NEW_APPLICATIONS_COUNT_UPDATE_SUCCESS = 'JOB_OPPORTUNITY_NEW_APPLICATIONS_COUNT_UPDATE_SUCCESS';
export const JOB_OPPORTUNITY_PENDING_ADMIN_REVIEW_APPLICATIONS_COUNT_UPDATE_SUCCESS = 'JOB_OPPORTUNITY_PENDING_ADMIN_REVIEW_APPLICATIONS_COUNT_UPDATE_SUCCESS';
export const MATCHING_CONSULTANTS_FETCH_SUCCESS = 'MATCHING_CONSULTANTS_FETCH_SUCCESS';
export const TALENT_MATCHING_JOBS_FETCH_SUCCESS = 'TALENT_MATCHING_JOBS_FETCH_SUCCESS';
export const INDEXED_JOB_OPPORTUNITIES_FETCH_SUCCESS = 'INDEXED_JOB_OPPORTUNITIES_FETCH_SUCCESS';

// Projects 
export const EMPLOYEE_PROJECTS_FETCH_SUCCESS = 'EMPLOYEE_PROJECTS_FETCH_SUCCESS';
export const PROJECT_REVIEWS_FETCH_SUCCESS = 'PROJECT_REVIEWS_FETCH_SUCCESS';

// User
export const USERS_DISABLE_SUCCESS = 'USERS_DISABLE_SUCCESS';
export const USERS_RESTORE_SUCCESS = 'USERS_RESTORE_SUCCESS';

//Public Holidays
export const PUBLIC_HOLIDAYS_FETCH_SUCCESS = 'PUBLIC_HOLIDAYS_FETCH_SUCCESS';

//Benefits
export const BENEFIT_REQUEST_CREATE_SUCCESS = 'PERKS_&_EQUIPMENT_REQUEST_CREATE_SUCCESS';
export const BENEFITS_FETCH_SUCCESS = 'PERKS_&_EQUIPMENT_FETCH_SUCCESS';
export const BENEFIT_TEMPLATES_FETCH_SUCCESS = 'PERKS_&_EQUIPMENT_TEMPLATES_FETCH_SUCCESS';
export const BENEFITS_DELETE_SUCCESS = 'PERKS_&_EQUIPMENT_DELETE_SUCCESS';
export const BENEFITS_UPDATE_SUCCESS = 'PERKS_&_EQUIPMENT_UPDATE_SUCCESS';
export const BENEFIT_TEMPLATE_REQUEST_CREATE_SUCCESS = 'PERKS_&_EQUIPMENT_TEMPLATE_REQUEST_CREATE_SUCCESS';

// Recruiters
export const RECRUITER_REGISTER_FETCH_SUCCESS = 'RECRUITER_REGISTER_FETCH_SUCCESS';
export const RECRUITERS_FETCH_SUCCESS = 'RECRUITERS_FETCH_SUCCESS';
export const RECRUITERS_REPLACE_SUCCESS = 'RECRUITERS_REPLACE_SUCCESS';
export const RECRUITER_INFORMATION_FETCH_SUCCESS = 'RECRUITER_INFORMATION_FETCH_SUCCESS';
export const RECRUITER_PROFILES_FETCH_SUCCESS = 'RECRUITER_PROFILES_FETCH_SUCCESS';
export const RECRUITER_PROFILE_INFO_FETCH_SUCCESS = 'RECRUITER_PROFILE_INFO_FETCH_SUCCESS';
export const REGISTER_RECRUITER_EMPLOYEE_SUCCESS = 'REGISTER_RECRUITER_EMPLOYEE_SUCCESS';
export const AGENCY_RECRUITER_HOME_INFORMATION_FETCH_SUCCESS = 'AGENCY_RECRUITER_HOME_INFORMATION_FETCH_SUCCESS';
export const DELETE_RECRUITER_EMPLOYEE_CV_SUCCESS = 'DELETE_RECRUITER_EMPLOYEE_CV_SUCCESS';
export const UPLOAD_RECRUITER_EMPLOYEE_CV_SUCCESS = 'UPLOAD_RECRUITER_EMPLOYEE_CV_SUCCESS';
export const RECRUITER_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS = 'RECRUITER_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS';
export const RECRUITER_AVATAR_UPDATE_SUCCESS = 'RECRUITER_AVATAR_UPDATE_SUCCESS';
export const RECRUITER_AVATAR_DELETE_SUCCESS = 'RECRUITER_AVATAR_DELETE_SUCCESS';
export const RECRUITER_CONTACT_INFORMATION_UPDATE_SUCCESS = 'RECRUITER_CONTACT_INFORMATION_UPDATE_SUCCESS';
export const RECRUITER_BASIC_INFORMATION_UPDATE_SUCCESS = 'RECRUITER_BASIC_INFORMATION_UPDATE_SUCCESS';
export const RECRUITER_ACCOUNT_MANAGER_INFORMATION_UPDATE_SUCCESS = 'RECRUITER_ACCOUNT_MANAGER_INFORMATION_UPDATE_SUCCESS';
export const RECRUITERS_ACCOUNT_MANAGERS_FETCH_SUCCESS = 'RECRUITERS_ACCOUNT_MANAGERS_FETCH_SUCCESS';

// Departments
export const DEPARTMENTS_FETCH_SUCCESS = 'DEPARTMENTS_FETCH_SUCCESS';
export const DEPARTMENTS_REPLACE_SUCCESS = 'DEPARTMENTS_REPLACE_SUCCESS';
export const DEPARTMENT_CREATE_SUCCESS = 'DEPARTMENT_CREATE_SUCCESS';
export const DEPARTMENT_DELETE_SUCCESS = 'DEPARTMENT_DELETE_SUCCESS';
export const DEPARTMENT_DETAILS_FETCH_SUCCESS = 'DEPARTMENT_DETAILS_FETCH_SUCCESS';
export const DEPARTMENT_EDIT_SUCCESS = 'DEPARTMENT_EDIT_SUCCESS';
export const DEPARTMENT_MEMBERS_FETCH_SUCCESS = 'DEPARTMENT_MEMBERS_FETCH_SUCCESS';
export const DEPARTMENT_MEMBERS_REPLACE_SUCCESS = 'DEPARTMENT_MEMBERS_REPLACE_SUCCESS';

// Applications
export const INTERVIEW_INVITATION_ADD_SUCCESS = 'INTERVIEW_INVITATION_ADD_SUCCESS';
export const INTERVIEW_INVITATION_FETCH_SUCCESS = 'INTERVIEW_INVITATION_FETCH_SUCCESS';
export const INTERVIEW_INVITATIONS_FETCH_SUCCESS = 'INTERVIEW_INVITATIONS_FETCH_SUCCESS';
export const INTERVIEW_DATE_UPDATE_SUCCESS = 'INTERVIEW_DATE_UPDATE_SUCCESS';
export const INTERVIEW_DATA_UPDATE_SUCCESS = 'INTERVIEW_DATA_UPDATE_SUCCESS';
export const INTERVIEW_STATUS_UPDATE_SUCCESS = 'INTERVIEW_STATUS_UPDATE_SUCCESS';

// UI/UX
export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION';
export const TOGGLE_MOBILE_NAVIGATION = 'TOGGLE_MOBILE_NAVIGATION';
export const INITIAL_JOURNEY_FETCH_SUCCESS = 'INITIAL_JOURNEY_FETCH_SUCCESS';
export const INITIAL_JOURNEY_UPDATE_SUCCESS = 'INITIAL_JOURNEY_UPDATE_SUCCESS';
export const INITIAL_JOURNEY_CURRENT_STEP_ID_UPDATE_SUCCESS = 'INITIAL_JOURNEY_CURRENT_STEP_ID_UPDATE_SUCCESS';
export const INITIAL_JOURNEY_COMPLETED_STEPS_UPDATE_SUCCESS = 'INITIAL_JOURNEY_COMPLETED_STEPS_UPDATE_SUCCESS';
export const TOGGLE_WORKSPACE_MIGRATION_DIALOG_SUCCESS = 'TOGGLE_WORKSPACE_MIGRATION_DIALOG_SUCCESS';
export const TOGGLE_JOURNEY_COMPLETED_SUCCESS = 'TOGGLE_JOURNEY_COMPLETED_SUCCESS';
export const TOGGLE_INCOMPLETE_PROFILE_DIALOG_SUCCESS = 'TOGGLE_INCOMPLETE_PROFILE_DIALOG_SUCCESS';
export const TOGGLE_RECEIVED_HIRED_NOTIFICATION = 'TOGGLE_RECEIVED_HIRED_NOTIFICATION';
export const SET_IS_CANDIDATE_HIRED = 'SET_IS_CANDIDATE_HIRED';
export const SET_IS_CANDIDATE_REJECTED = 'SET_IS_CANDIDATE_REJECTED';

// Main Menu
export const FETCH_MAIN_MENU_INFORMATION_SUCCESS = 'FETCH_MAIN_MENU_INFORMATION_SUCCESS';
export const UPDATE_MAIN_MENU_INFORMATION_SUCCESS = 'UPDATE_MAIN_MENU_INFORMATION_SUCCESS';

// Manager
export const MANAGED_EMPLOYEES_PENDING_HOLIDAYS_FETCH_SUCCESS = 'MANAGERS_OWN_EMPLOYEES_PENDING_HOLIDAYS_FETCH_SUCCESS';
export const MANAGED_EMPLOYEES_PENDING_HOLIDAYS_REPLACE_SUCCESS = 'MANAGED_EMPLOYEES_PENDING_HOLIDAYS_REPLACE_SUCCESS';
export const MANAGED_EMPLOYEES_REVIEWED_HOLIDAYS_FETCH_SUCCESS = 'MANAGED_OWN_EMPLOYEES_REVIEWED_HOLIDAYS_FETCH_SUCCESS';
export const MANAGED_EMPLOYEES_REVIEWED_HOLIDAYS_REPLACE_SUCCESS = 'MANAGED_EMPLOYEES_REVIEWED_HOLIDAYS_REPLACE_SUCCESS';
export const MANAGED_EMPLOYEES_APPROVED_HOLIDAYS_FETCH_SUCCESS = 'MANAGED_EMPLOYEES_APPROVED_HOLIDAYS_FETCH_SUCCESS';
export const MANAGED_EMPLOYEES_SICK_LEAVES_FETCH_SUCCESS = 'MANAGED_EMPLOYEES_SICK_LEAVES_FETCH_SUCCESS';
export const MANAGED_EMPLOYEES_HOLIDAYS_APPROVE_REQUESTS_SUCCESS = 'MANAGERS_OWN_EMPLOYEES_HOLIDAYS_APPROVE_REQUESTS_SUCCESS';
export const MANAGED_EMPLOYEES_HOLIDAYS_REJECT_REQUEST_SUCCESS = 'MANAGERS_OWN_EMPLOYEES_HOLIDAYS_REJECT_REQUEST_SUCCESS';
export const MANAGED_EMPLOYEE_HOLIDAY_CREATE_REQUESTS_SUCCESS = 'MANAGED_EMPLOYEE_HOLIDAY_CREATE_REQUESTS_SUCCESS';
export const MANAGED_EMPLOYEES_HOLIDAYS_DELETE_REQUESTS_SUCCESS = 'MANAGED_EMPLOYEES_HOLIDAYS_DELETE_REQUESTS_SUCCESS';
export const MANAGED_EMPLOYEES_DOCUMENTS_FETCH_SUCCESS = 'MANAGED_EMPLOYEES_DOCUMENTS_FETCH_SUCCESS';
export const MANAGED_EMPLOYEES_DOCUMENTS_REPLACE_SUCCESS = 'MANAGED_EMPLOYEES_DOCUMENTS_REPLACE_SUCCESS';

// Historical Notes
export const HISTORICAL_NOTES_FETCH_SUCCESS = 'HISTORICAL_NOTES_FETCH_SUCCESS';
export const HISTORICAL_NOTES_ADD_SUCCESS = 'HISTORICAL_NOTES_ADD_SUCCESS';
export const HISTORICAL_NOTES_EDIT_SUCCESS = 'HISTORICAL_NOTES_EDIT_SUCCESS';
export const HISTORICAL_NOTES_DELETE_SUCCESS = 'HISTORICAL_NOTES_DELETE_SUCCESS';
export const HISTORICAL_NOTES_FETCH_PENDING = 'HISTORICAL_NOTES_FETCH_PENDING';

// Explore Talents
export const FETCH_EXPLORE_TALENTS_PROFILES_SUCCESS = 'FETCH_EXPLORE_TALENTS_PROFILES_SUCCESS';
export const FETCH_MORE_EXPLORE_TALENTS_PROFILES_SUCCESS = 'FETCH_MORE_EXPLORE_TALENTS_PROFILES_SUCCESS';

// Section Loaders
export const EMPLOYEES_TABLE_DATA_FETCH_SUCCESS = 'EMPLOYEES_TABLE_DATA_FETCH_SUCCESS';
export const EMPLOYEES_TABLE_DATA_FETCHING_SUCCESS = 'EMPLOYEES_TABLE_DATA_FETCHING_SUCCESS';
export const ACTIVE_HOLIDAYS_TABLE_DATA_FETCH_SUCCESS = 'ACTIVE_HOLIDAYS_TABLE_DATA_FETCH_SUCCESS';
export const ACTIVE_HOLIDAYS_TABLE_DATA_FETCHING_SUCCESS = 'ACTIVE_HOLIDAYS_TABLE_DATA_FETCHING_SUCCESS';
export const CLIENTS_ACCOUNTS_TABLE_DATA_FETCH_SUCCESS = 'CLIENTS_ACCOUNTS_TABLE_DATA_FETCH_SUCCESS';
export const CLIENTS_ACCOUNTS_TABLE_DATA_FETCHING_SUCCESS = 'CLIENTS_ACCOUNTS_TABLE_DATA_FETCHING_SUCCESS';
export const CLIENTS_PROJECTS_OVERVIEW_DATA_FETCH_SUCCESS = 'CLIENTS_PROJECTS_OVERVIEW_DATA_FETCH_SUCCESS';
export const CLIENTS_PROJECTS_OVERVIEW_DATA_FETCHING_SUCCESS = 'CLIENTS_PROJECTS_OVERVIEW_DATA_FETCHING_SUCCESS';
export const RECRUITERS_LIST_TABLE_DATA_FETCH_SUCCESS = 'RECRUITERS_LIST_TABLE_DATA_FETCH_SUCCESS';
export const RECRUITERS_LIST_TABLE_DATA_FETCHING_SUCCESS = 'RECRUITERS_LIST_TABLE_DATA_FETCHING_SUCCESS';
export const JOB_OPPORTUNITIES_LIST_DATA_FETCH_SUCCESS = 'JOB_OPPORTUNITIES_LIST_DATA_FETCH_SUCCESS';
export const JOB_OPPORTUNITIES_LIST_DATA_FETCHING_SUCCESS = 'JOB_OPPORTUNITIES_LIST_DATA_FETCHING_SUCCESS';
export const JOB_OPPORTUNITY_PAGE_FETCH_SUCCESS = 'JOB_OPPORTUNITY_PAGE_FETCH_SUCCESS';
export const JOB_OPPORTUNITY_PAGE_FETCHING_SUCCESS = 'JOB_OPPORTUNITY_PAGE_FETCHING_SUCCESS';
export const JOB_MATCHING_CONSULTANTS_PAGE_FETCH_SUCCESS = 'JOB_MATCHING_CONSULTANTS_PAGE_FETCH_SUCCESS';
export const JOB_MATCHING_CONSULTANTS_PAGE_FETCHING_SUCCESS = 'JOB_MATCHING_CONSULTANTS_PAGE_FETCHING_SUCCESS';
export const HISTORICAL_NOTES_DATA_FETCH_SUCCESS = 'HISTORICAL_NOTES_DATA_FETCH_SUCCESS';
export const HISTORICAL_NOTES_DATA_FETCHING_SUCCESS = 'HISTORICAL_NOTES_DATA_FETCHING_SUCCESS';
export const EMPLOYEES_RELATED_OPPORTUNITIES_DATA_FETCH_SUCCESS = 'EMPLOYEES_RELATED_OPPORTUNITIES_DATA_FETCH_SUCCESS';
export const EMPLOYEES_RELATED_OPPORTUNITIES_DATA_FETCHING_SUCCESS = 'EMPLOYEES_RELATED_OPPORTUNITIES_DATA_FETCHING_SUCCESS';
export const EMPLOYEES_PERSONAL_INFORMATION_DATA_FETCH_SUCCESS = 'EMPLOYEES_PERSONAL_INFORMATION_DATA_FETCH_SUCCESS';
export const EMPLOYEES_PERSONAL_INFORMATION_DATA_FETCHING_SUCCESS = 'EMPLOYEES_PERSONAL_INFORMATION_DATA_FETCHING_SUCCESS';
export const EMPLOYEES_EMPLOYMENT_INFORMATION_DATA_FETCHING_SUCCESS = 'EMPLOYEES_EMPLOYMENT_INFORMATION_DATA_FETCHING_SUCCESS';
export const EMPLOYEES_EMPLOYMENT_INFORMATION_DATA_FETCH_SUCCESS = 'EMPLOYEES_EMPLOYMENT_INFORMATION_DATA_FETCH_SUCCESS';
export const PERFORMANCE_REVIEWS_DATA_FETCH_SUCCESS = 'PERFORMANCE_REVIEWS_DATA_FETCH_SUCCESS';
export const PERFORMANCE_REVIEWS_DATA_FETCHING_SUCCESS = 'PERFORMANCE_REVIEWS_DATA_FETCHING_SUCCESS';
export const PROFILE_DATA_FETCH_SUCCESS = 'PROFILE_DATA_FETCH_SUCCESS';
export const PROFILE_DATA_FETCHING_SUCCESS = 'PROFILE_DATA_FETCHING_SUCCESS';
export const TIME_TRACKING_DATA_FETCH_SUCCESS = 'TIME_TRACKING_DATA_FETCH_SUCCESS';
export const TIME_TRACKING_DATA_FETCHING_SUCCESS = 'TIME_TRACKING_DATA_FETCHING_SUCCESS';
export const EMPLOYEES_HOLIDAYS_DATA_FETCH_SUCCESS = 'EMPLOYEES_HOLIDAYS_DATA_FETCH_SUCCESS';
export const EMPLOYEES_HOLIDAYS_DATA_FETCHING_SUCCESS = 'EMPLOYEES_HOLIDAYS_DATA_FETCHING_SUCCESS';
export const CANDIDATE_INTERVIEW_INVITATIONS_DATA_FETCH_SUCCESS = 'CANDIDATE_INTERVIEW_INVITATIONS_DATA_FETCH_SUCCESS';
export const CANDIDATE_INTERVIEW_INVITATIONS_DATA_FETCHING_SUCCESS = 'CANDIDATE_INTERVIEW_INVITATIONS_DATA_FETCHING_SUCCESS';
export const BENEFITS_DATA_FETCH_SUCCESS = 'BENEFITS_DATA_FETCH_SUCCESS';
export const BENEFITS_DATA_FETCHING_SUCCESS = 'BENEFITS_DATA_FETCHING_SUCCESS';
export const EXPLORE_TALENTS_PROFILES_DATA_FETCH_SUCCESS = 'EXPLORE_TALENTS_PROFILES_DATA_FETCH_SUCCESS';
export const EXPLORE_TALENTS_PROFILES_DATA_FETCHING_SUCCESS = 'EXPLORE_TALENTS_PROFILES_DATA_FETCHING_SUCCESS';
export const CANDIDATES_PREFERENCES_DATA_FETCH_SUCCESS = 'CANDIDATES_PREFERENCES_DATA_FETCH_SUCCESS';
export const CANDIDATES_PREFERENCES_DATA_FETCHING_SUCCESS = 'CANDIDATES_PREFERENCES_DATA_FETCHING_SUCCESS';
export const CANDIDATE_VIDEO_INTERVIEW_FEEDBACK_DATA_FETCH_SUCCESS = 'CANDIDATE_VIDEO_INTERVIEW_FEEDBACK_DATA_FETCH_SUCCESS';
export const CANDIDATE_VIDEO_INTERVIEW_FEEDBACK_DATA_FETCHING_SUCCESS = 'CANDIDATE_VIDEO_INTERVIEW_FEEDBACK_DATA_FETCHING_SUCCESS';
export const APPLIED_CONSULTANTS_DATA_FETCH_SUCCESS = 'APPLIED_CONSULTANTS_DATA_FETCH_SUCCESS';
export const APPLIED_CONSULTANTS_DATA_UPDATE_SUCCESS = 'APPLIED_CONSULTANTS_DATA_UPDATE_SUCCESS';
export const CANDIDATE_APPLICATIONS_DATA_FETCH_SUCCESS = 'CANDIDATE_APPLICATIONS_DATA_FETCH_SUCCESS';
export const CANDIDATE_APPLICATIONS_DATA_FETCHING_SUCCESS = 'CANDIDATE_APPLICATIONS_DATA_FETCHING_SUCCESS';
export const CLIENTS_PROJECTS_PAGE_DATA_FETCH_SUCCESS = 'CLIENTS_PROJECTS_PAGE_DATA_FETCH_SUCCESS';
export const CLIENTS_PROJECTS_PAGE_DATA_FETCHING_SUCCESS = 'CLIENTS_PROJECTS_PAGE_DATA_FETCHING_SUCCESS';
export const ACCOUNT_MANAGER_DATA_FETCH_SUCCESS = 'ACCOUNT_MANAGER_DATA_FETCH_SUCCESS';
export const ACCOUNT_MANAGER_DATA_FETCHING_SUCCESS = 'ACCOUNT_MANAGER_DATA_FETCHING_SUCCESS';
export const CLIENT_PROJECT_DOCUMENTS_DATA_FETCH_SUCCESS = 'CLIENT_PROJECT_DOCUMENTS_DATA_FETCH_SUCCESS';
export const CLIENT_PROJECT_DOCUMENTS_DATA_FETCHING_SUCCESS = 'CLIENT_PROJECT_DOCUMENTS_DATA_FETCHING_SUCCESS';
export const JOB_OPPORTUNITY_DETAILS_DATA_FETCH_SUCCESS = 'JOB_OPPORTUNITY_DETAILS_DATA_FETCH_SUCCESS';
export const JOB_OPPORTUNITY_DETAILS_DATA_FETCHING_SUCCESS = 'JOB_OPPORTUNITY_DETAILS_DATA_FETCHING_SUCCESS';
export const CANDIDATE_APPLICATION_DATA_FETCH_SUCCESS = 'CANDIDATE_APPLICATION_DATA_FETCH_SUCCESS';
export const CANDIDATE_APPLICATION_DATA_FETCHING_SUCCESS = 'CANDIDATE_APPLICATION_DATA_FETCHING_SUCCESS';
export const DASHBOARD_DATA_FETCH_SUCCESS = 'DASHBOARD_DATA_FETCH_SUCCESS';
export const DASHBOARD_DATA_FETCHING_SUCCESS = 'DASHBOARD_DATA_FETCHING_SUCCESS';
export const CANDIDATE_OVERVIEW_PAGE_DATA_FETCH_SUCCESS = 'CANDIDATE_OVERVIEW_PAGE_DATA_FETCH_SUCCESS';
export const CANDIDATE_OVERVIEW_PAGE_DATA_FETCHING_SUCCESS = 'CANDIDATE_OVERVIEW_PAGE_DATA_FETCHING_SUCCESS';
export const CLIENT_PROJECTS_WITH_EMPLOYEES_DATA_FETCH_SUCCESS = 'CLIENT_PROJECTS_WITH_EMPLOYEES_DATA_FETCH_SUCCESS';
export const CLIENT_PROJECTS_WITH_EMPLOYEES_DATA_FETCHING_SUCCESS = 'CLIENT_PROJECTS_WITH_EMPLOYEES_DATA_FETCHING_SUCCESS';
export const CLIENT_PROJECTS_TOTAL_REVENUE_DATA_FETCH_SUCCESS = 'CLIENT_PROJECTS_TOTAL_REVENUE_DATA_FETCH_SUCCESS';
export const CLIENT_PROJECTS_TOTAL_REVENUE_DATA_FETCHING_SUCCESS = 'CLIENT_PROJECTS_TOTAL_REVENUE_DATA_FETCHING_SUCCESS';
export const CLIENT_INFORMATION_DATA_FETCH_SUCCESS = 'CLIENT_INFORMATION_DATA_FETCH_SUCCESS';
export const CLIENT_INFORMATION_DATA_FETCHING_SUCCESS = 'CLIENT_INFORMATION_DATA_FETCHING_SUCCESS';
export const TASKS_DATA_FETCH_SUCCESS = 'TASKS_DATA_FETCH_SUCCESS';
export const TASKS_DATA_FETCHING_SUCCESS = 'TASKS_DATA_FETCHING_SUCCESS';
export const SICK_LEAVES_DATA_FETCH_SUCCESS = 'SICK_LEAVES_DATA_FETCH_SUCCESS';
export const SICK_LEAVES_DATA_FETCHING_SUCCESS = 'SICK_LEAVES_DATA_FETCHING_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SSO_LOGIN_SUCCESS = 'SSO_LOGIN_SUCCESS';
export const CLIENT_INTERVIEW_DATES_DATA_FETCH_SUCCESS = 'CLIENT_INTERVIEW_DATES_DATA_FETCH_SUCCESS';
export const CLIENT_INTERVIEW_DATES_DATA_FETCHING_SUCCESS = 'CLIENT_INTERVIEW_DATES_DATA_FETCHING_SUCCESS';
export const CLIENT_JOBS_DASHBOARD_DATA_FETCH_SUCCESS = 'CLIENT_JOBS_DASHBOARD_DATA_FETCH_SUCCESS';
export const CLIENT_JOBS_DASHBOARD_DATA_FETCHING_SUCCESS = 'CLIENT_JOBS_DASHBOARD_DATA_FETCHING_SUCCESS';
export const CHECKLISTED_CANDIDATES_DATA_FETCH_SUCCESS = 'CHECKLISTED_CANDIDATES_DATA_FETCH_SUCCESS';
export const CHECKLISTED_CANDIDATES_DATA_FETCHING_SUCCESS = 'CHECKLISTED_CANDIDATES_DATA_FETCHING_SUCCESS';
export const PROJECT_DETAILS_PAGE_UNMOUNT_SUCCESS = 'PROJECT_DETAILS_PAGE_UNMOUNT_SUCCESS';
export const CANDIDATE_CALENDLY_INTERVIEW_DETAILS_FETCH_SUCCESS = 'CANDIDATE_CALENDLY_INTERVIEW_DETAILS_FETCH_SUCCESS';
export const APPLICATION_PROCESS_INFORMATION_FETCHING_SUCCESS = 'APPLICATION_PROCESS_INFORMATION_FETCHING_SUCCESS';
export const APPLICATION_PROCESS_INFORMATION_FETCH_SUCCESS = 'APPLICATION_PROCESS_INFORMATION_FETCH_SUCCESS';
export const CANDIDATE_PROFILE_PAGE_DATA_FETCHING_SUCCESS = 'CANDIDATE_PROFILE_PAGE_DATA_FETCHING_SUCCESS';
export const CANDIDATE_PROFILE_PAGE_DATA_FETCH_SUCCESS = 'CANDIDATE_PROFILE_PAGE_DATA_FETCH_SUCCESS';
export const DIALOG_ACTION_BUTTON_LOADER_SHOW_SUCCESS = 'DIALOG_ACTION_BUTTON_LOADER_SHOW_SUCCESS';
export const DIALOG_ACTION_BUTTON_LOADER_HIDE_SUCCESS = 'DIALOG_ACTION_BUTTON_LOADER_HIDE_SUCCESS';
export const ACTION_BUTTON_LOADER_SHOW_SUCCESS = 'ACTION_BUTTON_LOADER_SHOW_SUCCESS';
export const ACTION_BUTTON_LOADER_HIDE_SUCCESS = 'ACTION_BUTTON_LOADER_HIDE_SUCCESS';
export const KPI_REPORT_DATA_FETCH_SUCCESS = 'KPI_REPORT_DATA_FETCH_SUCCESS';
export const KPI_REPORT_DATA_FETCHING_SUCCESS = 'KPI_REPORT_DATA_FETCHING_SUCCESS';
export const REPORTS_PAGE_DATA_FETCH_SUCCESS = 'REPORTS_PAGE_DATA_FETCH_SUCCESS';
export const REPORTS_PAGE_DATA_FETCHING_SUCCESS = 'REPORTS_PAGE_DATA_FETCHING_SUCCESS';
export const AI_MATCHED_CANDIDATES_SCORES_FETCHING_SUCCESS = 'AI_MATCHED_CANDIDATES_SCORES_FETCHING_SUCCESS';
export const AI_MATCHED_CANDIDATES_SCORES_FETCH_SUCCESS = 'AI_MATCHED_CANDIDATES_SCORES_FETCH_SUCCESS';
export const AGENCY_QUERIED_CLIENTS_RESET_FETCH_SUCCESS = 'AGENCY_QUERIED_CLIENTS_RESET_FETCH_SUCCESS';
export const ACCOUNT_MANAGER_FETCH_ALL_SUCCESS = 'ACCOUNT_MANAGER_FETCH_ALL_SUCCESS';
export const EMPLOYEE_OVERVIEW_FETCHING_SUCCESS = 'EMPLOYEE_OVERVIEW_FETCHING_SUCCESS';
export const EMPLOYEE_OVERVIEW_FETCH_SUCCESS = 'EMPLOYEE_OVERVIEW_FETCH_SUCCESS';
export const SUPPLIER_EMPLOYEE_OVERVIEW_FETCHING_SUCCESS = 'SUPPLIER_EMPLOYEE_OVERVIEW_FETCHING_SUCCESS';
export const SUPPLIER_EMPLOYEE_OVERVIEW_FETCH_SUCCESS = 'SUPPLIER_EMPLOYEE_OVERVIEW_FETCH_SUCCESS';

// Coaching Opportunities
export const COACHING_OPPORTUNITY_CREATE_SUCCESS = 'COACHING_OPPORTUNITY_CREATE_SUCCESS';
export const COACHING_OPPORTUNITIES_EDIT_SUCCESS = 'COACHING_OPPORTUNITIES_EDIT_SUCCESS';
export const COACHING_OPPORTUNITIES_DELETE_SUCCESS = 'COACHING_OPPORTUNITIES_DELETE_SUCCESS';
export const COACHING_OPPORTUNITIES_FETCH_SUCCESS = 'COACHING_OPPORTUNITIES_FETCH_SUCCESS';
export const COACHING_OPPORTUNITY_FETCH_SUCCESS = 'COACHING_OPPORTUNITY_FETCH_SUCCESS';
export const COACHING_OPPORTUNITY_SESSION_CREATE_SUCCESS = 'COACHING_OPPORTUNITY_SESSION_CREATE_SUCCESS';
export const COACHING_OPPORTUNITIES_SESSIONS_FETCH_SUCCESS = 'COACHING_OPPORTUNITIES_SESSIONS_FETCH_SUCCESS';
export const COACHING_OPPORTUNITY_SESSIONS_FETCH_SUCCESS = 'COACHING_OPPORTUNITY_SESSIONS_FETCH_SUCCESS';

// UI
export const TOGGLE_BUSINESS_TRIP_BANNER_SUCCESS = 'TOGGLE_BUSINESS_TRIP_BANNER_SUCCESS';
export const TOGGLE_OVERTIME_BANNER_SUCCESS = 'TOGGLE_OVERTIME_BANNER_SUCCESS';
export const SEARCH_DATA_FETCH_SUCCESS = 'SEARCH_DATA_FETCH_SUCCESS';
export const SEARCH_DATA_FETCHING_SUCCESS = 'SEARCH_DATA_FETCHING_SUCCESS';

//Business Trips
export const BUSINESS_TRIPS_FETCH_SUCCESS = 'BUSINESS_TRIPS_FETCH_SUCCESS';
export const BUSINESS_TRIPS_REPLACE_SUCCESS = 'BUSINESS_TRIPS_REPLACE_SUCCESS';

// Reports
export const REPORTS_PAGE_FETCH_SUCCESS = 'REPORTS_PAGE_FETCH_SUCCESS';
export const KPI_REPORT_FETCH_SUCCESS = 'KPI_REPORT_FETCH_SUCCESS';

// Language
export const SWITCH_LANGUAGE_SUCCESS = 'SWITCH_LANGUAGE_SUCCESS';
