import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';

import { addUtcOffset } from 'utils/formatTimes';
import {
    validateDateFields,
    validateRequiredFields,
    validateInputForPositiveIntegerType,
} from 'utils/formValidations';

import { JOB_TYPES } from 'components/CandidateProfileJourney/Steps/YourProfileWrapper/YourProfileConstants';

const CandidateProfileInfoFormik = ({
    profileInfo,
    updateProfileInfo,
    requiredField,
    positiveFields,
    children
}) => {
    return (
        <Formik
            enableReinitialize
            initialValues={{
                netSalaryValue: profileInfo?.netSalaryValue || '',
                salaryCurrency: profileInfo?.salaryCurrency || '',
                countryOfTax: profileInfo?.countryOfTax || '',
                earliestDate: profileInfo?.earliestDate ? moment.utc(profileInfo.earliestDate) : null,
                experienceSince: profileInfo?.experienceSince || '',
                jobType: profileInfo?.jobType || '',
                assignee: profileInfo?.assignee || '',
                technologies: profileInfo?.technologies?.map(x => x.title) || [],
            }}
            validate={(values) => {
                const requestedFieldsValidation = requiredField ? validateRequiredFields(values, requiredField) : {};
                return profileInfo.earliestDate != null
                    ? {
                        ...requestedFieldsValidation,

                        ...validateDateFields(values, ['earliestDate']),

                        ...validateInputForPositiveIntegerType(values, positiveFields)
                    }
                    : {
                        ...requestedFieldsValidation,

                        ...validateInputForPositiveIntegerType(values, positiveFields)
                    }
                    ;
            }}
            onSubmit={async (values, { resetForm }) => {
                const preferencesData = [JOB_TYPES.FRONT_END_DEVELOPER, JOB_TYPES.BACK_END_DEVELOPER, JOB_TYPES.FULL_STACK_DEVELOPER, JOB_TYPES.MOBILE_DEVELOPER].includes(values.jobType)
                    ? { ...values, earliestDate: addUtcOffset(values.earliestDate), isWithTechStack: profileInfo?.technologies?.length > 0, technologies: values.technologies.map(x => ({ _id: x.toLowerCase(), title: x })) }
                    : { ...values, earliestDate: addUtcOffset(values.earliestDate), technologies: values.technologies.map(x => ({ _id: x.toLowerCase(), title: x })) }

                await updateProfileInfo(preferencesData, resetForm);
            }}
        >
            {children}
        </Formik>
    );
};

export default CandidateProfileInfoFormik;
