export const EMPLOYEE_MESSAGES = {
  FETCH_EMPLOYEE_VIEW_PROFILE_DATA_FAIL: 'Could not fetch employee profile.',
  DELETE_LANGUAGE_SUCCESS: 'Language deleted successfully',
  DELETE_LANGUAGE_FAIL: 'Language deleting failed',
  UPDATE_LANGUAGE_SUCCESS: 'Language updated successfully',
  UPDATE_LANGUAGE_FAIL: 'Language updating failed',
  ADD_LANGUAGE_SUCCESS: 'Language added successfully',
  ADD_LANGUAGE_FAIL: 'Language adding failed',
  DELETE_CERTIFICATE_SUCCESS: 'Certificate deleted successfully',
  DELETE_CERTIFICATE_FAIL: 'Certificate deleting failed',
  UPDATE_CERTIFICATE_SUCCESS: 'Certificate updated successfully',
  UPDATE_CERTIFICATES_SUCCESS: 'Certificates updated successfully',
  UPDATE_CERTIFICATE_FAIL: 'Certificate updating failed',
  ADD_CERTIFICATE_SUCCESS: 'Certificate added successfully',
  ADD_CERTIFICATE_FAIL: 'Certificate adding failed',
  FETCH_CERTIFICATE_FAIL: 'Loading Certificate failed',
  DELETE_EDUCATION_SUCCESS: 'Education entry deleted successfully',
  DELETE_EDUCATION_FAIL: 'Education entry deleting failed',
  UPDATE_EDUCATION_SUCCESS: 'Education entry updated successfully',
  UPDATE_EDUCATION_FAIL: 'Education entry updating failed',
  ADD_EDUCATION_SUCCESS: 'Education entry added successfully',
  ADD_EDUCATION_FAIL: 'Education entry adding failed',
  UPDATE_SKILLS_SUCCESS: 'Skills updated successfully',
  UPDATE_SKILLS_FAIL: 'Skills updating failed',
  DELETE_SKILL_SUCCESS: 'Skill deleted successfully',
  DELETE_SKILL_FAIL: 'Skill deleting failed',
  ADD_SKILL_SUCCESS: 'Skill added successfully',
  ADD_SKILL_FAIL: 'Skill adding failed',
  DELETE_PROJECT_SUCCESS: 'Project deleted successfully',
  DELETE_PROJECT_FAIL: 'Project deleting failed',
  UPDATE_PROJECT_SUCCESS: 'Project updated successfully',
  UPDATE_PROJECT_FAIL: 'Project updating failed',
  ADD_PROJECT_SUCCESS: 'Project added successfully',
  ADD_PROJECT_FAIL: 'Project adding failed',
  UPDATE_PROJECTS_SUCCESS: 'Projects updated successfully',
  UPDATE_PROJECTS_FAIL: 'Projects updating failed',
  UPDATE_PROJECT_TEMPLATE_SUCCESS: 'Project template updated successfully',
  UPDATE_PROJECT_TEMPLATE_FAIL: 'Project template updating failed',
  ADD_PROJECT_TEMPLATE_SUCCESS: 'Project template added successfully',
  ADD_PROJECT_TEMPLATE_FAIL: 'Project template adding failed',
  UPDATE_HOLIDAY_ALLOWANCE_SUCCESS: 'Holiday Allowance updated successfully',
  UPDATE_HOLIDAY_ALLOWANCE_FAIL: 'Holiday Allowance update fail',
  UPDATE_HOME_OFFICE_ALLOWANCE_SUCCESS: 'Home Office Allowance updated successfully',
  UPDATE_HOME_OFFICE_ALLOWANCE_FAIL: 'Home Office Allowance update fail',
  UPDATE_HOME_OFFICE_ALLOWANCE_INFO: 'Home Office Allowance updated successfully. NOTE: The remaining home office days of this employee will be updated next month',
  UPDATE_PERSONAL_INFO_SUCCESS: 'Personal information update successfully',
  UPDATE_PERSONAL_INFO_FAIL: 'Personal information update fail',
  EXPORT_TIMESHEETS_SUCCESS: 'Timesheets exported successfully.',
  EXPORT_TIMESHEETS_FAIL: 'Timesheets export failed.',
  ADD_EMPLOYEE_SUCCESS: 'New team member added successfully.',
  ADD_EMPLOYEE_FAIL: 'New team member adding failed.',
  ADD_EMPLOYEE_EMAIL_FAILURE_WARNING: 'This email is already used!',
  FETCH_EMPLOYEE_PROJECTS_FAIL: 'Could not fetch employee projects!',
  FETCH_LAST_VIEWED_JOB_FAIL: 'Could not fetch last seen job!',
  FETCH_EMPLOYEE_TIMESHEETS_NO_FILES:
    'This employee has not tracked time for any projects yet.',
  UPDATE_EMPLOYEES_ROLE_SUCCESS: 'Role has been successfully updated.',
  UPDATE_EMPLOYEES_ROLE_FAIL: 'Role update has failed',
  FETCH_FACE_TO_FACE_INTERVIEW_INFO_FAIL:
    'Face to face interview info loading failed.',
  FETCH_EMPLOYEES_FAIL: 'Employees loading failed.',
  FETCH_SKILL_SUGGESTIONS_FAIL: 'Search for CV skills failed.',
  FETCH_SUPPLIER_EMPLOYEES_FAIL: 'Partner employees loading failed.',
  FETCH_RECRUITER_EMPLOYEES_FAIL: 'Recruiter employees loading failed.',
  FETCH_CANDIDATES_FAIL: 'Candidates loading failed.',
  EXPORT_CANDIDATES_FAIL: 'Export candidates failed.',
  FETCH_TRANSFERRED_CANDIDATES_FAIL: 'Transferred candidates loading failed.',
  FETCH_USERS_SKILLS_FAIL: 'Could not fetch users skills.',
  CREATE_BENEFIT_SUCCESS: 'Perks & Equipment add successfully',
  CREATE_BENEFIT_FAIL: 'Perks & Equipment adding failed',
  FETCH_BENEFIT_TEMPLATES_FAIL: 'Could not fetch perks & equipment templates!',
  FETCH_BENEFITS_FAIL: 'Could not fetch perks & equipment!',
  DELETE_BENEFIT_SUCCESS: 'Perks & Equipment deleted successfully',
  DELETE_BENEFIT_FAIL: 'Perks & Equipment deleting failed',
  UPDATE_BENEFIT_SUCCESS: 'Perks & Equipment updated successfully',
  UPDATE_BENEFIT_FAIL: 'Perks & Equipment updating failed',
  TRACKED_DAYS_SUCCESS: 'Tracking days successfull',
  TRACKED_DAYS_FAIL: 'Tracking days failed',
  CANNOT_TRACK_GIVEN_DAYS: 'You cannot track time prior to the employment start date. Please talk to your HR manager for details.',
  FETCH_RELATED_OPPORTUNITIES_FAIL: 'Could not fetch related opportunities.',
  UPDATE_GENERAL_INFO_SUCCESS: 'General information updated successfully',
  UPDATE_GENERAL_INFO_FAIL: 'General information updating failed',
  UPDATE_CONTACT_INFO_SUCCESS: 'Contact information updated successfully',
  UPDATE_CONTACT_INFO_FAIL: 'Contact information updating failed',
  GENERATE_CV_PDF_SUCCESS: 'Your CV has been updated.',
  GENERATE_CV_PDF_INFO_WARNING:
    'Please check that all the necessary information has been added or make changes where necessary.',
  SOVREN_PARSING_SUCCESS:
    'CV converted successfully, please review the details.',
  SOVREN_PARSING_UNSUPPORTED_FORMAT: 'Please use only pdf or word formats',
  GENERATE_CV_PDF_FAIL:
    'Your PDF import has failed, please complete your CV manually.',
  FETCH_PREFERENCES_FAIL: 'Could not fetch candidate preferences.',
  UPDATE_PREFERENCES_SUCCESS: 'Preferences updated successfully',
  UPDATE_PREFERENCES_FAIL: 'Preferences updating failed',
  FETCH_CANDIDATE_FEEDBACK_INFORMATION_FAIL:
    'Could not fetch video interview feedback information.',
  EXPORT_CV_SUCCESS: 'Export successful',
  EXPORT_CV_FAIL: 'Export failed',
  UPDATE_CANDIDATE_ASSIGNEE_SUCCESS: 'Assignee updated successfully',
  UPDATE_CANDIDATE_ASSIGNEE_FAIL: 'Assignee updateing failed',
  FETCH_PROGRESS_DATA_FAIL: 'Could not load progress bar step',
  VIDEO_INTERVIEW_MISSING_STEPS_WARN:
    'You cannot proceed until you complete your CV and job preferences.',
  VIDEO_INTERVIEW_MISSING_STEPS_INFO_LIST:
    'In order to proceed, you need to fill out your Job Preferences.',
  VIDEO_INTERVIEW_ALLOW_DEVICES:
    'Please allow the platform to use your devices from browser settings.',
  MEDIA_RECORDER_NOT_SUPPORTED: `We've detected that you're using an old version of [browserName]. In order to record your video responses, please update your browser or use alternatives such as Chrome, Firefox, Opera, Edge or Safari.`,
  EXPORT_SUCCESS: 'Export successful.',
  TRACK_DAYS: 'Tracking days successfull',
  EDIT_EMPLOYEE_ACTIVE_COMPENSATION: 'Active Compensation updated successfully',
  EDIT_BANK_DETAILS: 'Bank Details updated successfully',
  EDIT_CONTRACT_INFO_SUCCESS: 'Contract information updated successfully',
  EDIT_CONTRACT_INFORMATION_FAIL: 'Update contract information failed!',
  FETCH_CONTRACT_INFORMATION_FAIL: 'Could not fetch contract information.',
  EDIT_GENERAL_INFO: 'Information successfully edited.',
  REQUEST_HOLIDAY_SUCCESS: 'Holiday request is successfull',
  AGENCY_INFO_EDIT: 'Agency info was successfully edited.',
  CONTACT_INFO_SUCCESS: 'Contact info was successfully updated.',
  BASIC_INFO_SUCCESS: 'Basic info was successfully updated.',
  DELETE_AVATAR_SUCCESS: 'Avatar was successfully deleted.',
  UPDATE_AVATAR_SUCCESS: 'Avatar was successfully updated.',
  FETCH_CANDIDATE_PROFILE_FAIL: 'Could not fetch candidate profile.',
  FETCH_CANDIDATE_APPLICATION_PAGE_FAIL: 'Could not fetch application page.',
  FETCH_CANDIDATE_INTERVIEW_AVAILABILITY_FAIL: 'You cannot proceed furhter.',
  FETCH_CANDIDATE_PERSONAL_INFORMATION_FAIL:
    'Could not fetch candidate personal information.',
  DOWNLOAD_EMPLOYEE_CV_FAIL: 'Failed to download employee cv.',
  FETCH_EMPLOYEE_UPLOADED_CV_FAIL: 'Could not fetch employee cv information',
  FETCH_PERSONAL_INFORMATION_FAIL: 'Could not fetch personal information.',
  FETCH_PROFIOLE_FAIL: 'Could not fetch employee profile.',
  CLEAR_EMPLOYEE_CURRENT_PROJECT_FAIL:
    'Could not clear employee current project.',
  UPDATE_OCCUPATION_STATUS_SUCCESS: 'Employee status successfully updated.',
  UPDATE_OCCUPATION_STATUS_FAIL: 'Update employee status failed!',
  CANDIDATE_BOOK_INTERVIEW_SUCCESS:
    'You successfully booked face to face interview.',
  CANDIDATE_BOOK_INTERVIEW_FAIL: 'Booking face to face interview failed!',
  CANDIDATE_HAS_BOOKED_INTERVIEW_WARN:
    "You've already booked a face to face interview with a member of our team.",
  FETCH_CV_INFORMATION_FAIL: 'Could not fetch CV information.',
  UPDATE_CANDIDATE_CREATE_PROFILE_JOURNEY_FAIL: 'Could not save information',
  FETCH_CANDIDATE_CREATE_PROFILE_JOURNEY_FAIL: 'Could not fetch information',
  UPDATE_RECRUITER_SUCCESS: 'Employee recruiter successfully updated.',
  UPDATE_RECRUITER_FAIL: 'Update recruiter failed!',
  COMPLETE_TIMESHEET_SUCCESS: 'Complete timesheet successfully',
  COMPLETE_TIMESHEET_FAIL: 'Failed to complete timesheet',
  CLIENT_USERS_FETCH_FAIL: 'Failed to fetch client users',
  EMPLOYEE_WORKSPACE_MIGRATION_INFO_UPDATE_FAIL: 'Failed to update workspace migration info',
  DELETE_SICK_LEAVE_UNSUCCESSFUL: 'Deleting your sick leave was unsuccessful, please talk to your HR manager for more details.',
  NOTIFY_ABOUT_SICK_LEAVE_PERIOD_CHANGE: 'Please notify HR Manager about the change in the sick leave period.',
  FETCH_EMPLOYEE_OVERVIEW_INFORMATION_FAIL: 'Could not fetch employee\'s overview.',
};

// TODO FIX MESSAGES
export const AGENCY_MESSAGES = {
    // Agency Home Information
    FETCH_HOME_INFORMATION_FAIL: 'Home information load failed.',

    // Agency Logo
    UPDATE_AGENCY_LOGO_SUCCESS: 'Agency logo was successfully edited.',
    UPDATE_AGENCY_LOGO_FAIL: 'Agency logo editing failed.',
    DELETE_AGENCY_LOGO_SUCCESS: 'Agency logo was successfully removed.',
    DELETE_AGENCY_LOGO_FAIL: 'Agency logo removing failed.',

    // Agency Basic Info
    UPDATE_BASIC_INFO_SUCCESS: 'Agency information was successfully edited.',
    UPDATE_BASIC_INFO_FAIL: 'Agency information editing failed.',
    FETCH_BASIC_INFO_FAIL: 'Agency information loading failed.',

    // Agency TimeSheets
    FETCH_TIMESHEETS_FAIL: 'Timesheets loading failed.',
    EXPORT_TIMESHEETS_SUCCESS: 'Timesheets exported successfully.',
    EXPORT_TIMESHEETS_FAIL: 'Timesheets export failed.',

    // Agency Performance Criterias
    UPDATE_CRITERIA_SUCCESS: 'Performance criterias updated successfully.',
    UPDATE_CRITERIA_FAIL: 'Performance criterias update fail.',
    FETCH_PERFORMANCE_CRITERIAS_FAIL: 'Failed to load performance criterias.',

    // Agency Holiday Allowence
    UPDATE_HOLIDAY_ALLOWANCE_SUCCESS: 'Holiday Allowance updated successfully.',
    UPDATE_HOLIDAY_ALLOWANCE_FAIL: 'Holiday Allowance update fail.',

    // Agency Employee
    FETCH_EMPLOYEE_SETTINGS_FAIL: 'Failed to load employee settings.',

    // Agency Important Documents Success
    UPDATE_IMPORTANT_DOCUMENTS_SUCCESS:
        'Important documents updated successfully.',
    UPLOAD_IMPORTANT_DOCUMENTS_FAIL: 'Failed upload important documents.',

    DELETE_IMPORTANT_DOCUMENTS_SUCCESS:
        'Important document deleted successfully.',
    DELETE_IMPORTANT_DOCUMENTS_FAIL: 'Failed delete important documents.',

    DOWNLOAD_IMPORTANT_DOCUMENTS_FAIL: 'Failed download important documents.',

    // Agency Training
    ADD_TRAINING_SUCCESS: 'Training added successfully.',
    ADD_TRAINING_FAIL: 'Failed adding training.',

    DELETE_TRAINING_SUCCESS: 'Training deleted successfully.',
    DELETE_TRAINING_FAIL: 'Failed deleting training.',

    FETCH_TRAININGS_FAIL: 'Failed fetching trainings.',

    UPDATE_TRAINING_SUCCESS: 'Training updated successfully.',
    UPDATE_TRAINING_FAIL: 'Failed updating training.',

    // Agency Training Files
    UPLOAD_TRAINING_FILES_SUCCESS: 'Training files uploaded successfully.',
    UPLOAD_TRAINING_FILES_FAIL: 'Failed uploading training files.',

    DOWNLOAD_TRAINING_FILE_FAIL: 'Failed downloading training file.',

    DELETE_TRAINING_FILE_SUCCESS: 'Training file deleted successfully',
    DELETE_TRAINING_FILE_FAIL: 'Failed deleting training file.',

    // Agency Training Topic
    ADD_TRAINING_TOPIC_SUCCESS: 'Topic added successfully.',
    ADD_TRAINING_TOPIC_FAIL: 'Failed adding topic.',

    UPDATE_TRAINING_TOPIC_SUCCESS: 'Topic updated successfully.',

    REMOVE_TRAINING_TOPIC_FAIL: 'Failed deleting topic.',
    REMOVE_TRAINING_TOPIC_SUCCESS: 'Topic deleted successfully.',

    FETCH_AGENCY_PROJECTS_FAIL: 'Failed fetching agency perks & equipment',

    // Agency Training Topic Videos
    ADD_TRAINING_TOPIC_VIDEO_SUCCESS: 'Video link added successfully.',
    ADD_TRAINING_TOPIC_VIDEO_FAIL: 'Failed adding video link.',

    DELETE_TRAINING_TOPIC_VIDEO_SUCCESS: 'Video deleted successfully.',
    DELETE_TRAINING_TOPIC_VIDEO_FAIL: 'Failed deleting video link.',

    FETCH_TRAINING_TOPIC_VIDEOS_FAIL: 'Failed fetching videos link.',

    // Agency Tasks
    FETCH_TASKS_FAIL: 'Failed fetching tasks.',

    //Agency Settings
    UPDATE_EVROTRUST_SETTINGS_SUCCESS: 'Evrotrust settings updated successfully.',
    UPDATE_EVROTRUST_SETTINGS_FAIL: 'Evrotrust settings update fail.',
    FETCH_EVROTRUST_SETTINGS_FAIL: 'Failed fetching evrotrust information.',
    UPDATE_IS_USING_EVROTRUST_SUCCESS: 'Using evrotrust updated.',
    UPDATE_IS_USING_EVROTRUST_FAIL: 'Update using evrotrust failed.',

    //Agency Management Page
    FETCH_USER_MANAGEMENT_PAGE_FAIL: 'FETCH_USER_MANAGEMENT_PAGE_FAIL',
    FETCH_DELETED_USERS_FAIL: 'Failed fetching deleted users',

    //Calendly
    UPDATE_CALENDLY_SETTINGS_SUCCESS: 'Calendly settings updated successfully',
    UPDATE_CALENDLY_SETTINGS_FAIL: 'Calendly settings update fail.',
    FETCH_CALENDLY_SETTINGS_FAIL: 'Failed fetching calendly information.',

    UPDATE_TERMS_AND_CONDITIONS_VERSION_FAIL:
        'Failed to update terms and conditions version.',
    GET_TERMS_AND_CONDITIONS_FAIL: 'Failed to get terms and conditions.',
    GET_AGENCY_NAME_FAIL: 'Failed to get agency name.',
    GET_AGENCY_MAIN_ADMIN_FAIL: 'Failed to get agency main admin.',

    GET_AGENCY_REVENUE: 'Failed to get agency revenue.',

    FETCH_AGENCY_SETTINGS_FAIL: 'Failed to fetch agency settings.',
    AGENCY_SETTINGS_UPDATE_SUCCESS: 'Agency settings updated successfully.',
    AGENCY_SETTINGS_UPDATE_FAIL: 'Failed to update agency settings.',
    AGENCY_NAME_ALREADY_EXISTS: 'Company name already exists!'
};

export const EMAIL_MESSAGES = {
    EMAIL_VERIFICATION_SUCCESS: 'Email successfully verified. Please login.',
    EMAIL_ALREADY_USED: 'The email address is already in use by another account.',
    EMAIL_DISABLED:
        'This email is already in use by a disabled profile. Please go to Settings / Deleted Users and either restore the deleted account or permanently delete it to enable usage of the same email address.',
    ACCOUNT_WITH_EMAIL_ALREADY_EXISTS: 'An account with this email already exists.',
};

export const DASHBOARD_MESSAGES = {
    FETCH_DASHBOARD_PAGE_INFORMATION_FAIL:
        'Could not fetch dashboard information.',
};

export const CLIENT_MESSAGES = {
    ADD_CLIENT_SUCCESS: 'The client has been registered successfully.',
    ADD_CLIENT_FAIL: 'Client registering failed.',
    ADD_CLIENT_NO_ACCOUNT_MANAGER_FAIL: 'There is no Account Manager assigned!',
    ADD_CLIENT_EMAIL_FAILURE_WARNING: 'This email is already used!',
    UPDATE_BASIC_INFO_SUCCESS: 'Basic information was successfully updated.',
    UPDATE_BASIC_INFO_FAIL: 'Basic information editing failed.',
    FETCH_BASIC_INFO_FAIL: 'Basic information loading failed.',
    FETCH_CLIENT_INFO_FAIL: 'Client information loading failed.',
    UPDATE_CONTACT_INFO_SUCCESS: 'Contact information was successfully updated.',
    UPDATE_CONTACT_INFO_FAIL: 'Contact information editing failed.',
    FETCH_CONTACT_INFO_FAIL: 'Contact information loading failed.',
    UPDATE_AVATAR_SUCCESS: 'Logo was successfully edited.',
    UPDATE_AVATAR_FAIL: 'Logo editing failed.',
    DELETE_AVATAR_SUCCESS: 'Logo was successfully removed.',
    DELETE_AVATAR_FAIL: 'Logo removing failed.',
    ADD_PROJECT_SUCCESS: 'Project added successfully.',
    FETCH_PROJECT_REVIEW_FAIL: 'Client review loading failed.',
    UPDATE_PROJECT_SUCCESS: 'Project updated successfully',
    ADD_PROJECT_FAIL: 'Could not add project.',
    UPDATE_PROJECT_FAIL: 'Project updating failed',
    FETCH_CLIENT_PROJECTS_FAIL: 'Client perks & equipment loading failed.',
    ADD_PROJECT_FEEDBACK_SUCCESS: 'Feedback added successfully.',
    ADD_PROJECT_FEEDBACK_FAIL: 'Add feedback failed.',
    ADD_PROJECT_EMPLOYEE_SUCCESS: 'Employee added successfully.',
    ADD_PROJECT_EMPLOYEE_FAIL: 'Add employee failed.',
    ADD_DEDICATED_PROJECT_EMPLOYEE_SUCCESS: 'Employee added successfully.',
    ADD_DEDICATED_PROJECT_EMPLOYEE_FAIL: 'Add employee failed.',
    EDIT_PROJECT_EMPLOYEE_SUCCESS: 'Employee edited successfully.',
    EDIT_PROJECT_EMPLOYEE_FAIL: 'Edit employee failed.',
    UPDATE_INVOICE_INFO_SUCCESS: 'Invoice information was successfully updated.',
    UPDATE_INVOICE_INFO_FAIL: 'Invoice information editing failed.',
    DELETE_PROJECT_EMPLOYEE_FAIL: 'Employee remove failed',
    DELETE_PROJECT_EMPLOYEE_SUCCESS: 'Employee successfully removed',
    DELETE_DEDICATED_PROJECT_EMPLOYEE_SUCCESS: 'Employee successfully removed',
    DELETE_DEDICATED_PROJECT_EMPLOYEE_FAIL: 'Employee remove failed',
    UPDATE_ACCOUNT_MANAGER_INFO_SUCCESS: 'New account manager/status was successfully assigned.',
    UPDATE_ACCOUNT_MANAGER_INFO_FAIL: 'Аccount manager editing failed!',
    FETCH_TASKS_FAIL: 'Fail fetching tasks.',
    ADD_PROJECT_FILES_SUCCESS: 'Files successfully added.',
    ADD_PROJECT_FILES_FAIL: 'Files adding failed.',
    ADD_PROJECT_LINK_SUCCESS: 'Link successfully added.',
    ADD_PROJECT_LINK_FAIL: 'Link adding failed.',
    FETCH_PROJECT_DOCUMENTS_FAIL: 'Poject documents loading failed.',
    DOWNLOAD_PROJECT_FILE_FAIL: 'file download failed.',
    DELETE_PROJECT_DOCUMENT_SUCCESS: 'Document successfully deleted.',
    DELETE_PROJECT_DOCUMENT_FAIL: 'Document deleting failed.',
    FETCH_PROJECTS_FEEDBACK_FAIL: 'Projects feedback loading failed.',
    INSUFFICIENT_TIME_SLOTS: 'You should provide at least two interview time slots.',
    FETCH_CLIENT_PROJECT_FAIL: 'Fail fetching client project.',
    FETCH_ALL_CLIENT_TEAM_HISTORY_FAIL: 'Fail fetching employee history.',
    FETCH_CLIENT_PROJECT_EMPLOYEE_HISTORY_FAIL: 'Fail fetching employee history.',
    CLIENT_UPDATE_FLAG_SUCCESS: 'Client flag successfully updated.',
    CLIENT_UPDATE_FLAG_FAIL: 'Client flag update failed.',
    CLIENT_FETCH_INVOICING_FAIL: 'Client invoicing fetch failed',
    FETCH_CLIENT_TOTAL_REVENUE_FAIL: 'Client total revenue fetching failed.',
    CLIENTS_DELETION_SUCCESS: 'Clients deleted successfully',
    CLIENTS_DELETION_FAILED: 'Clients deletion failed.',
    ADD_CLIENT_ADMIN_FAILED: 'Client admin adding failed',
    ADD_CLIENT_ADMIN_SUCCESS: 'Client admin added successfully',
    DELETE_CLIENT_ADMIN_FAILED: 'Client admin deleting failed',
    DELETE_CLIENT_ADMIN_SUCCESS: 'Client admin deleted successfully',
    FETCH_CLIENT_JOBS_DASHBOARD_FAIL: 'Jobs loading failed.',
    FETCH_CLIENT_NOTIFICATIONS_FAIL: 'Notifications fetch failed.',
    UPDATE_CLIENT_NOTIFICATIONS_FAIL: 'Notifications status update failed.',
    CLIENT_MISSING_LOGO_WARNING: 'The client for this job is missing a logo. Please update that immediately or switch the job privacy back to unpublished.',
    FETCH_CLIENT_JOURNEY_INFORMATION_FAIL: 'Failed to fetch client journey information.',
    UPDATE_CLIENT_JOURNEY_INFORMATION_FAIL: 'Failed to update client journey information.'
};

export const SUPPLIER_MESSAGES = {
    ADD_SUPPLIER_SUCCESS: 'The partner has been registered successfully.',
    ADD_SUPPLIER_FAIL: 'Partner registering failed.',
    ADD_SUPPLIER_EMAIL_FAILURE_WARNING: 'This email is already used!',
    UPDATE_BASIC_INFO_SUCCESS: 'Basic information was successfully updated.',
    UPDATE_BASIC_INFO_FAIL: 'Basic information editing failed.',
    FETCH_BASIC_INFO_FAIL: 'Basic information loading failed.',
    FETCH_SUPPLIER_INFO_FAIL: 'Partner information loading failed.',
    UPDATE_CONTACT_INFO_SUCCESS: 'Contact information was successfully updated.',
    UPDATE_CONTACT_INFO_FAIL: 'Contact information editing failed.',
    UPDATE_INVOICE_INFO_SUCCESS: 'Invoice information was successfully updated.',
    UPDATE_INVOICE_INFO_FAIL: 'Invoice information editing failed.',
    FETCH_CONTACT_INFO_FAIL: 'Contact information loading failed.',
    UPDATE_AVATAR_SUCCESS: 'Logo was successfully edited.',
    UPDATE_AVATAR_FAIL: 'Logo editing failed.',
    DELETE_AVATAR_SUCCESS: 'Logo was successfully removed.',
    DELETE_AVATAR_FAIL: 'Logo removing failed.',
    UPDATE_ACCOUNT_MANAGER_INFO_SUCCESS:
        'New account manager was successfully assigned.',
    UPDATE_ACCOUNT_MANAGER_INFO_FAIL: 'Update account manager failed.',
    FETCH_SUPPLIER_EMPLOYEE_VIEW_PROFILE_DATA_FAIL:
        'Could not fetch partner profile.',
    UPDATE_HOLIDAY_ALLOWANCE_SUCCESS: 'Holiday Allowance updated successfully.',
    UPDATE_HOLIDAY_ALLOWANCE_FAIL: 'Holiday Allowance update fail.',
    FETCH_EMPLOYEE_SETTINGS_FAIL: 'Failed to load employee settings.',
    UPDATE_EVROTRUST_SETTINGS_SUCCESS: 'Evrotrust settings updated successfully.',
    UPDATE_EVROTRUST_SETTINGS_FAIL: 'Evrotrust settings update fail.',
    FETCH_EVROTRUST_SETTINGS_FAIL: 'Failed fetching evrotrust information.',
    SUPPLIER_UPDATE_FLAG_SUCCESS: 'Partner flag successfully updated.',
    SUPPLIER_UPDATE_FLAG_FAIL: 'Partner flag update failed.',
};

export const CONTRACTS_MESSAGES = {
    ADD_CONTRACT_SUCCESS: 'Contract was successfully added.',
    UPDATE_ESIGNATURE_STATUS: 'The e-signature status of the document has been updated.',
    ADD_CONTRACT_FAIL: 'Contract adding failed.',
    DELETE_CONTRACT_SUCCESS: 'Contract was successfully deleted.',
    DELETE_CONTRACT_FAIL: 'Contract deleting failed.',
    FETCH_CONTRACT_FILES_FAIL: 'Contract files loading failed.',
    FETCH_CONTRACTS_FAIL: 'Contracts loading failed.',
    NO_FILES_TO_DOWNLOAD: 'No files to download.',
    NO_FILES_TO_UPLOAD: 'Pdf file upload is required.',
    MAX_FILES_COUNT_REACHED: 'Not allowed to upload more files',
    ADD_FILE_FORMAT_FAIL: 'File format not permited.',
    ADD_EMPTY_FILE_FAIL: 'Empty files not permited.',
    REMIND_CONTRACT_FILE_SIGN_EMAIL_SUCCESS: 'Successfully sent email reminder',
    REMIND_CONTRACT_FILE_SIGN_EMAIL_FAIL: 'Could not sent email reminder',
    REMIND_CONTRACT_FILE_SIGN_EMAIL_FAIL_406:
        'The contract is not in sign pending status',
    CONTRACT_DOES_NOT_REQUIRE_SIGNATURE:
        'Reminder emails are only sent for contracts with e-signature.',
};

export const HOLIDAYS_MESSAGES = {
    CREATE_HOLIDAY_REQUEST_FAIL: 'Could not create holiday.',
    CREATE_HOLIDAY_REQUEST_SUCCESS: 'Holiday request is successful.',
    DUPLICATE_HOLIDAY_REQUEST_ERROR:
        'Holiday request for the selected dates already exists!',
    FETCH_HOLIDAY_REQUESTS_FAIL: 'Could not fetch holidays.',
    REJECT_HOLIDAY_REQUEST_FAIL: 'Could not reject holiday request.',
    REJECT_HOLIDAY_REQUEST_SUCCESS: 'Holiday request rejected.',
    APPROVE_HOLIDAY_REQUEST_FAIL: 'Could not approve holiday request.',
    APPROVE_HOLIDAY_REQUEST_SUCCESS: 'Holiday request(s) approved.',
    FETCH_HOLIDAY_ALLOWANCE_FAIL: 'Could not fetch holiday allowance',
    DELETE_HOLIDAY_REQUESTS_SUCCESS: 'Successfully deleted requests.',
    DELETE_HOLIDAY_REQUESTS_FAIL: 'Could not delete request.',
    DOWNLOAD_SIGNED_HOLIDAY_FILE_SUCCESS:
        'Successfully downloaded holiday file/s',
    DOWNLOAD_SIGNED_HOLIDAY_FILE_FAIL: 'Could not download holiday file/s',
    DOWNLOAD_SIGNED_HOLIDAY_FILE_FAIL_UNSIGNED:
        'Could not download holiday file/s due to missing signature status',
    DOWNLOAD_SIGNED_HOLIDAY_FILE_NOT_FOUND_FAIL:
        'Holiday document was not found.',
    REMIND_HOLIDAY_FILE_SIGN_EMAIL_SUCCESS: 'Successfully sent email reminder',
    REMIND_HOLIDAY_FILE_SIGN_EMAIL_FAIL: 'Could not sent email reminder',
    REMIND_HOLIDAY_FILE_SIGN_EMAIL_FAIL_406:
        'The holiday is not in sign pending status',
    APPROVE_HOLIDAY_REQUEST_FAIL_MISSING_CONTRACT_DATA:
        'Holiday approval failed, due to missing contract information in the employee profile:',
    START_GENERATING_PDFS:
        'Your PDFs are being generated. We will notify you when this has been completed.',
    PDFS_GENERATED_SUCCESS: 'PDF generation has been successfully completed.',
};

export const STATIC_DATA_MESSAGES = {
    FETCH_STATIC_LANGUAGES_FAIL: 'Loading language list failed.',
};

export const AUTH_MESSAGES = {
    UPDATE_PASSWORD_SUCCESS: 'Password set successfully.',
    UPDATE_PASSWORD_FAIL: 'Password set unsuccessful.',
    VALIDATE_SESSION_FAIL:
        'Session invalid or blocked due to inactivity. Please re-enter your credentials',
    VERIFY_EMAIL_SUCCESS: 'Email verified successfully',
    VERIFY_EMAIL_FAIL: 'Email verification failed.',
    UNAUTHORIZED_ACCESS_RESPONSE_MESSAGES:
        'You are not authorized to access this page.',
    VERIFY_EMAIL_RESENT: `Your verification link has expired, we've send a new one to your email.`,
    ONBOARDING_UPDATE_SUCCESS: `Success.`,
    ONBOARDING_UPDATE_SUCCESS_CANDIDATE: `Welcome to Motion Software.`,
    USER_REGISTER_SUCCESS:
        'Successfully registered. A verification link has been sent to your email account.',
    RESET_PASSWORD_SUCCESS: 'Your password has been successfully reset.',
    SEND_PASSWORD_RESET_EMAIL_SUCCESS:
        'A reset password link has been sent to your email account.',
    SEND_PASSWORD_RESET_EMAIL_FAIL:
        'Couldn’t find your email. Make sure that the email address is correct.',
    SEND_VERIFICATION_EMAIL_SUCCESS: 'A link has been sent to your email inbox.',
    SOMETHING_WENT_WRONG: 'Something went wrong, please try again',
    LOGIN_SUCCESS: 'Login successful!',
    USERNAME_OR_PASSWORD_WRONG: 'Wrong username or password.',
    EMAIL_NOT_VERIFIED:
        'Your email has not been verified. Open verification link which has been sent to your emailbox.',
    EMAIL_CHANGED_AND_LOGGED_OUT:
        "We're logging you out, please check your new email inbox for the access link.",
    INSUFFICIENT_PERMISSIONS: 'Insufficient permissions to view page.',
    EMAIL_ALREADY_USED_WARNING: 'This email is already used!',
    CANDIDATE_REGISTER_SUCCESS:
        'Thank you for signing up. Please check your email to verify your account.',
    CLIENT_REGISTER_SUCCESS: 'Thank you for signing up. ',
    AGENCY_REGISTER_SUCCESS: 'Thank you for signing up. ',
    SUPPLIER_REGISTER_SUCCESS:
        'Thank you for signing up. Please check your email to verify your account.',
    CURRENTLY_DISABLED_REGISTRATION_FORM:
        'This registration form is currently disabled.',
    NO_ACCOUNT_ASSOCIATED_WITH_EMAIL: 'We didn\'t find an associated profile with your email, please complete the sign-up process.',
    SIGNED_IN_ANOTHER_TAB_OR_WINDOW: 'You signed in with another tab or window.',
    SIGNED_OUT_ANOTHER_TAB_OR_WINDOW: 'You signed out in another tab or window.',
};

export const PERFORMANCE_REVIEWS_MESSAGES = {
    PERFORMANCE_REVIEWS_FETCH_FAIL: 'Performance Reviews loading failed.',
    PERFORMANCE_REVIEW_CREATE_FAIL: 'Performance review created failed',
    PERFORMANCE_REVIEW_EDIT_FAIL: 'Performance review edit failed',
    PERFORMANCE_REVIEW_CREATE_SUCCESS: 'Performance review created success',
    PERFORMANCE_REVIEW_EDIT_SUCCESS: 'Performance review edited successfully',
    PERFORMANCE_REVIEW_DELETE_SUCCESS: 'Performance review deleted successfully',
};

export const SEARCH_MESSAGES = {
    SEARCH_RESULTS_FETCH_FAIL: 'Could not perform search.',
};

export const INVOICES_MESSAGES = {
    ADD_INVOICE_SUCCESS: 'Invoice was successfully added.',
    ADD_INVOICE_FAIL: 'Invoice adding failed.',
    UPDATE_INVOICE_STATUS_SUCCESS: 'Invoice status was successfully updated.',
    UPDATE_INVOICE_STATUS_FAIL: 'Invoice status updating failed.',
    FETCH_INVOICE_FILES_FAIL: 'Invoice files loading failed.',
    FETCH_INVOICE_FAIL: 'Invoices loading failed.',
    NO_FILES_TO_DOWNLOAD: 'No files to download.',
    ADD_FILE_FORMAT_FAIL: 'File format not permited.',
    ADD_EMPTY_FILE_FAIL: 'Empty files not permited.',
    EXPORT_TIMESHEETS_SUCCESS: 'Invoice timesheets exported successfully.',
    EXPORT_TIMESHEETS_FAIL: 'Invoice timesheets export failed.',
    RESEND_EMAILS_SUCCESS: 'Resend email successfully',
    RESEND_EMAILS_FAIL: 'Resend email failed',
    DELETE_INVOICE_SUCCESS: 'Invoice deleted successfully.',
    DELETE_INVOICE_FAIL: 'Invoice deletion failed.',
    EDIT_INVOICE_SUCCESS: 'Invoice edited successfully.',
    EDIT_INVOICE_FAIL: 'Invoice edit failed.',
};

export const USER_MESSAGES = {
    DELETE_USERS_SUCCESS: 'Profile deleted successfully.',
    RESTORE_USERS_SUCCESS: 'Users restored successfully.',
    DELETE_USERS_FAIL: 'Profile delete failed.',
    MARK_AS_DONE_FAIL: 'Mark as done failed.',
    RESTORE_USERS_FAIL: 'Users restore failed.',
    ACCEPT_TERMS_AND_CONDITIONS_INFO: 'Please accepnt terms and condititons',
    CUSTOMER_SUPPORT_REQUEST_SUCCESS:
        'Your customer support inquiry has been submitted successfully',
    CUSTOMER_SUPPORT_REQUEST_FAIL:
        'Your customer support problem submission failed',
    FETCH_USER_ACCOUNT_MANAGER_INFORMATION_FAIL:
        'Loading account manager information failed.',
    PERMANENTLY_DELETE_USERS_SUCCESS: 'User permanently deleted.',
    PERMANENTLY_DELETE_USERS_FAIL: 'User permanently delete failed.',
    UPDATE_USER_SUCCESS: 'User updated successfully.',
    UPDATE_USER_FAIL: 'User update failed.',
    FIND_A_MATCH_MISSING_CV_WARNING: 'The user is missing a CV, so finding a match has failed.',
    TERMINATE_USER_EMPLOYMENT_SUCCESS: "User's termination date updated successfully.",
    TERMINATE_USER_EMPLOYMENT_FAIL: "Updating user's termination date failed.",
};

export const RECRUITER_MESSAGES = {
    ADD_RECRUITER_SUCCESS: 'The recruiter has been registered successfully.',
    ADD_RECRUITER_FAIL: 'Recruiter registering failed.',
    ADD_RECRUITER_EMAIL_FAILURE_WARNING: 'This email is already used!',
    UPLOAD_RECRUITER_EMPLOYEE_CV_SUCCESS: 'Successfully uploaded employee CV.',
    FETCH_RECRUITER_INFO_FAIL: 'Recruiter information loading failed.',
    FETCH_RECRUITER_EMPLOYEES_FAIL: 'Could not get employees information.',
    FETCH_RECRUITER_EMPLOYEE_FAIL: 'Could not get employee information.',
    UPLOAD_RECRUITER_PROFILE_CV_FAILED: 'Could not upload employee CV.',
    DOWNLOAD_RECRUITER_PROFILE_CV_SUCCESS: 'Successfully downloaded profile CV.',
    DOWNLOAD_RECRUITER_PROFILE_CV_FAIL: 'Could not download profile CV.',
    DOWNLOAD_RECRUITER_PROFILE_CV_MISSING_CV_FAIL:
        'No CV was found for download.',
    DELETE_RECRUITER_PROFILES_SUCCESS: 'Successfully deleted profile/s.',
    DELETE_RECRUITER_PROFILES_FAIL: 'Could not delete profile/s.',
    DELETE_RECRUITER_PROFILE_CV_SUCCESS: 'Successfully deleted profile CV.',
    DELETE_RECRUITER_PROFILE_CV_FAIL: 'Could not delete profile CV.',
    UPDATE_AVATAR_SUCCESS: 'Logo was successfully edited.',
    UPDATE_AVATAR_FAIL: 'Logo editing failed.',
    DELETE_AVATAR_SUCCESS: 'Logo was successfully removed.',
    DELETE_AVATAR_FAIL: 'Logo removing failed.',
    UPDATE_BASIC_INFO_SUCCESS: 'Basic information was successfully updated.',
    UPDATE_BASIC_INFO_FAIL: 'Basic information editing failed.',
    UPDATE_CONTACT_INFO_SUCCESS: 'Contact information was successfully updated.',
    UPDATE_CONTACT_INFO_FAIL: 'Contact information editing failed.',
    UPDATE_ACCOUNT_MANAGER_INFO_SUCCESS:
        'New account manager was successfully assigned.',
    UPDATE_ACCOUNT_MANAGER_INFO_FAIL: 'Аccount manager editing failed!',
};

export const TASK_MESSAGES = {
    CREATE_TASK_SUCCESS: 'Task created successfully.',
    CREATE_TASK_FAIL: 'Task creating failed.',
    EDIT_TASK_SUCCESS: 'Task edited successfully.',
    EDIT_TASK_FAIL: 'Task editing failed.',
    DELETE_TASK_SUCCESS: 'Task deleted successfully.',
    DELETE_TASK_FAIL: 'Task deleting failed.',
    FETCH_TASKS_FAIL: 'Tasks loading failed.',
};

export const JOB_OPPORTUNITY_MESSAGES = {
    FETCH_JOBS_FAIL: 'Job Opportunities loading failed.',
    UPLOAD_IMAGE_FAIL: 'Uploade image failed.',
    DELETE_IMAGE_FAIL: 'Delete image failed.',
    CREATE_JOB_SUCCESS: 'Job created successfully.',
    CREATE_JOB_FAIL: 'Job creating failed.',
    UPDATE_JOB_SUCCESS: 'Job was successfully updated.',
    UPDATE_JOB_FAIL: 'Job updating failed.',
    UPDATE_JOB_PRIVACY_SUCCESS: 'Job privacy was successfully updated.',
    UPDATE_JOB_PRIVACY_FAIL: 'Job privacy updating failed.',
    DELETE_JOB_SUCCESS: 'Job was successfully deleted.',
    DELETE_JOB_FAIL: 'Job deleting failed.',
    FETCH_JOB_DETAILS_FAIL: 'Job Opportunity details loading failed.',
    FETCH_JOBS_TECHNOLOGIES_FAIL: 'Jobs technologies loading failed.',
    FETCH_JOB_PAGE_FAIL: 'Job page loading failed.',
    EXPORT_JOBS_SUCCESS: 'Export generated successfully.',
    EXPORT_JOBS_FAIL: 'Could not export jobs.',
    SEND_PROFILES_SUCCESS: 'Profiles sent successfully.',
    SEND_PROFILES_FAIL: 'Profiles sending failed.',
    SEND_EMAILS_TO_MATCHED_CANDIDATES_SUCCESS: 'Emails have been sent to candidates with matching profiles.',
    SEND_EMAILS_TO_MATCHED_CANDIDATES_FAIL: 'Emails haven\'t been sent to candidates with matching profiles.',
    FETCH_MATCHING_SCORE_FAIL: 'Fetching matching score failed.',
};

export const JOB_RECOMMENDATION_RATING_MESSAGES = {
    RATE_JOB_RECOMMENDATION_FAIL: 'Rating job recommendation failed.',
};

export const JOB_APPLICATION_MESSAGES = {
    ADD_APPLICATION_SUCCESS: 'Job application added successfully.',
    ADD_APPLICATION_FAIL: 'Job application adding failed.',
    FETCH_CANDIDATE_APPLICATION_FAIL: 'Could not fetch candidate application.',
    UPDATE_APPLICATION_STATUS_SUCCESS: 'Application status was successfully updated.',
    UPDATE_APPLICATION_STATUS_FAIL: 'Application status updating failed.',
    UPDATE_APPLICATION_ASSIGNEE_SUCCESS: 'Application assignee was successfully updated.',
    UPDATE_APPLICATION_ASSIGNEE_FAIL: 'Application assignee updating failed.',
    UPDATE_APPLICATION_START_DATE_SUCCESS: 'Application start date was successfully updated.',
    UPDATE_APPLICATION_START_DATE_FAIL: 'Application start date updating failed.',
    FETCH_CANDIDATE_APPLICATIONS_FAIL: 'Could not fetch candidate applications.',
    FETCH_CANDIDATE_APPLICATION_APPROVED_FAIL: 'Could not fetch approved application.',
    DELETE_JOB_APPLICATION_SUCCESS: 'Job application deleted successfully.',
    DELETE_JOB_APPLICATION_FAIL: 'Job application deleting failed.',
    SEND_JOB_APPLICATION_STATUS_REQUEST_SUCCESS: 'You have successfully submitted a request.',
    SEND_JOB_APPLICATION_STATUS_REQUEST_FAIL: 'Job application status request failed.'
};

export const DEPARTMENTS_MESSAGES = {
    FETCH_DEPARTMENTS_FAIL: 'Departments loading failed.',
    CREATE_DEPARTMENT_SUCCESS: `Department created successfully. You need to log in again if you are a department admin.`,
    CREATE_DEPARTMENT_FAIL: 'Department creating failed.',
    DELETE_DEPARTMENT_SUCCESS:
        'Department was successfully deleted. You need to log in again if you were a department admin.',
    DELETE_DEPARTMENT_FAIL: 'Department deleting failed.',
    FETCH_DEPARTMENT_DETAILS_FAIL: 'Department details loading failed.',
    EDIT_DEPARTMENT_SUCCESS:
        'Department was successfully edited. You need to log in again if you were or are a department admin.',
    EDIT_DEPARTMENT_FAIL: 'Department editing failed.',
    FETCH_DEPARTMENT_MEMBERS_FAIL: 'Department members loading failed.',
    REGISTER_DEPARTMENT_EMPLOYEE_SUCCESS:
        'Department employee added successfully.',
    REGISTER_DEPARTMENT_EMPLOYEE_FAIL: 'Department employee adding failed.',
    REPEATING_DEPARTMENT_EMPLOYEE:
        'The following employee is added as both member and admin of this department: {name}.',
    REPEATING_DEPARTMENT_EMPLOYEES:
        'The following employees are added as both member and admin of this department: {names}.',
};

export const HISTORICAL_NOTES_MESSAGES = {
    FETCH_HISTORICAL_NOTES_FAIL: 'Failed to load historical notes.',
    ADD_HISTORICAL_NOTES_FAIL: 'Failed to create historical note.',
    ADD_HISTORICAL_NOTES_SUCCESS: 'Historical note added successfully.',
    EDIT_HISTORICAL_NOTE_SUCCESS: 'Historical note edited successfully.',
    EDIT_HISTORICAL_NOTE_FAIL: 'Historical note edit failed.',
    DELETE_HISTORICAL_NOTE_SUCCESS: 'Historical note deleted succesfully.',
    DELETE_HISTORICAL_NOTE_FAIL: 'Historical note delete failed.',
};

export const VIDEO_MESSAGES = {
    UPLOAD_VIDEO_ANSWER_SUCCESS: 'Video answer uploaded successfully',
    UPLOAD_VIDEO_ANSWER_FAIL: 'Video answer upload failed',
    FETCH_VIDEO_QUESTION_FAIL: 'Video question loading failed',
    FETCH_VIDEO_INTERVIEW_CATEGORIES_FAIL:
        'Video interview categories loading failed',
};

export const CANDIDATE_MESSAGES = {
  UPDATE_CRITERIS_SUCCESS: 'Rating successfully updated.',
  UPDATE_CRITERIS_FAIL: 'Rate updating failed.',
  FETCH_CANDIDATE_JOURNEY_PAGE_FAIL: 'Could not fetch candidate journey page',
  ADD_CANDIDATE_SUCCESS: 'New candidate added successfully.',
  ADD_CANDIDATE_FAIL: 'New candidate adding failed.',
  ADD_CANDIDATE_EMAIL_FAILURE_WARNING: 'This email is already used!',
  CANDIDATE_PROFILE_STEPS_UPDATE_SUCCESS: 'Candidate profile successfully updated.',
  CANDIDATE_PROFILE_STEPS_UPDATE_FAIL: 'Candidate profile update failed.',
  FETCH_APPLICATION_PROCESS_INFORMATION_FAIL: 'Could not fetch application process information.',
  MARK_HIRED_NOTIFICATION_FAIL: 'Could not mark hired notification.',
};

export const CURRENCY_MESSAGES = {
    FETCH_CURRENCY_EXCHANGE_RATES_ERROR:
        'Could not fetch currency exchange rates.',
};

export const INVALID_ROUTE_MESSAGES = {
    INVALID_ROUTE: 'You have entered an invalid URL. Please try again.',
};

export const INTERVIEW_INVITATION_MESSAGES = {
    FETCH_CANDIDATE_INTERVIEW_INVITATIONS_FAIL:
        'Could not fetch candidate interview invitations.',
    UPDATE_INTERVIEW_DATE_FAIL: 'Interview date updating failed.',
    UPDATE_INTERVIEW_DATA_FAIL: 'Interview data updating failed.',
    UPDATE_INTERVIEW_STATUS_FAIL: 'Update interview status failed!',
    CREATE_INTERVIEW_INVITATION_FAIL: 'Interview invitation creation failed',
    INTERVIEW_DATE_IN_PAST:
        'The selected time slot is in the past. Please propose a new time slot for your interview.',
    INTERVIEW_RESCHEDULE_FAIL: 'Interview reschedule status failed!',
};

export const CHECK_OCCUPATION_STATUS_MESSAGE =
    'Please check if the employee status should be updated to "Billable".';

export const EXPLORE_TALENTS_MESSAGES = {
    FETCH_EXPLORE_TALENTS_FAIL: 'Could not fetch profiles.',
};
export const INVALID_PROJECTS_TOTAL_HOURS_MESSAGE =
    'Your projects total hours must be between 0 and 24.';

export const PUBLIC_HOLIDAYS_MESSAGE = {
    FETCH_PUBLIC_HOLIDAYS_FAIL: 'Could not fetch public holidays.',
};

export const MAIN_MENU_MESSAGES = {
    FETCH_MAIN_MENU_INFORMATION_FAIL: 'Could not fetch main menu information.',
};

export const CV_INVALID_FORMAT_MESSAGES = {
    IMAGE:
        'The document is just an image file. Please use an appropriate format.',
    UNPROCESSED: "The input document was recognized but could not be converted. Please make sure the file doesn't contain large images.",
    OTHER:
        'There was an issue converting the document, please try again or use a different document.',
};

export const BUSINESS_TRIP_MESSAGES = {
    CREATE_BUSINESS_TRIP_SUCCESS: 'Business trip added successfully.',
    CREATE_BUSINESS_TRIP_FAIL: 'Business trip adding failed.',
    FETCH_BUSINESS_TRIPS_FAIL: 'Could not fetch business trips.',
    DOWNLOAD_FILES_SUCCESS: 'Download files successful.',
    DOWNLOAD_FILES_FAIL: 'Download files failed.',
    EXPORT_BUSINESS_TRIPS_SUCCESS: 'Export expense reports successful.',
    EXPORT_BUSINESS_TRIPS_FAIL: 'Export expense reports failed.',
};

export const COACHING_OPPORTUNITIES_MESSAGES = {
    FETCH_COACHING_OPPORTUNITIES_FAIL: 'Failed to fetch coaching opportunities',
    FETCH_COACHING_OPPORTUNITY_FAIL: 'Failed to fetch coaching opportunity',
    CREATE_COACHING_OPPORTUNITY_SUCCESS: 'Coaching opportunity created successfully.',
    CREATE_COACHING_OPPORTUNITY_FAIL: 'Failed to create coaching opportunity.',
    EDIT_COACHING_OPPORTUNITY_SUCCESS: 'Coaching opportunity was successfully updated.',
    EDIT_COACHING_OPPORTUNITY_FAIL: 'Coaching opportunity update failed.',
    DELETE_COACHING_OPPORTUNITY_SUCCESS: 'Coaching opportunity was successfully deleted.',
    DELETE_COACHING_OPPORTUNITY_FAIL: 'Coaching opportunity delete failed.',
    CREATE_COACHING_OPPORTUNITY_SESSION_SUCCESS: 'Session created successfully.',
    CREATE_COACHING_OPPORTUNITY_SESSION_FAIL: 'Session creation failed.',
    FETCH_TAGS_SUGGESTIONS_FAIL: 'Failed fetching tags suggestions.',
    FETCH_COACHING_SESSIONS_FAIL: 'Failed fetching coaching sessions.',
    INVALID_CALENDLY_INFORMATION: 'Booking failed due to missing coach information, please talk to your account manager.',
};

export const DOCUMENTS_MESSAGES = {
    FETCH_DOCUMENTS_FAIL: 'Failed to fetch documents.',
    EXPORT_DOCUMENTS_DATA_SUCCESS: 'Export data successful.',
    EXPORT_DOCUMENTS_DATA_FAIL: 'Export data failed.',
    EXPORT_DOCUMENTS_FILES_SUCCESS: 'Export files successful.',
    EXPORT_DOCUMENTS_FILES_FAIL: 'Export files failed.',
    NO_SELECTED_DOCUMENTS_TO_EXPORT_WARNING: 'You should select documents to export.',
    NO_DOCUMENTS_TO_EXPORT_WARNING: 'There aren\'t any documents to be exported.',
    NO_PERMISSION_TO_DOWNLOAD_SOME_DOCUMENTS_WARNING: `You don't have permissions to download one or more of the selected documents. We've downloaded only the documents you have access to.`,
    NO_PERMISSION_TO_DOWNLOAD_DOCUMENT_WARNING: `You don't have permissions to download this document.`,
    NO_PERMISSION_TO_DOWNLOAD_ALL_DOCUMENTS_WARNING: `You don't have permissions to download these documents.`,
    EXPORT_DOCUMENTS_DATA_STARTED: 'Export files started. May take a few seconds.',
};

export const TRACKING_URL_MESSAGES = {
    COPY_TRACKING_URL_SUCCESS: 'Link copied successfully.',
};

export const REDIRECT_MESSAGES = {
    CANDIDATE_JOURNEY_REDIRECT_SHORTLY: "Success! You'll be redirected shortly.",
};

export const REPORTS_MESSAGES = {
    FETCH_REPORTS_PAGE_FAIL: 'Failed to fetch reports page.',
    EXPORT_REPORTS_PAGE_SUCCESS: 'Export generated successfully',
};

export const LANGUAGE_MESSAGES = {
    SWITCH_LANGUAGE_FAIL: 'Failed to switch language',
};

export const EXPORT_MESSAGES = {
    START_GENERATING: 'Your export is being generated, you will be able to view it shortly.',
    GENERATED_SUCCESSFULLY: 'Export generated successfully.',
};

export const FETCH_ERROR_MESSAGES = {
    FAILED_TO_FETCH: 'Failed to fetch',
};
