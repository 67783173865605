import { CLIENT_JOURNEY_STEPS } from "./ClientProfileJourneyConstants";

export const getClientInitialStepId = (journeyStepsIds) => {
    let stepId = '';

    const steps = CLIENT_JOURNEY_STEPS;

    for (let mainStepIndex = 0; mainStepIndex < steps.length; mainStepIndex++) {
        if (!journeyStepsIds.includes(steps[mainStepIndex]._id)) {
            stepId = steps[mainStepIndex]._id;
            break;
        }

        if (stepId) {
            break;
        }
    }

    return stepId;
};

export const getJourneyComponent = (currentStepId) => {
    switch (currentStepId) {
        case CLIENT_JOURNEY_STEPS[0]._id:
            return CLIENT_JOURNEY_STEPS[0].getComponent;
        case CLIENT_JOURNEY_STEPS[1]._id:
            return CLIENT_JOURNEY_STEPS[1].getComponent;
        default:
            throw new Error("Non-existing step");
    }
};

export const getClientMainStepTitles = () => CLIENT_JOURNEY_STEPS.map(x => x.title);
export const getClientCurrentMainStepTitle = (stepId) => CLIENT_JOURNEY_STEPS.find(x => stepId === x._id)?.title;
export const getClientCompletedMainStepTitles = (completedStepsIds) => CLIENT_JOURNEY_STEPS.filter(x => completedStepsIds.includes(x._id)).map(x => x.title);
export const getClientNextStepId = (currentStep) => {
    const currentIndex = CLIENT_JOURNEY_STEPS.indexOf(CLIENT_JOURNEY_STEPS.find(x => x._id === currentStep));
    const nextIndex = (currentIndex + 1);

    if (nextIndex >= CLIENT_JOURNEY_STEPS.length) {
        return -1;
    }
    
    return CLIENT_JOURNEY_STEPS[nextIndex]._id;
};
export const getClientFirstStepIdOfTitle = (stepTitle) => CLIENT_JOURNEY_STEPS.find(x => x.title === stepTitle)._id;
