import React from 'react';
import moment from 'moment';

import { ReactComponent as CalenderIcon } from 'assets/calender-icon.svg';
import { ReactComponent as TimeIcon } from 'assets/icons-clock.svg';

import { US_TIME_FORMAT, DATE_FORMAT_WITH_SLASH } from 'constants/commonConstants';

import './MyUpcomingInterviewCard.scss';

const MyUpcomingInterviewCard = ({ interview }) => {
    return (
        <div className="my-upcoming-interview-card">
            <p className="heading-xs color-dark-grey text-centered">Interview time</p>
            <p className="subheading-m color-grey text-centered">Here the client has proposed days and hours for the interview. <br/> Please choose below which is convenient for you.</p>
            <div className="date-time-wrapper">
                <div className="info-container">
                    <CalenderIcon />
                    <p className="subheading-xxl color-grey">{moment(interview.interviewDate?.date).format(DATE_FORMAT_WITH_SLASH)}</p>
                </div>
                <div className="info-container">
                    <TimeIcon />
                    <p className="subheading-xxl color-grey">
                        {
                            `${moment(interview.interviewDate?.date).format(US_TIME_FORMAT).split(' ').join('')} - ` +
                            `${moment(interview.interviewDate?.date).add(1, 'hours').format(US_TIME_FORMAT).split(' ').join('')}`
                        }
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MyUpcomingInterviewCard;
