import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getDisplayMode, getEmployeeBasicInfo } from 'reducers';

import { generateRawCv } from 'actions/rawCvActions';
import { showNotification } from 'actions/notificationActions';
import { exportCV } from 'actions/employeeActions';
import { exportSupplierEmployeesCVs } from 'actions/supplierActions';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import PaperCv from 'components/Shared/PaperCv';
import ImproveCv from 'components/CandidateProfileJourney/Steps/UploadResumeWrapper/CvCompletionPage/ImproveCv';
import CircularProgress from '@material-ui/core/CircularProgress';
import Base64 from 'utils/base64';
import { logEvent } from 'utils/amplitude';

import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { ROLES } from 'constants/userConstants';
import { CONTRACTS_MESSAGES } from 'constants/messageConstants';
import { ACCEPTED_FORMAT } from 'constants/commonConstants';

import './CvSection.scss';

const CvSection = ({
    userId,
    agencyId,
    isCandidateProfilePage,
    cvInformation,
    companyLogoUrl,
    isEditMode,
    enterEditMode,
    exitEditMode,
    handleSave,
    exportCandidateCV,
    exportCV,
    exportSupplierEmployeesCVs,
    updateCandidateJourneyCvPage,
    isCandidateMode,
    isAdminMode,
    isSupplierAdminMode,
    handleDownloadCV,
    uploadedCv,
    scrollToRef,
    isEmployee,
    role,
    basicInfo,
    generateRawCv,
    showNotification,
    dataUpdateState,
}) => {
    const intl = useIntl();

    const [sectionRefName, setSectionRefName] = useState('edit');
    const inputFilesRef = useRef();

    const editSection = (sectionName) => {
        setSectionRefName(sectionName);
        enterEditMode();
    }

    const handleOnInputChange = (files) => {
        if (files.length <= 1) {
            files.forEach(file => {
                if (file.size === 0) {
                    showNotification(CONTRACTS_MESSAGES.ADD_EMPTY_FILE_FAIL, NOTIFICATION_TYPES.ERROR);
                } else {
                    const fileExt = file.name.split('.').pop();

                    if (!ACCEPTED_FORMAT.some(x => x === fileExt)) {
                        showNotification(CONTRACTS_MESSAGES.ADD_FILE_FORMAT_FAIL, NOTIFICATION_TYPES.ERROR);
                    } else {
                        const reader = new FileReader();

                        reader.onload = async function (event) {
                            const modifiedDate = (new Date(file.lastModified)).toISOString().substring(0, 10);
                            const base64Text = Base64.encodeArray(event.target.result);

                            const data = new FormData();

                            data.append(`attachedfile1`, file);
                            data.append(`documentAsBase64String`, base64Text);
                            data.append(`documentLastModified`, modifiedDate);

                            await generateRawCv(agencyId, userId, data, role);
                        }

                        reader.readAsArrayBuffer(file);
                    }
                }
            })
        } else {
            showNotification(CONTRACTS_MESSAGES.MAX_FILES_COUNT_REACHED, NOTIFICATION_TYPES.ERROR);
        }
        inputFilesRef.current.value = '';
    };

    const exportCVsFunctions = {
        [ROLES.CANDIDATE]: exportCandidateCV,
        [ROLES.EMPLOYEE]: exportCV,
        [ROLES.ADMIN]: exportCV,
        [ROLES.SUPPLIER_EMPLOYEE]: exportSupplierEmployeesCVs,
        [ROLES.SUPPLIER_ADMIN]: exportSupplierEmployeesCVs,
    };

    const handleUploadCvClick = () => {
        logEvent(AMPLITUDE_EVENT_TYPES.CLICK_UPLOAD_RAW_CV, { rolesPermitted: [ROLES.ADMIN] });
        inputFilesRef.current.click();
    };

    const handleExportCV = () => {
        const user = {
            _id: userId,
            firstName: basicInfo.firstName,
            lastName: basicInfo.lastName
        };

        exportCVsFunctions[basicInfo.role]([user], agencyId);
    };

    return (
        <div className="cv-section">
            {dataUpdateState?.isDataUpdating
                ? <CircularProgress thickness={5} size={50} disableShrink className="page-loader" />
                : isEditMode
                    ? <ImproveCv
                        sectionRefName={sectionRefName}
                        employeeCvInformation={cvInformation}
                        agencyId={agencyId}
                        userId={userId}
                        discardAction={exitEditMode}
                        updateCandidateJourneyCvPage={updateCandidateJourneyCvPage}
                        isCandidateMode={isCandidateMode}
                        isEmployee={isEmployee}
                    />
                    : <div className="candidate-cv">
                        <PaperCv
                            scrollToRef={scrollToRef}
                            cvInformation={cvInformation}
                            companyLogoUrl={companyLogoUrl}
                            enterEditMode={editSection}
                            handleExportCV={handleExportCV}
                            enableEdit={true}
                        >
                            <PaperCv.ActionButtons />
                            <PaperCv.ProfileInformation />
                            <PaperCv.ProfileSummary />
                            <PaperCv.Skills />
                            <PaperCv.Education />
                            <PaperCv.Experience />
                            <PaperCv.Certificates />
                            <PaperCv.Languages />
                            <PaperCv.CompanyLogo />
                            {isCandidateProfilePage
                                ? <div className="journey-buttons-wrapper">
                                    {(isAdminMode || isSupplierAdminMode) &&
                                        (uploadedCv?.originalFileName
                                            ? <PrimaryButton
                                                className="download-original-cv-btn"
                                                text={intl.formatMessage({ id: "download-original" })}
                                                handleClick={handleDownloadCV}
                                            />
                                            : <>
                                                <input
                                                    hidden
                                                    type="file"
                                                    ref={inputFilesRef}
                                                    onChange={(e) => handleOnInputChange(Array.from(e.target.files))}
                                                />
                                                <PrimaryButton
                                                    className="upload-cv-btn"
                                                    text={intl.formatMessage({ id: "upload-cv" })}
                                                    handleClick={handleUploadCvClick}
                                                />
                                            </>)
                                    }
                                </div>
                                : <div className="journey-buttons-wrapper">
                                    <SecondaryButton
                                        className="white"
                                        text={intl.formatMessage({ id: "edit" })}
                                        handleClick={() => editSection('edit')}
                                    />
                                    <PrimaryButton
                                        className="purple"
                                        text={intl.formatMessage({ id: "continue" })}
                                        handleClick={handleSave}
                                    />
                                </div>
                            }
                        </PaperCv>
                    </div>
            }
        </div>
    )
};

const mapStateToProps = state => ({
    role: getDisplayMode(state),
    basicInfo: getEmployeeBasicInfo(state),
});

const mapDispatchToProps = {
    generateRawCv,
    showNotification,
    exportCV,
    exportSupplierEmployeesCVs
};

export default connect(mapStateToProps, mapDispatchToProps)(CvSection);
