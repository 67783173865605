import React, { useState, useEffect } from 'react';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import { Formik } from 'formik';

import AddSkillForm from './AddSkillForm';

import inputFieldMaxLength from './CategorySkillsConstants';
import { useDebounce } from 'utils/hooks';

import {
    validateRequiredFields,
    validateInputForWhiteSpaces,
    validateInputLength,
} from 'utils/formValidations';

import { ReactComponent as CloseIcon } from 'assets/icons-x.svg';

import './CategorySkills.scss';

const CategorySkills = ({
    skillCategory,
    skills,
    removeSkill,
    addSkill,
    handleSkillDrag,
    fetchSkillSuggestions,
    innerRef,
    addSkillOnSelect,
}) => {

    const removeElement = (index) => {
        const removedElement = skills[index];
        removeSkill({ skillId: removedElement._id, title: removedElement.name, isSkill: true });
    }

    const [searchInput, setSearchInput] = useState('');

    const debouncedSearchInput = useDebounce(searchInput, 500);

    useEffect(() => {
        debouncedSearchInput && fetchSkills(debouncedSearchInput);
    }, [debouncedSearchInput])

    const addNewSkill = (values, isNew) => addSkill({ name: values }, isNew);

    const fetchSkills = inputText => inputText && fetchSkillSuggestions(inputText);

    const SortableItem = SortableElement(({ index, value, onRemove }) => {
        return (
            <div className="skill-box global-css-skill-box">
                <span className="skill-name">{value}</span>
                <CloseIcon className="close-icon" onClick={() => onRemove(index)} />
            </div>
        )
    });

    const SortableList = SortableContainer(({ items, onRemove }) => {
        return (
            <div className="sort-list">
                {items.map((value, index) => {
                    return (
                        <SortableItem
                            key={`item-${index}`}
                            index={index}
                            onRemove={() => { onRemove(index) }}
                            value={value.name}
                        />
                    )
                })}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        handleSkillDrag(arrayMove(skills, oldIndex, newIndex), skillCategory)
    };

    return (
        <div className="add-skill-container" ref={innerRef}>
            <Formik
                initialValues={{
                    name: '',
                }}
                validate={values => {
                    setSearchInput(values.name);

                    return {
                        ...validateInputForWhiteSpaces(values, ['name']),
                        ...validateRequiredFields(values, ['name']),
                        ...validateInputLength(values, [{ property: 'name', maxLength: inputFieldMaxLength.name }]),
                    }
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(false);
                    addSkill(values, false);
                    resetForm({});
                }}
            >
                {(props) =>
                    <AddSkillForm
                        {...props}
                        addNewSkill={addNewSkill}
                        fetchSkillSuggestions={fetchSkills}
                        addSkillOnSelect={addSkillOnSelect} />
                }
            </Formik>

            <SortableList
                axis="xy"
                items={skills}
                onRemove={(index) => removeElement(index)}
                onSortEnd={onSortEnd}
                distance={2}
            />
        </div>
    );
};

export default CategorySkills;
