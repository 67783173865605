import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { ReactComponent as ExitToAppIcon } from 'assets/logout-icon.svg';
import { ReactComponent as ExitToAppIconClient } from 'assets/exit-icon-orange.svg';

import { logout } from 'actions/authActions';
import { updateCandidateJourneyCurrentStepId } from 'actions/candidateActions';

import { getInitialJourneyCompletedStepsIds, getCurrentJourneyStepId, getMenuInformation, getDisplayMode } from 'reducers';

import {
    getMainStepTitles,
    getCandidateCurrentMainStepTitle,
    getCandidateCompletedMainStepTitles,
    getFirstStepIdOfTitle,
    getCandidateNextMainStepTitle,
} from '../../CandidateProfileJourney/CandidateProfileJourneyUtils';
import { BASE_APP_TRADEMARK } from 'constants/env';
import { ROLES } from 'constants/userConstants';

import { getClientCompletedMainStepTitles, getClientCurrentMainStepTitle, getClientFirstStepIdOfTitle, getClientMainStepTitles } from 'components/ClientProfileJourney/ClientProfileJourneyUtils';

import './JourneyNavigation.scss';

const JourneyNavigation = ({
    completedStepsIds,
    currentJourneyStep,
    updateCandidateJourneyCurrentStepId,
    role,
    logout,
}) => {
    const history = useHistory();

    const CREATE_PROFILE_JOURNEY_STEP_TITLES = role === ROLES.CANDIDATE ? getMainStepTitles() : getClientMainStepTitles();
    const CREATE_PROFILE_JOURNEY_CURRENT_STEP_TITLE = role === ROLES.CANDIDATE ? getCandidateCurrentMainStepTitle(currentJourneyStep) : getClientCurrentMainStepTitle(currentJourneyStep);
    const CREATE_PROFILE_JOURNEY_COMPLETED_MAIN_STEP_TITLES = role === ROLES.CANDIDATE ? getCandidateCompletedMainStepTitles(completedStepsIds) : getClientCompletedMainStepTitles(completedStepsIds);
    const CREATE_PROFILE_JOURNEY_CURRENT_STEP_INDEX = CREATE_PROFILE_JOURNEY_STEP_TITLES.indexOf(CREATE_PROFILE_JOURNEY_CURRENT_STEP_TITLE);
    const CREATE_PROFILE_JOURNEY_PROGRESS_MAP = role === ROLES.CANDIDATE ? {
        0: "first",
        1: "second",
        2: "third",
        3: "fourth"
    } : {
        0: "first-half",
        1: "second-half"
    };

    const handleConfirmLogout = async () => {
        await logout();
        history.push('/login?logout=true');
    };

    const handleStepClick = (stepTitle) => {
        const clickedStepId = role === ROLES.CLIENT ? getClientFirstStepIdOfTitle(stepTitle) : getFirstStepIdOfTitle(stepTitle);
        updateCandidateJourneyCurrentStepId(clickedStepId);
    };

    return (
        isMobile
            ?
            <div className="candidate-initial-journey-navigation-mobile-wrapper">
                <div className="candidate-initial-journey-navigation-content">
                    <div className="candidate-initial-journey-navigation-header">
                        <div className="logo-wrapper">
                            {BASE_APP_TRADEMARK.LOGO}
                        </div>
                    </div>
                </div>
            </div>
            :
            <Drawer
                id="candidate-initial-journey-navigation"
                className="candidate-initial-journey-navigation-desktop-wrapper"
                variant="permanent"
            >
                <div className="candidate-initial-journey-navigation-content">
                    <div className="candidate-initial-journey-navigation-header">
                        <div className="logo-wrapper">
                            {BASE_APP_TRADEMARK.LOGO_WHITE}
                        </div>
                    </div>
                    <div className="steps-wrapper">
                        <div className="progress-wrapper">
                            <div className={`filled-progress ${role === ROLES.CLIENT ? "deep-orange" : ""} ${CREATE_PROFILE_JOURNEY_PROGRESS_MAP[CREATE_PROFILE_JOURNEY_CURRENT_STEP_INDEX]}`}></div>
                        </div>
                        <div className="menu-items-wrapper">
                            <p className={`heading-m ${role === ROLES.CLIENT ? "color-deep-orange" : "color-deep-purple"}`}>0{CREATE_PROFILE_JOURNEY_CURRENT_STEP_INDEX + 1}</p>
                            {CREATE_PROFILE_JOURNEY_STEP_TITLES.map((stepTitle, index) => {
                                const isLast = index === CREATE_PROFILE_JOURNEY_STEP_TITLES.length - 1;
                                const isCurrent = CREATE_PROFILE_JOURNEY_CURRENT_STEP_TITLE === stepTitle;
                                const isNext = getCandidateNextMainStepTitle(completedStepsIds) === stepTitle;
                                const isCompleted = CREATE_PROFILE_JOURNEY_COMPLETED_MAIN_STEP_TITLES.includes(stepTitle);
                                const isClickable = isCurrent || isNext || isCompleted;
                                return <p onClick={isClickable ? () => handleStepClick(stepTitle) : null} className={`step-item subheading-l ${isCurrent ? 'color-white' : 'color-grey'} ${isLast ? 'last-step' : ''} ${isClickable ? 'clickable' : ''}`}>{stepTitle}</p>
                            })}
                            {CREATE_PROFILE_JOURNEY_CURRENT_STEP_INDEX < CREATE_PROFILE_JOURNEY_STEP_TITLES.length - 1 && <p className="heading-m color-grey">0{CREATE_PROFILE_JOURNEY_CURRENT_STEP_INDEX + 2}</p>}
                        </div>
                    </div>


                    <div className="candidate-initial-journey-navigation-footer">
                        <ListItem button className="list-item" onClick={handleConfirmLogout}>
                            <ListItemIcon className="list-icon exit-icon">
                                {role === ROLES.CANDIDATE ? <ExitToAppIcon /> : <ExitToAppIconClient />}
                            </ListItemIcon>
                            <ListItemText primary="Log out" className={role === ROLES.CLIENT ? "list-item-text-orange" : "list-item-text"} />
                        </ListItem>
                    </div>
                </div>
            </Drawer>
    );
};

const mapStateToProps = (state) => ({
    completedStepsIds: getInitialJourneyCompletedStepsIds(state),
    currentJourneyStep: getCurrentJourneyStepId(state),
    menuInformation: getMenuInformation(state),
    role: getDisplayMode(state),
});

const mapDispatchToProps = {
    logout,
    updateCandidateJourneyCurrentStepId,
};

export default connect(mapStateToProps, mapDispatchToProps)(JourneyNavigation); 
