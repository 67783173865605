import React from 'react';

import JourneyNavigation from 'components/Common/JourneyNavigation';

import { ReactComponent as CoderyTeamBackground } from 'assets/codery-team-background.svg';

import './CandidateJourneyLayout.scss';

const CandidateJourneyLayout = ({ children }) => {
    return (
        <div className="candidate-journey-layout-wrapper">
            <JourneyNavigation />

            <main className="candidate-journey-layout-body-wrapper">
                <div id="scroller" className="candidate-journey-layout-content">
                    {children}
                </div>
            </main>

            <CoderyTeamBackground className="codery-team-background z-index-1" />
        </div>
    );
};

export default CandidateJourneyLayout;
