import api from './api';
import requester from './requester';

const authService = {
    register: async (userData) => await requester(api.users('register')).create(userData),
    registerCandidate: async (userData, isSSO) => await requester(api.registerCandidate(isSSO)).create(userData),
    registerUnassigned: async (userData) => await requester(api.registerUnassigned).create(userData),
    registerAsUnassignedAdmin: async (userData, isSSO) => await requester(api.registerUnassignedAdmin(isSSO)).create(userData),
    registerClient: async (userData, isSSO) => await requester(api.registerClient(isSSO)).create(userData),
    registerSupplier: async (userData) => await requester(api.registerSupplier).create(userData),
    sendChangePasswordEmail: async (email) => await requester(api.userForgottenPassword(email)).create(),
    getVerificationLink: (email) => requester(api.verificationLink(email)).getOne(),
    resendVerificationEmail: data => requester(api.resendVerificationEmail()).create(data),
    setRememberMe: () => requester(api.rememberMe()).update(),
};

export default authService;
