import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import moment from 'moment';

import MatchingLinearProgress from '../../../SharedComponents/MatchingLinearProgress';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import { Avatar, Tooltip, CircularProgress, } from '@material-ui/core';
import ThreeDotsIcon from '@material-ui/icons/MoreHoriz';
import { ReactComponent as AppliedCandidateCalendarIcon } from 'assets/applied-candidate-calendar.svg';
import { ReactComponent as NoteIcon } from 'assets/note-icon.svg';
import AppliedCandidateInformationIcon from 'assets/applied-candidates-information-icon.svg';
import { logEvent } from 'utils/amplitude';

import { candidateStatusMapper, candidateStatusToTooltipText, getPathToCandidateApplication } from 'utils/applicationUtils';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { ROLES } from 'constants/userConstants';
import { APPLICATION_STATUSES } from 'constants/applicationConstants';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

import './AppliedCandidate.scss';
import { convertStringToLanguageKey } from 'utils/languageUtils';


const ApplicationDetailsLink = ({ isSupplierAdmin, to, onClick, children }) => {
    return (
        isSupplierAdmin
            ? <>{children}</>
            : (
                <Link className="application-details-link"
                    to={to}
                    onClick={onClick}
                >
                    {children}
                </Link>
            )
    );
};

const AppliedCandidate = ({
    candidate,
    agencyName,
    showStatusLabel = true,
    showNotes = true,
    isAdmin,
    isSupplierAdmin,
    aiMatchedCandidatesScoresFetchState,
    handleDotsClick,
    handleRateCandidateClick,
    handleSendProfileClick,
}) => {
    const intl = useIntl();

    const params = useParams();
    const jobId = params.jobId || candidate.jobId;

    const [isShowMorePopupVisible, setIsShowMorePopupVisible] = useState(false);
    const [popupTransform, setPopupTransform] = useState({});

    const handleTooltipHover = (e, visibility) => {
        logEvent(AMPLITUDE_EVENT_TYPES.CLIENT_HOVER_MATCHING_SCORE, { rolesPermitted: [ROLES.CLIENT] }, { location: 'candidate page' });
        if (e) {
            const popup = e.target.parentNode.nextSibling;

            const popupBoundingRect = popup.getBoundingClientRect();
            const boundingRect = e.target.getBoundingClientRect();
            const topOffset = boundingRect.top;

            const yOffset = window.innerHeight - (topOffset + popupBoundingRect.height + 20);

            let yTranslate = 0;
            const xTranslate = e.target.offsetLeft - 115;

            if (yOffset < 0) {
                yTranslate = - (popupBoundingRect.height + 22);
            }

            setPopupTransform({ transform: `translate(${xTranslate}px, ${yTranslate}px)` });
        }
        setIsShowMorePopupVisible(visibility);
    };

    const logClickEvent = () => logEvent(AMPLITUDE_EVENT_TYPES.CLIENT_OPEN_CANDIDATE_PROFILE_FROM_JOB_PAGE);

    return (
        <div className="applied-candidate-item-wrapper">
            <ApplicationDetailsLink
                isSupplierAdmin={isSupplierAdmin}
                to={getPathToCandidateApplication(jobId, candidate._id, candidate.jobApplicationId)}
                onClick={logClickEvent}
            >
                <div className="applied-candidate-item">
                    <div className="item-row-wrapper">
                        {showStatusLabel &&
                            <Tooltip classes={{ tooltip: 'custom-tooltip' }} title={candidateStatusToTooltipText(candidate.status, intl.locale)}>
                                <div className={`applied-candidate-label ${candidateStatusMapper(candidate.status)?.toLowerCase().split(" ").join("-")}`} >
                                    <span className="applied-candidate-text">{intl.formatMessage({ id: convertStringToLanguageKey(candidateStatusMapper(candidate.status)) })}</span>
                                </div>
                            </Tooltip>
                        }
                        <div className="applied-candidate-person">
                            <div className="applied-candidate-avatar">
                                <Avatar alt="Candidate image" className="candidate-image" src={candidate?.profilePictureImgUrl} />
                            </div>
                            <div className="applied-candidate-name">
                                {candidate.name}
                            </div>
                        </div>

                        <div className="applied-candidate-date">
                            <div className="applied-candidate-calendar-icon">
                                <AppliedCandidateCalendarIcon />
                            </div>
                            <div className="applied-candidate-date-text">
                                {intl.formatMessage({ id: "applied" })}: {moment(candidate.appliedDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}
                            </div>
                        </div>
                        <div className="applied-candidate-matching">
                            <div className="applied-candidate-matching-text-container">
                                <span className="applied-candidate-matching-text">{intl.formatMessage({ id: "matching-score" })}</span>
                                <span
                                    className="applied-candidate-matching-icon"
                                    onMouseOver={(e) => handleTooltipHover(e, true)}
                                    onMouseLeave={() => handleTooltipHover(false)}
                                >
                                    <img src={AppliedCandidateInformationIcon} />
                                </span>

                                <div className={`applied-candidate-matching-tooltip ${isShowMorePopupVisible ? 'show' : 'hide'}`} style={popupTransform}>
                                    {Object.values(candidate.categoryWeights || {}).some(weight => weight !== 0) && <h3 className="applied-candidate-matching-tooltip-title">{intl.formatMessage({ id: "scored-high-candidate" })}</h3>}
                                    <MatchingLinearProgress categoryWeights={candidate.categoryWeights} />
                                </div>

                            </div>
                            <div className="applied-candidate-matching-number">
                                {(aiMatchedCandidatesScoresFetchState?.isDataFetching && candidate.isNew)
                                    ? <CircularProgress thickness={5} size={20} />
                                    : <>{candidate.matchingScore} <small>/100</small></>
                                }
                            </div>
                        </div>
                        {isAdmin &&
                            <div className="applied-candidate-actions" onClick={(event) => handleDotsClick(event, candidate)}>
                                <ThreeDotsIcon className="three-dots-icon" />
                            </div>
                        }
                    </div>
                    {(candidate.comment && showNotes) &&
                        <div className="applied-candidate-note-container">
                            <div className="note-icon-wrapper">
                                <NoteIcon />
                            </div>
                            <div>
                                {candidate.status === APPLICATION_STATUSES.PENDING_ADMIN_REVIEW
                                    ? <p className="note-agency-name">{intl.formatMessage({ id: "note-from" })} {candidate.applicationCreator?.name}</p>
                                    : <p className="note-agency-name">{intl.formatMessage({ id: "note-from" })} {agencyName}</p>
                                }
                                <p className="note-content">{candidate.comment}</p>
                            </div>
                        </div>
                    }
                    {(candidate.status === APPLICATION_STATUSES.NON_VETTED_CANDIDATE && isAdmin) &&
                        <div className="rate-candidate-button-wrapper">
                            <SecondaryButton
                                className="rate-candidate-button"
                                text={intl.formatMessage({ id: "rate-candidate" })}
                                handleClick={event => handleRateCandidateClick(event, candidate)}
                            />
                        </div>
                    }

                    {(candidate.status === APPLICATION_STATUSES.PENDING_ADMIN_REVIEW && isAdmin) &&
                        <div className="send-profile-button-wrapper">
                            <PrimaryButton
                                data-testid='forward-to-client-btn'
                                className="send-profile-button"
                                text={intl.formatMessage({ id: "send-to-client" })}
                                handleClick={event => handleSendProfileClick(event, candidate)}
                            />
                        </div>
                    }
                </div>
            </ApplicationDetailsLink>
        </div>
    );
}

export default AppliedCandidate;
