import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Formik } from 'formik';

import { sendPasswordResetEmail } from 'actions/authActions';

import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';

import FormInputField from 'components/Shared/FormInputField';
import ActionButton from 'components/Shared/Buttons/ActionButton';

import { validateRequiredFields, validateEmailAddress } from 'utils/formValidations';

import { ReactComponent as CoderyLogo } from 'assets/codery-logo.svg';

import './ForgottenPassword.scss';

const ForgottenPassword = ({
    sendPasswordResetEmail,
}) => (
    <div className="forgotten-password-wrapper">
        <div className="page-info-container">
            <CoderyLogo />
            <div className="codery-info">
                <p className="heading-l color-white">Forgot your password?</p>
                <p className="heading-s color-white">Enter your email and we will send you a password reset</p>
            </div>
        </div>
        <Formik
            initialValues={{
                email: ''
            }}
            validate={values => {
                return {
                    ...validateRequiredFields(values, ['email']),
                    ...validateEmailAddress(values, 'email'),
                }
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                sendPasswordResetEmail(values.email.toLowerCase().trim());
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
            }) => {
                const isEmailRequirementNotMet = errors.email && touched.email;

                return (
                    <Paper elevation={0} className="forgotten-password-form-wrapper rounded-l">
                        <p className="heading-m color-purple-grey">Enter e-mail</p>
                        <div className="form-wrapper">
                            <form autoComplete='off'>
                                <FormInputField
                                    className="smaller"
                                    label="Email"
                                    placeholder="Email"
                                    name="email"
                                    value={values.email}
                                    showValidationRequirement={isEmailRequirementNotMet}
                                    requirementText={errors.email}
                                    isRequirementNotMet={isEmailRequirementNotMet}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                                <div className="buttons-wrapper">
                                    <ActionButton
                                        text='Send Request'
                                        handleClick={handleSubmit}
                                    />
                                </div>
                            </form>
                            <p className="sign-text-wrapper">Didn't forget password? <Link component={RouterLink} to="/login">Sign in</Link></p>
                        </div>
                    </Paper>
                )
            }}
        </Formik>
    </div>

);

const mapDispatchToProps = {
    sendPasswordResetEmail
};

export default connect(null, mapDispatchToProps)(ForgottenPassword);
