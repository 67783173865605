import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getAgencyId, getActionButtonLoading } from 'reducers';

import { updateTermsAndConditionsVersion } from 'actions/agencyActions';

import ProfileCreationCard from 'components/Shared/ProfileCreationCard';
import FormControlCheckbox from 'components/Shared/FormControlCheckbox';
import CircularProgress from '@material-ui/core/CircularProgress';

import { validateRequiredFields } from 'utils/formValidations';
import { getHomePath } from 'utils/navigationUtils';

import { motionRedirectLinks } from 'components/Register/RegisterConstants';

const AcceptTermsAndConditions = ({
    agencyId,
    isActionButtonLoading,
    updateTermsAndConditionsVersion,
}) => {
    const history = useHistory();

    return (
        <ProfileCreationCard>
            <ProfileCreationCard.Header />
            <ProfileCreationCard.ContentWrapper>
                <ProfileCreationCard.ContentTitle>
                    Review the terms and conditions
                </ProfileCreationCard.ContentTitle>
                <ProfileCreationCard.ContentDivider />
                <Formik
                    initialValues={{
                        isTermsAndConditionsAccepted: false,
                    }}
                    validate={values => {
                        return {
                            ...validateRequiredFields(values, ['isTermsAndConditionsAccepted'])
                        };
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(false);
                        await updateTermsAndConditionsVersion(agencyId);
                        const homePath = getHomePath();
                        history.push({ pathname: homePath.pathname });
                    }}
                >
                    {({ values, errors, touched, setFieldValue, handleSubmit }) => {
                        const isTCChecked = values.isTermsAndConditionsAccepted;
                        const hasTCError = errors.isTermsAndConditionsAccepted;
                        const isTCFieldTouched = touched.isTermsAndConditionsAccepted;

                        return (
                            <>
                                <ProfileCreationCard.ContentBody>
                                    <form autoComplete="off">
                                        <FormControlCheckbox
                                            className="centered"
                                            isTouched={isTCFieldTouched}
                                            hasError={hasTCError}
                                            name="isTermsAndConditionsAccepted"
                                            label={
                                                <span>
                                                    I agree to the platform's <a href={motionRedirectLinks.privacy.link} target="_blank">{motionRedirectLinks.privacy.label}.</a>
                                                </span>
                                            }
                                            checked={isTCChecked}
                                            handleChange={() => setFieldValue('isTermsAndConditionsAccepted', !isTCChecked)}
                                        />
                                        <ProfileCreationCard.ContentAction
                                            text={isActionButtonLoading ? <CircularProgress thickness={5} className="white" /> : "Continue"}
                                            handleClick={handleSubmit}
                                        />
                                    </form>
                                </ProfileCreationCard.ContentBody>
                            </>
                        );
                    }}
                </Formik>
            </ProfileCreationCard.ContentWrapper>
        </ProfileCreationCard>
    );
};

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
    isActionButtonLoading: getActionButtonLoading(state),
});

const mapDispatchToProps = {
    updateTermsAndConditionsVersion,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptTermsAndConditions);
