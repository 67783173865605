import { APPLICATION_STATUSES } from 'constants/applicationConstants';

export const STEPS = {
    PENDING_ADMIN_REVIEW: 'PENDING_ADMIN_REVIEW',
    PENDING_CLIENT_REVIEW: 'PENDING_CLIENT_REVIEW',
    INVITED_TO_INTERVIEW: 'INVITED_TO_INTERVIEW',
    FINAL_DECISION: 'FINAL_DECISION',
};

export const APPLICATION_STATUS_TO_STEP_MAP = {
    [APPLICATION_STATUSES.INVITED_FOR_INTERVIEW]: (isInterviewPending, isInterviewConfirmed) =>  
        isInterviewPending ||  isInterviewConfirmed ? STEPS.INVITED_TO_INTERVIEW : STEPS.FINAL_DECISION,
    [APPLICATION_STATUSES.PENDING_ADMIN_REVIEW]: () => STEPS.PENDING_ADMIN_REVIEW,
    [APPLICATION_STATUSES.NON_VETTED_CANDIDATE]: () => STEPS.PENDING_ADMIN_REVIEW,
    [APPLICATION_STATUSES.ACCEPTED_INTERVIEW]: (isInterviewPending, isInterviewConfirmed) => 
        isInterviewPending || isInterviewConfirmed ? STEPS.INVITED_TO_INTERVIEW : STEPS.FINAL_DECISION,
    [APPLICATION_STATUSES.NEW_APPLICATION]: () => STEPS.PENDING_CLIENT_REVIEW,
};

export const STEP_TO_TITLE_MAP = {
    [STEPS.PENDING_ADMIN_REVIEW]: 'Your application is being reviewed',
    [STEPS.PENDING_CLIENT_REVIEW]: 'Your application is being reviewed by client',
    [STEPS.INVITED_TO_INTERVIEW]: 'Waiting for the interview',
    [STEPS.FINAL_DECISION]: "Waiting for client's response",
};
