import React from 'react';
import { useIntl } from 'react-intl';

import moment from 'moment';

import MissingInformation from '../MissingInformation';

import { ReactComponent as EditCVIcon } from 'assets/edit-candidate-cv-icon.svg';

import { ROLES } from 'constants/userConstants';
import { MONTH_NAME_FORMAT } from 'constants/timesFormat';

import { ReactComponent as DateIcon } from 'assets/cv-date-icon.svg';

import './CvCertificates.scss';

const CvCertificates = ({
    certificates,
    enterEditMode,
    enableEdit,
    displayMode,
    scrollToRef
}) => {
    const intl = useIntl();
    const isClient = displayMode === ROLES.CLIENT;

    moment.locale(intl.locale);

    return (
        <>
            <div ref={scrollToRef} className="cv-certificates-wrapper">
                <div className="header">
                    <h1 className="title subheading-xxl color-dark-grey">{intl.formatMessage({ id: "certificates" })}</h1>
                    {enableEdit &&
                        <div className="item-edit-icon-wrapper" onClick={() => enterEditMode('certificates')}>
                            <EditCVIcon />
                        </div>
                    }
                </div>
                {certificates?.length > 0 ?
                    certificates.map(certificate =>
                        <div className="dates-row" key={certificate._id}>
                            <div>
                                {certificate.documentUrl
                                    ? <a href={certificate.documentUrl} target='_blank' rel='noreferrer'>
                                        <p className="row-heading subheading-l color-dark-grey">{certificate.title}</p>
                                    </a>
                                    : <p className="row-heading subheading-l color-dark-grey">{certificate.title}</p>}
                            </div>
                            <div className="dates-wrapper">
                                {certificate.issueDate
                                    &&
                                    <>
                                        <DateIcon />
                                        <p className="dates">
                                            <span className="subheading-m color-grey">{moment(certificate.issueDate).format(MONTH_NAME_FORMAT)}</span>
                                        </p>
                                    </>
                                }
                            </div>
                        </div>
                    )
                    : isClient ? <div className="empty-category" /> : <MissingInformation />
                }
            </div>
        </>
    )
}

export default CvCertificates;
