import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

import { updateCandidatePreferences } from 'actions/candidateActions';
import { fetchAllCountries } from 'actions/staticDataActions';
import { getCandidatePreferences, getCountries, getUserId, getAgencyId } from 'reducers';
import CandidateJourneyPreferencesFormView from './CandidateJourneyPreferencesFormView';
import CandidateJourneyPreferencesFormik from './CandidateJourneyPreferencesFormik';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import PaperHeader from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperHeader';
import PaperContent from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperContent';
import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import UnsupportedLocationDialog from 'components/Shared/UnsupportedLocationDialog';

import './CandidateJourneyPreferences.scss';

const CandidateJourneyPreferences = ({
    userId,
    agencyId,
    candidatePreferences,
    updateCandidatePreferences,
    fetchAllCountries,
    countries,
    handleGoToNextStep,
}) => {
    useEffect(() => {
        fetchAllCountries();
    }, []);

    const handleIsEarliestDateUnknownClick = (values, setValues) => {
        setValues({
            ...values,
            isEarliestDateUnknown: !values.isEarliestDateUnknown,
            earliestDate: !values.isEarliestDateUnknown ? null : candidatePreferences?.earliestDate,
        });
    };

    const updatePreferences = async (preferencesData, resetForm, stepData) => {
        await updateCandidatePreferences(agencyId, userId, preferencesData, stepData);
        resetForm();
    };

    const [isUnsupportedLocationDialogOpen, setIsUnsupportedLocationDialogOpen] = useState(false);

    const handleCloseUnsupportedLocationDialog = () => setIsUnsupportedLocationDialogOpen(false);

    return (
        <div className="candidate-journey-preferences">
            <CandidateJourneyPreferencesFormik
                candidatePreferences={candidatePreferences}
                updatePreferences={updatePreferences}
                handleGoToNextStep={handleGoToNextStep}
            >
                {(props) => (
                    <>
                        <div className="candidate-journey-preferences-view">
                            <PaperElement classes={[isMobile ? 'max' : 'sm']}>
                                <PaperHeader title="Select job preferences" />
                                <PaperContent>
                                    <CandidateJourneyPreferencesFormView
                                        {...props}
                                        countries={countries}
                                        handleIsEarliestDateUnknownClick={handleIsEarliestDateUnknownClick}
                                        setIsUnsupportedLocationDialogOpen={setIsUnsupportedLocationDialogOpen}
                                    />
                                </PaperContent>
                                <div className="journey-buttons-wrapper">
                                    <PrimaryButton className="purple" text={'Continue'} handleClick={props.handleSubmit} />
                                </div>
                            </PaperElement>
                        </div>
                    </>
                )}
            </CandidateJourneyPreferencesFormik>
            <UnsupportedLocationDialog
                isOpen={isUnsupportedLocationDialogOpen}
                handleCloseDialog={handleCloseUnsupportedLocationDialog}
            />
        </div>
    );
};
const mapStateToProps = (state) => ({
    candidatePreferences: getCandidatePreferences(state),
    countries: getCountries(state),
    agencyId: getAgencyId(state),
    userId: getUserId(state),
});

const mapDispatchToProps = {
    updateCandidatePreferences,
    fetchAllCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateJourneyPreferences); 
