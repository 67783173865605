import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import { getUserId, getDisplayMode, isTermsAndConditions, getPreselectedRole } from 'reducers';

import { updateUserUnassignedRole } from 'actions/userActions';

import RoleSelectionFormView from './RoleSelectionFormView';

import { validateRequiredFields } from 'utils/formValidations';

import { ROLES } from 'constants/userConstants';

import './RoleSelection.scss';

const RoleSelection = ({
    userId,
    displayMode,
    isTermsAndConditions,
    preselectedRole,
    updateUserUnassignedRole,
}) => {
    const history = useHistory();

    return (
        <div className="role-selection-wrapper">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    role: preselectedRole || (displayMode === ROLES.UNASSIGNED ? '' : displayMode),
                    isTermsAndConditionsAccepted: isTermsAndConditions || false
                }}
                validate={(values) => {
                    return { ...validateRequiredFields(values, ['role', 'isTermsAndConditionsAccepted']) }
                }}
                onSubmit={async (values) => {
                    if (displayMode !== values.role) {
                        await updateUserUnassignedRole(userId, values);
                    }

                    values.role === ROLES.CANDIDATE
                        ? history.push(`/select-a-role/candidate`)
                        : history.push(`/select-a-role/client`);
                }}
            >
                {(props) => {
                    return (
                        <div className="role-selection-content">
                            <p className="heading-m color-white" variant="h3">How do you want to use the platform?</p>
                            <RoleSelectionFormView {...props} preselectedRole={preselectedRole} />
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
};

const mapStatetoProps = (state) => ({
    userId: getUserId(state),
    displayMode: getDisplayMode(state),
    isTermsAndConditions: isTermsAndConditions(state),
    preselectedRole: getPreselectedRole(state),
});

const mapDispatchToProps = {
    updateUserUnassignedRole
};

export default connect(mapStatetoProps, mapDispatchToProps)(RoleSelection);
