import React from 'react';
import { useIntl } from 'react-intl';

import PrimaryButton from '../Buttons/PrimaryButton';
import { ReactComponent as ContentPasteIcon } from 'assets/content-paste-icon.svg';

import { BASE_APP_URL } from 'constants/env';
import './CandidateInvitationLink.scss';

const CandidateInvitationLink = ({
    agencyId,
    agencyName,
}) => {
    const intl = useIntl();

    const agencyData = {
        agencyId,
        agencyName,
    };

    const agencyInfo = encodeURIComponent(btoa(JSON.stringify(agencyData)));

    const handleAddToClipboard = () => {
        navigator.clipboard.writeText(`${BASE_APP_URL}/register/${agencyInfo}`);
    };

    return (
        <div className="candidate-invitation-link">
            <div className="header-text">{intl.formatMessage({ id: "invitation-link" })}</div>
            <div className="sub-header-text">{intl.formatMessage({ id: "invitation-link-text" })}</div>
            <div className="link-container">
                <div className="link-container-text-wrapper">
                    <p>{`${BASE_APP_URL}/register/${agencyInfo}`}</p>
                </div>
                <PrimaryButton
                    handleClick={handleAddToClipboard}
                    icon={<ContentPasteIcon />}
                />
            </div>
        </div>
    )
};

export default CandidateInvitationLink;
