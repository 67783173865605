import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getLoginLoading, getSSOLoginLoading } from 'reducers';

import { ssoLoginSuccess, login, logout } from 'actions/authActions';
import { showNotification } from 'actions/notificationActions';
import { createSSOLoggingRecord } from 'actions/loggingActions';

import AuthCard from 'components/Shared/AuthCard';
import LoginFormik from './LoginFormik';

import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { AUTH_MESSAGES } from 'constants/messageConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { LOGIN_CARD } from './LoginConstants';
import { UI_VIEWS } from 'constants/authConstants';

import { signInAgencyWithPopup } from 'utils/firebase';
import { getHomePath } from 'utils/navigationUtils';
import { logEvent } from 'utils/amplitude';

import { ReactComponent as CoderyTitleImageCandidate } from 'assets/welcome-back-candidate.svg';
import { ReactComponent as CoderyTitleImageClient } from 'assets/welcome-back-client.svg';

import { DEFAULT_AGENCY_ID } from 'constants/env';

import './Login.scss';

const Login = ({
    loginLoading,
    ssoLoginLoading,
    ssoLoginSuccess,
    login,
    logout,
    showNotification,
    createSSOLoggingRecord,
}) => {
    const history = useHistory();

    const [selectedView, setSelectedView] = useState(UI_VIEWS.CANDIDATE);

    const agencyData = {
        agencyId: DEFAULT_AGENCY_ID,
    };

    const agencyInfo = encodeURIComponent(btoa(JSON.stringify(agencyData)));

    const handleLogin = values => {
        const { email, password, rememberMe } = values;
        login(email.toLowerCase().trim(), password, rememberMe);
    };

    const handleSSOLogin = async event => {
        event.preventDefault();
        /* 
        This will prevent redirecting from the isGuest component,
        so we can redirect manually after successful login.
        */
        history.replace({ state: { isSSOLogin: true } });

        try {
            ssoLoginSuccess(false);
            const user = await signInAgencyWithPopup();
            user.isNew && logEvent(AMPLITUDE_EVENT_TYPES.REGISTER, null, { role: user.role, type: 'SSO' });
            await login(user.email, null, false, false, true, user.credential);
            const homePath = getHomePath(history.location.state?.gotoUrl);
            history.push({ pathname: homePath.pathname, state: { ...history.location.state, ...homePath.state } });
        } catch (err) {
            createSSOLoggingRecord({ message: err.message });
            // making a request to the backend will trigger refreshing the token and storing it
            // so we need to trigger logout to clear it
            await logout();
            if (err.message === AUTH_MESSAGES.NO_ACCOUNT_ASSOCIATED_WITH_EMAIL) {
                showNotification(err.message, NOTIFICATION_TYPES.INFO);
                history.push(`/login`);
            }
        } finally {
            ssoLoginSuccess(true);
        }
    };

    return (
        <AuthCard
            className="login-wrapper"
            title={LOGIN_CARD.TITLE}
            titleAsImage={true}
            titleImageComponent={selectedView.TITLE === !UI_VIEWS.CANDIDATE.TITLE ? <CoderyTitleImageClient /> : <CoderyTitleImageCandidate/>}
            mainSubtitle={"Codery is Eastern Europe's developer-focused job platform. Create a profile and let companies apply to you."}
        >
            <LoginFormik
                selectedView={selectedView}
                setSelectedView={setSelectedView}
                loginLoading={loginLoading}
                ssoLoginLoading={ssoLoginLoading}
                handleLogin={handleLogin}
                handleSSOLogin={handleSSOLogin}
                agencyInfo={agencyInfo}
            />
        </AuthCard>
    );
};

const mapStateToProps = state => ({
    loginLoading: getLoginLoading(state),
    ssoLoginLoading: getSSOLoginLoading(state),
});

const mapDispatchToProps = {
    ssoLoginSuccess,
    login,
    logout,
    showNotification,
    createSSOLoggingRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
