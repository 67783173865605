import React from 'react';

import CheckIcon from '@material-ui/icons/Check';
import { ReactComponent as InvalidFieldIcon } from 'assets/invalid-field.svg';

import './FormValidationRequirement.scss';

const FormValidationRequirement = ({
    requirementText,
    isRequirementMet,
    isRequirementNotMet,
}) => {

    return (
        <div className={`form-validation-requirement${isRequirementNotMet ? ' not-met' : isRequirementMet ? ' met' : ''}`}>
            <div className="icon-wrapper">
                {isRequirementNotMet && <InvalidFieldIcon />}
                {isRequirementMet && <CheckIcon />}
            </div>
            <span>{requirementText}</span>
        </div>
    )
};

export default FormValidationRequirement;
