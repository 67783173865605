import React from 'react';
import { connect } from 'react-redux';

import { getAgencyId } from 'reducers';
import { sendJobApplicationStatusRequestEmail } from 'actions/jobApplicationActions';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import './AskRecruiterBanner.scss';

const AskRecruiterBanner = ({
    agencyId,
    applicationId,
    sendJobApplicationStatusRequestEmail,
}) => {
    const handleAskRecruiterClick = () => {
        sendJobApplicationStatusRequestEmail(agencyId, applicationId);
    };

    return (
        <div className="ask-recruiter-banner-container">
            <div className="ask-recruiter-banner-content">
                <p className="heading-xs color-dark-grey">What's the status?</p>
                <p className="banner-body-text subheading-m color-grey">
                    We know waiting for a response is unpleasant and we aim to move your application forward as fast as we can. However, if you wish to receive an up to date information about your application click the button below and our recruitment team will be in touch.
                </p>
            </div>
            <PrimaryButton
                className="ask-recruiter-button purple"
                text="Ask Recruiter"
                handleClick={handleAskRecruiterClick}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
});

const mapDispatchToProps = {
    sendJobApplicationStatusRequestEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(AskRecruiterBanner);
