import React from 'react';
import { useIntl } from 'react-intl';

import moment from 'moment';
import { ErrorMessage } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';

import isHoliday from 'hoc/isHoliday';


import { CURRENCIES } from 'constants/currenciesConstant';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { getAdditionalText } from '../ApproveApplicationDialogConstants';

const ApproveApplicationFormView = ({
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    isContractJob,
    accountManager,
    isClientMode,
    isAgencyJob,
    handleStartDateChange,
}) => {
    const intl = useIntl();

    return (
        <form className="approve-application-form-view" autoComplete="off">
            {isContractJob &&
                <div className="additional-info-text-wrapper">
                    <p>{isAgencyJob || isClientMode ? getAdditionalText(accountManager?.email).OWN_JOB : getAdditionalText(accountManager?.email).CLIENT_JOB}</p>
                </div>
            }
            <div className="start-date-container">
                <div className="start-date-title">
                    {intl.formatMessage({ id: "start-date" })}
                </div>
                <div className="start-date-field">
                    <KeyboardDatePicker
                        allowKeyboardControl
                        disableToolbar
                        className={(errors.startDate && touched.startDate) ? "datepicker-field-error" : "datepicker-field"}
                        label={intl.formatMessage({ id: "start-date" })}
                        variant="inline"
                        inputVariant="outlined"
                        value={values.startDate}
                        onChange={value => {
                            handleStartDateChange(moment(value));
                            setFieldValue('startDate', moment(value));
                        }}
                        openTo="date"
                        views={["date"]}
                        renderDay={isHoliday}
                        format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                        autoOk={true}
                    />
                    <ErrorMessage name="startDate" component="div" className="invalid-field-message" />
                </div>
            </div>

            {!isContractJob && <div className="monthly-salary-container">
                <div className="monthly-salary-title">
                    {intl.formatMessage({ id: "monthly-gross-salary" })}
                </div>
                <div className="form-cols">
                    <div className="col-1of2">
                        <TextField
                            type="text"
                            variant="outlined"
                            label={intl.formatMessage({ id: "monthly-gross-salary" })}
                            value={values.salaryValue}
                            name="salaryValue"
                            onChange={handleChange}
                            fullWidth={true}
                        />
                        <ErrorMessage name="salaryValue" component="div" className="invalid-field-message" />
                    </div>

                    <div className="col-2of2">
                        <TextField
                            select
                            fullWidth
                            label={intl.formatMessage({ id: "currency" })}
                            value={values.salaryCurrency}
                            variant="outlined"
                            onChange={handleChange}
                            name="salaryCurrency"
                            id="salaryCurrency"
                        >
                            {CURRENCIES.map(currency => {
                                return (
                                    <MenuItem key={currency.VALUE} value={currency.LABEL}>{currency.LABEL}</MenuItem>
                                )
                            })}
                        </TextField>

                        <ErrorMessage name="salaryCurrency" component="div" className="invalid-field-message" />
                    </div>
                </div>
            </div>}
            <div className="hire-feedback-container">
                <div className="hire-feedback-title">
                    {intl.formatMessage({ id: "candidate-interview-feedback" })}
                </div>
                <div>
                    <TextField
                        className="hire-feedback-field"
                        name="comment"
                        value={values.comment}
                        onChange={handleChange}
                        id="hire-feedback"
                        label={intl.formatMessage({ id: "overall-performance" })}
                        margin="normal"
                        variant="outlined"
                        multiline
                        minRows='4'
                    />
                    <ErrorMessage name="comment" component="div" className="invalid-field-message" />
                </div>
            </div>
        </form>
    )
};

export default ApproveApplicationFormView;
