import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
    fetchSupplierTeamPage,
    fetchAdminTeamPage,
    registerEmployee,
    exportCV,
    exportAdminTeam,
} from 'actions/employeeActions';
import { exportSupplierEmployeesCVs, exportSupplierTeam } from 'actions/supplierActions';
import { disableUsers } from 'actions/userActions';
import {
    getAgencyId,
    isSupplierAdminMode,
    getUserId,
    isAdminMode,
    getEmployees,
    getEmployeesTableDataFetchState,
    getLanguage,
    getAgencyName,
} from 'reducers';

import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import ConfirmInviteUserDialog from 'components/Shared/ConfirmInviteUserDialog';
import AddTeamMemberDialog from 'components/Employees/AddTeamMemberDialog';
import EmployeesTable from './EmployeesTable';

import { logEvent } from 'utils/amplitude';

import { EMPLOYEES_HEAD_CELLS, SUPPLIER_EMPLOYEES_HEAD_CELLS, DEFAULT_ADMIN_COLUMNS, DEFAULT_SUPPLIER_COLUMNS, TEAM_TABLE_LEGEND_ITEMS, TEAM_TABLE_COLUMNS, DEFAULT_TEAM_ADMIN_COLUMNS } from './EmployeesTable/EmployeesTableConstants';
import { ROLES } from 'constants/userConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import './Employees.scss';

const Employees = ({
    fetchAdminTeamPage,
    exportCV,
    agencyId,
    userId,
    companyName,
    registerEmployee,
    isAdminMode,
    isSupplierAdminMode,
    dataFetchState,
    selectedLanguage,
    fetchSupplierTeamPage,
    exportSupplierEmployeesCVs,
    disableUsers,
    exportAdminTeam,
    exportSupplierTeam,
}) => {
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [newUserData, setNewUserData] = useState({});
    const [isButtonInitial, setIsButtonInitial] = useState(true);
    const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
    const [employeesToRemove, setEmployeesToRemove] = useState([]);
    const [reload, setReload] = useState(false);

    const headCells = isAdminMode ? EMPLOYEES_HEAD_CELLS : SUPPLIER_EMPLOYEES_HEAD_CELLS;
    const defaultColumns = isAdminMode ? DEFAULT_ADMIN_COLUMNS : DEFAULT_SUPPLIER_COLUMNS;

    const fetchPage = isSupplierAdminMode ? fetchSupplierTeamPage.bind(null, agencyId, userId) : fetchAdminTeamPage.bind(null, agencyId);

    const handleCloseConfirmRemoveDialog = () => { setOpenConfirmRemoveDialog(false); setEmployeesToRemove([]); };

    const removeEmployeesClickHandler = (employee) => {
        if (employee._id !== userId) {
            setOpenConfirmRemoveDialog(true);
            setEmployeesToRemove([employee]);
        }
    };

    const handleRemoveEmployees = () => {
        disableUsers(employeesToRemove.map(x => x._id), ROLES.EMPLOYEE);
        setOpenConfirmRemoveDialog(false);
        setIsButtonInitial(true);
    };

    const handleOpenDialog = () => {
        logEvent(AMPLITUDE_EVENT_TYPES.CLICK_ADD_TEAM_MEMBER);
        setIsAddDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setNewUserData({});
        setIsAddDialogOpen(false);
    };

    const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

    const handleExportCV = (employee) => {
        isSupplierAdminMode ?
            exportSupplierEmployeesCVs([employee], agencyId) :
            exportCV([employee], agencyId);
    };

    const handleRegisterMember = async memberInputData => {
        const { email, name, employmentType, startDate, asAdmin } = memberInputData;

        memberInputData = {
            email,
            name,
            addedBy: userId,
        };

        memberInputData = isSupplierAdminMode
            ? {
                ...memberInputData,
                supplierId: userId,
                role: ROLES.SUPPLIER_EMPLOYEE,
                employmentInformation: { generalInfo: { isVetted: false, isAvailable: false } }
            }
            : {
                ...memberInputData,
                role: asAdmin ? ROLES.ADMIN : ROLES.EMPLOYEE,
                employmentInformation: {
                    generalInfo: { startDate },
                    contractInformation: { employmentType }
                },
            }

        if (memberInputData.email) {
            setNewUserData(memberInputData);
            setOpenConfirmDialog(true);
            return;
        }

        await registerEmployee(memberInputData, agencyId, true).then(() => setReload(true));
        handleCloseDialog();
    };

    const handleProceedClick = async () => {
        try {
            handleCloseConfirmDialog();
            await registerEmployee(newUserData, agencyId, true).then(() => setReload(true));

            handleCloseDialog();
        } catch (error) {
            setNewUserData(prevState => ({ ...prevState, email: '' }));
            handleCloseConfirmDialog();
        }
    };

    return (
        <div className="employees-table-wrapper">
            <EmployeesTable
                agencyId={agencyId}
                userId={userId}
                companyName={companyName}
                selectedLanguage={selectedLanguage}
                isButtonInitial={isButtonInitial}
                handleOpenAddMemberDialog={handleOpenDialog}
                removeEmployeesClickHandler={removeEmployeesClickHandler}
                handleExportCV={handleExportCV}
                dataSelector={getEmployees}
                headCells={headCells}
                exportAdminTeam={exportAdminTeam}
                exportSupplierTeam={exportSupplierTeam}
                isSupplierAdminMode={isSupplierAdminMode}
                isAdminMode={isAdminMode}
                defaultColumns={defaultColumns}
                dataFetchState={dataFetchState}
                tableColumns={TEAM_TABLE_COLUMNS}
                defaultTableColumns={DEFAULT_TEAM_ADMIN_COLUMNS}
                legendItems={TEAM_TABLE_LEGEND_ITEMS}
                fetchPage={fetchPage}
                reload={reload}
                setReload={setReload}
            />

            <AddTeamMemberDialog
                isOpen={isAddDialogOpen}
                handleCloseDialog={handleCloseDialog}
                handleRegisterMember={handleRegisterMember}
                newUserData={newUserData}
                isAddingEmployee={isAdminMode}
                isAdminMode={isAdminMode}
            />
            <ConfirmInviteUserDialog
                openDialog={openConfirmDialog}
                invitedUser={ROLES.EMPLOYEE}
                handleInviteUser={handleProceedClick}
                handleCloseDialog={handleCloseConfirmDialog}
            />
            <ConfirmDeleteDialog
                itemToDelete={employeesToRemove.length > 1
                    ? 'these employees'
                    : 'this employee'
                }
                handleDeleteItem={handleRemoveEmployees}
                openDialog={openConfirmRemoveDialog}
                handleCloseDialog={handleCloseConfirmRemoveDialog}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
    userId: getUserId(state),
    companyName: getAgencyName(state),
    isSupplierAdminMode: isSupplierAdminMode(state),
    isAdminMode: isAdminMode(state),
    dataFetchState: getEmployeesTableDataFetchState(state),
    selectedLanguage: getLanguage(state),
});

const mapDispatchToProps = {
    fetchAdminTeamPage,
    exportCV,
    registerEmployee,
    fetchSupplierTeamPage,
    disableUsers,
    exportSupplierEmployeesCVs,
    exportAdminTeam,
    exportSupplierTeam,
};

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
