import React, { useState } from 'react';

import { InputAdornment, Menu, MenuItem, Paper, TextField } from '@material-ui/core';
import { ErrorMessage, Formik } from 'formik';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { validateRequiredFields } from 'utils/formValidations';

import { COUNTRIES } from './PersonalDetailsConstants';
import { CLIENT_JOURNEY_STEPS } from 'components/ClientProfileJourney/ClientProfileJourneyConstants';

import { ReactComponent as DownIcon } from 'assets/arrow-down.svg';
import { ReactComponent as UpIcon } from 'assets/arrow-up.svg';

import '../../ClientProfileJourney.scss';

const CountrySelect = ({
    selectedCountry,
    setSelectedCountry,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleToggle = (event) => {
        setIsOpen(!isOpen);

        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setIsOpen(false);
        setAnchorEl(null);
    };

    const handleSelect = (country) => {
        setSelectedCountry(country);
        handleClose();
    }

    return (
        <div className="country-select-container">
            <div className="country-icon">{selectedCountry.icon}</div>
            <div
                id="basic-button"
                className="country-select-btn"
                aria-controls={isOpen ? 'country-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isOpen ? 'true' : undefined}
                onClick={handleToggle}
            >
                {isOpen ? <UpIcon /> : <DownIcon />}
            </div>
            <p className="country-code subheading-m color-dark-grey">{selectedCountry.code}</p>
            <Menu
                id="country-menu"
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
            >
                {Object.values(COUNTRIES).map(country => (
                    <MenuItem onClick={() => handleSelect(country)}>{country.icon}</MenuItem>
                ))}
            </Menu>
        </div>
    )
};

const PersonalDetails = ({
    personalDetails,
    handleGoToNextStep,
}) => {
    const extractCodeFromPhoneNumber = (value) => Object.values(COUNTRIES).find(x => value.includes(x.code));

    const [selectedCountry, setSelectedCountry] = useState(personalDetails?.contactNumber ? extractCodeFromPhoneNumber(personalDetails?.contactNumber) : COUNTRIES.BG);

    const removeCodeFromPhoneNumber = (value) => value.replace(selectedCountry.code, '');

    return (
        <Paper className="step-component">
            <p className="heading-m color-dark-grey">Personal Details</p>
            <Formik
                initialValues={{
                    position: personalDetails?.position || '',
                    contactNumber: personalDetails?.contactNumber ? removeCodeFromPhoneNumber(personalDetails?.contactNumber) : '',
                }}
                validate={(values) => ({ ...validateRequiredFields(values, ['position']) })}
                onSubmit={async (values) => {
                    const data = {
                        stepId: CLIENT_JOURNEY_STEPS[0]._id,
                        values,
                    };

                    values.contactNumber = `${selectedCountry.code}${values.contactNumber}`

                    await handleGoToNextStep(data);
                }}
                enableReinitialize={true}
            >
                {({ values, handleChange, handleSubmit }) => (
                    <form className="step-component-form">
                        <div className="form-control">
                            <p className="subheading-xl color-gray">What is your position in the company?</p>
                            <TextField
                                name="position"
                                label="Job position"
                                value={values.position}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                            />
                            <ErrorMessage name="position" component="div" className="invalid-field-message" />
                        </div>
                        <div className="form-control">
                            <p className="subheading-xl color-gray">What is your phone number? (Opt.)</p>
                            <TextField
                                name="contactNumber"
                                label="Phone number"
                                value={values.contactNumber}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CountrySelect selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <PrimaryButton text="Next" className="dark centered" handleClick={handleSubmit} />
                    </form>
                )}
            </Formik>
        </Paper >
    )
};

export default PersonalDetails;
