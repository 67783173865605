import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { motionRedirectLinks } from 'components/Register/RegisterConstants';

import { ReactComponent as Checked } from 'assets/codery-checkbox-checked.svg';
import { ReactComponent as Unchecked } from 'assets/codery-checkbox-unchecked.svg';

import './TermsAndConditionsCheckBox.scss';

const TermsAndConditionsCheckBox = ({
    isTermsAndConditionsAccepted,
    handleChange,
}) => {

    return (
        <FormControlLabel
            className="terms-and-conditions-label"
            label={
                <div>
                    I agree to the platform's <a href={motionRedirectLinks.privacy.link} target="_blank">{motionRedirectLinks.privacy.label}</a>.
                </div>
            }
            control={
                <Checkbox
                    checkedIcon={<Checked />}
                    icon={<Unchecked />}
                    name="isTermsAndConditionsAccepted"
                    checked={isTermsAndConditionsAccepted}
                    onChange={handleChange}
                />
            }
        />
    );
};

export default TermsAndConditionsCheckBox;
