import React, { useEffect, createContext, useContext } from 'react';
import { connect } from 'react-redux';

import {
    getAppliedConsultantsList,
    getAllInvoices,
    getClientPastAndActiveProjectsCount,
    getClientTeamMembersCount,
    getAgencyName,
    getClientProjectsFeedback,
    getClientId,
    getClientProjects,
    getMenuInformation,
    getDashboardDataFetchState,
} from 'reducers';

import { fetchClientDashboardInformation, fetchClientProjects, submitFeedbackToProject } from 'actions/clientActions';

import ClientProjectsFeedback from 'components/Client/ClientProjectsFeedback';
import ClientJobOpportunitiesBanners from '../ClientDashboardComponents/ClientJobOpportunitesBanners';
import ClientProfiles from '../ClientDashboardComponents/ClientProfiles';
import ClientInvoices from '../ClientDashboardComponents/ClientInvoices';
import ClientProjects from '../ClientDashboardComponents/ClientProjects';
import ClientTeamMembers from '../ClientDashboardComponents/ClientTeamMembers';

import CircularProgress from '@material-ui/core/CircularProgress';

import { ROLES } from 'constants/userConstants';

import './ClientDashboard.scss';

export const ClientDashboardContext = createContext();

const { Provider } = ClientDashboardContext;

const ClientDashboard = ({
    userId,
    agencyId,
    clientId,
    children,
    dataFetchState,
    agencyName,
    appliedCandidates,
    invoices,
    activeProjectsCount,
    pastProjectsCount,
    contractMembersCount,
    fullTimeMembersCount,
    fetchClientDashboardInformation,
    projectsFeedback,
    fetchClientProjects,
    contactPerson,
    submitFeedbackToProject,
    clientName,
    ...props
}) => {

    const context = {
        userId,
        agencyId,
        clientId,
        isDataAvailable: true,
        agencyName,
        appliedCandidates,
        invoices,
        activeProjectsCount,
        pastProjectsCount,
        contractMembersCount,
        fullTimeMembersCount,
        projectsFeedback,
        submitFeedbackToProject,
        clientName,
        fetchClientDashboardInformation,
        ...props
    };

    useEffect(() => {
        fetchClientProjects(agencyId, clientId, 'name');
        fetchClientDashboardInformation(agencyId, clientId);
    }, []);

    return (
        <div>
            {
                dataFetchState?.isDataFetching
                    ? <CircularProgress thickness={5} size={50} disableShrink className="page-loader" />
                    : <Provider value={context}>
                        <div className="dashboard-content-wrapper">
                            {children}
                        </div>
                    </Provider>
            }
        </div >
    )
};

const JobOpportunitiesBanners = () => {
    return (
        <div className="client-dashboard-inner-content-wrapper">
            <ClientJobOpportunitiesBanners />
        </div>
    )
}

const Profiles = () => {
    const { appliedCandidates, agencyName } = useContext(ClientDashboardContext);

    return (
        appliedCandidates.length > 0 &&
        <div className="client-dashboard-inner-content-wrapper">
            <ClientProfiles
                appliedCandidates={appliedCandidates}
                agencyName={agencyName}
            />
        </div>
    );
};

const Invoices = () => {
    const { agencyId, userId, invoices } = useContext(ClientDashboardContext);

    return (
        invoices.length > 0 &&
        <div className="client-dashboard-inner-content-wrapper">
            <ClientInvoices
                agencyId={agencyId}
                userId={userId}
                invoices={invoices}
                invoicerRole={ROLES.CLIENT}
            />
        </div>
    );
};

const Projects = () => {
    const { activeProjectsCount, pastProjectsCount } = useContext(ClientDashboardContext);

    return (
        <div className="client-dashboard-inner-content-wrapper">
            <ClientProjects
                activeProjectsCount={activeProjectsCount}
                pastProjectsCount={pastProjectsCount}
            />
        </div>
    );
};

const TeamMembers = () => {
    const { contractMembersCount, fullTimeMembersCount } = useContext(ClientDashboardContext);

    return (
        <div className="client-dashboard-inner-content-wrapper">
            <ClientTeamMembers
                contractMembersCount={contractMembersCount}
                fullTimeMembersCount={fullTimeMembersCount}
            />
        </div>
    );
};

const PerformanceAnalytics = () => {
    const { 
        projectsFeedback,
        projects,
        agencyId,
        clientId,
        submitFeedbackToProject,
        clientName,
        fetchClientDashboardInformation
    } = useContext(ClientDashboardContext);

    if (projects.length === 0 && projectsFeedback.length === 0) {
        return null;
    }

    return (
        <div className="client-dashboard-inner-content-wrapper">
            <ClientProjectsFeedback
                projectsFeedback={projectsFeedback}
                projects={projects}
                agencyId={agencyId}
                clientId={clientId}
                submitFeedbackToProject={submitFeedbackToProject}
                clientName={clientName}
                fetchClientDashboardInformation={fetchClientDashboardInformation}
            />
        </div>
    );
};

ClientDashboard.JobOpportunitiesBanners = JobOpportunitiesBanners;
ClientDashboard.Profiles = Profiles;
ClientDashboard.Invoices = Invoices;
ClientDashboard.Projects = Projects;
ClientDashboard.TeamMembers = TeamMembers;
ClientDashboard.PerformanceAnalytics = PerformanceAnalytics;

const mapStateToProps = state => {
    const agencyName = getAgencyName(state);
    const { appliedConsultants } = getAppliedConsultantsList(state, {});
    const invoices = getAllInvoices(state);
    const { activeProjectsCount, pastProjectsCount } = getClientPastAndActiveProjectsCount(state);
    const { contractMembersCount, fullTimeMembersCount } = getClientTeamMembersCount(state);
    const projectsFeedback = getClientProjectsFeedback(state);
    const clientId = getClientId(state);
    const projects = getClientProjects(state);
    const clientName = getMenuInformation(state).menuName;
    const dataFetchState = getDashboardDataFetchState(state);

    return {
        agencyName,
        appliedCandidates: appliedConsultants,
        invoices,
        activeProjectsCount,
        pastProjectsCount,
        contractMembersCount,
        fullTimeMembersCount,
        projectsFeedback,
        clientId,
        projects,
        clientName,
        dataFetchState,
    };
};

const mapDispatchToProps = {
    fetchClientDashboardInformation,
    fetchClientProjects,
    submitFeedbackToProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientDashboard);
