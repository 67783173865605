import React from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as UploadCloudIcon } from 'assets/file-upload-icon.svg';

import UploadFiles from '../UploadFiles';

import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { ROLES } from 'constants/userConstants';

import { logEvent } from 'utils/amplitude';

import './CandidateJourneyUploadFile.scss';

const CandidateJourneyUploadFile = ({
    buttonRef,
    handleOnInputChange,
    inputFilesRef,
    getRootProps,
    uploads,
    setUploads,
    setInputFilesRefState,
}) => {
    const intl = useIntl();

    return (
        <div className="candidate-journey-upload-file" {...getRootProps()}>
            <input className="pdf-input"
                hidden
                type="file"
                ref={inputFilesRef}
                onChange={handleOnInputChange}
            />
            <div ref={buttonRef} className="import-pdf-container">
                <span>
                    <UploadCloudIcon className="cloud-icon" />
                </span>
                <span className="subheading-xl color-dark-grey">{intl.formatMessage({ id: "drag-and-drop" })}</span>
                <span className="subheading-xl color-dark-grey">{intl.formatMessage({ id: "or" })}
                <br/>
                <button
                    className='browse-button'
                    type="file"
                    onClick={() => {
                        logEvent(AMPLITUDE_EVENT_TYPES.CLICK_UPLOAD_RAW_CV, { rolesPermitted: [ROLES.ADMIN] });
                        inputFilesRef.current.click();
                        setInputFilesRefState(inputFilesRef);
                    }}
                >
                    {intl.formatMessage({ id: "browse" })}
                </button>
                </span>
            </div>
            <p className="subheading-xs color-grey allowed-formats">.doc, .docx, .pdf, .txt</p>
            {uploads?.type &&
                <UploadFiles
                    name="filesAttached"
                    filesAttached={uploads?.type === 'file' ? uploads.filesAttached : uploads.attachedLink}
                    setUploads={setUploads}
                />
            }
        </div>
    );
};

export default CandidateJourneyUploadFile;
