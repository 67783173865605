import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { continueClientJourney, fetchClientJourneyInformation } from 'actions/clientActions';
import { getAgencyId, getClientBasicInformation, getClientContactInformation, getClientId, getCurrentJourneyStepId, getInitialJourneyCompletedStepsIds, getIsJourneyCompleted } from 'reducers';

import { getClientNextStepId, getJourneyComponent } from './ClientProfileJourneyUtils';
import { getHomePath } from 'utils/navigationUtils';

import './ClientProfileJourney.scss';

const ClientProfileJourney = ({
    agencyId,
    clientId,
    currentStepId,
    completedSteps,
    fetchClientJourneyInformation,
    personalDetails,
    continueClientJourney,
    companyDetails,
    isJourneyCompleted,
}) => {
    const history = useHistory();

    useEffect(() => {
        fetchClientJourneyInformation(agencyId, clientId);
    }, []);

    useEffect(() => {
        if (isJourneyCompleted) {
            const homePath = getHomePath();
            history.push(homePath);
        }
    }, [isJourneyCompleted]);

    const StepComponent = getJourneyComponent(currentStepId);

    const handleGoToNextStep = (data) => {
        const nextStep = getClientNextStepId(currentStepId);
        continueClientJourney(agencyId, clientId, data, completedSteps, nextStep);
    };

    return (
        <div className="client-profile-journey-wrapper">
            <StepComponent
                companyDetails={companyDetails}
                personalDetails={personalDetails}
                handleGoToNextStep={handleGoToNextStep}
            />
        </div>
    )
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    clientId: getClientId(state),
    currentStepId: getCurrentJourneyStepId(state),
    completedSteps: getInitialJourneyCompletedStepsIds(state),
    personalDetails: getClientContactInformation(state),
    companyDetails: getClientBasicInformation(state),
    isJourneyCompleted: getIsJourneyCompleted(state)
});

const mapDispatchToProps = {
    fetchClientJourneyInformation,
    continueClientJourney,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfileJourney);
