import React from 'react';
import { Formik } from 'formik';

import CandidateCustomerSupportRequestFormView from './CandidateCustomerSupportRequestFormView';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { validateRequiredFields, validateInputLength } from 'utils/formValidations';

import { DESCRIPTION_MAX_LENGTH } from '../../../CustomerSupportConstants';

import './CandidateCustomerSupportRequestForm.scss';

const CandidateCustomerSupportRequestForm = ({
    requestCustomerSupport,
    accountManager
}) => {
    return (
        <Formik
            initialValues={{
                name: '',
                description: ''
            }}

            validate={values => {
                return {
                    ...validateRequiredFields(values, ['name', 'description']),
                    ...validateInputLength(values, [
                        { property: 'description', maxLength: DESCRIPTION_MAX_LENGTH },
                    ]),
                }
            }}

            onSubmit={(values, { resetForm, setSubmitting }) => {
                setSubmitting(false);
                values = accountManager ? { ...values, accountManager } : values;
                requestCustomerSupport(values);
                resetForm();
            }}
        >
            {(props) => (
                <div className="candidate-customer-support-request-container">
                    <div className="candidate-customer-support-form-content">
                        <div>
                            <p className="title heading-s color-dark-grey">How can we help?</p>
                            <p className="helper-text subheading-xl color-grey" >
                                If you're experiencing problems that you need help with, please complete the form below and our customer support team will get back to you within 48 hours.
                            </p>
                            <CandidateCustomerSupportRequestFormView {...props} />
                        </div>
                        <div className="action-buttons">
                            <PrimaryButton
                                className="purple"
                                text="Send"
                                handleClick={props.handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default CandidateCustomerSupportRequestForm;
