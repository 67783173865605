import React, { createContext } from 'react';

import LeftAlignedActions from './LeftAlignedActions';
import RightAlignedActions from './RightAlignedActions';

import SelectFilterList from '../SelectFilterList';

import './SectionActionsWrapper.scss';

export const SectionActionsWrapperContext = createContext();
const { Provider } = SectionActionsWrapperContext;

const SectionActionsWrapper = ({
    children,
}) => {
    const context = {};

    return (
        <div className="section-actions-wrapper">
            <Provider value={context}>
                {children}
            </Provider>
        </div>
    );
};

const SectionTitle = ({
    sectionTitle,
}) => {
    return (
        <h1 className="heading-xs color-grey">{sectionTitle}</h1>
    );
};

const SectionFilter = ({
    options,
    selectedOption,
    setSelectedOption
}) => {
    return (
        <SelectFilterList
            value={selectedOption}
            title={selectedOption}
            items={options}
            closeMenuOnSelect={true}
            handleChange={(_, item) => setSelectedOption(item)}
        />
    )
};

SectionActionsWrapper.SectionTitle = SectionTitle;
SectionActionsWrapper.SectionFilter = SectionFilter;
SectionActionsWrapper.LeftAlignedActions = LeftAlignedActions;
SectionActionsWrapper.RightAlignedActions = RightAlignedActions;

export default SectionActionsWrapper;
