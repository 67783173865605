import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import moment from 'moment';

import { arrayMove } from 'react-sortable-hoc';

import { Formik } from 'formik';
import SkillsDialog from '../../DialogsCV/SkillsDialog';
import LanguageDialog from '../../DialogsCV/LanguageDialog';
import EducationDialog from '../../DialogsCV/EducationDialog';
import CertificateDialog from '../../DialogsCV/CertificateDialog';
import ProjectDialog from '../../DialogsCV/ProjectDialog';
import ConfirmDialog from 'components/Shared/ConfirmDialog';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import PaperHeader from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperHeader';
import PaperContent from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperContent';
import SortableList from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/SortableList';
import SortableSkillList from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/SortableSkillList';
import ImproveGeneralCvFormView from './ImproveGeneralCvFormView';
import { ReactComponent as IconMaterialAdd } from "assets/icon-material-add.svg";

import './ImproveCv.scss';

const ImproveCv = ({
    sectionRefName,
    agencyId,
    userId,
    handleNextStep,
    discardAction,
    updateCandidateJourneyCvPage,
    employeeCvInformation,
    isCandidateMode,
    isEmployee,
}) => {
    const intl = useIntl();
    const formikRef = useRef();
    const history = useHistory();
    const prevLocation = useLocation().pathname;

    const [isConfirmRemoveDialogOpen, setIsConfirmRemoveDialogOpen] = useState(false);
    const [isLanguageDialogOpen, setIsLanguageDialogOpen] = useState(false);
    const [isProjectDialogOpen, setIsProjectDialogOpen] = useState(false);
    const [isSkillsDialogOpen, setIsSkillsDialogOpen] = useState(false);
    const [isCertificateDialogOpen, setIsCertificateDialogOpen] = useState(false);
    const [isEducationDialogOpen, setIsEducationDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [itemToRemove, setItemToRemove] = useState({});
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isConfirmationLeaveDialogOpen, setIsConfirmationLeaveDialogOpen] = useState(false);
    const [locationToGo, setLocationToGo] = useState(null);
    const [goToLocation, setGoToLocation] = useState(false);

    const editCvStartRef = useRef(null);
    const experienceRef = useRef(null);
    const profileSummaryRef = useRef(null);
    const skillsRef = useRef(null);
    const educationRef = useRef(null);
    const certificatesRef = useRef(null);
    const languagesRef = useRef(null);

    const refs = {
        profileSummary: profileSummaryRef,
        skills: skillsRef,
        education: educationRef,
        certificates: certificatesRef,
        languages: languagesRef,
        experience: experienceRef,
        edit: editCvStartRef,
    }

    useEffect(() => {
        if (refs[sectionRefName].current) {
            refs[sectionRefName].current.scrollIntoView({ behavior: 'smooth' });
            refs[sectionRefName].current = '';
        }
    }, [refs]);

    useEffect(() => {
        if (goToLocation) {
            history.push(locationToGo);
        }
    }, [goToLocation])

    useEffect(() => {
        let unlisten = () => null;

        if (hasUnsavedChanges && !locationToGo) {
            unlisten = history.listen((location) => {
                if (location.pathname !== prevLocation) {
                    history.push(prevLocation);
                    setLocationToGo(location.pathname);
                    setIsConfirmationLeaveDialogOpen(true);
                }
            });
        }

        return () => {
            unlisten()
        }
    }, [hasUnsavedChanges]);

    const onSortEnd = (setterFunction, collection, type, { oldIndex, newIndex }) => {
        setterFunction(type, arrayMove(collection, oldIndex, newIndex));
    };

    const handleDialogToggle = (setIsDialogOpen, selectedItem) => {
        if (selectedItem) {
            setSelectedItem(selectedItem);
        } else {
            setSelectedItem({});
        }

        setIsDialogOpen(prevState => !prevState);
    };

    const handleSubmitDialog = (field, newValue, itemIndex) => {
        const setFieldValue = formikRef.current.setFieldValue;
        const values = formikRef.current.values;

        setHasUnsavedChanges(true);

        //Edit item
        if (itemIndex || itemIndex === 0) {
            const { index, ...rest } = newValue;

            setFieldValue(field, [
                ...values[field].slice(0, index),
                rest,
                ...values[field].slice(index + 1)
            ]);

            return;
        }

        //Add item
        if (Array.isArray(newValue)) {
            setFieldValue(field, [...values[field], ...newValue]);
        } else {
            setFieldValue(field, [...values[field], newValue]);
        }
    };

    const handleRemoveItem = (field, itemToRemove) => {
        setItemToRemove({ field, ...itemToRemove });
        setIsConfirmRemoveDialogOpen(true);
    };

    const handleConfirmRemoveItem = () => {
        const setFieldValue = formikRef.current.setFieldValue;
        const values = formikRef.current.values;
        const { field, index } = itemToRemove;

        setFieldValue(field, [
            ...values[field].slice(0, index),
            ...values[field].slice(index + 1)
        ]);

        setIsConfirmRemoveDialogOpen(false);
        setHasUnsavedChanges(true);
    };

    const handleCloseConfirmDialog = () => {
        setIsConfirmRemoveDialogOpen(false);
        setItemToRemove({});
    };

    const handleCloseConfirmationLeaveDialog = () => setIsConfirmationLeaveDialogOpen(false);

    const handleValidationErrors = () => {
        formikRef.current.validateForm().then(errors => {
            if (Object.values(errors).length > 0) {
                profileSummaryRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        })
    };

    const handleConfirmLeavePage = () => {
        setIsConfirmationLeaveDialogOpen(false);
        setHasUnsavedChanges(false);
        setGoToLocation(true);
    };

    const initialGeneralInfo = isEmployee ? {
        position: employeeCvInformation.profileInformation.position || '',
        profileSummary: employeeCvInformation.profileSummary || '',
        experienceSince: employeeCvInformation.profileInformation.experienceSince ? moment.utc(employeeCvInformation.profileInformation.experienceSince) : null,
        profileType: employeeCvInformation.profileInformation.profileType || '',
    } : {
        position: employeeCvInformation.profileInformation.position || '',
        profileSummary: employeeCvInformation.profileSummary || '',
        experienceSince: employeeCvInformation.profileInformation.experienceSince ? moment.utc(employeeCvInformation.profileInformation.experienceSince) : null,
    };

    return (
        <div ref={editCvStartRef} className="edit-cv-wrapper">
            <PaperElement classes={["edit-container", "step-wrapper"]} >
                {isCandidateMode &&
                    <PaperElement>
                        <PaperHeader title={intl.formatMessage({ id: "improve-cv-title-text" })} />
                        <PaperContent classes={["content-width", "paper-element-description"]}>
                            <p>{intl.formatMessage({ id: "improve-cv-content-text" })}</p>
                        </PaperContent>
                    </PaperElement>
                }
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        generalInfo: initialGeneralInfo,
                        languages: employeeCvInformation.languages || [],
                        skills: employeeCvInformation.skills || [],
                        education: employeeCvInformation.education || [],
                        certificates: employeeCvInformation.certificates || [],
                        projects: employeeCvInformation.projects || []
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        if (!handleNextStep) {
                            updateCandidateJourneyCvPage(agencyId, userId, values);
                            discardAction();
                        } else {
                            const action = updateCandidateJourneyCvPage.bind(null, agencyId, userId, values);
                            handleNextStep(null, true, action);
                        }
                    }}
                >
                    {(props) => (
                        <>
                            <div ref={profileSummaryRef}>
                                <PaperElement>
                                    <PaperHeader title={intl.formatMessage({ id: "general" })} />
                                    <PaperContent>
                                        <ImproveGeneralCvFormView setHasUnsavedChanges={setHasUnsavedChanges} isEmployee={isEmployee} {...props} />
                                    </PaperContent>
                                </PaperElement>
                            </div>
                            <div ref={skillsRef}>
                                <PaperElement>
                                    <PaperHeader title={intl.formatMessage({ id: "skills-text" })} classes={["flex-header"]}>
                                        <IconMaterialAdd onClick={() => handleDialogToggle(setIsSkillsDialogOpen)} />
                                    </PaperHeader>
                                    <PaperContent classes={["paper-element-content"]}>
                                        <SortableSkillList
                                            items={props.values.skills}
                                            onRemove={(x) => {
                                                const copy = [...props.values.skills];
                                                copy.splice(x, 1);
                                                props.setFieldValue('skills', copy);
                                                setHasUnsavedChanges(true);
                                            }}
                                            onSortEnd={(data) => onSortEnd(props.setFieldValue, props.values.skills, 'skills', data)}
                                            axis="xy"
                                        />
                                        <SkillsDialog
                                            open={isSkillsDialogOpen}
                                            onClose={handleDialogToggle.bind(null, setIsSkillsDialogOpen)}
                                            onSubmit={handleSubmitDialog.bind(null, 'skills')}
                                            skills={props.values.skills}
                                        />
                                    </PaperContent>
                                </PaperElement>
                            </div>
                            <div ref={educationRef}>
                                <PaperElement>
                                    <PaperHeader title={intl.formatMessage({ id: "education" })} classes={["flex-header"]}>
                                        <IconMaterialAdd onClick={() => handleDialogToggle(setIsEducationDialogOpen)} />
                                    </PaperHeader>
                                    <PaperContent classes={["paper-element-content"]}>
                                        <SortableList
                                            distance={1}
                                            items={props.values.education}
                                            onRemove={handleRemoveItem.bind(null, 'education')}
                                            onEdit={handleDialogToggle.bind(null, setIsEducationDialogOpen)}
                                            onSortEnd={(data) => onSortEnd(props.setFieldValue, props.values.education, 'education', data)}
                                        />
                                        <EducationDialog
                                            open={isEducationDialogOpen}
                                            onClose={handleDialogToggle.bind(null, setIsEducationDialogOpen)}
                                            onSubmit={handleSubmitDialog.bind(null, 'education')}
                                            selectedItem={selectedItem}
                                        />
                                    </PaperContent>
                                </PaperElement>
                            </div>
                            <div ref={experienceRef}>
                                <PaperElement>
                                    <PaperHeader title={intl.formatMessage({ id: "experience" })} classes={["flex-header"]}>
                                        <IconMaterialAdd onClick={() => handleDialogToggle(setIsProjectDialogOpen)} />
                                    </PaperHeader>
                                    <PaperContent classes={["paper-element-content"]}>
                                        <SortableList
                                            distance={1}
                                            items={props.values.projects}
                                            onRemove={handleRemoveItem.bind(null, 'projects')}
                                            onEdit={handleDialogToggle.bind(null, setIsProjectDialogOpen)}
                                            onSortEnd={(data) => onSortEnd(props.setFieldValue, props.values.projects, 'projects', data)}
                                        />
                                        <ProjectDialog
                                            open={isProjectDialogOpen}
                                            onClose={handleDialogToggle.bind(null, setIsProjectDialogOpen)}
                                            onSubmit={handleSubmitDialog.bind(null, 'projects')}
                                            selectedItem={selectedItem}
                                        />
                                    </PaperContent>
                                </PaperElement>
                            </div>
                            <div ref={certificatesRef}>
                                <PaperElement>
                                    <PaperHeader title={intl.formatMessage({ id: "certificates" })} classes={["flex-header"]}>
                                        <IconMaterialAdd onClick={() => handleDialogToggle(setIsCertificateDialogOpen)} />
                                    </PaperHeader>
                                    <PaperContent classes={["paper-element-content"]}>
                                        <SortableList
                                            distance={1}
                                            items={props.values.certificates}
                                            onRemove={handleRemoveItem.bind(null, 'certificates')}
                                            onEdit={handleDialogToggle.bind(null, setIsCertificateDialogOpen)}
                                            onSortEnd={(data) => onSortEnd(props.setFieldValue, props.values.certificates, 'certificates', data)}
                                        />
                                        <CertificateDialog
                                            open={isCertificateDialogOpen}
                                            onClose={handleDialogToggle.bind(null, setIsCertificateDialogOpen)}
                                            onSubmit={handleSubmitDialog.bind(null, 'certificates')}
                                            selectedItem={selectedItem}
                                        />
                                    </PaperContent>
                                </PaperElement>
                            </div>
                            <div ref={languagesRef}>
                                <PaperElement>
                                    <PaperHeader title={intl.formatMessage({ id: "languages" })} classes={["flex-header"]}>
                                        <IconMaterialAdd onClick={() => handleDialogToggle(setIsLanguageDialogOpen)} />
                                    </PaperHeader>
                                    <PaperContent classes={["paper-element-content"]}>
                                        <SortableList
                                            distance={1}
                                            items={props.values.languages}
                                            onRemove={handleRemoveItem.bind(null, 'languages')}
                                            onEdit={handleDialogToggle.bind(null, setIsLanguageDialogOpen)}
                                            onSortEnd={(data) => onSortEnd(props.setFieldValue, props.values.languages, 'languages', data)}
                                            withTranslations
                                        />
                                        <LanguageDialog
                                            open={isLanguageDialogOpen}
                                            onClose={handleDialogToggle.bind(null, setIsLanguageDialogOpen)}
                                            onSubmit={handleSubmitDialog.bind(null, 'languages')}
                                            selectedItem={selectedItem}
                                        />
                                    </PaperContent>
                                </PaperElement>
                            </div>
                            <div className="journey-buttons-wrapper">
                                <SecondaryButton className="white" text={intl.formatMessage({ id: "cancel" })} handleClick={discardAction} />
                                <PrimaryButton className="purple" text={intl.formatMessage({ id: "save" })} handleClick={() => { handleValidationErrors(); props.handleSubmit() }} />
                            </div>
                        </>
                    )}
                </Formik >
                <ConfirmDeleteDialog
                    itemToDelete={itemToRemove.title || itemToRemove.name || itemToRemove.institution || ''}
                    handleDeleteItem={() => handleConfirmRemoveItem()}
                    openDialog={isConfirmRemoveDialogOpen}
                    handleCloseDialog={handleCloseConfirmDialog}
                />
                <ConfirmDialog
                    openDialog={isConfirmationLeaveDialogOpen}
                    dialogTitle={intl.formatMessage({ id: "confirm" })}
                    dialogSubtitle={intl.formatMessage({ id: "leave-page-confirmation-text" })}
                    discardButtonName={intl.formatMessage({ id: "no" })}
                    confirmButtonName={intl.formatMessage({ id: "yes" })}
                    handleCloseDialog={handleCloseConfirmationLeaveDialog}
                    handleConfirmDialog={handleConfirmLeavePage}
                />
            </PaperElement >
        </div>
    );
};


export default ImproveCv;
