import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { isEqual } from 'lodash';

import SelectionCard from '../SelectionCard';
import SelectTechStack from '../../UploadResumeWrapper/DialogsCV/SelectTechStack';

import { CANDIDATE_EXPERIENCE_OPTIONS, JOB_TYPES } from '../YourProfileConstants';

import { getJobOpportunitiesTechnologies } from 'reducers';

import { getSelectionCardSubTitle } from './ExperienceSelectionUtils';
import { validateRequiredFields, validatesArrayLength } from 'utils/formValidations';

import './ExperienceSelection.scss';

const JobTypeSelection = ({
    handleContinueJourney,
    agencyId,
    userId,
    candidatePreferences,
    jobsTechnologies,
    updateCandidatePreferences,
}) => {
    const [errorClassName, setErrorClassName] = useState('');
    const isDeveloper = [JOB_TYPES.FRONT_END_DEVELOPER, JOB_TYPES.BACK_END_DEVELOPER, JOB_TYPES.FULL_STACK_DEVELOPER, JOB_TYPES.MOBILE_DEVELOPER].includes(candidatePreferences.jobType);

    const experienceSinceRef = useRef(null);

    const initialValues = isDeveloper
        ? {
            experienceSince: candidatePreferences.experienceSince || '',
            technologies: candidatePreferences.technologies?.map(x => x.title) || [],
        }
        : { experienceSince: candidatePreferences.experienceSince || '' };

    const handleOnInputChangeTechnologies = (props, newValue) => {
        props.setFieldValue('technologies', [...props.values.technologies, newValue.title]);
    };

    const handleOnDeleteSkill = (props, index) => {
        props.setFieldValue('technologies', [...props.values.technologies.slice(0, index), ...props.values.technologies.slice(index + 1)]);
    };

    const addNewSkill = (props, newValue) => {
        props.setFieldValue('technologies', [...props.values.technologies, newValue]);
    };

    const handleContinueClick = ({ errors, touched, handleSubmit, validateForm }) => {
        validateForm().then(formErrors => {
            if (formErrors.experienceSince) {
                experienceSinceRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        });


        if (Object.values(errors).length > 0 && Object.values(touched).length > 0) {
            setErrorClassName('transition-error');
            setTimeout(() => setErrorClassName('animation-error'), 10);
        }

        handleSubmit();
    };

    return (
        <div className="experience-selection-wrapper">
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validate={(values) => {
                    let validationsObject = { ...validateRequiredFields(values, ['experienceSince']) };

                    // there should not be a validation if we do not have technologies at all
                    const technologiesMinLength = jobsTechnologies.length > 0 ? 1 : 0;

                    if (isDeveloper) {
                        validationsObject = {
                            ...validationsObject,
                            ...validatesArrayLength(values, 'technologies', technologiesMinLength)
                        }
                    };

                    return validationsObject;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    const isDirty = !isEqual(values, initialValues);

                    const finalResult = {...values, technologies: values.technologies?.map(x => ({_id: x.toLowerCase(), title: x})) || []};

                    const action = updateCandidatePreferences.bind(null, agencyId, userId, finalResult);

                    handleContinueJourney(null, isDirty, action);
                }}
            >
                {(props) => (
                    <>
                        <div ref={experienceSinceRef}>
                            <SelectionCard
                                {...props}
                                title="Tell us about your experience"
                                subTitle={getSelectionCardSubTitle(candidatePreferences.jobType)}
                                options={CANDIDATE_EXPERIENCE_OPTIONS}
                                name="experienceSince"
                                errorClassName={errorClassName}
                                primaryButtonText={"Continue"}
                                primaryButtonClickHandler={handleContinueClick.bind(null, props)}
                                additionalOptionsComponent={isDeveloper && jobsTechnologies.length > 0 ?
                                    <div>
                                        <p className="subheading-xl color-grey experience-text">What is your preferred tech stack?</p>
                                        <SelectTechStack
                                            label="Stack"
                                            handleInputChange={handleOnInputChangeTechnologies.bind(null, props)}
                                            handleOnDeleteSkill={handleOnDeleteSkill.bind(null, props)}
                                            addNewSkill={addNewSkill.bind(null, props)}
                                            technologies={props.values.technologies}
                                            error={props.errors.technologies && props.touched.technologies ? props.errors.technologies : ''}
                                        />
                                    </div>
                                    : null}
                            />
                        </div>
                    </>
                )}
            </Formik>
        </div>
    );
};

const mapStateToProps = state => ({
    jobsTechnologies: getJobOpportunitiesTechnologies(state),
});

export default connect(mapStateToProps)(JobTypeSelection);
