import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getAllProjectReviews, fetchClientProjects } from 'actions/clientActions';
import {
    getProjectFeedback,
} from 'reducers';

import PerformanceReviewOverall from './ProjectFeedbackOverall';
import PerformanceAnalytics from 'components/Shared/PerformanceAnalytics';
import PerformanceReviewsHistory from './PerformanceReviewsHistory';
import { feedbackCriteria } from '../ProjectsConstants';

import './ProjectFeedbackReview.scss';
import { CircularProgress } from '@material-ui/core';

const PerformanceReview = ({
    match,
    feedback,
    getAllProjectReviews,
    fetchClientProjects,
    agencyId,
}) => {
    const [isDataLoading, setIsDataLoading] = useState(true);

    useEffect(() => {
        fetchClientProjects(agencyId, match.params.id)
            .then(() => getAllProjectReviews(agencyId, match.params.id, match.params.projectId))
                .then (() => setIsDataLoading(false));
    }, []);

    return (
        <Fragment>
            { isDataLoading ? <CircularProgress thickness={5} size={50} className="page-loader" /> :
                <>
                    <PerformanceReviewOverall
                        overallPerformance={feedback}
                        agencyId={agencyId}
                    />

                    <PerformanceAnalytics
                        title="Performance analytics"
                        performanceReviews={feedback.reviews}
                        performanceCriterias={feedbackCriteria}
                    />

                    <PerformanceReviewsHistory
                        performanceReviews={feedback.reviews}
                    />
                
                </>
            }
        </Fragment>
    )
}

const mapStateToProps = (state, props) => ({
    feedback: getProjectFeedback(state, props.match.params.projectId),
});

const mapDispatchToProps = {
    getAllProjectReviews,
    fetchClientProjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceReview);
