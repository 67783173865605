import React from 'react';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

const DialogDiscardButton = ({ className, children, onClick, ...rest }) => {
    return (
        <SecondaryButton
            className={`white ${className ? className : ''}`}
            text={children}
            handleClick={onClick}
            {...rest}
        />
    );
};

export default DialogDiscardButton;
