import CompanyDetails from "./Steps/CompanyDetails/CompanyDetails";
import PersonalDetails from "./Steps/PersonalDetails";

export const stepIds = [
    "6666c49111cd4bba0ee4594e",
    "6666c4a011cd4bba0ee4594f"
];

export const CLIENT_JOURNEY_STEPS = [
    {
        _id: stepIds[0],
        title: 'Personal Details',
        getComponent: (props) => (
            <PersonalDetails {...props} />
        )
    },
    {
        _id: stepIds[1],
        title: 'Company Details',
        getComponent: (props) => (
            <CompanyDetails {...props} />
        )
    }
];
