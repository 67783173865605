import { EMPLOYMENT_TYPES } from "constants/employeeConstants";
import { ROLES } from "constants/userConstants";

export const MAX_LENGTH = 200;

export const MOMENT_UTC_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const DEPARTMENT_VIEW_KEY = 'Department View';

export const HOLIDAY_TYPES = {
    PAID: 'Paid',
    UNPAID: 'Unpaid',
    ACCRUED_OVERTIME: 'Accrued Overtime',
    VISA_BUSINESS_TRIP: 'Visa Business Trip',
    BUSINESS_TRIP: 'Business Trip',
    CLIENT_HOLIDAY: 'Client Holiday',
    HOME_OFFICE: 'Home Office'
};

export const HOLIDAY_TYPES_MAP = {
    [DEPARTMENT_VIEW_KEY]: {
        [EMPLOYMENT_TYPES.FULLTIME_CONTRACT]: [
            { value: HOLIDAY_TYPES.PAID },
            { value: HOLIDAY_TYPES.UNPAID },
            { value: HOLIDAY_TYPES.ACCRUED_OVERTIME },
            { value: HOLIDAY_TYPES.HOME_OFFICE }
        ],
        [EMPLOYMENT_TYPES.FREELANCE_CONTRACT]: [
            { value: HOLIDAY_TYPES.UNPAID },
        ],
    },
    [ROLES.ADMIN]: {
        [EMPLOYMENT_TYPES.FULLTIME_CONTRACT]: [
            { value: HOLIDAY_TYPES.PAID },
            { value: HOLIDAY_TYPES.UNPAID },
            { value: HOLIDAY_TYPES.ACCRUED_OVERTIME },
            { value: HOLIDAY_TYPES.VISA_BUSINESS_TRIP },
            { value: HOLIDAY_TYPES.BUSINESS_TRIP },
            { value: HOLIDAY_TYPES.CLIENT_HOLIDAY },
            { value: HOLIDAY_TYPES.HOME_OFFICE }
        ],
        [EMPLOYMENT_TYPES.FREELANCE_CONTRACT]: [
            { value: HOLIDAY_TYPES.UNPAID },
        ],
    },
    [ROLES.SUPPLIER_ADMIN]: {
        [EMPLOYMENT_TYPES.FULLTIME_CONTRACT]: [
            { value: HOLIDAY_TYPES.PAID },
            { value: HOLIDAY_TYPES.UNPAID },
            { value: HOLIDAY_TYPES.ACCRUED_OVERTIME },
            { value: HOLIDAY_TYPES.VISA_BUSINESS_TRIP },
            { value: HOLIDAY_TYPES.BUSINESS_TRIP },
            { value: HOLIDAY_TYPES.CLIENT_HOLIDAY },
            { value: HOLIDAY_TYPES.HOME_OFFICE }
        ],
        [EMPLOYMENT_TYPES.FREELANCE_CONTRACT]: [
            { value: HOLIDAY_TYPES.UNPAID },
        ],
    },
    [ROLES.EMPLOYEE]: {
        [EMPLOYMENT_TYPES.FULLTIME_CONTRACT]: [
            { value: HOLIDAY_TYPES.PAID },
            { value: HOLIDAY_TYPES.UNPAID },
            { value: HOLIDAY_TYPES.ACCRUED_OVERTIME },
            { value: HOLIDAY_TYPES.VISA_BUSINESS_TRIP },
            { value: HOLIDAY_TYPES.BUSINESS_TRIP },
            { value: HOLIDAY_TYPES.CLIENT_HOLIDAY },
            { value: HOLIDAY_TYPES.HOME_OFFICE }
        ],
        [EMPLOYMENT_TYPES.FREELANCE_CONTRACT]: [
            { value: HOLIDAY_TYPES.UNPAID },
        ],
    },
    [ROLES.SUPPLIER_EMPLOYEE]: {
        [EMPLOYMENT_TYPES.FULLTIME_CONTRACT]: [
            { value: HOLIDAY_TYPES.PAID },
            { value: HOLIDAY_TYPES.UNPAID },
            { value: HOLIDAY_TYPES.ACCRUED_OVERTIME },
            { value: HOLIDAY_TYPES.VISA_BUSINESS_TRIP },
            { value: HOLIDAY_TYPES.BUSINESS_TRIP },
            { value: HOLIDAY_TYPES.CLIENT_HOLIDAY },
            { value: HOLIDAY_TYPES.HOME_OFFICE }
        ],
        [EMPLOYMENT_TYPES.FREELANCE_CONTRACT]: [
            { value: HOLIDAY_TYPES.UNPAID },
        ],
    },
};

export const HOLIDAY_ARTICLES = {
    [DEPARTMENT_VIEW_KEY]: {
        [EMPLOYMENT_TYPES.FULLTIME_CONTRACT]: [
            { value: 'Article 155 - Regular Paid Holiday' },
            { value: 'Article 157 - Funeral (Bereavement leave)' },
            { value: 'Article 157 - Wedding (Wedding leave)' },
            { value: 'Article 157 - Blood donation' },
            { value: 'Article 163 - Leave due to pregnancy and childbirth (Maternity leave)' },
            { value: 'Article 163 - Leave due to pregnancy and childbirth (Paternity leave)' },
            { value: 'Article 164 - Paid leave for raising a young child up to 2 years of age (Parental leave)' },
            { value: 'Article 169 - Trainings and Certifications' },
        ],
        [EMPLOYMENT_TYPES.FREELANCE_CONTRACT]: [],
    },
    [ROLES.ADMIN]: {
        [EMPLOYMENT_TYPES.FULLTIME_CONTRACT]: [
            { value: 'Article 155 - Regular Paid Holiday' },
            { value: 'Article 157 - Funeral (Bereavement leave)' },
            { value: 'Article 157 - Wedding (Wedding leave)' },
            { value: 'Article 157 - Blood donation' },
            { value: 'Article 163 - Leave due to pregnancy and childbirth (Maternity leave)' },
            { value: 'Article 163 - Leave due to pregnancy and childbirth (Paternity leave)' },
            { value: 'Article 164 - Paid leave for raising a young child up to 2 years of age (Parental leave)' },
            { value: 'Article 169 - Trainings and Certifications' },
        ],
        [EMPLOYMENT_TYPES.FREELANCE_CONTRACT]: [],
    },
    [ROLES.SUPPLIER_ADMIN]: {
        [EMPLOYMENT_TYPES.FULLTIME_CONTRACT]: [
            { value: 'Article 155 - Regular Paid Holiday' },
            { value: 'Article 157 - Funeral (Bereavement leave)' },
            { value: 'Article 157 - Wedding (Wedding leave)' },
            { value: 'Article 169 - Trainings and Certifications' },
        ],
        [EMPLOYMENT_TYPES.FREELANCE_CONTRACT]: [],
    },
    [ROLES.EMPLOYEE]: {
        [EMPLOYMENT_TYPES.FULLTIME_CONTRACT]: [
            { value: 'Article 155 - Regular Paid Holiday' },
            { value: 'Article 157 - Funeral (Bereavement leave)' },
            { value: 'Article 157 - Wedding (Wedding leave)' },
            { value: 'Article 157 - Blood donation' },
            { value: 'Article 163 - Leave due to pregnancy and childbirth (Maternity leave)' },
            { value: 'Article 163 - Leave due to pregnancy and childbirth (Paternity leave)' },
            { value: 'Article 164 - Paid leave for raising a young child up to 2 years of age (Parental leave)' },
            { value: 'Article 169 - Trainings and Certifications' },
        ],
        [EMPLOYMENT_TYPES.FREELANCE_CONTRACT]: [],
    },
    [ROLES.SUPPLIER_EMPLOYEE]: {
        [EMPLOYMENT_TYPES.FULLTIME_CONTRACT]: [
            { value: 'Article 155 - Regular Paid Holiday' },
            { value: 'Article 157 - Funeral (Bereavement leave)' },
            { value: 'Article 157 - Wedding (Wedding leave)' },
            { value: 'Article 169 - Trainings and Certifications' },
        ],
        [EMPLOYMENT_TYPES.FREELANCE_CONTRACT]: [],
    },
};

export const ARTICLE_NUMBERS = {
    REGULAR: 155,
    FUNERAL_WEDDING_OR_BLOOD_DONATION: 157,
    PREGNANCY_AND_CHILDBIRTH: 163,
    RAISING_YOUNG_CHILD: 164,
    TRAININGS_AND_CERTIFICATIONS: 169,
}
