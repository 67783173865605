import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ReactComponent as RadioButtonUncheckedIcon } from 'assets/codery-checkbox-unchecked.svg';
import { ReactComponent as RadioButtonCheckedIcon } from 'assets/codery-checkbox-checked.svg';

import './FormControlCheckbox.scss';

const FormControlCheckbox = ({
    className,
    isTouched,
    hasError,
    name,
    label,
    checked,
    handleChange
}) => {

    return (
        <FormControlLabel
            className={`form-control-checkbox${(isTouched && hasError ? ' error' : '')}${className ? ` ${className}` : ''}`}
            label={label}
            control={
                <Checkbox
                    size="small"
                    name={name}
                    checked={checked}
                    onChange={handleChange}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                />
            }
        />
    );
};

export default FormControlCheckbox;
