import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getStateAgencyName } from 'utils/helpers';
import { getResultClickRoute } from 'utils/searchUtils';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Search from 'components/Shared/Search';

import { getToggleMobileNavigation, getDisplayMode } from 'reducers';

import { toggleMobileNavigation } from 'actions/uiActions';

import { ROLES } from 'constants/userConstants';
import { REQUIRED_FIELDS } from '../Header/headerConstants';
import { BASE_APP_TRADEMARK } from 'constants/env';

import './MobileHeader.scss';

const MobileHeader = ({
    toggleMobileNavigationValue,
    toggleMobileNavigation,
    displayMode
}) => {
    const [isSearchInputVisible, setIsSearchInputVisible] = useState(false);

    const openMobileNavigationHandler = () => toggleMobileNavigation(true);

    const history = useHistory();

    const handleResultClick = (hideDropdown, result) => {
        history.push(getResultClickRoute(displayMode, result));
        hideDropdown();
    };

    const handleSearchSeeAllResultsClick = (handleBlur, _, searchTerm) => {
        history.push(`/${getStateAgencyName()}/search-results?searchTerm=${searchTerm}`);
        handleBlur();
    };
    
    const hideSearch = displayMode === ROLES.SUPPLIER_EMPLOYEE || displayMode === ROLES.EMPLOYEE || displayMode === ROLES.CANDIDATE;

    return (
        <div className={toggleMobileNavigationValue ? "mobile-header hidden" : "mobile-header"}>
            <div className="mobile-header-left">
                <IconButton className="btn-icon-mobile menu-icon" onClick={openMobileNavigationHandler}>
                    <MenuIcon />
                </IconButton>
                {BASE_APP_TRADEMARK.LOGO}
            </div>

            {!hideSearch && !isSearchInputVisible && 
                <IconButton 
                    className="btn-icon-mobile" 
                    onClick={() => setIsSearchInputVisible(true)}
                >
                    <SearchIcon />
                </IconButton>}
            

            {isSearchInputVisible && 
                <Search
                    handleClick={handleResultClick}
                    handleClickTitle={handleSearchSeeAllResultsClick}
                    requiredFields={REQUIRED_FIELDS}
                    isMobileHeaderSearch={true}
                    closeMobileSearchInput={() => setIsSearchInputVisible(false)}
                >
                    <Search.SearchDropdown dropdownTitle="See all results">
                        <Search.SearchDropdown.PeopleMenu compact={displayMode === ROLES.ADMIN} />
                        <Search.SearchDropdown.ClientsMenu compact={displayMode === ROLES.ADMIN} />
                        <Search.SearchDropdown.SupplierAdminMenu compact={displayMode === ROLES.ADMIN} />
                        <Search.SearchDropdown.SupplierEmployeesMenu compact={displayMode === ROLES.ADMIN} />
                        <Search.SearchDropdown.CandidatesMenu compact={displayMode === ROLES.ADMIN} />
                    </Search.SearchDropdown>
                </Search>}            
        </div>
    );
}

const mapStateToProps = state => ({
    toggleMobileNavigationValue: getToggleMobileNavigation(state),
    displayMode: getDisplayMode(state),
});

const mapDispatchToProps = {
    toggleMobileNavigation,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
