import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { getAgencyId, getCountries, getActionButtonLoading } from 'reducers';

import { updateAgencyInfo } from 'actions/agencyProfileActions';
import { fetchAllCountries } from 'actions/staticDataActions';

import ProfileCreationCard from 'components/Shared/ProfileCreationCard';
import FormInputField from 'components/Shared/FormInputField';
import FormSelect from 'components/Shared/FormSelect';
import CircularProgress from '@material-ui/core/CircularProgress';

import { validateRequiredFields, validateCompanyName } from 'utils/formValidations';
import { useSessionStorage } from 'utils/hooks';

import { COMPANY_SIZES_ARRAY } from 'constants/agencyConstants';

const TellUsAboutYourCompany = ({
    agencyId,
    countries,
    isActionButtonLoading,
    updateAgencyInfo,
    fetchAllCountries,
}) => {
    const [, setShowStartTrial] = useSessionStorage('showStartTrial', false);

    useEffect(() => {
        fetchAllCountries();
    }, []);

    return (
        <Formik
            initialValues={{
                name: '',
                companySize: '',
                country: '',
            }}
            validate={values => {
                return {
                    ...validateRequiredFields(values, ['name', 'companySize', 'country']),
                    ...validateCompanyName(values, 'name'),
                };
            }}
            onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(false);
                setShowStartTrial(true);
                window.dataLayer.push({event: 'full_submission_conversion'});
                await updateAgencyInfo(agencyId, values);
            }}
        >
            {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit }) => {
                const isCompanyNameRequirementNotMet = errors.name && touched.name;
                const isCompanySizeRequirementNotMet = errors.companySize && touched.companySize;
                const isCountryRequirementNotMet = errors.country && touched.country;

                return (
                    <>
                        <ProfileCreationCard.ContentBody>
                            <form autoComplete="off">
                                <FormInputField
                                    placeholder='Company Name'
                                    name="name"
                                    value={values.name}
                                    showValidationRequirement={isCompanyNameRequirementNotMet}
                                    requirementText={errors.name}
                                    isRequirementNotMet={isCompanyNameRequirementNotMet}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />

                                <FormSelect
                                    placeholder='Company Size'
                                    options={COMPANY_SIZES_ARRAY}
                                    showValidationRequirement={isCompanySizeRequirementNotMet}
                                    requirementText={errors.companySize}
                                    isRequirementNotMet={isCompanySizeRequirementNotMet}
                                    handleChange={(value) => setFieldValue('companySize', value)}
                                />

                                <FormSelect
                                    placeholder='Company Location'
                                    options={countries.map(x => ({ value: x.name, name: x.name }))}
                                    showValidationRequirement={isCountryRequirementNotMet}
                                    requirementText={errors.country}
                                    isRequirementNotMet={isCountryRequirementNotMet}
                                    handleChange={(value) => setFieldValue('country', value)}
                                />
                                <ProfileCreationCard.ContentAction
                                    text={isActionButtonLoading ? <CircularProgress thickness={5} className="white" /> : "Next"}
                                    handleClick={handleSubmit}
                                />
                            </form>
                        </ProfileCreationCard.ContentBody>
                    </>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
    countries: getCountries(state),
    isActionButtonLoading: getActionButtonLoading(state),
});

const mapDispatchToProps = {
    updateAgencyInfo,
    fetchAllCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(TellUsAboutYourCompany);
