import React from 'react';
import { useIntl } from 'react-intl';

import moment from 'moment';
import { ErrorMessage } from 'formik';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';

import isHoliday from 'hoc/isHoliday';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR, MAX_DATE } from 'constants/commonConstants';
import { ROLES } from 'constants/userConstants';
import { EMPLOYMENT_TYPES } from 'constants/employeeConstants';
import { HOLIDAY_TYPES_MAP, HOLIDAY_ARTICLES, DEPARTMENT_VIEW_KEY, HOLIDAY_TYPES } from './HolidayRequestDialogConstants';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './HolidayRequestFormView.scss';

const HolidayRequestFormView = ({
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    displayMode,
    employmentType,
    isDepartmentView,
}) => {
    const intl = useIntl();

    return (
        <form className="request-holiday-form" autoComplete="off">
            <div className='holiday-type-field'>
                <TextField
                    select
                    name="type"
                    label={intl.formatMessage({ id: "type" })}
                    value={values.type}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    error={errors.type && touched.type}
                    inputProps={{ 'data-testid': "holiday-type-field" }}
                >
                    {HOLIDAY_TYPES_MAP[isDepartmentView ? DEPARTMENT_VIEW_KEY : displayMode][employmentType || EMPLOYMENT_TYPES.FULLTIME_CONTRACT].map(x => (
                        <MenuItem key={x.value} value={x.value}>
                            {intl.formatMessage({ id: convertStringToLanguageKey(x.value) })}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="type" component="div" className="invalid-field-message" />
            </div>

            <div className="date-fields-block">
                <div className="start-date-container">
                    <KeyboardDatePicker
                        autoOk={true}
                        required
                        name="startDate"
                        className="start-date-field"
                        label={intl.formatMessage({ id: "start-date" })}
                        disableToolbar
                        minDate={values.type === HOLIDAY_TYPES.HOME_OFFICE ? new Date(moment().startOf('month')) : new Date(moment().subtract(6, 'month').startOf('month'))}
                        maxDate={values.type === HOLIDAY_TYPES.HOME_OFFICE ? new Date(moment().endOf('month')) : values.endDate || MAX_DATE}
                        disablePast={displayMode !== ROLES.ADMIN && !isDepartmentView}
                        variant="inline"
                        inputVariant="outlined"
                        fullWidth
                        value={values.startDate}
                        onChange={value => {
                            setFieldValue('startDate', moment(value));
                        }}
                        renderDay={isHoliday}
                        format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                        allowKeyboardControl={true}
                    />
                    <ErrorMessage name="startDate" component="div" className="invalid-field-message" />
                </div>
                <div className="end-date-container">
                    <KeyboardDatePicker
                        autoOk={true}
                        required
                        name="endDate"
                        className="end-date-field"
                        label={intl.formatMessage({ id: "to" })}
                        disableToolbar
                        minDate={values.startDate || new Date()}
                        maxDate={values.type === HOLIDAY_TYPES.HOME_OFFICE ? new Date(moment().endOf('month')) : MAX_DATE}
                        variant="inline"
                        inputVariant="outlined"
                        fullWidth
                        value={values.endDate}
                        onChange={value => {
                            setFieldValue('endDate', moment(value));
                        }}
                        format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                        renderDay={isHoliday}
                        allowKeyboardControl={true}
                    />
                    <ErrorMessage name="endDate" component="div" className="invalid-field-message" />
                </div>

            </div>

            {values.type === 'Paid' &&
                <div className='holiday-type-field'>
                    <TextField
                        select
                        name="byArticle"
                        label={intl.formatMessage({ id: "by-article" })}
                        value={values.byArticle}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={errors.byArticle && touched.byArticle}
                        inputProps={{ 'data-testid': "holiday-article-field" }}
                    >
                        {HOLIDAY_ARTICLES[isDepartmentView ? DEPARTMENT_VIEW_KEY : displayMode][employmentType || EMPLOYMENT_TYPES.FULLTIME_CONTRACT].map(x => (
                            <MenuItem key={x.value} value={x.value}>
                                {intl.formatMessage({ id: convertStringToLanguageKey(x.value) })}
                            </MenuItem>
                        ))}
                    </TextField>
                    <ErrorMessage name="byArticle" component="div" className="invalid-field-message" />
                </div>
            }

            <div className='reason-field'>
                <TextField
                    name="reason"
                    value={values.reason}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "reason" })}
                    error={errors.reason && touched.reason}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="reason" component="div" className="invalid-field-message" />
            </div>
        </form>
    );
}

export default HolidayRequestFormView;
