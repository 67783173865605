import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import CalendlyDialog from 'components/Shared/CalendlyDialog';
import Pagination from 'components/Shared/Pagination';

import MyApplicationCard from './MyApplicationCard';

import { showNotification } from 'actions/notificationActions';

import { getAgencyCalendlyInfo } from 'reducers';

import { ReactComponent as NoInterviewsToShowBackground } from 'assets/no-interviews-background.svg';

import { EMPLOYEE_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { APPLICATION_STATUSES } from 'constants/applicationConstants';

import './MyApplicationsList.scss';

const ITEMS_PER_PAGE = 4;

const MyApplicationsList = ({
    applications,
    agencyCalendlyInfo,
    showNotification,
}) => {
    const filterOptions = ["All", "Active", "Inactive"];

    const [isCalendlyDialogOpen, setIsCalendlyDialogOpen] = useState(false);
    const [selectedFilterOption, setSelectedFilterOption] = useState(filterOptions[0]);
    const [page, setPage] = useState(1);

    const handleOpenCalendlyDialog = () => setIsCalendlyDialogOpen(true);
    const handleCloseCalendlyDialog = () => setIsCalendlyDialogOpen(false);

    const handleEventScheduling = () => {
        showNotification(EMPLOYEE_MESSAGES.CANDIDATE_BOOK_INTERVIEW_SUCCESS, NOTIFICATION_TYPES.SUCCESS);
        handleCloseCalendlyDialog();
    };

    const applyFilter = (applications) => {
        let filteredApplications;

        switch (selectedFilterOption) {
            case filterOptions[0]:
                filteredApplications = applications;
                break;
            case filterOptions[1]:
                filteredApplications = applications.filter(x => [APPLICATION_STATUSES.ACCEPTED_INTERVIEW, APPLICATION_STATUSES.INVITED_FOR_INTERVIEW, APPLICATION_STATUSES.AWAITING_INTERVIEW_FEEDBACK, APPLICATION_STATUSES.NEW_APPLICATION, APPLICATION_STATUSES.PENDING_ADMIN_REVIEW].includes(x.status))
                break;
            case filterOptions[2]:
                filteredApplications = applications.filter(x => [APPLICATION_STATUSES.APPROVED, APPLICATION_STATUSES.REJECTED, APPLICATION_STATUSES.REJECTED_BY_ADMIN].includes(x.status))
                break;    
            default:
                filteredApplications = applications;
                break;
        }

        return filteredApplications;
    }

    const maxPages = Math.ceil(applyFilter(applications).length / ITEMS_PER_PAGE)

    const filterApplications = (applications) => {
        let filteredApplications;

        // Filter based on selected filter
        filteredApplications = applyFilter(applications);

        // Pagination filtering
        const start = (page - 1) * ITEMS_PER_PAGE;
        const end = start + ITEMS_PER_PAGE < applications?.length ? start + ITEMS_PER_PAGE : applications.length;

        filteredApplications = filteredApplications.slice(start, end);

        return filteredApplications;
    };


    // Reset the page when the filter changes
    useEffect(() => {
        setPage(1);
    }, [selectedFilterOption]);

    return (
        <div className="my-applications-list-wrapper">
            {applications?.length > 0 ?
                <Fragment>
                    <SectionActionsWrapper>
                        <SectionActionsWrapper.LeftAlignedActions>
                            <SectionActionsWrapper.SectionTitle sectionTitle='My Interviews' />
                        </SectionActionsWrapper.LeftAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions>
                            <SectionActionsWrapper.SectionFilter options={filterOptions} selectedOption={selectedFilterOption} setSelectedOption={setSelectedFilterOption} />
                        </SectionActionsWrapper.RightAlignedActions>
                    </SectionActionsWrapper>
                    <div className="my-applications-list">
                        {filterApplications(applications).map(application =>
                            <MyApplicationCard
                                key={application.applicationId}
                                application={application}
                            />
                        )}
                    </div>
                </Fragment>
                : <div className="no-applications-found">
                    <NoInterviewsToShowBackground />
                    <p className="no-applications-text heading-s color-grey">It's quiet for now...</p>
                </div>
            }
            {!applications?.length ?
                <div className="contact-hr-wrapper">
                    <div className="contact-hr-content">
                        <p className="heading-xs color-dark-grey">You may need consultation with HR agent</p>
                        <p className="subheading-m color-grey">Set up a FREE 30 minute consultation with one of our project leaders so we can discuss your requirements.</p>
                    </div>
                    <PrimaryButton className="contact-hr-button purple" text="Contact HR agent" handleClick={handleOpenCalendlyDialog} />
                </div>
                : null
            }
            {isCalendlyDialogOpen &&
                <CalendlyDialog
                    handleClose={handleCloseCalendlyDialog}
                    calendlyInfo={agencyCalendlyInfo}
                    handleEventScheduling={handleEventScheduling}
                />
            }
            {applications?.length ? <Pagination page={page} totalPages={maxPages} setPage={setPage} /> : null}
        </div>
    );
};

const mapStateToProps = (state) => ({
    agencyCalendlyInfo: getAgencyCalendlyInfo(state),
});

const mapDispatchToProps = {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyApplicationsList);
