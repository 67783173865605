import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import PrimaryAddButton from 'components/Shared/Buttons/PrimaryAddButton';

import { getEmployees, getRoles, getEmployeesTableDataFetchState, getUserId } from 'reducers';
import { fetchAllSupplierEmployees, registerEmployee } from 'actions/employeeActions';

import AddTeamMemberDialog from 'components/Employees/AddTeamMemberDialog';
import ConfirmInviteUserDialog from 'components/Shared/ConfirmInviteUserDialog';
import ExploreTalentsEmployeeCard from 'components/ExploreTalents/ExploreTalentsEmployeeCard';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ROLES } from 'constants/userConstants';
import './SupplierEmployees.scss';

const SupplierEmployees = ({
    agencyId,
    userId,
    employees,
    supplierId,
    registerEmployee,
    fetchAllSupplierEmployees,
    dataFetchState,
}) => {
    const intl = useIntl();

    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [newUserData, setNewUserData] = useState({});
    
    useEffect(() => {
        const preserveState = true;
        fetchAllSupplierEmployees(agencyId, supplierId, preserveState)
    }, [supplierId]);

    const handleOpenDialog = () => setIsAddDialogOpen(true);
    const handleCloseDialog = () => setIsAddDialogOpen(false);
    const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

    const handleSubmitClick = async (values) => {
        values = {
            ...values,
            employmentInformation: { generalInfo: { isAvailable: false, isVetted: false } },
            supplierId: supplierId,
            role: ROLES.SUPPLIER_EMPLOYEE,
            addedBy: userId,
        };

        if (values.email) {
            setNewUserData(values)
            setOpenConfirmDialog(true);
            return
        }

        await registerEmployee(values, agencyId);
        handleCloseDialog();
    };

    const handleProceedClick = async () => {
        handleCloseConfirmDialog();
        await registerEmployee(newUserData, agencyId);
        handleCloseDialog();
        setNewUserData({});
    };
  
    return (
        <div>
            {dataFetchState.isDataFetching && employees.length === 0
                ? <CircularProgress thickness={5} size={50} disableShrink className="page-loader" />
                : <div className="supplier-employees-content">
                    <div className="action-header">
                        <PrimaryAddButton className="add-member-button" handleClick={handleOpenDialog} text={intl.formatMessage({ id: "add-team-member" })} />
                    </div>
                    {employees.map(employee => {
                        return (
                            <ExploreTalentsEmployeeCard
                                key={employee._id}
                                employee={employee}
                            >
                                <ExploreTalentsEmployeeCard.ProfileWrapper>
                                    <ExploreTalentsEmployeeCard.ProfileWrapper.RoleBadge />
                                    <div className="avatar-status-wrapper">
                                        <ExploreTalentsEmployeeCard.ProfileWrapper.Avatar />
                                        <ExploreTalentsEmployeeCard.ProfileWrapper.StatusOrb />
                                    </div>
                                    <ExploreTalentsEmployeeCard.ProfileWrapper.VettedProfile />

                                </ExploreTalentsEmployeeCard.ProfileWrapper>

                                <ExploreTalentsEmployeeCard.ProfileInfoWrapper employee={employee}>
                                    <ExploreTalentsEmployeeCard.ProfileInfoWrapper />
                                </ExploreTalentsEmployeeCard.ProfileInfoWrapper>

                                <ExploreTalentsEmployeeCard.ButtonsWrapper>
                                    <ExploreTalentsEmployeeCard.ButtonsWrapper.ViewProfile />
                                    <ExploreTalentsEmployeeCard.ButtonsWrapper.EditProfile />
                                </ExploreTalentsEmployeeCard.ButtonsWrapper>
                            </ExploreTalentsEmployeeCard>
                        )
                    })}

                    <AddTeamMemberDialog
                        isOpen={isAddDialogOpen}
                        handleCloseDialog={handleCloseDialog}
                        handleRegisterMember={handleSubmitClick}
                    />
                    <ConfirmInviteUserDialog
                        openDialog={openConfirmDialog}
                        invitedUser={ROLES.EMPLOYEE}
                        handleInviteUser={handleProceedClick}
                        handleCloseDialog={handleCloseConfirmDialog}
                    />
                </div>
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    userId: getUserId(state),
    employees: getEmployees(state),
    roles: getRoles(state),
    dataFetchState: getEmployeesTableDataFetchState(state),
});

const mapDispatchToProps = {
    fetchAllSupplierEmployees,
    registerEmployee
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierEmployees);
