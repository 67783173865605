
import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import MissingInformation from '../MissingInformation';

import { ReactComponent as EditCVIcon } from 'assets/edit-candidate-cv-icon.svg';

import { ROLES } from 'constants/userConstants';
import { MONTH_NAME_FORMAT } from 'constants/timesFormat';

import { ReactComponent as DateIcon } from 'assets/cv-date-icon.svg';

import './CvEducation.scss';

const CvEducation = ({
    education,
    enterEditMode,
    enableEdit,
    displayMode,
}) => {
    const intl = useIntl();

    const isClient = displayMode === ROLES.CLIENT;

    return (
        <>
            <div className="cv-education-wrapper">
                <div className="header">
                    <h1 className="title subheading-xxl color-dark-grey">{intl.formatMessage({ id: "education" })}</h1>
                    {enableEdit &&
                        <div className="item-edit-icon-wrapper" onClick={() => enterEditMode('education')}>
                            <EditCVIcon />
                        </div>
                    }
                </div>
                {education?.length > 0 ?
                    education?.map(e =>
                        <div className="dates-row" key={e._id}>
                            <div>
                                <p className="subheading-l color-dark-grey">{e.title}</p>
                                {e.institution && <p className="row-subheading subheading-m color-grey">{e.institution}</p>}
                            </div>
                            <div className="dates-wrapper">
                                {e.startDate
                                    && <>
                                        <DateIcon />
                                        <p className="dates">
                                            <span className="subheading-m color-grey">{moment(e.startDate).format(MONTH_NAME_FORMAT)}</span>
                                            <span className="subheading-m color-grey"> - </span>
                                            {e.currentlyStudy
                                                ? <span className="subheading-m color-grey">ongoing</span>
                                                : <span className="subheading-m color-grey">{moment(e.endDate).format(MONTH_NAME_FORMAT)}</span>}
                                        </p>
                                    </>}
                            </div>
                        </div>)
                    : isClient ? <div className="empty-category" /> : <MissingInformation />
                }
            </div>
        </>
    )
}

export default CvEducation;
