export const initialValuesClient = (basicInformation) => {
    return {
        name: basicInformation.name,
        country: basicInformation?.country || '',
        hqLocation: basicInformation?.hqLocation || '',
        obfuscatedName: basicInformation?.obfuscatedName || '',
        companyWebsite: basicInformation?.companyWebsite || ''
    };
};


export const initialValues = (basicInformation) => {
    return {
        name: basicInformation.name,
        country: basicInformation?.country || '',
        email: basicInformation?.email || '',
    };
};

export const getInitialValues = (isClient, basicInformation) => {
    return isClient ? initialValuesClient(basicInformation) : initialValues(basicInformation)
}
