import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { getDialogActionButtonLoading } from 'reducers';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import { CircularProgress, DialogActions } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';

import DialogDiscardButton from 'components/Shared/DialogButtons/DialogDiscardButton';
import DialogCloseButton from 'components/Shared/DialogButtons/DialogCloseButton';
import DialogSaveButton from 'components/Shared/DialogButtons/DialogSaveButton';
import ConfirmDialog from 'components/Shared/ConfirmDialog';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './CommonFormikDialog.scss';

const CommonFormikDialog = ({
    dialogTitle,
    onSave,
    open,
    onClose,
    className,
    children,
    saveBtnText = 'Save',
    discardBtnText = 'Discard',
    size,
    dialogActionButtonLoading,
    displayButtons = true,
    ...rest
}) => {
    const intl = useIntl();

    const { dirty, resetForm, values, isSubmitting } = useFormikContext();
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const dialogRef = useRef(null);

    useEffect(() => {
        if (!open && dialogRef.current) {
            setTimeout(() => {
                resetForm();
            }, 100);
        }
    }, [open]);

    const handleCloseConfirmationDialog = () => setIsConfirmationDialogOpen(false);

    const handleCloseDialog = () => {
        if (dirty) {
            setIsConfirmationDialogOpen(true);
        } else {
            onClose();
        }
    }

    const handleConfirmDialog = () => {
        onClose();
        handleCloseConfirmationDialog();
    }

    return (
        <>
            <MuiDialog open={open} onClose={handleCloseDialog} ref={dialogRef} className={`common-formik-dialog ${size} ${className ? className : ''}`} maxWidth="sm" {...rest}>
                <MuiDialogTitle disableTypography className="dialog-title">
                    <Typography variant="body2" className="dialog-title-text heading-s color-dark-grey" data-testid="dialog-title-id">
                        {dialogTitle}
                    </Typography>
                    <DialogCloseButton className="dialog-close-btn" onClick={handleCloseDialog} />
                </MuiDialogTitle>
                <DialogContent className="dialog-content">{children}</DialogContent>
                {displayButtons &&
                    <DialogActions className="dialog-actions-container">
                        <DialogDiscardButton onClick={() => onClose()}>{intl.formatMessage({ id: convertStringToLanguageKey(discardBtnText) })}</DialogDiscardButton>
                        <DialogSaveButton
                            className={`${values.isSubmitDisabled ? 'disabled' : ''} ${dialogActionButtonLoading ? 'disabled loading' : ''}`}
                            disabled={values.isSubmitDisabled || dialogActionButtonLoading}
                            onClick={() => {
                                if (isSubmitting) {
                                    return;
                                }

                                onSave()
                            }}
                        >
                            <span>{intl.formatMessage({ id: convertStringToLanguageKey(saveBtnText) })}</span>
                            {dialogActionButtonLoading && <CircularProgress thickness={5} className="button-loader" color="inherit" size={20} />}
                        </DialogSaveButton>
                    </DialogActions>
                }
            </MuiDialog>
            <ConfirmDialog
                openDialog={isConfirmationDialogOpen}
                dialogTitle={intl.formatMessage({ id:"unsaved-changes-dialog-text" })}
                confirmButtonName={intl.formatMessage({ id:"yes" })}
                discardButtonName={intl.formatMessage({ id:"no" })}
                handleConfirmDialog={handleConfirmDialog}
                handleCloseDialog={handleCloseConfirmationDialog}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    dialogActionButtonLoading: getDialogActionButtonLoading(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CommonFormikDialog);
