import api from './api';
import requester from './requester';
import { URL_QUERY_ACTION_TYPES } from 'constants/typeConstants';

const fetchInternalEmployees = (agencyId, fields, roles) => requester(api.employees(agencyId, fields, roles)).getMany();

const employeeService = {
    fetchInternalEmployees,
    exportAdminTeam: (agencyId, query, fileName) => requester(api.adminTeamExport(agencyId)).saveAsAction(fileName, query),
    exportEmployeeInformation: (agencyId, employeeId, fileName, departmentId) => requester(api.employeeInformationExport(agencyId, employeeId), { departmentId }).saveAsAction(fileName),
    fetchAdminTeamPage: (agencyId, query) => requester(api.adminTeamPage(agencyId, query)).getMany(),
    fetchKnowYourTeamSample: (agencyId, userId, size) => requester(api.teamSample(agencyId, userId, size)).getMany(),
    fetchAllClientConsultants: (agencyId, fields) => requester(api.clientConsultants(agencyId, fields)).getMany(),
    fetchAllConsultantsData: (agencyId, fields) => requester(api.consultants(agencyId, fields)).getMany(),
    fetchAllSupplierEmployees: (agencyId, supplierId) => requester(api.supplierEmployees(agencyId, supplierId)).getMany(),
    fetchSupplierTeamPage: (agencyId, supplierId, query) => requester(api.supplierTeamPage(agencyId, supplierId, query)).getMany(),
    fetchAllCandidates: agencyId => requester(api.candidates(agencyId)).getMany(),
    fetchAllRecruiterEmployees: (agencyId, recruiterId) => requester(api.recruiterEmployees(agencyId, recruiterId)).getMany(),
    registerEmployee: (employeeData, agencyId) => requester(api.employees(agencyId)).create(employeeData),
    createLanguage: (language, employeeId, agencyId, departmentId) => requester(api.languages(employeeId, agencyId, departmentId)).create(language),
    updateLanguage: (language, employeeId, agencyId, departmentId) => requester(api.languageModify(employeeId, language._id, agencyId, departmentId)).update(language),
    deleteLanguage: (langId, employeeId, agencyId, departmentId) => requester(api.languageModify(employeeId, langId, agencyId, departmentId)).delete(),
    createEducationEntry: (education, employeeId, agencyId, departmentId) => requester(api.education(employeeId, agencyId, departmentId)).create(education),
    updateEducationEntry: (education, employeeId, agencyId, departmentId) => requester(api.educationModify(employeeId, education._id, agencyId, departmentId)).update(education),
    updateEducationEntries: (educationEntries, employeeId, agencyId) => requester(api.educationModifyEntries(employeeId, agencyId)).update(educationEntries),
    deleteEducationEntry: (eduId, employeeId, agencyId, departmentId) => requester(api.educationModify(employeeId, eduId, agencyId, departmentId)).delete(),
    createReview: (reviewData, employeeId, agencyId) => requester(api.performanceReviews(employeeId, agencyId)).create(reviewData),
    updateReview: (reviewData, employeeId, agencyId) => requester(api.performanceReviews(employeeId, agencyId)).update(reviewData),
    deleteReview: (reviewId, employeeId, agencyId) => requester(api.performanceReviews(employeeId, agencyId, reviewId)).delete(),
    updateEmployeeActiveCompensation: (activeCompensation, employeeId, agencyId) =>
        requester(api.updateEmployeeActiveCompensation(employeeId, agencyId)).update(activeCompensation),
    updateHolidayAllowance: (holidayAllowance, employeeId, agencyId, departmentId) => {
        const obj = { ...holidayAllowance, daysPerYear: Number(holidayAllowance.daysPerYear) };
        return requester(api.holidayAllowance(employeeId, agencyId, departmentId)).update(obj)
    },
    updateHomeOfficeAllowance: (homeOfficeAllowance, employeeId, agencyId, departmentId) => requester(api.homeOfficeAllowance(employeeId, agencyId, departmentId)).update({value: Number(homeOfficeAllowance)}),
    updateBankDetails: (bankDetails, employeeId, agencyId, departmentId) => requester(api.bankDetails(employeeId, agencyId, departmentId)).update(bankDetails),
    getAllReviews: (employeeId, agencyId) => requester(api.performanceReviews(employeeId, agencyId)).getMany(),
    getProfile: (employeeId, agencyId, departmentId) => requester(api.employeeProfile(employeeId, agencyId, departmentId)).getOne(),
    fetchSkillSuggestions: (agencyId, inputText) => requester(api.fetchSkillSuggestions(agencyId, inputText)).getMany(),
    updateProfileSkills: (agencyId, employeeId, profileSkills, departmentId) => requester(api.profileSkills(agencyId, employeeId, departmentId)).update(profileSkills),
    getProjectEntry: (projectId, employeeId, agencyId, departmentId) => requester(api.getProject(employeeId, projectId, agencyId, departmentId)).getOne(),
    createProjectEntry: (project, employeeId, agencyId, departmentId, linkedInSkillCategoryId) => requester(api.project(employeeId, agencyId, departmentId)).create({ project, linkedInSkillCategoryId }),
    updateProjectEntry: (project, employeeId, agencyId, departmentId, linkedInSkillCategoryId) => requester(api.projectModify(employeeId, project._id, agencyId, departmentId)).update({ project, linkedInSkillCategoryId }),
    deleteProjectEntry: (projectId, employeeId, agencyId, departmentId) => requester(api.projectModify(employeeId, projectId, agencyId, departmentId)).delete(),
    updatePersonalProjects: (projects, employeeId, agencyId, departmentId) => requester(api.project(employeeId, agencyId, departmentId)).update(projects),
    createProfileSkill: (agencyId, employeeId, skillName, isNew, departmentId) => requester(api.profileSkills(agencyId, employeeId, departmentId)).create({ skillName, isNew }),
    deleteProfileSkill: (agencyId, employeeId, profileSkillId, departmentId) => requester(api.profileSkill(agencyId, employeeId, profileSkillId, departmentId)).delete(),
    createCertificate: (certificate, employeeId, agencyId, departmentId) => requester(api.certificates(employeeId, agencyId, departmentId)).create(certificate),
    updateCertificate: (certificate, employeeId, agencyId, departmentId) => requester(api.certificateModify(employeeId, certificate._id, agencyId, departmentId)).update(certificate),
    updateCertificates: (certificates, employeeId, agencyId) => requester(api.certificatesModify(employeeId, agencyId)).update(certificates),
    deleteCertificate: (certId, employeeId, agencyId, departmentId) => requester(api.certificateModify(employeeId, certId, agencyId, departmentId)).delete(),
    updateGeneralInfo: (info, employeeId, agencyId, departmentId) => requester(api.generalInfo(employeeId, agencyId, departmentId)).update(info),

    fetchEmploymentInformation: (agencyId, employeeId, departmentId) => requester(api.employmentInformation(agencyId, employeeId, departmentId)).getOne(),
    fetchEmployeeUploadedCv: (agencyId, employeeId) => requester(api.employeeUploadedCv(agencyId, employeeId)).getOne(),
    downloadEmployeeCv: (agencyId, employeeId, fileName) => requester(api.employeeUploadedCvDocument(agencyId, employeeId)).saveAs(fileName),

    fetchPersonalInfo: (agencyId, employeeId, departmentId) => requester(api.personalInfo(agencyId, employeeId, departmentId)).getOne(),
    fetchPersonalInfoViewer: (agencyId, employeeId) => requester(api.personalInfoView(agencyId, employeeId)).getOne(),
    updateContactInfo: (agencyId, departmentId, employeeId, contactInfo) => requester(api.contactInfo(agencyId, departmentId, employeeId)).create(contactInfo),
    updatePersonalInfo: (agencyId, departmentId, employeeId, personalInfo) => requester(api.personalInformation(agencyId, departmentId, employeeId)).create(personalInfo),
    updateBasicInfo: (agencyId, departmentId, employeeId, basicInfo) => requester(api.basicInfo(agencyId, departmentId, employeeId)).create(basicInfo),

    getTrackedDays: (agencyId, employeeId, month, departmentId) => requester(api.trackedDays(agencyId, departmentId, employeeId, month)).getOne(),
    trackDays: (agencyId, departmentId, employeeId, data, month, type) => requester(api.trackedDaysWithType(agencyId, departmentId, employeeId, month, type)).uploadFile(data),

    getExportedCVs: (agencyId, employeeIds, fileName, departmentId) => requester(api.cv(agencyId, employeeIds, departmentId)).saveAs(fileName),
    createTask: (task, employeeId, agencyId) => requester(api.task(employeeId, agencyId)).create(task),
    updateTask: (task, employeeId, agencyId) => requester(api.taskModify(employeeId, task._id, agencyId)).update(task),
    getAllTasks: (employeeId, agencyId, onBehalfOf) => requester(api.task(employeeId, agencyId, onBehalfOf)).getMany(),

    updateAvatar: (croppedImgData, employeeId, agencyId, departmentId) => requester(api.updateAvatar(agencyId, employeeId, departmentId)).uploadFile(croppedImgData),
    deleteAvatar: (employeeId, agencyId, departmentId) => requester(api.deleteAvatar(employeeId, agencyId, departmentId)).delete(),
    getEmployeeExportedTimesheet: (employeeId, month, fileName, agencyId, departmentId, trackedProjects) =>
        requester(api.employeeTimesheet(employeeId, month, agencyId, departmentId, URL_QUERY_ACTION_TYPES.DOWNLOAD)).saveAsAction(fileName, { trackedProjects }),
    createHolidayRequest: (holidayRequests, employeeId, agencyId, departmentId) => requester(api.holidayRequest(employeeId, agencyId, departmentId)).create(holidayRequests),
    fetchHolidays: (employeeId, agencyId, departmentId) => requester(api.employeeHolidays(employeeId, agencyId, departmentId)).getMany(),
    fetchHolidaysForPeriod: (employeeId, agencyId, from, to, departmentId) => requester(api.employeeHolidaysForPeriod(employeeId, agencyId, from, to, departmentId)).getMany(),
    fetchHolidayAllowance: (employeeId, agencyId, departmentId) => requester(api.holidayAllowance(employeeId, agencyId, departmentId)).getOne(),
    updateHolidayRequests: (employeeId, agencyId, departmentId, requests, type, isAutoSign) => requester(api.employeeHolidayRequests(employeeId, agencyId, departmentId, type, isAutoSign)).updateMany(requests),
    fetchEmployeeViewProfileData: (userId, agencyId) => requester(api.fetchEmployeeViewProfileData(userId, agencyId)).getHTML(),
    fetchEmployeeViewPublicProfileData: (userId) => requester(api.fetchEmployeeViewPublicProfileData(userId)).getHTML(),
    downloadSignedHolidayFile: (agencyId, departmentId, employeeId, holidayId, fileName, isDeleted) => requester(api.downloadSignedHolidayFile(agencyId, departmentId, employeeId, holidayId, isDeleted)).saveAs(fileName),
    downloadSignedHolidayFiles: (agencyId, departmentId, employeeId, fileName, holidaysInfos) => requester(api.downloadSignedHolidayFiles(agencyId, departmentId, employeeId)).saveAsAction(fileName, holidaysInfos),
    fetchEmployeeProjects: (employeeId, agencyId) => requester(api.employeeProjects(agencyId, employeeId)).getMany(),
    fetchEmployeeProjectsWithClientInfo: (employeeId, agencyId) => requester(api.employeeProjectsWithClientInfo(agencyId, employeeId)).getMany(),
    updateEmployeesRole: (agencyId, updateData) => requester(api.employeesRole(agencyId)).updateMany(updateData),
    createBenefitTemplate: (agencyId, employeeId, benefitData) => requester(api.benefitTemplates(agencyId, employeeId)).create(benefitData),
    createBenefit: (agencyId, employeeId, benefitData) => requester(api.benefits(agencyId, employeeId)).create(benefitData),
    fetchBenefitTemplates: (agencyId, employeeId) => requester(api.benefitTemplates(agencyId, employeeId)).getOne(),
    fetchBenefits: (agencyId, employeeId) => requester(api.benefits(agencyId, employeeId)).getOne(),
    deleteBenefit: (agencyId, employeeId, benefitId) => requester(api.benefitModify(agencyId, employeeId, benefitId)).delete(),
    updateBenefit: (agencyId, employeeId, benefitId, benefitData) => requester(api.benefitModify(agencyId, employeeId, benefitId)).update(benefitData),
    fetchTimeTrackingInformation: (agencyId, employeeId, departmentId, month) => requester(api.employeeTimeTracking(agencyId, employeeId, departmentId, month)).getOne(),
    updateContractInformation: (agencyId, departmentId, employeeId, contractData) => requester(api.contractInformation(agencyId, departmentId, employeeId, contractData)).update(contractData),
    updateEmploymentDates: (agencyId, employeeId, departmentId, employmentDatesData) => requester(api.employmentDates(agencyId, employeeId, departmentId)).update(employmentDatesData),
    fetchContractInformation: (agencyId, employeeId) => requester(api.contractInformation(agencyId, employeeId)).getOne(),
    sendHolidaySignReminderEmail: (agencyId, departmentId, employeeId, holidayId) => requester(api.sendHolidaySignReminderEmail(agencyId, departmentId, employeeId, holidayId)).create(),
    fetchEmployeeRelatedOpportunities: (agencyId, employeeId, departmentId) => requester(api.employeeRelatedOpportunities(agencyId, employeeId, departmentId)).getMany(),
    fetchEmployeeDashboardInformation: (agencyId, employeeId, from, to) => requester(api.employeeDashboardInformation(agencyId, employeeId, from, to)).getOne(),
    fetchCandidatePersonalInformation: (agencyId, candidateId) => requester(api.candidatePersonalInformation(agencyId, candidateId)).getOne(),
    updateEmployeeOccupationStatus: (agencyId, employeeId, data) => requester(api.employeeOccupationStatus(agencyId, employeeId)).update(data),
    updateEmployeeRecruiter: (agencyId, employeeId, data) => requester(api.employeeRecruiter(agencyId, employeeId)).update(data),
    fetchEmployeeHolidayPage: (agencyId, employeeId, from, to, departmentId) => requester(api.employeeHolidayPage(agencyId, employeeId, from, to, departmentId)).getOne(),
    fetchEmployeeTeam: (agencyId, employeeId) => requester(api.getEmployeeTeam(agencyId, employeeId)).getMany(),
    updateEmployeeCv: (agencyId, employeeId, cvData) => requester(api.employeeCv(agencyId, employeeId)).update(cvData),
    updateWorkspaceMigrationInfo: (agencyId, employeeId, data) => requester(api.workspaceMigrationInfo(agencyId, employeeId)).update(data),
    employeesTimesheetCompletion: (agencyId, employeeId, from, to) => requester(api.employeesTimesheetCompletion(agencyId, employeeId, from, to)).create(),
    fetchEmployeeOverviewInformation: (agencyId, employeeId, departmentId) => requester(api.employeeOverview(agencyId, employeeId, departmentId)).getOne()
};

export default employeeService;
