import React from 'react';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

import Divider from '@material-ui/core/Divider';

import JobTypeLabel from 'components/JobsDashboard/SharedComponents/JobTypeLabel';

import { ReactComponent as CompanyIcon } from 'assets/icon-company.svg';
import { ReactComponent as LocationIcon } from 'assets/icon-location.svg';
import { ReactComponent as DateAddedIcon } from 'assets/icon-date-added.svg';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

import './Job.scss'

const Job = ({
    jobOpportunity,
}) => (
    <div className="job-wrapper">
        <div className={`job-container${isMobile ? ' mobile' : ''}`}>
            <div className="job-inner">
                <JobTypeLabel jobType={jobOpportunity.jobType} />
                <div className="job-header-wrapper">
                    <div className="header-info">
                        <div className="job-title">
                            <h4>{jobOpportunity.position}</h4>
                            {jobOpportunity.isLastViewedJob && <span className="label seen">Seen</span>}
                            {jobOpportunity.application?.hasApplied && <span className="label applied">Applied</span>}
                        </div>
                        <div className="job-company-date-wrapper">
                            <div className="job-company">
                                <CompanyIcon className="icon" />
                                <p>{jobOpportunity.client.name}</p>
                            </div>
                            <div className="job-date">
                                <DateAddedIcon className="icon" />
                                <p>{moment(jobOpportunity.dateAdded).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</p>
                            </div>
                        </div>
                        <div className="job-location">
                            <LocationIcon className="icon" />
                            <p>{`${jobOpportunity.geographicLocations?.length ? `Remote (${jobOpportunity.geographicLocations.join(", ")})` : 'Remote'}`}</p>
                        </div>
                    </div>
                </div>
                <Divider className="divider" variant="middle" />
                <div className="job-body">
                    {
                        jobOpportunity.aboutTheClient &&
                        <div className="job-description">
                            <h6 className="description-title">About the client</h6>
                            <pre className="text-description">{jobOpportunity.aboutTheClient}</pre>
                        </div>
                    }
                    {
                        jobOpportunity.jobSummary &&
                        <div className="job-description">
                            <h6 className="description-title">About the job</h6>
                            <pre className="text-description">{jobOpportunity.jobSummary}</pre>
                        </div>
                    }
                    {
                        jobOpportunity.aboutCandidate &&
                        <div className="job-description">
                            <h6 className="description-title">Responsibilities</h6>
                            <pre className="text-description">{jobOpportunity.aboutCandidate}</pre>
                        </div>
                    }
                    {
                        jobOpportunity.mandatorySkills?.length > 0 &&
                        <div className="mandatory-skills">
                            <h6 className="description-title">Mandatory Skills</h6>
                            <div className="skills-list">
                                {jobOpportunity.mandatorySkills?.map((skill, index) => <div key={`mandatory-${index}-${skill.name}`} className="skill"><p>{skill.name}</p></div>)}
                            </div>
                        </div>
                    }
                    {
                        jobOpportunity.niceToHaveSkills?.length > 0 &&
                        <div className="nice-to-have-skills">
                            <h6 className="description-title">Nice to have</h6>
                            <div className="skills-list">
                                {jobOpportunity.niceToHaveSkills?.map((skill, index) => <div key={`nice-to-have-${index}-${skill.name}`} className="skill"><p>{skill.name}</p></div>)}
                            </div>
                        </div>
                    }
                    {
                        jobOpportunity.requirements &&
                        <div className="job-description">
                            <h6 className="description-title">Requirements</h6>
                            <pre className="text-description">{jobOpportunity.requirements}</pre>
                        </div>
                    }
                    {
                        jobOpportunity.benefits &&
                        <div className="job-description">
                            <h6 className="description-title">Additional Benefits</h6>
                            <pre className="text-description">{jobOpportunity.benefits}</pre>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div >
);

export default Job;
