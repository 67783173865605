import React from 'react';

import { ReactComponent as LeftArrow } from 'assets/arrow-left.svg';
import { ReactComponent as RightArrow } from 'assets/arrow-right.svg';

import './Pagination.scss';

const Pagination = ({
    page,
    setPage,
    totalPages
}) => {
    const goForward = () => {
        if (page + 1 <= totalPages) {
            setPage(page + 1);
        }
    };

    const goBackwards = () => {
        if (page - 1 >= 1) {
            setPage(page - 1);
        }
    };

    return (
        <div className="pagination-wrapper">
            <LeftArrow onClick={goBackwards} />
            <p className="subheading-xs color-dark-grey">{page} of {totalPages}</p>
            <RightArrow onClick={goForward} />
        </div>
    )
};

export default Pagination;
