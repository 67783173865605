import React, { useState } from 'react';

import moment from 'moment';

import { ReactComponent as LocationIcon } from 'assets/icons-location.svg';
import { ReactComponent as ClockIcon } from 'assets/icons-clock.svg';
import { ReactComponent as CalendarIcon } from 'assets/calender-icon.svg';

import { candidateStatusMapper } from 'utils/applicationUtils';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { DATE_FORMAT_WITH_SLASH } from 'constants/commonConstants';

import './JobCard.scss';

const JobCard = ({
    applicationDetails,
    jobDetails,
}) => {
    const [isJobInfoExpanded, setIsJobInfoExpanded] = useState(false);

    const toggleJobDetails = () => setIsJobInfoExpanded(prevState => !prevState);

    return (
        <div className="job-card-wrapper">
            <div className="card-header">
                <div className="company-info">
                    <img src={applicationDetails.companyLogo} />
                    <div>
                        <p className="subheading-xs color-grey">Company</p>
                        <p className="heading-xs color-dark-grey">{applicationDetails.company}</p>
                    </div>
                </div>
                <div className={`application-status subheading-xs-thicker ${candidateStatusMapper(applicationDetails.status)?.replace(" ", "-").toLowerCase()}`}>{candidateStatusMapper(applicationDetails.status)}</div>
            </div>
            <Divider className="section-divider" />
            <div className="position-info">
                <p className="subheading-xs color-grey">Position needed:</p>
                <p className="heading-xs color-dark-grey">{applicationDetails.position}</p>
            </div>
            <div className="additional-details-wrapper">
                <div className="additional-details-content">
                    <div className="detail">
                        <LocationIcon />
                        <p className="subheading-xxl color-grey">Remote ({jobDetails.geographicLocations?.join(', ')})</p>
                    </div>
                    <div className="detail">
                        <ClockIcon />
                        <p className="subheading-xxl color-grey">{applicationDetails.jobType}</p>
                    </div>
                    <div className="detail">
                        <CalendarIcon />
                        <p className="subheading-xxl color-grey">{moment(applicationDetails.applicationDate).format(DATE_FORMAT_WITH_SLASH)}</p>
                    </div>
                </div>
                <p className="link" onClick={toggleJobDetails}>Show {isJobInfoExpanded ? "less" : "more"}</p>
            </div>
            {isJobInfoExpanded &&
                <div className="full-job-details-body">
                    {
                        jobDetails.aboutTheClient &&
                        <div className="job-section">
                            <Typography className="subheading-xxl color-dark-grey" variant="body2" >
                                About the company
                            </Typography>
                            <Divider className="section-divider" />
                            <p className="subheading-m color-grey">{jobDetails.aboutTheClient}</p>
                        </div>
                    }
                    {
                        jobDetails.jobSummary &&
                        <div className="job-section">
                            <Typography className="subheading-xxl color-dark-grey" variant="body2" >
                                About the job
                            </Typography>
                            <Divider className="section-divider" />
                            <p className="subheading-m color-grey">{jobDetails.jobSummary}</p>
                        </div>
                    }
                    {jobDetails.mandatorySkills?.length > 0 && (
                        <div className="job-section">
                            <Typography className="subheading-xxl color-dark-grey" variant="body2">
                                Mandatory Skills
                            </Typography>
                            <Divider className="section-divider" />
                            <div className="skills-container">
                                {jobDetails.mandatorySkills.map((x) => (
                                    <div key={x.name} className="skill-container">
                                        <span className="skill">{x.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {jobDetails.niceToHaveSkills?.length > 0 && (
                        <div className="job-section">
                            <Typography className="subheading-xxl color-dark-grey" variant="body2">
                                Nice To Have
                            </Typography>
                            <Divider className="section-divider" />
                            <div className="skills-container">
                                {jobDetails.niceToHaveSkills.map((x) => (
                                    <div key={x.name} className="skill-container">
                                        <span className="skill">{x.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {
                        jobDetails.aboutCandidate &&
                        <div className="job-section">
                            <Typography className="subheading-xxl color-dark-grey" variant="body2" >
                                About the candidate
                            </Typography>
                            <Divider className="section-divider" />
                            <p className="subheading-m color-grey">{jobDetails.aboutCandidate}</p>
                        </div>
                    }
                    {
                        jobDetails.requirements &&
                        <div className="job-section">
                            <Typography className="subheading-xxl color-dark-grey" variant="body2" >
                                Requirements
                            </Typography>
                            <Divider className="section-divider" />
                            <p className="subheading-m color-grey">{jobDetails.requirements}</p>
                        </div>
                    }
                    {
                        jobDetails.benefits &&
                        <div className="job-section">
                            <Typography className="subheading-xxl color-dark-grey" variant="body2" >
                                Additional benefits
                            </Typography>
                            <Divider className="section-divider" />
                            <p className="subheading-m color-grey">{jobDetails.benefits}</p>
                        </div>
                    }
                </div>
            }
        </div>
    )
};

export default JobCard;
