import moment from 'moment';
import { auth } from 'utils/firebase';
import { logEvent, setUserId } from 'utils/amplitude';
import authService from 'services/authService';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { getStateAgencyName } from 'utils/helpers';

import {
    LOGIN_FETCH_SUCCESS,
    REGISTER_FETCH_SUCCESS,
    LOGOUT_FETCH_SUCCESS,
    TOKEN_REFRESH_SUCCESS,
    PASSWORD_RESET_EMAIL_SEND_SUCCESS,
    PASSWORD_RESET_SUCCESS,
    SWITCH_MODE,
    REGISTER_CLIENT_FETCH_SUCCESS,
    LOGIN_SUCCESS,
    SSO_LOGIN_SUCCESS,
    USER_INFORMATION_FETCH_SUCCESS,
    USER_HAS_NEEDED_EVROTRUST_INFO_SUCCESS,
} from './actionTypes';

import { fetchUnassignedUserTermsAndConditions } from './userActions';
import { setHomeInformationInitialState, updateTermsAndConditionsVersion } from 'actions/agencyActions';
import { hideApplicationLoader, showApplicationLoader } from 'actions/applicationLoaderActions';
import { setRememberMeWithToken, fetchHomeInformationWithToken, fetchUnassignedHomeInformationWithToken } from './tokenPropActions';

import { showNotification, clearNotification } from 'actions/notificationActions';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { AUTH_MESSAGES } from 'constants/messageConstants';
import { OOD_CODE_ERRORS } from 'constants/authConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { ROLES } from 'constants/userConstants';

export const fetchLoginSuccess = (userData) => ({
    type: LOGIN_FETCH_SUCCESS,
    payload: userData
});

const fetchRegisterSuccess = (userData) => ({
    type: REGISTER_FETCH_SUCCESS,
    payload: userData
});

const fetchLogoutSuccess = () => ({
    type: LOGOUT_FETCH_SUCCESS
});

export const refreshTokenSuccess = token => ({
    type: TOKEN_REFRESH_SUCCESS,
    payload: token
});

const sendPasswordResetEmailSuccess = () => ({
    type: PASSWORD_RESET_EMAIL_SEND_SUCCESS
});

const resetPasswordSuccess = () => ({
    type: PASSWORD_RESET_SUCCESS
});

const fetchRegisterClientSuccess = user => ({
    type: REGISTER_CLIENT_FETCH_SUCCESS,
    payload: user
});

export const loginSuccess = (data) => ({
    type: LOGIN_SUCCESS,
    payload: data,
});

export const ssoLoginSuccess = (data) => ({
    type: SSO_LOGIN_SUCCESS,
    payload: data,
});

export const switchMode = mode => ({
    type: SWITCH_MODE,
    payload: mode
});

export const fetchUserInformationSuccess = data => ({
    type: USER_INFORMATION_FETCH_SUCCESS,
    payload: data,
});

export const fetchUserHasNeededEvrotrustInfoSuccess = data => ({
    type: USER_HAS_NEEDED_EVROTRUST_INFO_SUCCESS,
    payload: data,
});

// Composite Action Creators
export const login = (email, password, rememberMe, isTermsAndConditionsAccepted, isSSO = false, userCredential = null) => async dispatch => {
    try {
        dispatch(loginSuccess(false));

        let response;

        if (isSSO) {
            response = await auth.signInWithCredential(userCredential);
        } else {
            response = await auth.signInWithEmailAndPassword(email, password);
        }

        const currentUser = auth.currentUser;

        if (!currentUser.emailVerified) {
            await auth.signOut();
            throw new Error('Email not verified.');
        }

        const idtoken = await currentUser.getIdToken(true);

        let agencyId;
        let roles;

        await currentUser.getIdTokenResult().then(idtokenResult => {
            agencyId = idtokenResult.claims.agencyId;
            roles = idtokenResult.claims.roles;
        });

        if (roles.includes(ROLES.UNASSIGNED_ADMIN)) {
            await dispatch(fetchUnassignedHomeInformationWithToken(agencyId, currentUser.toJSON(), roles[0], idtoken, isTermsAndConditionsAccepted));
        } else if (!roles.includes(ROLES.UNASSIGNED) && !roles.includes(ROLES.UNASSIGNED_CLIENT)) {
            await dispatch(fetchHomeInformationWithToken(agencyId, currentUser.toJSON(), roles[0], idtoken, isTermsAndConditionsAccepted));
        }

        if (roles.includes(ROLES.UNASSIGNED_CLIENT)) {
            await dispatch(fetchUnassignedUserTermsAndConditions(currentUser.toJSON().uid))
        }

        if (rememberMe) {
            await dispatch(setRememberMeWithToken(idtoken));
        }

        if (isTermsAndConditionsAccepted) {
            await dispatch(updateTermsAndConditionsVersion(agencyId));
        }

        dispatch(fetchLoginSuccess({ user: response.user.toJSON(), idtoken, agencyId, roles, displayMode: roles[0], rememberMe }));
        localStorage.removeItem('referrer');
        localStorage.removeItem('gclid');

        if (isSSO) {
            logEvent(AMPLITUDE_EVENT_TYPES.LOGIN, null, { type: 'SSO' });
        } else {
            logEvent(AMPLITUDE_EVENT_TYPES.LOGIN, null, { type: 'Form' });
        }
    } catch (err) {
        if (err.message.includes('Email not verified.')) {
            dispatch(showNotification(AUTH_MESSAGES.EMAIL_NOT_VERIFIED, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(showNotification(AUTH_MESSAGES.USERNAME_OR_PASSWORD_WRONG, NOTIFICATION_TYPES.ERROR));
        }
    } finally {
        dispatch(loginSuccess(true));
    }
};

export const register = userData => async dispatch => {
    try {
        const user = await authService.register(userData);
        await dispatch(fetchRegisterSuccess(user));

        dispatch(showNotification(AUTH_MESSAGES.USER_REGISTER_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (err) {
        dispatch(showNotification(err.message, NOTIFICATION_TYPES.ERROR));
    }
}

export const registerCandidate = userData => async dispatch => {
    try {
        const user = await authService.registerCandidate(userData);
        logEvent(AMPLITUDE_EVENT_TYPES.REGISTER, null, { role: ROLES.CANDIDATE, type: 'Form' });
        await dispatch(fetchRegisterSuccess(user));
        return user;
    } catch (err) {
        dispatch(showNotification(err.message, NOTIFICATION_TYPES.ERROR));
    }
}

export const registerClient = userData => async dispatch => {
    dispatch(showApplicationLoader());
    try {
        const user = await authService.registerClient(userData);
        logEvent(AMPLITUDE_EVENT_TYPES.REGISTER, null, { role: ROLES.CLIENT, type: 'Form' });
        dispatch(fetchRegisterClientSuccess({ ...user, roles: [user.role] }));
        dispatch(showNotification(AUTH_MESSAGES.CLIENT_REGISTER_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (err) {
        dispatch(showNotification(err.message, NOTIFICATION_TYPES.ERROR));
        throw err;
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const registerAsUnassignedAdmin = userData => async dispatch => {
    try {
        const data = await authService.registerAsUnassignedAdmin(userData);
        logEvent(AMPLITUDE_EVENT_TYPES.REGISTER, null, { type: 'Form' });

        if (data?._id) {
            setUserId(data._id);
            logEvent(AMPLITUDE_EVENT_TYPES.REGISTER_AGENCY_SUCCESS);
        }
    } catch (err) {
        dispatch(showNotification(err.message, NOTIFICATION_TYPES.ERROR));
        throw err;
    }
}

// This action may be obsolete - use registerSupplier from supplierActions instead
export const registerSupplier = userData => dispatch => {
    dispatch(showApplicationLoader());
    try {
        dispatch(showNotification(AUTH_MESSAGES.CURRENTLY_DISABLED_REGISTRATION_FORM, NOTIFICATION_TYPES.INFO));
    } catch (err) {
        dispatch(showNotification(err.message, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const refreshToken = () => dispatch => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            let newToken;
            if (unsubscribe) {
                unsubscribe();
            }

            if (user) {
                if (user.emailVerified) {
                    const newToken = await user.getIdToken();
                    resolve(newToken);
                    dispatch(refreshTokenSuccess(newToken));
                }
            }

            resolve(newToken);
        })
    });
}

export const logout = () => async dispatch => {
    try {
        // TODO: notification logout success

        if (window.localStream) {
            const tracks = window.localStream.getTracks();

            tracks.forEach(function (track) {
                track.stop();
            });
        };

        await auth.signOut();

        dispatch(fetchLogoutSuccess());
        dispatch(push('/login'));
        dispatch(setHomeInformationInitialState());

        window.localStream = null;
    } catch (err) {
        // TODO: notification logout error
        console.error(err);
    }
}

export const sendPasswordResetEmail = email => async dispatch => {
    try {
        await auth.signOut();
        await authService.sendChangePasswordEmail(email);

        dispatch(setHomeInformationInitialState());
        dispatch(sendPasswordResetEmailSuccess());
    } finally {
        dispatch(showNotification(AUTH_MESSAGES.SEND_PASSWORD_RESET_EMAIL_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
        dispatch(push('/login'))
    }
}

export const sendVerificationEmail = data => async dispatch => {
    try {
        await authService.resendVerificationEmail(data);

        dispatch(showNotification(AUTH_MESSAGES.SEND_VERIFICATION_EMAIL_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
        dispatch(push('/login'))
    } catch (err) {
        dispatch(showNotification(err.message, NOTIFICATION_TYPES.ERROR));
    }
}

export const resetPassword = (actionCode, password) => async dispatch => {
    try {
        await auth.verifyPasswordResetCode(actionCode);
        await auth.confirmPasswordReset(actionCode, password);

        dispatch(resetPasswordSuccess());
        dispatch(showNotification(AUTH_MESSAGES.RESET_PASSWORD_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (err) {
        dispatch(showNotification(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR));
    }
}

export const invalidSessionLogout = () => dispatch => {
    dispatch(logout());
    dispatch(clearNotification());
}

export const checkActionCode = (actionCode) => async dispatch => {
    try {
        await auth.checkActionCode(actionCode);
    } catch (error) {
        return '/expired-link/reset-password';
    }
};

export const verifyUserEmail = (actionCode, expirationDate, email) => async dispatch => {
    try {
        const currentTimestamp = moment().unix();

        if (expirationDate - currentTimestamp > 0) {
            //Check if action code is valid because firebase ood code expiration date is 3 days
            await auth.checkActionCode(actionCode).then(respond => {
                return respond;
            }).catch(async error => {
                //if ood code is expired generate a new one
                if (error.code.includes(OOD_CODE_ERRORS.EXPIRED)) {
                    const newLink = await authService.getVerificationLink(email);
                    const { oobCode } = queryString.parse(newLink.link);
                    actionCode = oobCode;
                }
            })

            await auth.applyActionCode(actionCode);
        } else {
            return '/expired-link/set-password';
        }
    } catch (error) {
        return '/expired-link/set-password';
    }
};

export const deniedAccessRedirect = () => dispatch => {
    dispatch(push(`/${getStateAgencyName()}/dashboard`));
};

