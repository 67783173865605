import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchProjectTemplates } from 'actions/projectTemplateActions';
import { fetchProfile, profileDataFetchingSuccess } from 'actions/employeeActions';
import { getProfileDataFetchState } from 'reducers';
import ProfileGeneralForm from './ProfileGeneralForm';
import ProfileLanguages from './ProfileLanguages';
import ProfileEducation from './ProfileEducation';
import ProfileCertificates from './ProfileCertificates';
import ProfileSkills from './ProfileSkills';
import ProfileUploadCV from './ProfileUploadCV';
import ProfileProjects from './ProfileProjects';
import ProfileTimeTracking from './ProfileTimeTracking';
import ProfileAvailability from './ProfileAvailability';
import ProfileAssignee from './ProfileAssignee';
import ProfileHiredBanner from './ProfileHiredBanner';
import ProfileVideoInterview from './ProfileVideoInterview';
import ProfileCvSection from 'components/Person/Profile/ProfileCvSection';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Profile.scss';

const Profile = ({
    agencyId,
    userId,
    departmentId,
    fetchProjectTemplates,
    fetchProfile,
    profileDataFetchingSuccess,
    dataFetchState,
    children,
    isDataAvailable,
}) => {

    useEffect(() => {
        if (!isDataAvailable) {
            fetchProfile(userId, agencyId, departmentId);
            fetchProjectTemplates(agencyId);
        }

        return () => profileDataFetchingSuccess();
    }, [userId]);

    children = React.Children.map(children, (child) => {
        return React.cloneElement(child, {
            employeeId: userId,
            agencyId,
            departmentId,
            dataFetchState,
            isEmployee: true,
        });
    });

    return (
        <div className='profile-info-content-wrapper'>
            {dataFetchState?.isDataFetching && dataFetchState?.isDataFetched
                ? <CircularProgress thickness={5} size={50} disableShrink className="page-loader" />
                : <div>
                    {children}
                </div>
            }
        </div>
    );
};

Profile.UploadCV = ProfileUploadCV;
Profile.GeneralForm = ProfileGeneralForm;
Profile.Languages = ProfileLanguages;
Profile.Skills = ProfileSkills;
Profile.Education = ProfileEducation;
Profile.Certificates = ProfileCertificates;
Profile.Projects = ProfileProjects;
Profile.TimeTracking = ProfileTimeTracking;
Profile.Availability = ProfileAvailability;
Profile.Assignee = ProfileAssignee;
Profile.HiredBanner = ProfileHiredBanner;
Profile.VideoInterview = ProfileVideoInterview;
Profile.Cv = ProfileCvSection;

const mapDispatchtoProps = {
    fetchProjectTemplates,
    fetchProfile,
    profileDataFetchingSuccess,
};

const mapStateToProps = (state) => ({
    dataFetchState: getProfileDataFetchState(state),
})

export default connect(mapStateToProps, mapDispatchtoProps)(Profile);
