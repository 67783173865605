import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { getCountries } from 'reducers';

import { fetchAllCountries } from 'actions/staticDataActions';

import RoleSelection from './RoleSelection';
import FillInformation from './FillInformation';

import { BASE_APP_TRADEMARK } from 'constants/env';

import './ChooseUsage.scss';

const ChooseUsage = ({
    countries,
    fetchAllCountries,
}) => {
    const match = useRouteMatch();

    useEffect(() => {
        fetchAllCountries();
    }, [])

    return (
        <div className={`select-a-role-wrapper${isMobile ? ' mobile' : ''}`}>
            <div className="select-a-role-content">
                <div className="logo-card-media-wrapper">
                    {BASE_APP_TRADEMARK.LOGO_WHITE}
                </div>
                <Switch>
                    <Route exact path={`${match.path}`} render={(props) => (
                        <RoleSelection {...props} />
                    )} />

                    <Route exact path={`${match.path}/client`} render={(props) => (
                        <FillInformation {...props} countries={countries} />
                    )} />
                </Switch>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    countries: getCountries(state),
});

const mapDispatchToProps = {
    fetchAllCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseUsage);
