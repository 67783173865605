import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { fetchCoachingOpportunities, fetchEmployeeCoachingSessions } from 'actions/coachingOpportunitiesActions';
import { fetchTrainings } from 'actions/agencyActions';

import { 
    getAgencyTrainings,
    getCoachingOpportunities,
    getCoachingOpportunitiesPastSessions,
    getCoachingOpportunitiesUpcomingSessions,
    getDisplayMode
} from 'reducers';

import { CircularProgress } from '@material-ui/core';

import Section from 'components/Shared/Section';
import NothingToShow from 'components/NothingToShow';
import CoachingMentoring from './CoachingMentoring';
import TrainingCard from './TrainingCard';
import SessionCard from '../Shared/SessionCard';
import BookedSessions from 'components/Settings/CoachingOpportunityDetails/BookedSessions';

import './Trainings.scss';

const Trainings = ({
    agencyId,
    userId,
    displayMode,
    fetchCoachingOpportunities,
    fetchTrainings,
    fetchEmployeeCoachingSessions,
    coachingOpportunities,
    trainings,
    upcomingSessions,
    pastSessions,
    history,
}) => {
    const intl = useIntl();

    const [isPageLoading, setIsPageLoading] = useState(true);

    useEffect(() => {
        fetchCoachingOpportunities(agencyId, displayMode).then(() => {
            fetchTrainings(agencyId).then(() => {
                fetchEmployeeCoachingSessions(agencyId, userId).then(() => {
                    setIsPageLoading(false);
                });
            });
        });
    }, []);

    if (isPageLoading) {
        return (
            <div className="trainings-wrapper">
                <div className="loader-wrapper">
                    <CircularProgress thickness={5} size={50} className="centered" />
                </div>
            </div>
        );
    }

    return (
        <div className="trainings-wrapper">
            <Section
                title={intl.formatMessage({ id: "submenu-trainings" })}
            >
                { trainings.length
                    ?
                    <div className="trainings-list">
                        {trainings.map((x) => <TrainingCard key={x._id} training={x} history={history} />)}
                    </div>
                    : <NothingToShow items={intl.formatMessage({ id: "submenu-trainings" })} />
                }
            </Section>
            <Section
                title={intl.formatMessage({ id: "coaching-and-mentoring" })}
            >
                { coachingOpportunities.length
                    ? <CoachingMentoring coachingOpportunities={coachingOpportunities} />
                    : <NothingToShow items={intl.formatMessage({ id: "active-coaching-mentoring" })} />
                }

            </Section>
            <Section title={intl.formatMessage({ id: "upcoming-sessions" })}>
                { upcomingSessions.length 
                    ?
                    <div className="global-sessions-list">
                        {upcomingSessions.map((x) =>
                            <SessionCard
                                key={x._id}
                                session={x}
                                title={x.coachingOpportunity.title}
                                subTitle={x.coachingOpportunity.coachName}
                                withHeaderBackground
                            />)}
                    </div>
                    : <NothingToShow items={intl.formatMessage({ id: "upcoming-coaching-mentoring" })} />
                }
            </Section>
            <Section title={intl.formatMessage({ id: "history-of-past-sessions" })}>
                { pastSessions.length > 0
                    ? 
                        <BookedSessions
                            coachingSessions={pastSessions}
                        />
                    : <NothingToShow items={intl.formatMessage({ id: "past-coaching-sessions" })} />
                }
            </Section>
        </div>
    )
};

const mapStateToProps = state => ({
    coachingOpportunities: getCoachingOpportunities(state),
    trainings: getAgencyTrainings(state),
    displayMode: getDisplayMode(state),
    upcomingSessions: getCoachingOpportunitiesUpcomingSessions(state),
    pastSessions: getCoachingOpportunitiesPastSessions(state),
});

const mapDispatchToProps = {
    fetchCoachingOpportunities,
    fetchTrainings,
    fetchEmployeeCoachingSessions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trainings);
