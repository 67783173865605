import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import moment from 'moment';

import {
    updateInterviewDate,
    updateInterviewData,
} from 'actions/interviewInvitationActions';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import InterviewInvitePopup from 'components/Shared/InterviewInvitePopup';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as CalendarIcon } from 'assets/calender-icon.svg';
import { ReactComponent as ClockIcon } from 'assets/icons-clock.svg';
import { ReactComponent as CalendarIconWhite } from 'assets/calender-icon-white.svg';
import { ReactComponent as ClockIconWhite } from 'assets/icons-clock-white.svg';

import { ReactComponent as CheckedIcon } from 'assets/codery-checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/codery-checkbox-unchecked.svg';

import { INTERVIEW_INVITATION_STATUSES, INTERVIEW_INVITATION_CONTRIBUTORS } from 'constants/interviewInvitationConstants';
import { CONTINENTAL_TIME_FORMAT, DATE_FORMAT_WITH_SLASH } from 'constants/commonConstants';
import { ROLES } from 'constants/userConstants';

import './ApplicationInterviewSlots.scss';

const ApplicationInterviewSlots = ({
    userId,
    agencyId,
    candidateId,
    clientId,
    applicationId,
    displayMode,
    interviewInvitation,
    updateInterviewData,
    updateInterviewDate,
    subtitle,
    isWaitingForResponse,
    setIsConfettiActive,
}) => {
    const intl = useIntl();

    const [checkedBox, setCheckedBox] = useState({});
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
    const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(true);

    const handleCheckBox = (timeSlot, index) => {
        if (checkedBox?.date === timeSlot.date && checkedBox?.time === timeSlot.time) {
            setIsApproveButtonDisabled(true);
            setCheckedBox({});
        } else {
            setIsApproveButtonDisabled(false);
            setCheckedBox({ ...timeSlot, index });
        }
    };

    const getInterviewSlotItemAdditionalClass = isInterviewSlotSelected => {
        if (Object.values(checkedBox).length === 0) {
            return '';
        } else if (isInterviewSlotSelected) {
            return 'selected';
        }
    };

    const handleOpenInviteDialog = () => setIsInviteDialogOpen(true);
    const handleCloseInviteDialog = () => setIsInviteDialogOpen(false);

    const handelInterviewInvitationSubmit = async (formData) => {
        const { clientNote, ...restData } = formData;

        const additionalData = {
            dateAdded: moment.utc().format(),
            status: INTERVIEW_INVITATION_STATUSES.PENDING,
            proposedBy: displayMode === ROLES.CANDIDATE ? INTERVIEW_INVITATION_CONTRIBUTORS.PARTICIPANT : INTERVIEW_INVITATION_CONTRIBUTORS.INTERVIEWER,
            interviewer: clientId,
            participant: candidateId,
            application: applicationId,
            note: clientNote
        };

        await updateInterviewData(agencyId, interviewInvitation._id, { ...restData, ...additionalData });
    };

    const handleConfirmInterview = (interviewId, timeBox) => {
        const { index, ...timeSlot } = timeBox;

        if (Object.keys(timeSlot).length > 0) {
            updateInterviewDate(agencyId, interviewId, timeSlot);
        }

        setIsConfettiActive(true);
    };

    return (
        <Paper className="paper-element application-interview-slots-content-wrapper">
            <Typography className="interviews-title heading-xs color dark-grey">{intl.formatMessage({ id: "interview" })}</Typography>
            <Typography className="interviews-sub-title subheading-m color-grey">{subtitle || intl.formatMessage({ id: "candidate-applications-interview-slots-subtitle" })}</Typography>
            <ul className="application-interview-slots-list">
                {interviewInvitation.interviewTimeSlots.map((timeSlot, index) => {
                    const isInterviewSlotSelected = checkedBox?.date === timeSlot.date && checkedBox?.time === timeSlot.time && checkedBox?.index === index;

                    return (
                        <li
                            key={index}
                            className={`application-interview-slot-item${isWaitingForResponse ? ' waiting-for-response' : ''} ${getInterviewSlotItemAdditionalClass(isInterviewSlotSelected)}`}
                            onClick={() => !isWaitingForResponse && handleCheckBox(timeSlot, index)}
                        >
                            <div className="item-row">
                                {isInterviewSlotSelected ? <CalendarIconWhite /> : <CalendarIcon />}
                                <p className="subheading-xxl">{moment(timeSlot.date).format(DATE_FORMAT_WITH_SLASH)}</p>
                            </div>
                            <div className="item-row">
                                {isInterviewSlotSelected ? <ClockIconWhite /> : <ClockIcon />}
                                <p className="subheading-xxl">
                                    {moment(timeSlot.time).format(CONTINENTAL_TIME_FORMAT)} - {moment(timeSlot.time).add(1, 'hours').format(CONTINENTAL_TIME_FORMAT)}
                                </p>
                            </div>
                            <div className="item-row">
                                {isInterviewSlotSelected ? <CheckedIcon /> : <UncheckedIcon />}
                            </div>
                        </li>
                    );
                })}
            </ul>

            {!isWaitingForResponse && <div className="interview-slots-action-buttons">
                <SecondaryButton className="white" text={intl.formatMessage({ id: "propose-new-slots" })} handleClick={handleOpenInviteDialog} />
                {Object.values(checkedBox).length > 0 &&
                    <PrimaryButton
                        className="purple"
                        text={intl.formatMessage({ id: "confirm" })}
                        handleClick={() => handleConfirmInterview(interviewInvitation._id, checkedBox)}
                        disabled={isApproveButtonDisabled}
                    />
                }
            </div>}

            <InterviewInvitePopup
                dialogTitle={intl.formatMessage({ id: "propose-new-slots" })}
                isDialogOpen={isInviteDialogOpen}
                isWithNote={true}
                isLocationDisabled={true}
                displayMode={displayMode}
                interviewInvitation={interviewInvitation}
                handleCloseDialog={handleCloseInviteDialog}
                handelInterviewInvitationSubmit={handelInterviewInvitationSubmit}
            />
        </Paper>
    );
};

const mapDispatchToProps = {
    updateInterviewDate,
    updateInterviewData,
};

export default connect(null, mapDispatchToProps)(ApplicationInterviewSlots);
