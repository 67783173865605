import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import LanguageSelector from './LanguageSelector';

import { toggleNavigation, toggleMobileNavigation } from 'actions/uiActions';
import { logout, switchMode } from 'actions/authActions';
import {
    fetchAgencyBasicInfo,
    setHomeInformationInitialState,
    fetchMainMenuInformation,
} from 'actions/agencyActions';
import { fetchAgencyPublicHolidays } from 'actions/publicHolidaysActions';
import {
    getDisplayMode,
    getCurrentUser,
    isAdmin,
    getAgencyName,
    isHiddenSections,
    isAuthenticated as isUserAuthenticated,
    getAccess,
    getAgencyRouteName,
    getMenuInformation,
    getToggleMobileNavigation,
    getUserPermissions,
} from 'reducers';

import {
    getPixelsAsNumber,
    getStateAgencyName,
    useWindowDimensions
} from 'utils/helpers';

import defaultLogo from 'assets/default-logo.svg';

import { getMenuForRole } from './constants/NavigationConstants';
import { ROLES } from 'constants/userConstants';
import { BASE_APP_TRADEMARK } from 'constants/env';

import { ReactComponent as TermsAndConditionsIcon } from 'assets/terms-and-conditions-icon.svg';
import { ReactComponent as SupportIcon } from 'assets/support-icon.svg';
import { ReactComponent as LogoutIcon } from 'assets/logout-icon.svg';
import { ReactComponent as EmployeeIcon } from 'assets/employee-icon.svg';

import styleConstants from 'style-constants.scss';

import './Navigation.scss';

const Navigation = ({
    logout,
    displayMode,
    switchMode,
    user,
    isAdmin,
    agencyName,
    agencyRouteName,
    hiddenSections,
    fetchAgencyBasicInfo,
    setHomeInformationInitialState,
    isUserAuthenticated,
    accessAsManager,
    userPermissions,
    toggleNavigation,
    toggleNavigationValue,
    toggleMobileNavigation,
    toggleMobileNavigationValue,
    fetchAgencyPublicHolidays,
    fetchMainMenuInformation,
    mainMenuInformation,
}) => {
    const history = useHistory();
    const { width } = useWindowDimensions();

    const closeMobileNavigationHandler = () => toggleMobileNavigation(false);

    const mainNavScroller = document.getElementById("main-navigation");
    const defaultAvatar = [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPPLIER_ADMIN, ROLES.RECRUITER].includes(displayMode) ? defaultLogo : '';

    useEffect(() => {
        if (isUserAuthenticated && user.agencyId && displayMode !== ROLES.CLIENT) {
            fetchAgencyBasicInfo(user.agencyId);
            fetchAgencyPublicHolidays(user.agencyId, user._id, displayMode);
        }
    }, [displayMode, user.agencyId]);


    useEffect(() => {
        if (!toggleNavigationValue
            && width >= getPixelsAsNumber(styleConstants.desktop)
            && width <= getPixelsAsNumber(styleConstants.smallDesktop)
        ) {
            toggleNavigation(true);
        }
    }, [width]);

    useEffect(() => {
        if (mainNavScroller) {
            mainNavScroller.scrollTo(0, 0);
        }
    }, [toggleMobileNavigationValue]);

    const handleLogout = () => {
        logout().then(() => history.push('/login?logout=true'));
    }

    const handleSwitchRoleMode = () => {
        const updatedRole = displayMode === ROLES.ADMIN ? ROLES.EMPLOYEE : ROLES.ADMIN;

        setHomeInformationInitialState();
        switchMode(updatedRole);
        fetchMainMenuInformation(user.agencyId, user._id, updatedRole);
        history.push(`/${getStateAgencyName()}/dashboard`);
    };

    const modeText = displayMode === 'admin' ? 'Employee' : 'Admin';

    const getSwitchModeLanguageKey = () => modeText === "Employee" ? "additional-menu-switch-to-employee-mode" : "additional-menu-switch-to-admin-mode";

    return isUserAuthenticated && (
        <Drawer id="main-navigation" variant="permanent" className='navigation' open={true}>
            <IconButton className="close-mobile-navigation-button" onClick={closeMobileNavigationHandler}>
                <KeyboardBackspaceIcon />
            </IconButton>

            <div className="navigation-header">
                {BASE_APP_TRADEMARK.LOGO_WHITE}
                <div className="divider"></div>
            </div>

            {getMenuForRole(displayMode, accessAsManager, userPermissions, hiddenSections, agencyRouteName, user.agencyId, user._id)}

            <div className="divider"></div>

            <div className="navigation-additional-menu">
                <List className="nav-additional-list">
                    <ListItem
                        button
                        component="a"
                        href={`https://teamcodery.com/terms`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="list-item"
                    >
                        <div className="list-item-icon-wrapper">
                            <ListItemIcon className="list-item-icon">
                                <TermsAndConditionsIcon />
                            </ListItemIcon>
                        </div>

                        <FormattedMessage id={"terms-and-conditions"}>{(msg) => <ListItemText primary={msg} />}</FormattedMessage>
                    </ListItem>
                    {displayMode === ROLES.CANDIDATE && <ListItem
                        button
                        component={NavLink}
                        exact={true}
                        to={`/${agencyRouteName}/customer-support`}
                        className="list-item"
                        activeClassName="Mui-selected"
                    >
                        <div className="list-item-icon-wrapper">
                            <ListItemIcon className="list-item-icon">
                                <SupportIcon />
                            </ListItemIcon>
                        </div>

                        <FormattedMessage id={"customer-support"}>{(msg) => <ListItemText primary={msg} />}</FormattedMessage>
                    </ListItem>}
                    {isAdmin &&
                        <ListItem
                            button
                            onClick={handleSwitchRoleMode}
                            className="list-item"
                            activeClassName="Mui-selected"
                        >
                            <div className="list-item-icon-wrapper">
                                <ListItemIcon className="list-item-icon">
                                    <EmployeeIcon />
                                </ListItemIcon>
                            </div>

                            <FormattedMessage id={getSwitchModeLanguageKey()}>{(msg) => <ListItemText primary={msg} />}</FormattedMessage>
                        </ListItem>
                    }
                    <ListItem
                        button
                        component={NavLink}
                        exact={true}
                        to={`/logout`}
                        onClick={handleLogout}
                        className="list-item"
                        activeClassName="Mui-selected"
                    >
                        <div className="list-item-icon-wrapper">
                            <ListItemIcon className="list-item-icon">
                                <LogoutIcon />
                            </ListItemIcon>
                        </div>

                        <FormattedMessage id={"logout"}>{(msg) => <ListItemText primary={msg} />}</FormattedMessage>
                    </ListItem>
                </List>
            </div>

            {(displayMode === ROLES.ADMIN || displayMode === ROLES.EMPLOYEE) && <LanguageSelector userId={user._id} />}

            <div className="navigation-footer">
                <div className="divider"></div>
                <div className="navigation-footer-content">
                    <Avatar
                        alt="Company logo"
                        className="company-logo"
                        src={mainMenuInformation.menuAvatar || defaultAvatar}
                    />
                    <div className="user-info-wrapper">
                        <Typography variant="h5" className="subheading-s color-white">
                            {mainMenuInformation.menuName}
                        </Typography>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
    user: getCurrentUser(state),
    isAdmin: isAdmin(state),
    agencyName: getAgencyName(state),
    hiddenSections: isHiddenSections(state),
    isUserAuthenticated: isUserAuthenticated(state),
    accessAsManager: getAccess(state),
    userPermissions: getUserPermissions(state),
    toggleMobileNavigationValue: getToggleMobileNavigation(state),
    agencyRouteName: getAgencyRouteName(state),
    mainMenuInformation: getMenuInformation(state),
});

const mapDispatchToProps = {
    logout,
    switchMode,
    fetchAgencyBasicInfo,
    setHomeInformationInitialState,
    toggleNavigation,
    toggleMobileNavigation,
    fetchAgencyPublicHolidays,
    fetchMainMenuInformation,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Navigation);
