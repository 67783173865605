import React from "react";

import Avatar from '@material-ui/core/Avatar';

const ActiveHolidaysEvent = ({ event, handleEmployeeBoxClick }) => {
    const colorClass = event.isSickLeave ? 'sick-leave' : event.isHomeOffice ? 'home-office' : event.isActive ? 'active' : 'upcoming';
   
    return (
        <div
            className={`holiday-event ${colorClass}`}
            onClick={() => handleEmployeeBoxClick(event._id)}>
            <Avatar
                alt="Holiday Event Avatar"
                className="avatar"
                src={event.avatarUrl}
            />
            <span>{event.title}</span>
        </div>
    )
};

export default ActiveHolidaysEvent;
