import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { getStateAgencyName } from 'utils/helpers';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandableList from '../ExpandableList/ExpandableList';

import { getToggleNavigation, getInterviewInvitationsList, getParticipantPendingInterviewInvitations } from 'reducers';
import { toggleMobileNavigation } from 'actions/uiActions';

import { temporaryRoute } from 'constants/commonConstants';

import '../ExpandableList/ExpandableList.scss';
import './MainMenu.scss';

const MainMenu = ({
    menuItems,
    toggleNavigationValue,
    toggleMobileNavigation,
    interviewInvitations,
    pendingInterviewInvitations,
}) => {
    const history = useHistory();

    const isMenuItemSelected = (route, children = []) => {
        const currentPath = window.location.pathname;

        let isSelected = currentPath.startsWith(route);

        for (const child of children) {
            if (currentPath.startsWith(child.ENDPOINT.split('?')[0])) {
                isSelected = true;
            }
        }

        return isSelected;
    };

    const [listsStatus, setListsStatus] = useState([]);

    useEffect(() => {
        const mappedMenuItems = menuItems
            .filter(item => !item.ROUTE.includes('temporary'))
            .map(item => ({ ...item, isSelected: isMenuItemSelected(item.ROUTE, item.ITEMS), isListExpanded: isMenuItemSelected(item.ROUTE, item.ITEMS) }));
        setListsStatus(mappedMenuItems);
    }, [menuItems]);

    const navigateToFirstItem = menuTitle => {
        const menuItem = listsStatus.find(x => x.TITLE === menuTitle);
        const endpointItem = menuItem.ITEMS.find(x => x.ENDPOINT !== temporaryRoute);
        history.push(endpointItem.ENDPOINT);
    };

    const handleExpandList = menuTitle => {
        const isMenuItemOpen = listsStatus.find(item => (item?.TITLE === menuTitle))?.isSelected;
        if (!isMenuItemOpen) {
            navigateToFirstItem(menuTitle);
        }
        const newListItemsStatus = listsStatus
            .map(item => ({
                ...item,
                isSelected: item.TITLE === menuTitle,
                isListExpanded: item.TITLE === menuTitle ? !item.isListExpanded : false,
            }));
        setListsStatus(newListItemsStatus);
    };

    const handleCloseExpandleList = () => {
        const newListItemsStatus = listsStatus.map(item => ({
            ...item,
            isSelected: false,
            isListExpanded: false,
        }));
        setListsStatus(newListItemsStatus);
        toggleMobileNavigation(false);
    };

    const exact = itemPath => itemPath === `/${getStateAgencyName()}/dashboard` || itemPath === `/${getStateAgencyName()}/`;

    return (
        <List className="nav-main-list">
            {listsStatus.map(menuItem => (
                (menuItem.ITEMS)
                    ? <ExpandableList
                        languageKey={menuItem.LANG_KEY}
                        key={menuItem.TITLE}
                        listItemTitle={menuItem.TITLE}
                        listItemIcon={menuItem.ICON}
                        listItemsArray={menuItem.ITEMS}
                        isOpen={menuItem.isListExpanded}
                        isSelected={menuItem.isSelected}
                        handleExpandList={handleExpandList}
                        toggleMobileNavigation={toggleMobileNavigation}
                    />
                    :

                    <ListItem
                        button
                        component={NavLink}
                        exact={exact(menuItem.ROUTE)}
                        to={menuItem.ROUTE}
                        className="list-item"
                        activeClassName="Mui-selected"
                        onClick={handleCloseExpandleList}
                    >
                        <div className="list-item-icon-wrapper">
                            <ListItemIcon className="list-item-icon">
                                {menuItem.ICON}
                            </ListItemIcon>
                        </div>

                        {menuItem.LANG_KEY ? <FormattedMessage id={menuItem.LANG_KEY}>{(msg) => <ListItemText primary={msg} />}</FormattedMessage> : <ListItemText primary={menuItem.TITLE} />}
                        {
                            menuItem.TITLE === 'My Interviews' && pendingInterviewInvitations.length > 0
                                ? <div className="notification-badge"><span className="badge-text">{pendingInterviewInvitations.length}</span></div>
                                : null
                        }
                        {
                            menuItem.TITLE === 'Upcoming Interviews' && interviewInvitations.length > 0
                                ? <div className="notification-badge"><span className="badge-text">{interviewInvitations.length}</span></div>
                                : null
                        }
                    </ListItem>
            ))}
        </List>
    );
};

const mapStateToProps = state => ({
    toggleNavigationValue: getToggleNavigation(state),
    interviewInvitations: getInterviewInvitationsList(state),
    pendingInterviewInvitations: getParticipantPendingInterviewInvitations(state),
});

const mapDispatchToProps = {
    toggleMobileNavigation,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
