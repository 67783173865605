import React, { useEffect, useContext, useState, Fragment } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import ThreeDotsOptionsPopover from 'components/Shared/ThreeDotsOptionsPopover';
import {
    Checkbox,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from '@material-ui/core';
import ThreeDotsIcon from '@material-ui/icons/MoreVert';

import { TableWrapperContext } from '../TableWrapper';

import { parseQueryString, updateQueryWithMultipleEntries } from 'utils/helpers';

import { ReactComponent as PreviousPageArrowIcon } from 'assets/table-previous-page-icon.svg';
import { ReactComponent as NextPageArrowIcon } from 'assets/table-next-page-icon.svg';

import './TableContent.scss';

const TableContent = () => {
    const {
        columns,
        selectedColumns,
        dataSelector,
        sortQuerySelector,
        adjustKeysFunction,
        isTableDataLoading,
        withPagination,
        page,
        setPage,
        pagesCount,
        pageQuerySelector,
        handleFilterChanges,
        withLegend,
        legendItems,
        tableRef,
        withActions,
        getActionsHandler,
        withLink,
        withCheckboxes,
        checkedRows,
        setCheckedRows,
        generateToString,
    } = useContext(TableWrapperContext);

    const intl = useIntl();
    const history = useHistory();

    const [currentSortColumn, setCurrentSortColumn] = useState("");
    const [currentSortDir, setCurrentSortDir] = useState("desc");

    const [selectedItem, setSelectedItem] = useState({});

    const stateData = useSelector((state) => dataSelector(state, page));

    const getColumnObjFromValue = (columnValue) => columns.find(column => {
        if (typeof column.value === "string") {
            return column.value === columnValue;
        } else {
            return column.value?.includes(columnValue);
        }
    });

    useEffect(() => {
        const queryParams = parseQueryString(history.location.search, true);

        if (queryParams[sortQuerySelector]?.length) {
            setCurrentSortColumn(queryParams[sortQuerySelector][0]);
            setCurrentSortDir(queryParams[`${sortQuerySelector}Dir`] ? queryParams[`${sortQuerySelector}Dir`][0] : "desc")
        } else {
            setCurrentSortColumn("");
            setCurrentSortDir("desc");
        }

        if (queryParams[pageQuerySelector]?.length) {
            setPage(+queryParams[pageQuerySelector][0]);
        }

        Boolean(handleFilterChanges) && handleFilterChanges();
    }, [history.location.search]);

    const handleSort = (column) => {
        const newSortState = currentSortDir === "asc" ? "desc" : "asc";

        const newSortQuery = {
            [sortQuerySelector]: column,
            [`${sortQuerySelector}Dir`]: newSortState,
        };

        if (withPagination) {
            newSortQuery[pageQuerySelector] = 1;
        }

        const updatedQuery = updateQueryWithMultipleEntries(history.location.search, newSortQuery);

        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    const handleGoToNextPage = () => {
        const nextPage = page + 1;

        if (nextPage <= pagesCount) {
            const newPageQuery = { [pageQuerySelector]: nextPage };
            const updatedQuery = updateQueryWithMultipleEntries(history.location.search, newPageQuery);
            const tableElement = tableRef.current;
            tableElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "start" });
            history.replace({ pathname: history.location.pathname, search: updatedQuery });
        }
    };

    const handleGoToPreviousPage = () => {
        const previousPage = page - 1;

        if (previousPage >= 1) {
            const newPageQuery = { [pageQuerySelector]: previousPage };
            const updatedQuery = updateQueryWithMultipleEntries(history.location.search, newPageQuery);
            const tableElement = tableRef.current;
            tableElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "start" });
            history.replace({ pathname: history.location.pathname, search: updatedQuery });
        }
    };

    // Three dots
    const [anchorEl, setAnchorEl] = useState(null);

    const handleThreeDotsClick = (event, item) => {
        event.preventDefault();
        setSelectedItem(item);
        setAnchorEl(event.target);
    };

    const handleCloseActionsPopover = () => {
        setSelectedItem(selectedItem);
        setAnchorEl(null);
    }

    // Checked rows
    const handleChangeCheckedRows = (item) => {
        if (checkedRows.some(x => x._id === item._id)) {
            setCheckedRows(state => state.filter(x => x._id !== item._id));
        } else {
            setCheckedRows(state => [...state, item]);
        }
    };

    const getItemsShowingText = () => {
        let text;

        if (stateData.length === 1) {
            text = intl.formatMessage({ id: "showing-items-text-singular" });
        } else {
            text = intl.formatMessage({ id: "showing-items-text-plural" });
        }

        return text.replace("{}", stateData.length);
    }

    return (
        <TableContainer component={Paper} className="table-content-wrapper">
            <Table>
                <TableHead>
                    <TableRow>
                        {selectedColumns.map(column => {
                            const columnObj = getColumnObjFromValue(column);
                            return (
                                <TableCell key={columnObj?.value} className={`table-cell${columnObj?.className ? ` ${columnObj?.className}` : ''}`}>
                                    {columnObj?.isSortable
                                        ?
                                        <TableSortLabel
                                            disabled={!columnObj.isSortable}
                                            active={columnObj.value === currentSortColumn}
                                            direction={currentSortDir}
                                            onClick={() => handleSort(columnObj.value)}
                                        >
                                            <Typography variant="h1">{columnObj?.langKey ? intl.formatMessage({ id: columnObj.langKey}) : columnObj.name}</Typography>
                                        </TableSortLabel>
                                        : <Typography variant="h1">{columnObj?.langKey ? intl.formatMessage({ id: columnObj.langKey}) : columnObj.name}</Typography>
                                    }

                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                {isTableDataLoading ?
                    <TableBody>
                        <div className="table-loader-wrapper">
                            <CircularProgress thickness={5} className="table-loader" />
                        </div>
                    </TableBody> :
                    <TableBody>
                        {stateData.length === 0
                            ?
                            <TableCell colSpan={columns.length}>
                                <Typography variant="body1" className="empty-table-text">
                                    {intl.formatMessage({ id: "no-data-available" })}
                                </Typography>
                            </TableCell>
                            :
                            <Fragment>{adjustKeysFunction(stateData).map((row, stateDataIdx) => (
                                <TableRow key={stateDataIdx}>
                                    {selectedColumns.map((column, idx) => (
                                        <TableCell
                                            key={idx}
                                            className={`${withActions && idx === selectedColumns.length - 1 && 'last-column'}`}
                                            component={withLink ? Link : 'td'}
                                            to={withLink ? generateToString(row.item) : ''}
                                        >
                                            <Typography variant="body1" className={withCheckboxes && idx === 0 ? 'with-checkboxes' : ''}>
                                                {
                                                    withCheckboxes && idx === 0 &&
                                                    <Checkbox disableRipple className="table-checkbox" checked={checkedRows.includes(row.item) || checkedRows.some(x => x._id === row.item._id)} onChange={() => handleChangeCheckedRows(row.item)} />
                                                }
                                                {row[getColumnObjFromValue(column)?.value] || '-'}
                                                {
                                                    withActions && idx === selectedColumns.length - 1 &&
                                                    <div className="global-dots-icon-wrapper end-alignment">
                                                        <ThreeDotsIcon onClick={(event) => handleThreeDotsClick(event, row.item)} />
                                                    </div>
                                                }
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                            </Fragment>
                        }
                    </TableBody>
                }
            </Table>
            {!isTableDataLoading &&
                <div className="table-pagination-wrapper">
                    {withPagination && <Typography variant="body2">{getItemsShowingText()}</Typography>}
                    {withLegend &&
                        <div className="legend-items-wrapper">
                            {legendItems.map((item, idx) => (
                                <div className="legend-item" key={idx}>
                                    <div className="color-box" style={{ backgroundColor: item.color, width: "17px", height: "17px", borderRadius: "4px" }}></div>
                                    <Typography variant="body2">{item?.langKey ? intl.formatMessage({ id: item.langKey}) : item.text}</Typography>
                                </div>
                            ))}
                        </div>
                    }
                    {withPagination &&
                        <div className="table-pagination-navigation">
                            <Typography variant="body2"><span>{page}</span> {intl.formatMessage({ id: "of" })} {pagesCount}</Typography>
                            <div className="table-pagination-controls">
                                <PreviousPageArrowIcon className={page === 1 ? "arrow-disabled" : ""} onClick={handleGoToPreviousPage} />
                                <NextPageArrowIcon className={page === pagesCount ? "arrow-disabled" : ""} onClick={handleGoToNextPage} />
                            </div>
                        </div>
                    }
                </div>
            }

            {Boolean(anchorEl) &&
                <ThreeDotsOptionsPopover
                    anchorEl={anchorEl}
                    listItems={getActionsHandler(selectedItem)}
                    handleClose={handleCloseActionsPopover}
                    item={selectedItem}
                    closeOnClick
                />
            }
        </TableContainer>
    )
};

export default TableContent;
