import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';

import isHoliday from 'hoc/isHoliday';

import { EMPLOYMENT_TYPES } from 'constants/employeeConstants';
import { DATE_PICKER_FORMAT, FULL_DATE_WITH_UTC_OFFSET_FORMAT } from 'constants/timesFormat';
import { COUNTRIES_OF_EMPLOYMENT, FULL_YEAR_ONLY } from 'constants/commonConstants';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const EmploymentContractDetailsFormView = ({
    values,
    errors,
    touched,
    setFieldValue,
    countries,
    setShowHeaderButtons,
    employmentType,
}) => {
    const intl = useIntl();

    return (
        <form className="employment-contract-details-form grid-form-double-row" autoComplete="off">
            <div className="form-field">
                <TextField
                    required
                    select
                    fullWidth
                    name="employmentType"
                    label={intl.formatMessage({ id: "employment-type" })}
                    variant="outlined"
                    value={values.employmentType}
                    onChange={e => {
                        setShowHeaderButtons(true);
                        setFieldValue('employmentType', e.target.value)
                    }}
                    error={errors.employmentType && touched.employmentType}
                >
                    {Object.values(EMPLOYMENT_TYPES).map((type, i) => (
                        <MenuItem
                            native="true"
                            key={i}
                            value={type}
                        >
                            {intl.formatMessage({ id: convertStringToLanguageKey(type) })}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="employmentType" component="div" className="invalid-field-message" />
            </div>

            <div className="form-field">
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="country-label">{intl.formatMessage({ id: "country" })}</InputLabel>
                    <Select
                        labelId="country-label"
                        id="person-country"
                        name="country"
                        value={values.country}
                        onChange={(e) => {
                            setShowHeaderButtons(true);
                            setFieldValue('country', e.target.value);
                        }}
                        label="Country of Employment"
                        error={errors.country && touched.country}
                    >
                        {countries
                            .filter(country => COUNTRIES_OF_EMPLOYMENT.includes(country.name))
                            .map(country => (
                                <MenuItem key={country.name} value={country.name}>
                                    {intl.formatMessage({ id: convertStringToLanguageKey(country.name) })}
                                </MenuItem>
                            ))}
                    </Select>
                    <ErrorMessage name="country" component="div" className="invalid-field-message" />
                </FormControl>
            </div>

            <div className="form-field">
                <KeyboardDatePicker
                    autoOk
                    fullWidth
                    disableToolbar
                    helperText=""
                    openTo="date"
                    variant="inline"
                    name="startDate"
                    label={intl.formatMessage({ id: "start-date" })}
                    inputVariant="outlined"
                    renderDay={isHoliday}
                    format={DATE_PICKER_FORMAT}
                    value={values.startDate}
                    error={!!(errors.startDate && touched.startDate)}
                    onChange={value => {
                        setShowHeaderButtons(true);
                        setFieldValue('startDate', moment(value).utc().format(FULL_DATE_WITH_UTC_OFFSET_FORMAT));
                    }}
                />
                <ErrorMessage name="startDate" component="div" className="invalid-field-message" />
            </div>

            <div className="form-field">
                <KeyboardDatePicker
                    autoOk
                    fullWidth
                    disableToolbar
                    helperText=""
                    openTo="date"
                    variant="inline"
                    name="terminationDate"
                    label={intl.formatMessage({ id: "end-date" })}
                    inputVariant="outlined"
                    renderDay={isHoliday}
                    format={DATE_PICKER_FORMAT}
                    value={values.terminationDate}
                    error={!!(errors.terminationDate && touched.terminationDate)}
                    onChange={value => {
                        setShowHeaderButtons(true);
                        setFieldValue('terminationDate', moment(value).utc().format());
                    }}
                />
                <ErrorMessage name="terminationDate" component="div" className="invalid-field-message" />
            </div>

            <div className="form-field">
                <TextField
                    fullWidth
                    name="pin"
                    label={intl.formatMessage({ id: "pin" })}
                    type="text"
                    variant="outlined"
                    value={values.pin}
                    onChange={e => {
                        setShowHeaderButtons(true);
                        setFieldValue('pin', e.target.value)
                    }}
                    error={errors.pin && touched.pin}
                />
                <ErrorMessage name="pin" component="div" className="invalid-field-message" />
            </div>

            <div className="form-field">
                <TextField
                    name="daysPerYear"
                    label={`${intl.formatMessage({ id: "paid-holiday-for-year" })} ${moment().endOf('year').format(FULL_YEAR_ONLY)}`}
                    type="text"
                    value={values.daysPerYear}
                    onChange={e => {
                        setShowHeaderButtons(true);
                        setFieldValue('daysPerYear', e.target.value)
                    }}
                    error={!!(errors.daysPerYear && touched.daysPerYear)}
                    variant="outlined"
                />
                <ErrorMessage name="daysPerYear" component="div" className="invalid-field-message" />
            </div>

            { employmentType === EMPLOYMENT_TYPES.FULLTIME_CONTRACT &&
                <div className="form-field">
                    <TextField
                        name="homeOfficeAllowance"
                        label={`${intl.formatMessage({ id: "monthly-home-office-days" })}`}
                        type="text"
                        value={values.homeOfficeAllowance}
                        onChange={e => {
                            setShowHeaderButtons(true);
                            setFieldValue('homeOfficeAllowance', e.target.value)
                        }}
                        error={!!(errors.homeOfficeAllowance && touched.homeOfficeAllowance)}
                        variant="outlined"
                    />
                    <ErrorMessage name="homeOfficeAllowance" component="div" className="invalid-field-message" />
                </div>
            }
        </form>
    );
};

export default EmploymentContractDetailsFormView;
