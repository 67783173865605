import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getSkillSuggestions, getIsSkillSuggestionFetching } from 'reducers';
import { setSkillSuggestionsInitialState } from 'actions/employeeActions';

import TextField from '@material-ui/core/TextField';
import CategorySkillsDropdown from './CategorySkillsDropdown';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ErrorMessage } from 'formik';

const LOADER_SIZE = '20px';

const AddSkillForm = ({
    values,
    setValues,
    addSkillOnSelect,
    addNewSkill,
    handleSubmit,
    handleChange,
    skillSuggestions,
    fetchSkillSuggestions,
    isSkillSuggestionFetching,
    setSkillSuggestionsInitialState,
}) => {
    const intl = useIntl();
    const skillsInputRef = useRef();

    const [isInputFocused, setIsInputFocused] = useState(false);
    const [showResultsDropdown, setShowResultsDropdown] = useState(true);

    useEffect(() => {
        setShowResultsDropdown(isInputFocused)
    }, [skillSuggestions]);

    const handleFocus = () => {
        setIsInputFocused(true);

        !values.name && setSkillSuggestionsInitialState();

        setShowResultsDropdown(skillSuggestions.length > 0 && isSkillSuggestionFetching);

        if (values.name) {
            fetchSkillSuggestions(values.name);
        }
    };

    const handleBlur = () => { setIsInputFocused(false); setShowResultsDropdown(false); };

    const handleOnInputChange = e => { setShowResultsDropdown(false); handleChange(e); };

    const selectSkill = value => {
        if (addSkillOnSelect) {
            addNewCategorySkill(value);
        } else {
            setShowResultsDropdown(false);
            setValues({ name: value }, false);
        }
    };

    const addNewCategorySkill = value => {
        setValues({ name: '' }, false);
        setShowResultsDropdown(false);

        const foundExistingSkill = skillSuggestions.some(x => x.title.toLowerCase() === value.toLowerCase());

        addNewSkill(value, !(skillSuggestions.length > 0 && foundExistingSkill));
    };

    return (
        <form className="add-skill-form" autoComplete="off" onSubmit={handleSubmit}>
            <div className="add-skill-input">
                <TextField
                    ref={skillsInputRef}
                    name="name"
                    type="text"
                    className="skill-name-field"
                    variant="outlined"
                    value={values.name}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onChange={handleOnInputChange}
                />
                {isSkillSuggestionFetching && isInputFocused &&
                    <div className="search-skills-loader"><CircularProgress thickness={5} size={LOADER_SIZE} /></div>}
                {values.name && !isSkillSuggestionFetching && <CategorySkillsDropdown
                    currentInput={values.name}
                    showResultsDropdown={showResultsDropdown}
                    onSelect={selectSkill}
                    addNewSkill={addNewCategorySkill}
                    skillSuggestions={skillSuggestions} />}
                <ErrorMessage name="name" component="div" className="invalid-field-message" />
            </div>
            <button type="submit" className="add-skill-button">{intl.formatMessage({ id: "add-button-text" })}</button>
        </form>
    );
};

const mapStateToProps = state => ({
    skillSuggestions: getSkillSuggestions(state),
    isSkillSuggestionFetching: getIsSkillSuggestionFetching(state),
});

const mapDispatchtoProps = { setSkillSuggestionsInitialState };

export default connect(mapStateToProps, mapDispatchtoProps)(AddSkillForm);
