import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import './KPIList.scss';

const KPIList = ({ data, dataFetchState }) => {
    return (
        <div className="kpi-list">
            {data.map((kpi, index) => {
                return (
                    <div
                        key={index}
                        className={["kpi-item", kpi.additionalClasses?.length > 0 ? [...kpi.additionalClasses] : []].join(' ').trim()}
                    >
                        {dataFetchState?.isDataFetching
                            ? <CircularProgress thickness={5} size={16} disableShrink className="kpi-item-loader" />
                            : <>
                                <div className="kpi-item-amount-wrapper">
                                    <Typography variant="h4" className="kpi-item-amount">{kpi.amount}</Typography>
                                    {kpi.percentageChange &&
                                        <div className={`kpi-item-percentage-change-wrapper${kpi.percentageChange > 0 ? ' red' : ' green'}`}>
                                            {kpi.percentageChange > 0
                                                ? <>
                                                    <ArrowDownwardIcon className="kpi-item-percentage-change-icon" />
                                                    <span className="kpi-item-percentage-change-text">{`${Math.abs(kpi.percentageChange)}%`}</span>
                                                </>
                                                : <>
                                                    <ArrowUpwardIcon className="kpi-item-percentage-change-icon" />
                                                    <span className="kpi-item-percentage-change-text">{`${Math.abs(kpi.percentageChange)}%`}</span>
                                                </>
                                            }
                                        </div>
                                    }
                                </div>
                                <Typography variant="h5" className="kpi-item-title">{kpi.title}</Typography>
                            </>
                        }
                    </div>
                )
            })}
        </div>
    );
};

export default KPIList;
