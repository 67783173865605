import {
    DEPARTMENTS_FETCH_SUCCESS,
    DEPARTMENTS_REPLACE_SUCCESS,
    DEPARTMENT_CREATE_SUCCESS,
    DEPARTMENT_DELETE_SUCCESS,
    DEPARTMENT_DETAILS_FETCH_SUCCESS,
    DEPARTMENT_EDIT_SUCCESS,
    DEPARTMENT_MEMBERS_FETCH_SUCCESS,
    DEPARTMENT_MEMBERS_REPLACE_SUCCESS,
} from 'actions/actionTypes';

import { showNotification } from 'actions/notificationActions';
import { authorizedShowNotification } from './actionHelpers';
import { showApplicationLoader, hideApplicationLoader } from 'actions/applicationLoaderActions';
import { showDialogActionButtonLoader, hideDialogActionButtonLoader } from './sectionsLoadersActions';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { DEPARTMENTS_MESSAGES, EXPORT_MESSAGES } from 'constants/messageConstants';

import departmentService from 'services/departmentService';

const replaceDepartmentsSuccess = (departments) => ({
    type: DEPARTMENTS_REPLACE_SUCCESS,
    payload: departments,
});

const fetchDepartmentsSuccess = departments => ({
    type: DEPARTMENTS_FETCH_SUCCESS,
    payload: departments
});

const createDepartmentSuccess = department => ({
    type: DEPARTMENT_CREATE_SUCCESS,
    payload: department
});

const deleteDepartmentSuccess = departmentId => ({
    type: DEPARTMENT_DELETE_SUCCESS,
    payload: departmentId,
});

const fetchDepartmentDetailsSuccess = departmentDetails => ({
    type: DEPARTMENT_DETAILS_FETCH_SUCCESS,
    payload: departmentDetails
});

const editDepartmentSuccess = department => ({
    type: DEPARTMENT_EDIT_SUCCESS,
    payload: department
});

const fetchDepartmentMembersSuccess = members => ({
    type: DEPARTMENT_MEMBERS_FETCH_SUCCESS,
    payload: members
});

const replaceDepartmentMembersSuccess = members => ({
    type: DEPARTMENT_MEMBERS_REPLACE_SUCCESS,
    payload: members,
});

export const fetchDepartments = (agencyId, query, replaceState) => async dispatch => {
    try {
        const result = await departmentService.fetchDepartments(agencyId, query);

        if (replaceState) {
            dispatch(replaceDepartmentsSuccess(result.departments));
        } else {
            dispatch(fetchDepartmentsSuccess(result.departments));
        }
        return result.totalCount;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DEPARTMENTS_MESSAGES.FETCH_DEPARTMENTS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const createDepartment = (agencyId, departmentData, updateState = true) => async dispatch => {
    try {
        const result = await departmentService.createDepartment(agencyId, departmentData);

        updateState && dispatch(createDepartmentSuccess(result));
        dispatch(showNotification(DEPARTMENTS_MESSAGES.CREATE_DEPARTMENT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DEPARTMENTS_MESSAGES.CREATE_DEPARTMENT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteDepartment = (agencyId, departmentId, updateState = true) => async dispatch => {
    try {
        await departmentService.deleteDepartment(agencyId, departmentId);

        updateState && dispatch(deleteDepartmentSuccess(departmentId));
        dispatch(showNotification(DEPARTMENTS_MESSAGES.DELETE_DEPARTMENT_SUCCESS, NOTIFICATION_TYPES.SUCCESS))
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DEPARTMENTS_MESSAGES.DELETE_DEPARTMENT_FAIL, NOTIFICATION_TYPES.ERROR))
    }
}

export const fetchDepartmentDetails = (agencyId, departmentId) => async dispatch => {
    dispatch(showApplicationLoader());

    try {
        const departmentDetails = await departmentService.fetchDepartmentDetails(agencyId, departmentId);

        dispatch(fetchDepartmentDetailsSuccess(departmentDetails));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DEPARTMENTS_MESSAGES.FETCH_DEPARTMENT_DETAILS_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const fetchEmployeeDepartmentDetails = (agencyId, employeeId) => async dispatch => {
    try {
        const departmentDetails = await departmentService.fetchEmployeeDepartment(agencyId, employeeId);

        dispatch(fetchDepartmentDetailsSuccess(departmentDetails));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DEPARTMENTS_MESSAGES.FETCH_DEPARTMENT_DETAILS_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const editDepartment = (agencyId, departmentId, departmentData, updateState = true) => async dispatch => {
    try {
        const department = await departmentService.editDepartment(agencyId, departmentId, departmentData);

        updateState && dispatch(editDepartmentSuccess(department));
        dispatch(showNotification(DEPARTMENTS_MESSAGES.EDIT_DEPARTMENT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DEPARTMENTS_MESSAGES.EDIT_DEPARTMENT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchDepartmentMembers = (agencyId, departmentId, query, replaceState) => async dispatch => {
    try {
        const result = await departmentService.fetchDepartmentsMembers(agencyId, departmentId, query);

        if (replaceState) {
            dispatch(replaceDepartmentMembersSuccess(result.departmentMembers));
        } else {
            dispatch(fetchDepartmentMembersSuccess(result.departmentMembers));
        }
        return result.totalCount;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DEPARTMENTS_MESSAGES.FETCH_DEPARTMENT_DETAILS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const registerDepartmentEmployee = (agencyId, departmentId, employeeData) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        await departmentService.registerDepartmentEmployee(agencyId, departmentId, employeeData);

        dispatch(showNotification(DEPARTMENTS_MESSAGES.REGISTER_DEPARTMENT_EMPLOYEE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DEPARTMENTS_MESSAGES.REGISTER_DEPARTMENT_EMPLOYEE_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const exportDepartment = (agencyId, departmentId, query, fileName) => async dispatch => {
    try {
        dispatch(showNotification(EXPORT_MESSAGES.START_GENERATING, NOTIFICATION_TYPES.INFO));

        await departmentService.exportDepartment(agencyId, departmentId, query, fileName);

        dispatch(showNotification(EXPORT_MESSAGES.GENERATED_SUCCESSFULLY, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};
