import { push } from 'connected-react-router';
import { auth } from 'utils/firebase';

import agencyProfileService from 'services/agencyProfileService';

import { fetchUserInformationSuccess, refreshTokenSuccess } from './authActions';
import { fetchHomeInformationWithToken } from './tokenPropActions';
import { authorizedShowNotification } from './actionHelpers';
import { hideActionButtonLoader, showActionButtonLoader } from './sectionsLoadersActions';

import { PROFILE_CREATION_STEPS_FETCH_SUCCESS } from './actionTypes';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { AGENCY_MESSAGES, AUTH_MESSAGES } from 'constants/messageConstants';

export const fetchProfileCreationStepsSuccess = data => ({
    type: PROFILE_CREATION_STEPS_FETCH_SUCCESS,
    payload: data,
});

export const updateUserInfo = (agencyId, userData) => async dispatch => {
    try {
        dispatch(showActionButtonLoader());

        const result = await agencyProfileService.updateUserInfo(agencyId, userData);

        await auth.currentUser.reload();
        const { displayName } = auth.currentUser.toJSON();

        dispatch(fetchUserInformationSuccess({ user: { displayName } }));
        dispatch(fetchProfileCreationStepsSuccess(result.profileCreationSteps));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR))
    } finally {
        dispatch(hideActionButtonLoader());
    }
};

export const updateAgencyInfo = (agencyId, agencyData) => async dispatch => {
    try {
        dispatch(showActionButtonLoader());

        const result = await agencyProfileService.updateAgencyInfo(agencyId, agencyData);

        await auth.currentUser.reload();
        const idtoken = await auth.currentUser.getIdToken(true);
        const currentUser = auth.currentUser.toJSON();

        await dispatch(refreshTokenSuccess(idtoken));
        await dispatch(fetchUserInformationSuccess({ user: {}, roles: [result.role], displayMode: result.role }));
        await dispatch(fetchProfileCreationStepsSuccess(result.profileCreationSteps));
        await dispatch(push('/create-company-profile/start-trial'));
        await dispatch(fetchHomeInformationWithToken(result.agencyId, currentUser, result.role, idtoken, true));
    } catch (error) {
        if (error.message === AGENCY_MESSAGES.AGENCY_NAME_ALREADY_EXISTS) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR))
        } else {
            dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR))
        }
    } finally {
        dispatch(hideActionButtonLoader());
    }
};


export const fetchUserInfo = (agencyId) => async dispatch => {
    try {
        const result = await agencyProfileService.fetchUserInfo(agencyId);

        dispatch(fetchProfileCreationStepsSuccess(result.profileCreationSteps));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR))
    }
};
