import moment from 'moment';
import { v4 as uuid } from 'uuid';

import {
    TRANSFERRED_CANDIDATES_FETCH_SUCCESS,
    TRANSFERRED_CANDIDATES_FETCH_PENDING,
    PERSONAL_INFO_FETCH_SUCCESS,
    PROFILE_DETAILS_FETCH_SUCCESS,
    PROFILE_FETCH_SUCCESS,
    CANDIDATE_PREFERENCES_FETCH_SUCCESS,
    CANDIDATE_PREFERENCES_UPDATE_SUCCESS,
    CV_EXPORT_SUCCESS,
    CANDIDATE_ASSIGNEE_UPDATE_SUCCESS,
    CANDIDATE_CRITERIA_FETCH_SUCCESS,
    CANDIDATE_CRITERIA_UPDATE_SUCCESS,
    VIDEO_INTERVIEW_ANSWER_FETCH_SUCCESS,
    CANDIDATE_BOOK_INTERVIEW_SUCCESS,
    CANDIDATE_APPLICATION_FETCH_SUCCESS,
    INTERVIEW_INVITATION_FETCH_SUCCESS,
    COUNTRIES_FETCH_ALL_SUCCESS,
    AGENCY_ADMINS_FETCH_SUCCESS,
    CANDIDATE_CONTRACTOR_FILTERS,
    CANDIDATES_PREFERENCES_DATA_FETCH_SUCCESS,
    CANDIDATES_PREFERENCES_DATA_FETCHING_SUCCESS,
    CANDIDATE_VIDEO_INTERVIEW_FEEDBACK_DATA_FETCH_SUCCESS,
    CANDIDATE_VIDEO_INTERVIEW_FEEDBACK_DATA_FETCHING_SUCCESS,
    CANDIDATE_APPLICATION_DATA_FETCH_SUCCESS,
    CANDIDATE_APPLICATION_DATA_FETCHING_SUCCESS,
    CANDIDATE_OVERVIEW_PAGE_DATA_FETCH_SUCCESS,
    CANDIDATE_OVERVIEW_PAGE_DATA_FETCHING_SUCCESS,
    CANDIDATE_JOURNEY_START_INFORMATION_UPDATE_SUCCESS,
    VIDEO_INTERVIEW_QUESTIONS_FETCH_SUCCESS,
    CANDIDATE_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    CANDIDATE_PROFILE_PAGE_FETCH_SUCCESS,
    CANDIDATE_JOURNEY_INFO_FETCH_SUCCESS,
    INITIAL_JOURNEY_COMPLETED_STEPS_UPDATE_SUCCESS,
    CANDIDATE_VIEWED_JOB_UPDATE_SUCCESS,
    CANDIDATE_VIEWED_JOB_FETCH_SUCCESS,
    CANDIDATE_REGISTER_FETCH_SUCCESS,
    CANDIDATE_PROFILE_STEPS_FETCH_SUCCESS,
    CANDIDATE_PROFILE_STEPS_UPDATE_SUCCESS,
    CHECKLISTED_CANDIDATES_FETCH_SUCCESS,
    CHECKLISTED_CANDIDATES_DATA_FETCH_SUCCESS,
    CANDIDATE_STATUS_HISTORY_FETCH_SUCCESS,
    CHECKLISTED_CANDIDATES_DATA_FETCHING_SUCCESS,
    CANDIDATE_CALENDLY_INTERVIEW_DETAILS_FETCH_SUCCESS,
    CANDIDATE_APPLICATION_PROCESS_INFORMAITON_FETCH_SUCCESS,
    CANDIDATE_APPLICATION_PROCESS_INFORMAITON_UPDATE_SUCCESS,
    APPLICATION_PROCESS_INFORMATION_FETCHING_SUCCESS,
    APPLICATION_PROCESS_INFORMATION_FETCH_SUCCESS,
    CANDIDATE_PROFILE_PAGE_DATA_FETCHING_SUCCESS,
    CANDIDATE_PROFILE_PAGE_DATA_FETCH_SUCCESS,
    CV_PAGE_UPDATE_SUCCESS,
    CV_PAGE_UPDATING_SUCCESS,
    CANDIDATES_FETCH_SUCCESS,
    EMPLOYEES_FETCH_SUCCESS,
    EMPLOYEES_FETCH_PENDING,
} from './actionTypes';
import { fetchEmployeeUploadedCvSuccess, updatePersonalInfoSuccess } from './employeeActions';
import { fetchJobOpportunityDetailsSuccess } from './jobOpportunityActions';
import { showDialogActionButtonLoader, hideDialogActionButtonLoader } from './sectionsLoadersActions';
import {
    fetchAgencyBasicInfoSuccess,
    initialJourneyUpdateSuccess,
    initialJourneyUpdateCurrentStepIdSuccess,
    fetchAgencyCalendlyInfoSuccess,
    fetchJourneyStartInformationFetchSuccess,
    initialJourneyFetchSuccess,
} from './agencyActions';
import { fetchAllStaticLanguagesSuccess } from './staticDataActions.js';
import { hideApplicationLoader, showApplicationLoader } from "./applicationLoaderActions";
import { toggleIncompleteProfileDialog, toggleJourneyCompleted, toggleReceivedHiredNotificationSuccess } from './uiActions';
import candidateService from '../services/candidateService';
import agencyService from '../services/agencyService';
import { authorizedShowNotification } from 'actions/actionHelpers';
import { showNotification } from "./notificationActions";
//TODO: Migrate all messages to CANDIDATE_MESSAGES
import { EMPLOYEE_MESSAGES, CANDIDATE_MESSAGES, VIDEO_MESSAGES, CV_INVALID_FORMAT_MESSAGES, EMAIL_MESSAGES, EXPORT_MESSAGES } from "../constants/messageConstants";
import { NOTIFICATION_TYPES } from "../constants/notificationTypes";
import { DATE_FORMAT_WITH_DASH, ISO_8601_DATE_FORMAT } from 'constants/commonConstants';
import { CANDIDATE_STATUSES } from 'constants/candidateConstants';

import { getCandidateInitialStepId } from 'components/CandidateProfileJourney/CandidateProfileJourneyHelpers';

const fetchCandidatesPending = (transactionId) => ({
    type: EMPLOYEES_FETCH_PENDING,
    transactionId,
});

const fetchCandidatesSuccess = (candidatesData, transactionId) => ({
    type: CANDIDATES_FETCH_SUCCESS,
    payload: candidatesData,
    transactionId,
});

const replaceCandidatesSuccess = (candidatesData, transactionId) => ({
    type: EMPLOYEES_FETCH_SUCCESS,
    payload: candidatesData,
    transactionId
});

const fetchTransferredCandidatesSuccess = transferredCandidatesData => ({
    type: TRANSFERRED_CANDIDATES_FETCH_SUCCESS,
    payload: transferredCandidatesData
});

const fetchTransferredCandidatesPending = () => ({
    type: TRANSFERRED_CANDIDATES_FETCH_PENDING,
});

export const fetchCandidatePersonalInformationSuccess = personalInformation => ({
    type: PERSONAL_INFO_FETCH_SUCCESS,
    payload: personalInformation
});

const fetchCandidateVideoInterviewQuestionsSuccess = interviewQuestionsData => ({
    type: VIDEO_INTERVIEW_QUESTIONS_FETCH_SUCCESS,
    payload: interviewQuestionsData
});

export const fetchCandidateVideoInterviewAnswersSuccess = answerData => ({
    type: VIDEO_INTERVIEW_ANSWER_FETCH_SUCCESS,
    payload: answerData
});

export const fetchProfileSuccess = profileData => ({
    type: PROFILE_FETCH_SUCCESS,
    payload: profileData,
});

const fetchProfileDetailsSuccess = profileDetails => ({
    type: PROFILE_DETAILS_FETCH_SUCCESS,
    payload: profileDetails,
});

export const fetchCandidatePreferencesSuccess = preferencesData => ({
    type: CANDIDATE_PREFERENCES_FETCH_SUCCESS,
    payload: preferencesData
});

const updateCandidatePreferencesSuccess = preferencesData => ({
    type: CANDIDATE_PREFERENCES_UPDATE_SUCCESS,
    payload: preferencesData
});

const updateCandidateAssigneeSuccess = assigneeData => ({
    type: CANDIDATE_ASSIGNEE_UPDATE_SUCCESS,
    payload: assigneeData,
});

const fetchCandidateCriteriaSuccess = preferencesData => ({
    type: CANDIDATE_CRITERIA_FETCH_SUCCESS,
    payload: preferencesData
});

const updateCandidateCriteriaSuccess = criteriaData => ({
    type: CANDIDATE_CRITERIA_UPDATE_SUCCESS,
    payload: criteriaData
});

const exportCVSuccess = () => ({
    type: CV_EXPORT_SUCCESS,
});

export const fetchCandidateApplicationSuccess = applicationData => ({
    type: CANDIDATE_APPLICATION_FETCH_SUCCESS,
    payload: applicationData
});

export const fetchInterviewInvitationSuccess = invitationData => ({
    type: INTERVIEW_INVITATION_FETCH_SUCCESS,
    payload: invitationData
});

const fetchPersonalInfoSuccess = personalData => ({
    type: PERSONAL_INFO_FETCH_SUCCESS,
    payload: personalData
});

const fetchAllCountriesSuccess = countries => ({
    type: COUNTRIES_FETCH_ALL_SUCCESS,
    payload: countries,
});

const fetchAdminsSuccess = adminsData => ({
    type: AGENCY_ADMINS_FETCH_SUCCESS,
    payload: adminsData
});

const bookFaceToFaceInterviewSuccess = candidateData => ({
    type: CANDIDATE_BOOK_INTERVIEW_SUCCESS,
    payload: candidateData,

});

const fetchCandidateContractorFiltersSuccess = data => ({
    type: CANDIDATE_CONTRACTOR_FILTERS,
    payload: data,
});

const candidatesPreferencesDataFetchSuccess = () => ({
    type: CANDIDATES_PREFERENCES_DATA_FETCH_SUCCESS,
});

const candidatesPreferencesDataFetchingSuccess = () => ({
    type: CANDIDATES_PREFERENCES_DATA_FETCHING_SUCCESS,
});

const candidateVideoInterviewFeedbackDataFetchSuccess = () => ({
    type: CANDIDATE_VIDEO_INTERVIEW_FEEDBACK_DATA_FETCH_SUCCESS,
});

const candidateVideoInterviewFeedbackDataFetchingSuccess = () => ({
    type: CANDIDATE_VIDEO_INTERVIEW_FEEDBACK_DATA_FETCHING_SUCCESS,
});

const candidateApplicationDataFetchSuccess = () => ({
    type: CANDIDATE_APPLICATION_DATA_FETCH_SUCCESS,
});

const candidateApplicationDataFetchingSuccess = () => ({
    type: CANDIDATE_APPLICATION_DATA_FETCHING_SUCCESS,
});

const candidateOverviewPageDataFetchSuccess = () => ({
    type: CANDIDATE_OVERVIEW_PAGE_DATA_FETCH_SUCCESS,
});

const candidateOverviewPageDataFetchingSuccess = () => ({
    type: CANDIDATE_OVERVIEW_PAGE_DATA_FETCHING_SUCCESS,
});

const updateCandidateJourneyStartInformationSuccess = data => ({
    type: CANDIDATE_JOURNEY_START_INFORMATION_UPDATE_SUCCESS,
    payload: data
});

const candidateProfilePageUnmounted = () => ({
    type: CANDIDATE_PROFILE_PAGE_UNMOUNTED_SUCCESS,
});

const candidateProfilePageFetchSuccess = data => ({
    type: CANDIDATE_PROFILE_PAGE_FETCH_SUCCESS,
    payload: data
});

const candidateJourneyInfoFetchSuccess = data => ({
    type: CANDIDATE_JOURNEY_INFO_FETCH_SUCCESS,
    payload: data
});

export const initialJourneyUpdateCompletedStepsSuccess = completedStep => ({
    type: INITIAL_JOURNEY_COMPLETED_STEPS_UPDATE_SUCCESS,
    payload: completedStep
});

const updateCandidateViewedJobSuccess = data => ({
    type: CANDIDATE_VIEWED_JOB_UPDATE_SUCCESS,
    payload: data
});

export const fetchCandidateViewedJobSuccess = (data) => ({
    type: CANDIDATE_VIEWED_JOB_FETCH_SUCCESS,
    payload: data
});

const fetchRegisterCandidateSuccess = (userData) => ({
    type: CANDIDATE_REGISTER_FETCH_SUCCESS,
    payload: userData
});

export const fetchCandidateProfileStepsSuccess = data => ({
    type: CANDIDATE_PROFILE_STEPS_FETCH_SUCCESS,
    payload: data,
});

const updateCandidateProfileStepsSuccess = data => ({
    type: CANDIDATE_PROFILE_STEPS_UPDATE_SUCCESS,
    payload: data,
});

const fetchChecklistedCandidatesSuccess = (data) => ({
    type: CHECKLISTED_CANDIDATES_FETCH_SUCCESS,
    payload: data,
});

const checklistedCandidatesTableDataFetchSuccess = () => ({
    type: CHECKLISTED_CANDIDATES_DATA_FETCH_SUCCESS,
});

const checklistedCandidatesTableDataFetchingSuccess = () => ({
    type: CHECKLISTED_CANDIDATES_DATA_FETCHING_SUCCESS,
});

export const fetchCandidateStatusHistorySuccess = data => ({
    type: CANDIDATE_STATUS_HISTORY_FETCH_SUCCESS,
    payload: data,
});

const fetchCandidateCalendlyInterviewDetailsSuccess = (data) => ({
    type: CANDIDATE_CALENDLY_INTERVIEW_DETAILS_FETCH_SUCCESS,
    payload: data,
});

const fetchCandidateApplicationProcessInformationSuccess = (data) => ({
    type: CANDIDATE_APPLICATION_PROCESS_INFORMAITON_FETCH_SUCCESS,
    payload: data,
});

export const updateCandidateApplicationProcessInformationSuccess = data => ({
    type: CANDIDATE_APPLICATION_PROCESS_INFORMAITON_UPDATE_SUCCESS,
    payload: data,
});

const candidateApplicationProcessInformationFetchingSuccess = () => ({
    type: APPLICATION_PROCESS_INFORMATION_FETCHING_SUCCESS,
});

const candidateApplicationProcessInformationFetchSuccess = () => ({
    type: APPLICATION_PROCESS_INFORMATION_FETCH_SUCCESS,
});

const candidateProfilePageDataFetchingSuccess = () => ({
    type: CANDIDATE_PROFILE_PAGE_DATA_FETCHING_SUCCESS,
});

const candidateProfilePageDataFetchSuccess = () => ({
    type: CANDIDATE_PROFILE_PAGE_DATA_FETCH_SUCCESS,
});

export const cvPageUpdateSuccess = () => ({
    type: CV_PAGE_UPDATE_SUCCESS,
});

export const cvPageUpdatingSuccess = () => ({
    type: CV_PAGE_UPDATING_SUCCESS,
});

export const fetchAllCandidates = (agencyId, queryParams, resetState) => async dispatch => {
    const transactionId = uuid();

    dispatch(fetchCandidatesPending(transactionId));

    try {
        const result = await candidateService.fetchAllCandidates(agencyId, queryParams);

        if (resetState) {
            dispatch(replaceCandidatesSuccess(result.candidates, transactionId));
        } else {
            dispatch(fetchCandidatesSuccess(result.candidates, transactionId));
        }

        return result.totalCount;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_CANDIDATES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const exportCandidates = (agencyId, query) => async dispatch => {
    try {
        dispatch(showNotification(EXPORT_MESSAGES.START_GENERATING, NOTIFICATION_TYPES.INFO));

        const fileName = `${moment().format(DATE_FORMAT_WITH_DASH)}-candidates`;
        await candidateService.exportCandidates(agencyId, query, fileName);

        dispatch(showNotification(EXPORT_MESSAGES.GENERATED_SUCCESSFULLY, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const exportCandidateInformation = (agencyId, candidateId, fileName) => async dispatch => {
    try {
        dispatch(showNotification(EXPORT_MESSAGES.START_GENERATING, NOTIFICATION_TYPES.INFO));

        await candidateService.exportCandidateInformation(agencyId, candidateId, fileName);

        dispatch(showNotification(EXPORT_MESSAGES.GENERATED_SUCCESSFULLY, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchAllTransferredCandidates = agencyId => async dispatch => {
    dispatch(fetchTransferredCandidatesPending());

    try {
        const transferredCandidates = await candidateService.fetchAllTransferredCandidates(agencyId);

        dispatch(fetchTransferredCandidatesSuccess(transferredCandidates));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_TRANSFERRED_CANDIDATES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchCandidatePersonalInformation = (agencyId, candidateId) => async dispatch => {
    try {
        const personalInformation = await candidateService.fetchCandidatePersonalInformation(agencyId, candidateId);

        dispatch(fetchCandidatePersonalInformationSuccess(personalInformation));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_CANDIDATES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const generateCV = (agencyId, candidateId, files) => async dispatch => {
    try {
        const result = await candidateService.generateCV(agencyId, candidateId, files);

        dispatch(fetchProfileSuccess(result));
        dispatch(showNotification(EMPLOYEE_MESSAGES.GENERATE_CV_PDF_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchCandidatePreferences = (agencyId, candidateId) => async dispatch => {
    try {
        dispatch(candidatesPreferencesDataFetchingSuccess());

        const preferencesData = await candidateService.fetchCandidatePreferences(agencyId, candidateId);
        dispatch(fetchCandidatePreferencesSuccess(preferencesData));

    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_PREFERENCES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(candidatesPreferencesDataFetchSuccess());
    }
};

export const updateCandidatePreferences = (agencyId, candidateId, preferencesData, stepData) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const {
            updatedPreferencesData,
            status,
            statusLastEdit
        } = await candidateService.updateCandidatePreferences(agencyId, candidateId, preferencesData, stepData);

        dispatch(updateCandidatePreferencesSuccess(updatedPreferencesData));

        if (status) {
            dispatch(updatePersonalInfoSuccess({ status }));

            if (statusLastEdit) {
                dispatch(fetchCandidateCriteriaSuccess({ status, statusLastEdit }));
            }
        }

        if (!stepData) {
            dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_PREFERENCES_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
        }
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_PREFERENCES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const fetchCandidateCriteria = (agencyId, candidateId) => async dispatch => {
    try {
        const criteriaData = await candidateService.fetchCandidateCriteria(agencyId, candidateId);
        dispatch(fetchCandidateCriteriaSuccess(criteriaData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_PREFERENCES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateCandidateCriteria = (agencyId, candidateId, criteriaData, isWithNotification = true) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const { statusHistory, ...updateCandidateCriteriaData } = await candidateService.updateCandidateCriteria(agencyId, candidateId, criteriaData);

        dispatch(updateCandidateCriteriaSuccess(updateCandidateCriteriaData));
        dispatch(updatePersonalInfoSuccess({ status: criteriaData.status }));
        dispatch(fetchCandidateStatusHistorySuccess({ statusHistory }));

        if (isWithNotification) {
            dispatch(showNotification(CANDIDATE_MESSAGES.UPDATE_CRITERIS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
        }
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CANDIDATE_MESSAGES.UPDATE_CRITERIS_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const bookFaceToFaceInterview = (agencyId, candidateId, eventUris, isThroughReminderEmail, stepData, withEmail) => async dispatch => {
    try {
        const { calendlyInterviewDetails, ...hasBookedInterview } = await candidateService.setBookedInterview(agencyId, candidateId, eventUris, isThroughReminderEmail, stepData, withEmail);

        dispatch(bookFaceToFaceInterviewSuccess(hasBookedInterview));
        if (calendlyInterviewDetails) {
            dispatch(fetchCandidateCalendlyInterviewDetailsSuccess(calendlyInterviewDetails));
        }
        dispatch(showNotification(EMPLOYEE_MESSAGES.CANDIDATE_BOOK_INTERVIEW_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.CANDIDATE_BOOK_INTERVIEW_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const exportCandidateCV = (candidates, agencyId) => async dispatch => {
    try {
        const agencyInfo = await agencyService.fetchAgencyName(agencyId);

        let fileName = '';

        if (candidates.length === 1) {
            fileName = `${candidates[0].firstName}-${agencyInfo.name}-CV`;
        } else {
            fileName = `${moment().format(ISO_8601_DATE_FORMAT)}-${agencyInfo.name}-CV-Export`
        }

        await candidateService.getExportedCVs(agencyId, candidates.map(x => x._id), fileName);

        dispatch(exportCVSuccess());
        dispatch(showNotification(EMPLOYEE_MESSAGES.EXPORT_CV_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.EXPORT_CV_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateCandidateAssignee = (agencyId, candidateId, assigneeId) => async dispatch => {
    try {
        const result = await candidateService.updateCandidateAssignee(agencyId, candidateId, assigneeId);

        dispatch(updateCandidateAssigneeSuccess(result));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_CANDIDATE_ASSIGNEE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_CANDIDATE_ASSIGNEE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const uploadCandidateVideoInterviewQuestionAnswer = (agencyId, candidateId, category, questionId, videoAnswerData, stepData) => async dispatch => {
    try {
        if (stepData) {
            videoAnswerData.append('stepData', JSON.stringify(stepData));
        }
        await candidateService.uploadCandidateVideoInterviewQuestionAnswer(agencyId, candidateId, category, questionId, videoAnswerData);

        if (!stepData) {
            dispatch(showNotification(VIDEO_MESSAGES.UPLOAD_VIDEO_ANSWER_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
        }
    } catch (error) {
        dispatch(authorizedShowNotification(error)(VIDEO_MESSAGES.UPLOAD_VIDEO_ANSWER_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchCandidateVideoInterviewQuestions = (agencyId, candidateId, category) => async dispatch => {
    try {
        const videoInterviewQuestionData = await candidateService.fetchCandidateVideoInterviewQuestions(agencyId, candidateId, category);
        dispatch(fetchCandidateVideoInterviewQuestionsSuccess(videoInterviewQuestionData.questions));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(VIDEO_MESSAGES.FETCH_VIDEO_QUESTION_FAIL, NOTIFICATION_TYPES.ERROR));
    }
}

export const fetchCandidateVideoInterviewAnswers = (agencyId, candidateId) => async dispatch => {
    try {
        const videoInterviewAnswerData = await candidateService.fetchCandidateVideoInterviewAnswers(agencyId, candidateId);
        dispatch(fetchCandidateVideoInterviewAnswersSuccess(videoInterviewAnswerData));
    } catch (error) {
        // commented due to task #4438
        // dispatch(authorizedShowNotification(error)(VIDEO_MESSAGES.FETCH_VIDEO_QUESTION_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchCandidateVideoInterviewFeedbackInformation = (agencyId, candidateId) => async dispatch => {
    try {
        dispatch(candidateVideoInterviewFeedbackDataFetchingSuccess());

        const feedbackInformation = await candidateService.fetchCandidateVideoInterviewFeedbackInformation(agencyId, candidateId);

        dispatch(fetchCandidateCriteriaSuccess(feedbackInformation));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_CANDIDATE_FEEDBACK_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(candidateVideoInterviewFeedbackDataFetchSuccess());
    }
};

export const fetchCandidateProfile = (agencyId, candidateId) => async dispatch => {
    try {
        dispatch(showApplicationLoader());

        const profileData = await candidateService.fetchProfile(agencyId, candidateId);

        dispatch(fetchProfileSuccess(profileData));

    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_CANDIDATE_PROFILE_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const fetchCandidateApplicationPage = (agencyId, candidateId, applicationId) => async dispatch => {
    try {
        dispatch(candidateApplicationDataFetchingSuccess());
        const { journeyVideoInterviewAnswer, ...rest } = await candidateService.fetchCandidateApplicationPage(agencyId, candidateId, applicationId);

        dispatch(fetchCandidateVideoInterviewAnswersSuccess(journeyVideoInterviewAnswer ? [journeyVideoInterviewAnswer] : []));
        dispatch(fetchCandidatePreferencesSuccess(rest.preferences));
        dispatch(fetchProfileSuccess(rest.profile));
        dispatch(fetchCandidateApplicationSuccess(rest.application));
        dispatch(fetchInterviewInvitationSuccess(rest.interviewInvitation));
        dispatch(fetchJobOpportunityDetailsSuccess(rest.jobDetails))
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_CANDIDATE_APPLICATION_PAGE_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(candidateApplicationDataFetchSuccess());
    }
};

export const fetchCandidatePersonalInformationPage = (agencyId, candidateId) => async dispatch => {
    try {
        dispatch(candidateOverviewPageDataFetchingSuccess());

        const candidateData = await candidateService.fetchCandidatePersonalInformationPages(agencyId, candidateId);

        dispatch(fetchCandidateProfileStepsSuccess(candidateData.profileSteps));
        dispatch(fetchAdminsSuccess(candidateData.adminsData));
        dispatch(fetchCandidatePreferencesSuccess(candidateData.preferencesData));
        dispatch(fetchAllCountriesSuccess(candidateData.countries));
        dispatch(fetchAgencyBasicInfoSuccess(candidateData.agencyInfo));
        dispatch(fetchProfileSuccess(candidateData.profileData));
        dispatch(fetchProfileDetailsSuccess(candidateData.profileData));
        dispatch(fetchAllStaticLanguagesSuccess(candidateData.profileData.staticLanguages));
        dispatch(fetchPersonalInfoSuccess(candidateData.personalData));
        dispatch(fetchCandidateVideoInterviewAnswersSuccess(candidateData.questionAnswersData));
        dispatch(fetchCandidateStatusHistorySuccess({ statusHistory: candidateData.statusHistory }));
        dispatch(candidateJourneyInfoFetchSuccess(candidateData.candidateJourneyInfo))
        if (candidateData.calendlyInterviewDetails) {
            dispatch(fetchCandidateCalendlyInterviewDetailsSuccess(candidateData.calendlyInterviewDetails));
        }
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_CANDIDATE_PERSONAL_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(candidateOverviewPageDataFetchSuccess());
    }
};

export const fetchCandidateContractorFilters = (agencyId, candidateId) => async dispatch => {
    try {
        const contractorFilters = await candidateService.fetchCandidateContractorFilters(agencyId, candidateId);
        dispatch(fetchCandidateContractorFiltersSuccess(contractorFilters));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_EMPLOYEES_ROLE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateCandidateJourneyStartInformation = (agencyId, candidateId, updateData) => async dispatch => {
    try {
        const result = await candidateService.updateCandidateJourneyStartInformation(agencyId, candidateId, updateData);

        dispatch(updateCandidateJourneyStartInformationSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateCandidateCreateProfileJourney = (action, completedStep, isCompleted) => async dispatch => {
    try {
        const result = await action()

        if (result && result.error) {
            throw new Error(result.error.message)
        }

        dispatch(initialJourneyUpdateSuccess({ completedStep, isCompleted }));
    } catch (error) {
        if (Object.values(CV_INVALID_FORMAT_MESSAGES).includes(error.message)) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_CANDIDATE_CREATE_PROFILE_JOURNEY_FAIL, NOTIFICATION_TYPES.ERROR));
        }

        return { error }
    }
};

export const updateCandidateJourneyCurrentStepId = (currentStepId) => dispatch => {
    try {
        dispatch(initialJourneyUpdateCurrentStepIdSuccess(currentStepId));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateCandidatesJourneyCompletedSteps = (agencyId, candidateId, stepData) => async (dispatch) => {
    try {
        await candidateService.updateCandidatesJourneyCompletedSteps(agencyId, candidateId, stepData);

        dispatch(initialJourneyUpdateCompletedStepsSuccess(stepData));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                EMPLOYEE_MESSAGES.UPDATE_CANDIDATE_CREATE_PROFILE_JOURNEY_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const updateCandidateJourneyCvPage = (agencyId, candidateId, cvData, stepInfo) => async (dispatch) => {
    try {
        dispatch(cvPageUpdatingSuccess());
        const data = {
            ...cvData,
            stepInfo,
        };

        const { generalInfo, ...rest } = await candidateService.updateCandidateJourneyImproveCvPage(agencyId, candidateId, data);

        const profileData = { ...rest, employmentInformation: { generalInfo } }
        dispatch(fetchProfileSuccess(profileData));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                EMPLOYEE_MESSAGES.UPDATE_CANDIDATE_CREATE_PROFILE_JOURNEY_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    } finally {
        dispatch(cvPageUpdateSuccess());
    }
};

export const updateCandidateJourneyIsCompleted = (agencyId, userId, isJourneyCompleted) => async (dispatch) => {
    try {
        await candidateService.updateCandidateJourneyIsCompleted(agencyId, userId, isJourneyCompleted);
        dispatch(toggleJourneyCompleted(true));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                EMPLOYEE_MESSAGES.FETCH_CANDIDATE_CREATE_PROFILE_JOURNEY_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const fetchCandidateProfilePage = (agencyId, candidateId) => async (dispatch) => {
    try {
        dispatch(candidateProfilePageDataFetchingSuccess());

        const result = await candidateService.fetchCandidateProfilePage(agencyId, candidateId);

        dispatch(candidateProfilePageFetchSuccess(result));
        dispatch(fetchAllStaticLanguagesSuccess(result.profileData.staticLanguages));
        dispatch(fetchCandidateVideoInterviewAnswersSuccess(result.questionAnswersData));
        dispatch(fetchProfileSuccess(result.profileData));
        dispatch(fetchCandidatePreferencesSuccess(result.preferencesData));
        dispatch(fetchCandidatePersonalInformationSuccess(result.personalInformationData));
        dispatch(fetchAgencyBasicInfoSuccess(result.agencyInfo));
        dispatch(fetchAllCountriesSuccess(result.countries));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                EMPLOYEE_MESSAGES.FETCH_CANDIDATE_CREATE_PROFILE_JOURNEY_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    } finally {
        dispatch(candidateProfilePageDataFetchSuccess());
    }
};

export const resetCandidateInformationState = () => dispatch => {
    dispatch(candidateProfilePageUnmounted());
};

export const fetchCandidateJourneyPage = (agencyId, candidateId, updateInitialJourneyState = true) => async dispatch => {
    try {
        const result = await candidateService.fetchCandidateJourneyPage(agencyId, candidateId);
        const { agencyInfo, ...restCvData } = result.cvData;
        const { initialJourney, isJourneyStarted } = result;

        const journeyStepsIds = (initialJourney?.completedSteps || []).map(x => x._id);
        const stepTypes = (initialJourney?.completedSteps || []).map(x => x.type);


        dispatch(fetchCandidatePreferencesSuccess(result.preferences));
        dispatch(fetchAgencyCalendlyInfoSuccess({ calendlyInfo: result.agencyCalendlyData }));
        dispatch(fetchCandidateVideoInterviewQuestionsSuccess(result.videoQuestions));
        dispatch(fetchProfileSuccess(restCvData));
        dispatch(fetchAllStaticLanguagesSuccess(result.staticLanguages));
        dispatch(fetchAgencyBasicInfoSuccess(agencyInfo));

        if (updateInitialJourneyState) {
            dispatch(fetchJourneyStartInformationFetchSuccess({ isJourneyStarted }));
            dispatch(initialJourneyFetchSuccess({
                ...initialJourney,
                currentStepId: getCandidateInitialStepId(journeyStepsIds, stepTypes),
            }));
        }

        if (result.rawCvProgressData.uploadedCv) {
            dispatch(fetchEmployeeUploadedCvSuccess({ uploadedCv: result.rawCvProgressData.uploadedCv }));
        }

        if (result.cvData.personalInformation.status === CANDIDATE_STATUSES.INCOMPLETE_PROFILE) {
            dispatch(toggleIncompleteProfileDialog(true));
        }
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CANDIDATE_MESSAGES.FETCH_CANDIDATE_JOURNEY_PAGE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateCandidateViewedJob = (agencyId, candidateId, jobOpportunityId) => async (dispatch) => {
    try {

        const result = await candidateService.updateCandidateViewedJob(agencyId, candidateId, { jobId: jobOpportunityId });

        dispatch(updateCandidateViewedJobSuccess(result));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                EMPLOYEE_MESSAGES.UPDATE_CANDIDATE_CREATE_PROFILE_JOURNEY_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
};

export const fetchCandidateViewedJob = (agencyId, candidateId, showErrorNotification = true) => async (dispatch) => {
    try {
        dispatch(showApplicationLoader());

        const result = await candidateService.fetchCandidateViewedJob(agencyId, candidateId);

        dispatch(fetchCandidateViewedJobSuccess(result.lastViewedJob || ''));
        dispatch(fetchJobOpportunityDetailsSuccess(result.jobOpportunityDetails));
    } catch (error) {
        if (showErrorNotification) {
            dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_LAST_VIEWED_JOB_FAIL, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(fetchCandidateViewedJobSuccess(''));
            dispatch(fetchJobOpportunityDetailsSuccess({}));
        }
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const registerCandidate = (agencyId, candidateData) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const candidate = await candidateService.registerCandidate(agencyId, candidateData);
        if (candidate._id) {
            dispatch(fetchRegisterCandidateSuccess(candidate));
            dispatch(showNotification(CANDIDATE_MESSAGES.ADD_CANDIDATE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
        }
    } catch (error) {
        if (error.message === EMAIL_MESSAGES.EMAIL_ALREADY_USED) {
            dispatch(authorizedShowNotification(error)(CANDIDATE_MESSAGES.ADD_CANDIDATE_EMAIL_FAILURE_WARNING, NOTIFICATION_TYPES.WARNING));
        } else if (error.message === EMAIL_MESSAGES.EMAIL_DISABLED) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.WARNING));
        } else {
            dispatch(authorizedShowNotification(error)(CANDIDATE_MESSAGES.ADD_CANDIDATE_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const updateCandidateProfileSteps = (agencyId, candidateId, data) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const updatedData = await candidateService.updateCandidateProfileSteps(agencyId, candidateId, data);

        dispatch(updateCandidateProfileStepsSuccess(updatedData));
        dispatch(showNotification(CANDIDATE_MESSAGES.CANDIDATE_PROFILE_STEPS_UPDATE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CANDIDATE_MESSAGES.CANDIDATE_PROFILE_STEPS_UPDATE_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const fetchChecklistedCandidates = (agencyId, filters) => async dispatch => {
    dispatch(checklistedCandidatesTableDataFetchingSuccess());

    try {
        const result = await candidateService.fetchChecklistedCandidates(agencyId, filters);

        dispatch(fetchChecklistedCandidatesSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_CANDIDATES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(checklistedCandidatesTableDataFetchSuccess());
    }
};

export const fetchCandidateApplicationProcessInformation = (agencyId, candidateId) => async dispatch => {
    dispatch(candidateApplicationProcessInformationFetchingSuccess(false));

    try {
        const result = await candidateService.fetchCandidateApplicationProcessInformation(agencyId, candidateId);

        dispatch(fetchCandidateApplicationProcessInformationSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CANDIDATE_MESSAGES.FETCH_APPLICATION_PROCESS_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(candidateApplicationProcessInformationFetchSuccess());
    }
};

export const markHasReceivedHiredNotification = (agencyId, candidateId) => async dispatch => {
    try {
        const result = await candidateService.markHiredNotification(agencyId, candidateId);

        dispatch(toggleReceivedHiredNotificationSuccess(result.hasReceivedHiredNotification));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(CANDIDATE_MESSAGES.MARK_HIRED_NOTIFICATION_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};
