import moment from 'moment';
import { auth } from 'utils/firebase';

import {
    fetchAgencyHomeInformationSuccess,
    fetchClientHomeInformationSuccess,
    fetchEmployeeHomeInformationSuccess,
    fetchSupplierHomeInformationSuccess,
    fetchRecruiterHomeInformationSuccess,
    fetchAgencyUiDependenciesSuccess,
    fetchClientsInvoicingStatusDoneSuccess,
    fetchPendingHolidaysCountSuccess,
    fetchOnboardingSuccess,
    fetchKnowYourTeamSampleOnLoginSuccess,
    fetchAgencyNameSuccess,
    fetchMainMenuInformationSuccess,
    fetchJourneyStartInformationFetchSuccess,
    initialJourneyFetchSuccess,
    fetchTermsAndConditionsSucces,
} from 'actions/agencyActions';
import { fetchUserInformationSuccess } from 'actions/authActions';
import { authorizedShowNotification } from 'actions/actionHelpers';
import { ACCESS_AS_MANAGER_SET_SUCCESS } from 'actions/actionTypes';
import { fetchProfileCreationStepsSuccess } from 'actions/agencyProfileActions';
import tokenPropService from 'services/tokenPropService';
import { AGENCY_MESSAGES } from 'constants/messageConstants';
import { ROLES } from 'constants/userConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { getCandidateInitialStepId } from 'components/CandidateProfileJourney/CandidateProfileJourneyHelpers';
import { setIsCandidateHiredSuccess, setIsCandidateRejectedSuccess, toggleReceivedHiredNotificationSuccess, toggleWorkspaceMigrationDialog } from './uiActions';
import { shouldWorkspaceDialogBeReset } from 'utils/uiHelpers';
import { switchLanguageSuccess } from './languageActions';
import { getClientInitialStepId } from 'components/ClientProfileJourney/ClientProfileJourneyUtils';

const setAccessAsManagerSuccess = data => ({
    type: ACCESS_AS_MANAGER_SET_SUCCESS,
    payload: data
});

const FETCH_INFO_MAPPER = {
    [ROLES.ADMIN]: fetchAgencyHomeInformationSuccess,
    [ROLES.CLIENT]: fetchClientHomeInformationSuccess,
    [ROLES.EMPLOYEE]: fetchEmployeeHomeInformationSuccess,
    [ROLES.SUPPLIER_ADMIN]: fetchSupplierHomeInformationSuccess,
    [ROLES.SUPPLIER_EMPLOYEE]: fetchEmployeeHomeInformationSuccess,
    [ROLES.RECRUITER]: fetchRecruiterHomeInformationSuccess,
    [ROLES.CANDIDATE]: fetchEmployeeHomeInformationSuccess,
    [ROLES.UNASSIGNED]: fetchEmployeeHomeInformationSuccess,
};

export const setRememberMeWithToken = (idtoken) => async dispatch => {
    await tokenPropService.setRememberMeWithToken(idtoken);
}

export const fetchHomeInformationWithToken = (agencyId, currentUser, displayMode, idtoken, isTermsAndConditions) => async dispatch => {

    try {
        const { uid: userId, displayName } = currentUser;
        const userData = { userId, role: displayMode };
        const isLogin = true;

        const {
            mainMenuInformation,
            isClientsInvoicingStatusDone,
            hasClients,
            isOnboardingPassed,
            isTermsAndConditionsAccepted,
            agencyName,
            agencyUiDependencies,
            accessAsManager,
            userPermissions,
            workspaceMigrationInfo,
            isCurrentlyOnProject,
            ...homeInformation
        } = await tokenPropService.fetchHomeInformationWithToken(agencyId, userData, idtoken, isLogin);

        isTermsAndConditions = isTermsAndConditions || isTermsAndConditionsAccepted;

        const menuInformation = displayMode === ROLES.ADMIN
            ? {
                adminName: homeInformation.adminName || '',
                agencyName: agencyName || '',
                logoImgUrl: homeInformation.logoImgUrl || '',
            } : homeInformation;

        const pendingHolidays = homeInformation.pendingHolidaysCount || [];

        displayMode !== ROLES.ADMIN && dispatch(fetchAgencyNameSuccess({ name: agencyName }));
        displayMode && dispatch(FETCH_INFO_MAPPER[displayMode](menuInformation));

        accessAsManager && dispatch(setAccessAsManagerSuccess({ accessAsManager }));

        dispatch(fetchAgencyUiDependenciesSuccess(agencyUiDependencies));
        dispatch(fetchMainMenuInformationSuccess(mainMenuInformation));
        dispatch(fetchUserInformationSuccess({ user: { displayName, uid: userId }, agencyId, displayMode, userPermissions: userPermissions?.permissions }));
        dispatch(fetchOnboardingSuccess({ isOnboardingPassed }));
        dispatch(fetchTermsAndConditionsSucces({ isTermsAndConditionsAccepted }));
        dispatch(switchLanguageSuccess(homeInformation.language || 'en'));

        if (displayMode === ROLES.ADMIN || displayMode === ROLES.SUPPLIER_ADMIN) {
            dispatch(fetchClientsInvoicingStatusDoneSuccess({ isClientsInvoicingStatusDone, hasClients }));
            dispatch(fetchPendingHolidaysCountSuccess(pendingHolidays));
        }

        if (displayMode === ROLES.CANDIDATE) {
            const { initialJourney, isJourneyStarted, isHired, isRejected, hasReceivedHiredNotification } = homeInformation;

            const journeyStepsIds = (initialJourney?.completedSteps || []).map(x => x._id);
            const stepTypes = (initialJourney?.completedSteps || []).map(x => x.type);

            dispatch(fetchJourneyStartInformationFetchSuccess({ isJourneyStarted }));
            dispatch(setIsCandidateHiredSuccess(isHired));
            dispatch(setIsCandidateRejectedSuccess(isRejected));
            dispatch(toggleReceivedHiredNotificationSuccess(hasReceivedHiredNotification));
            dispatch(initialJourneyFetchSuccess({
                ...initialJourney,
                currentStepId: getCandidateInitialStepId(journeyStepsIds, stepTypes),
            }));
        }

        if (displayMode === ROLES.CLIENT) {
            const { initialJourney, isJourneyStarted } = homeInformation;
            const journeyStepIds = (initialJourney?.completedSteps || []).map(x => x._id);

            dispatch(fetchJourneyStartInformationFetchSuccess({ isJourneyStarted }));
            dispatch(initialJourneyFetchSuccess({
                ...initialJourney,
                currentStepId: getClientInitialStepId(journeyStepIds),
            }));
        }

        if (displayMode === ROLES.EMPLOYEE) {
            const sampleSize = 2;
            const sample = await tokenPropService.fetchKnowYourTeamSampleWithToken(agencyId, userId, sampleSize, idtoken);
            dispatch(fetchKnowYourTeamSampleOnLoginSuccess(sample))
        }

        // workspace migration dialog
        // dialog appears until 19 Feb 2023, if day is after that ignore
        const isTodayBefore19thFeb = moment().isBefore(new Date('2023-02-19'));

        if (!isTodayBefore19thFeb || (displayMode === ROLES.SUPPLIER_EMPLOYEE && !isCurrentlyOnProject)) {
            dispatch(toggleWorkspaceMigrationDialog(false));
        }

        if ((displayMode === ROLES.ADMIN || displayMode === ROLES.EMPLOYEE || (displayMode === ROLES.SUPPLIER_EMPLOYEE && isCurrentlyOnProject)) && workspaceMigrationInfo) {
            const shouldDialogBeReset = shouldWorkspaceDialogBeReset(workspaceMigrationInfo);

            if (shouldDialogBeReset && isTodayBefore19thFeb) {
                dispatch(toggleWorkspaceMigrationDialog(true));
            } else {
                dispatch(toggleWorkspaceMigrationDialog(!workspaceMigrationInfo.seen));
            }
        }
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AGENCY_MESSAGES.FETCH_HOME_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchUnassignedHomeInformationWithToken = (agencyId, currentUser, displayMode, idtoken, isTermsAndConditions) => async dispatch => {

    try {
        const { uid: userId, displayName } = currentUser;
        const userData = { userId, role: displayMode };
        const isLogin = true;

        const {
            isTermsAndConditionsAccepted,
            profileCreationSteps,
        } = await tokenPropService.fetchUnassignedHomeInformationWithToken(agencyId, userData, idtoken, isLogin);

        isTermsAndConditions = isTermsAndConditions || isTermsAndConditionsAccepted;

        dispatch(fetchUserInformationSuccess({ user: { displayName, uid: userId }, agencyId, displayMode }));
        dispatch(fetchTermsAndConditionsSucces({ isTermsAndConditionsAccepted }))

        if (displayMode === ROLES.UNASSIGNED_ADMIN) {
            dispatch(fetchProfileCreationStepsSuccess(profileCreationSteps));
        }

    } catch (error) {
        dispatch(authorizedShowNotification(error)(AGENCY_MESSAGES.FETCH_HOME_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const refetchHomeInformationWithToken = () => async dispatch => {
    const currentUser = auth.currentUser;

    if (currentUser) {
        const idtoken = await currentUser.getIdToken();

        let agencyId;
        let roles;

        await currentUser.getIdTokenResult().then(idtokenResult => {
            agencyId = idtokenResult.claims.agencyId;
            roles = idtokenResult.claims.roles;
        });

        if (roles.includes(ROLES.UNASSIGNED_ADMIN)) {
            await dispatch(fetchUnassignedHomeInformationWithToken(agencyId, currentUser.toJSON(), roles[0], idtoken, true));
        } else {
            await dispatch(fetchHomeInformationWithToken(agencyId, currentUser.toJSON(), roles[0], idtoken, true));
        }
    }
};
