import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useLocation, useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getStateAgencyName } from 'utils/helpers';
import { Calendar, momentLocalizer, Views, Navigate } from "react-big-calendar";

import moment from "moment";

import {
    mapDispatchToPropsPicker,
    getBrowserLocation
} from 'components/TeamHolidays/TeamHolidaysUtils';

import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import ActiveHolidaysWeekView from './ActiveHolidaysWeekView';
import ActiveHolidaysToolbar from './ActiveHolidaysToolbar';
import ActiveHolidaysEvent from './ActiveHolidaysEvent/ActiveHolidaysEvent';
import ActiveHolidaysLegend from './ActiveHolidaysLegend';

import { fetchProjectEmployeesApprovedHolidays } from 'actions/clientActions';
import { getAgencyId, getDisplayMode, getHolidays } from 'reducers';

import { ROLES } from 'constants/userConstants';
import { MONTH_DAY_DATE_FORMAT, DATE_FORMAT_WITH_DASH } from 'constants/commonConstants';
import { DAYS_PERIOD } from './ActiveHolidaysConstants';

import './ActiveHolidays.scss';

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const ActiveHolidays = ({
    agencyId,
    userId,
    holidays,
    fetchApprovedHolidays,
    fetchSickLeaves,
    publicHolidays,
    displayMode,
    fetchProjectEmployeesApprovedHolidays,
    clientId,
    projectId,
    dataFetchState,
}) => {
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();

    const [currentDate, setCurrentDate] = useState(moment().format());
    const firstRender = useRef(true);

    
    useEffect(() => {
        if (!firstRender.current) {
            const from = moment(currentDate).format(DATE_FORMAT_WITH_DASH);
            const to = moment(currentDate).add(14, 'days').format(DATE_FORMAT_WITH_DASH);
            clientId
                ? fetchProjectEmployeesApprovedHolidays(agencyId, clientId, projectId, from, to)
                : fetchApprovedHolidays(agencyId, from, to, userId);

            !clientId && fetchSickLeaves(agencyId, from, to, userId);
        } else {
            firstRender.current = false;
        }
    }, [currentDate]);

    const customDayPropGetter = date => {
        let className = '';
        if (
            (moment(date).day() === 0 || moment(date).day() === 6)
            || publicHolidays?.includes(moment(date).format(DATE_FORMAT_WITH_DASH))) {
            className = 'weekend';
        }
        return { className };
    };

    const handleEmployeeBoxClick = (employeeId) => {
        const teamHolidaysPath = `/${getStateAgencyName()}/team/holidays`;

        if (displayMode === ROLES.ADMIN && teamHolidaysPath === location.pathname) {
            history.push(`/${getStateAgencyName()}/team/people/${employeeId}/holidays`);
        }
    }

    const onNavigate = (action) => {
        if (action === Navigate.NEXT) {
            setCurrentDate(moment(currentDate).add(DAYS_PERIOD, 'days'));
        } else if (action === Navigate.PREVIOUS) {
            setCurrentDate(moment(currentDate).subtract(DAYS_PERIOD, 'days').format());
        }
    }

    return (
        <div className='active-holidays-container' >
            {displayMode === ROLES.EMPLOYEE || displayMode === ROLES.SUPPLIER_EMPLOYEE
                ? <div>
                    <SectionActionsWrapper>
                        <SectionActionsWrapper.SectionTitle
                            sectionTitle={intl.formatMessage({ id: "active-holidays-section-title" })}
                        />
                        <ActiveHolidaysToolbar
                            label={`${moment(currentDate).format(MONTH_DAY_DATE_FORMAT)} - ${moment(currentDate).add(DAYS_PERIOD, 'days').format(MONTH_DAY_DATE_FORMAT)}`}
                            onNavigate={onNavigate}
                        />
                    </SectionActionsWrapper>
                    {dataFetchState?.isDataFetching && holidays.length === 0 && <CircularProgress thickness={5} size={25} disableShrink className="active-holidays-loader" />}

                    <Paper className="active-holidays-chart-wrapper">
                        <Calendar
                            selectable
                            localizer={localizer}
                            events={holidays}
                            defaultView={Views.WEEK}
                            views={{
                                week: ActiveHolidaysWeekView
                            }}
                            date={moment(currentDate)}
                            components={{
                                toolbar: Fragment,
                                event: (props) => <ActiveHolidaysEvent {...props} handleEmployeeBoxClick={handleEmployeeBoxClick} />,
                                week: {
                                    header: ({ date, localizer }) => <div className="weekdays">{localizer.format(date, 'dd DD')}</div>
                                }
                            }}
                            dayPropGetter={customDayPropGetter}
                        />
                        <ActiveHolidaysLegend />
                    </Paper>
                </div>
                : <div>
                    <SectionActionsWrapper>
                        <SectionActionsWrapper.SectionTitle
                            sectionTitle={intl.formatMessage({ id: "active-holidays-section-title" })}
                        />
                        <ActiveHolidaysToolbar
                            label={`${moment(currentDate).format(MONTH_DAY_DATE_FORMAT)} - ${moment(currentDate).add(DAYS_PERIOD, 'days').format(MONTH_DAY_DATE_FORMAT)}`}
                            onNavigate={onNavigate}
                        />
                    </SectionActionsWrapper>

                    <Paper className="active-holidays-chart-wrapper">
                        {dataFetchState?.isDataFetching && <CircularProgress thickness={5} size={25} disableShrink className="active-holidays-loader" />}
                        <Calendar
                            selectable
                            localizer={localizer}
                            events={holidays}
                            defaultView={Views.WEEK}
                            views={{
                                week: ActiveHolidaysWeekView
                            }}
                            date={moment(currentDate)}
                            components={{
                                toolbar: Fragment,
                                event: (props) => <ActiveHolidaysEvent {...props} handleEmployeeBoxClick={handleEmployeeBoxClick} />,
                                week: {
                                    header: ({ date, localizer }) => <div className="weekdays">{localizer.format(date, 'dd DD')}</div>
                                }
                            }}
                            dayPropGetter={customDayPropGetter}
                        />
                        <ActiveHolidaysLegend />
                    </Paper>
                </div>
            }

        </div>
    )
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const browserLocation = getBrowserLocation(ownProps.history)
    const dispatchToPropsPicker = mapDispatchToPropsPicker(browserLocation);

    return {
        fetchApprovedHolidays: (...args) => dispatch(dispatchToPropsPicker.fetchApprovedHolidays(...args)),
        fetchSickLeaves: (...args) => dispatch(dispatchToPropsPicker.fetchSickLeaves(...args)),
        fetchProjectEmployeesApprovedHolidays: (...args) => dispatch(fetchProjectEmployeesApprovedHolidays(...args)),
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
        holidays: getHolidays(state, ownProps.history.location.pathname),
        agencyId: getAgencyId(state),
        displayMode: getDisplayMode(state),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActiveHolidays));
