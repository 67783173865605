import { ReactComponent as BgIcon } from 'assets/bg-icon.svg';
import { ReactComponent as ItIcon } from 'assets/it-icon.svg';
import { ReactComponent as GrIcon } from 'assets/gr-icon.svg';
import { ReactComponent as UkIcon } from 'assets/uk-icon.svg';

export const COUNTRIES = {
    BG: {
        code: '+359',
        icon: <BgIcon />
    },
    IT: {
        code: '+39',
        icon: <ItIcon />
    },
    GR: {
        code: '+49',
        icon: <GrIcon />
    },
    UK: {
        code: '+44',
        icon: <UkIcon />
    },
};
