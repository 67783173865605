import moment from 'moment';

export const areAllDatesInThePast = (dates) => {
    for (const date of dates) {
        if (moment(date).isAfter(moment())) {
            return false;
        }
    }

    return true;
};

export const workDaysBetweenTwoDates = (firstDate, secondDate) => {
    const startDate = moment(firstDate);
    const endDate = moment(secondDate);

    let workdays = 0;

    // Calculate the difference in workdays between startDate and endDate
    for (let date = startDate.clone(); date.isSameOrBefore(endDate, 'day'); date.add(1, 'day')) {
        if (date.weekday() >= 1 && date.weekday() <= 5) { // Monday to Friday (1 to 5)
            workdays++;
        }
    }

    return workdays;
}
