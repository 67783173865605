import React from 'react';
import { useIntl } from 'react-intl';

import moment from 'moment';
import { ROLES } from 'constants/userConstants';

import { ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Typography } from '@material-ui/core';

import UploadFiles from 'components/Shared/UploadFiles';

import isHoliday from 'hoc/isHoliday';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR, MAX_DATE } from 'constants/commonConstants';
import { CURRENCIES } from 'constants/currenciesConstant';

import './AddInvoiceFormView.scss';

const ACCEPTED_FORMATS = ['pdf', 'xls', 'xlsx'];

const AddInvoiceFormView = ({
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    projects,
    isClientInvoice,
    hiddenSections,
    displayMode,
}) => {
    const intl = useIntl();
    const hasProjects = projects.length > 0;

    return (
        <form className="add-invoice-form" autoComplete="off">
            <div className="invoice-fields-container">
                <div className="invoice-field">
                    <TextField
                        select
                        name="projectId"
                        label={intl.formatMessage({ id: "select-project" })}
                        value={values.projectId}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={errors.projectId && touched.projectId}
                        disabled={!hasProjects && displayMode === ROLES.SUPPLIER_ADMIN}
                    >
                        {projects.map((project, i) => (
                            <MenuItem key={i} value={project._id}>
                                {project.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <ErrorMessage name="projectId" component="div" className="invalid-field-message" />
                    {!hasProjects && displayMode === ROLES.SUPPLIER_ADMIN && (
                        <Typography className="info-message">
                            You will be able to select an active invoicing project and create an invoice once there is
                            an allocated resource on a project.
                        </Typography>
                    )}
                </div>

                <div className="invoice-field">
                    <KeyboardDatePicker
                        autoOk={true}
                        label={intl.formatMessage({ id: "period" })}
                        name="period"
                        views={['year', 'month']}
                        openTo="month"
                        variant="inline"
                        inputVariant="outlined"
                        value={values.period}
                        PopoverProps={{ className: 'invoice-dialog-period-popover' }}
                        onChange={(value) => {
                            setFieldValue('period', moment(value));
                        }}
                        fullWidth
                        allowKeyboardControl={true}
                    />
                    <ErrorMessage name="period" component="div" className="invalid-field-message" />
                </div>
            </div>
            <div className="invoice-fields-container">

                <div className="invoice-field">
                    <KeyboardDatePicker
                        autoOk={true}
                        label={intl.formatMessage({ id: "issue-date" })}
                        name="issueDate"
                        disableToolbar
                        maxDate={values.endDate || MAX_DATE}
                        variant="inline"
                        inputVariant="outlined"
                        value={values.issueDate}
                        onChange={(value) => {
                            setFieldValue('issueDate', moment(value));
                        }}
                        renderDay={isHoliday}
                        fullWidth
                        format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                        allowKeyboardControl={true}
                    />
                    <ErrorMessage name="issueDate" component="div" className="invalid-field-message" />
                </div>

                <div className="invoice-field">
                    <KeyboardDatePicker
                        autoOk={true}
                        label={intl.formatMessage({ id: "due-date" })}
                        name="dueDate"
                        disableToolbar
                        minDate={values.issueDate || new Date()}
                        variant="inline"
                        inputVariant="outlined"
                        value={values.dueDate}
                        onChange={(value) => {
                            setFieldValue('dueDate', moment(value));
                        }}
                        renderDay={isHoliday}
                        fullWidth
                        format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                        allowKeyboardControl={true}
                    />
                    <ErrorMessage name="dueDate" component="div" className="invalid-field-message" />
                </div>
            </div>

            <div className="invoice-fields-container">
                <div className="invoice-field">
                    <TextField
                        name="amount"
                        value={values.amount}
                        onChange={handleChange}
                        label={intl.formatMessage({ id: "amount" })}
                        error={errors.amount && touched.amount}
                        variant="outlined"
                        fullWidth
                        type="number"
                    />
                    <ErrorMessage name="amount" component="div" className="invalid-field-message" />
                </div>

                <div className="invoice-field">
                    <TextField
                        select
                        label={intl.formatMessage({ id: "currency" })}
                        value={values.currency}
                        variant="outlined"
                        onChange={handleChange}
                        name="currency"
                        id="currency"
                        fullWidth={true}
                    >
                        {CURRENCIES.map((currency) => {
                            return (
                                <MenuItem key={currency.VALUE} value={currency.LABEL}>
                                    {currency.LABEL}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                    <ErrorMessage name="currency" component="div" className="invalid-field-message" />
                </div>
            </div>

            <div className="invoice-field-full">
                <TextField
                    name="invoiceNumber"
                    value={values.invoiceNumber}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "invoice-id" })}
                    error={errors.invoiceNumber && touched.invoiceNumber}
                    variant="outlined"
                    fullWidth
                    type="text"
                />
                <ErrorMessage name="invoiceNumber" component="div" className="invalid-field-message" />
            </div>

            <div className="invoice-field-full">
                <TextField
                    name="emailsTo"
                    onChange={handleChange}
                    value={values.emailsTo}
                    label={intl.formatMessage({ id: "emails-of-recipients-optional" })}
                    error={errors.emailsTo && touched.emailsTo}
                    variant="outlined"
                    fullWidth
                    disabled={
                        (hiddenSections && displayMode === ROLES.SUPPLIER_ADMIN ? false : !isClientInvoice) ||
                        (displayMode === ROLES.SUPPLIER_ADMIN && !hasProjects)
                    }
                    helperText={isClientInvoice && intl.formatMessage({ id: "email-addresses-helper-text" })}
                />
                <ErrorMessage name="emailsTo" component="div" className="invalid-field-message" />
                {displayMode === ROLES.SUPPLIER_ADMIN && !hasProjects && (
                    <Typography className="info-message">
                        You will be able to add an email of recipient once there is allocated resource to a project.
                    </Typography>
                )}
            </div>

            <div className="invoice-field-full">
                <TextField
                    name="emailsCc"
                    value={values.emailsCc}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "cc-emails" })}
                    error={errors.emailsCc && touched.emailsCc}
                    variant="outlined"
                    fullWidth
                    helperText={intl.formatMessage({ id: "email-addresses-helper-text" })}
                />
                <ErrorMessage name="emailsCc" component="div" className="invalid-field-message" />
            </div>

            <div className="invoice-field-full">
                <TextField
                    name="emailsBcc"
                    value={values.emailsBcc}
                    onChange={handleChange}
                    label={intl.formatMessage({ id: "bcc-emails" })}
                    error={errors.emailsBcc && touched.emailsBcc}
                    variant="outlined"
                    fullWidth
                    helperText={intl.formatMessage({ id: "email-addresses-helper-text" })}
                />
                <ErrorMessage name="emailsBcc" component="div" className="invalid-field-message" />
            </div>

            <div className="files-attached">
                <UploadFiles
                    name="filesAttached"
                    filesAttached={values.filesAttached}
                    acceptedFormats={ACCEPTED_FORMATS}
                    setFieldValue={setFieldValue}
                    titleText={intl.formatMessage({ id: "attach-invoice" })}
                    isFormReinitialized={true}
                />
                <ErrorMessage name="filesAttached" component="div" className="invalid-field-message" />
            </div>
        </form>
    );
};

export default AddInvoiceFormView;
