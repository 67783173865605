import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    getCandidateApplications,
    getUpcomingInterviewsList,
    getCandidateApplicationsDataFetchState,
    getCandidateInterviewInvitationsDataFetchState,
    getApplicationProcessInformationFetchState,
} from 'reducers';

import { fetchCandidateInterviewInvitations } from 'actions/interviewInvitationActions';
import { fetchCandidateApplications } from 'actions/jobApplicationActions';
import { fetchCandidateApplicationProcessInformation } from 'actions/candidateActions';

import ApplicationProcessBanner from 'components/Shared/ApplicationProcessBanner';
import MyApplicationsList from './MyApplicationsList';
import MyUpcomingInterviewsList from './MyUpcomingInterviewsList';
import CircularProgress from '@material-ui/core/CircularProgress';

import './MyApplications.scss';

const MyApplications = ({
    userId,
    agencyId,
    fetchCandidateApplications,
    fetchCandidateInterviewInvitations,
    fetchCandidateApplicationProcessInformation,
    applications,
    upcomingInterviews,
    applicationsDataFetchState,
    interviewInvitationsDataFetchState,
    applicationProcessInformationFetchState
}) => {
    useEffect(() => {
        fetchCandidateApplications(agencyId, userId);
        fetchCandidateInterviewInvitations(agencyId, userId);
        fetchCandidateApplicationProcessInformation(agencyId, userId);
    }, []);

    return (
        <div className="my-applications-wrapper">
            {(applicationsDataFetchState.isDataFetching && !applicationsDataFetchState.isDataFetched) 
            || (interviewInvitationsDataFetchState.isDataFetching && !interviewInvitationsDataFetchState.isDataFetched)
            || (applicationProcessInformationFetchState.isDataFetching && !applicationProcessInformationFetchState.isDataFetched)
                ? <CircularProgress thickness={5} size={50} className="page-loader" />
                : <>
                    <ApplicationProcessBanner />
                    <MyApplicationsList applications={applications} />
                    {upcomingInterviews?.length > 0 && <MyUpcomingInterviewsList upcomingInterviews={upcomingInterviews} />}
                </>
            }
        </div>
    )
};

const mapStateToProps = (state) => ({
    applications: getCandidateApplications(state).list,
    upcomingInterviews: getUpcomingInterviewsList(state),
    applicationsDataFetchState: getCandidateApplicationsDataFetchState(state),
    interviewInvitationsDataFetchState: getCandidateInterviewInvitationsDataFetchState(state),
    applicationProcessInformationFetchState: getApplicationProcessInformationFetchState(state),
});

const mapDispatchToProps = {
    fetchCandidateApplications,
    fetchCandidateInterviewInvitations,
    fetchCandidateApplicationProcessInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyApplications);
