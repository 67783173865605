import { JOB_TYPES } from "../YourProfileConstants";

export const getSelectionCardSubTitle = candidateJobType => {
    switch (candidateJobType) {
        case JOB_TYPES.FULL_STACK_DEVELOPER:
        case JOB_TYPES.FRONT_END_DEVELOPER:
        case JOB_TYPES.BACK_END_DEVELOPER:
        case JOB_TYPES.MOBILE_DEVELOPER:
            return 'How many years of professional experience do you have as a Software Engineer?';
        case JOB_TYPES.OTHER:
            return 'How many years of professional experience do you have in your field?'
        default:
            return `How many years of professional experience do you have as a ${candidateJobType}?`;
    }
};
