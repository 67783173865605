import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import { getProgressBarProperties } from './ApplicationStatusBannerUtils';

import { APPLICATION_STATUS_TO_STEP_MAP, STEP_TO_TITLE_MAP } from './ApplicationStatusBannerConstants';

import { ReactComponent as ApplicationBannerIcon } from 'assets/conversation-illustration.svg';

import './ApplicationStatusBanner.scss';

const ApplicationStatusBanner = ({
    status,
    isInterviewPending,
    isInterviewConfirmed,
}) => {
    const step = APPLICATION_STATUS_TO_STEP_MAP[status](isInterviewPending, isInterviewConfirmed);

    return (
        <div className="application-status-banner-container">
            <ApplicationBannerIcon />
            <p className="status-text heading-xs color-dark-grey">{STEP_TO_TITLE_MAP[step]}</p>
            <div className="steps-list-container">
                {getProgressBarProperties(step).map((properties, idx) => <LinearProgress key={idx} {...properties} />)}
            </div>
        </div>
    )
};

export default ApplicationStatusBanner;
