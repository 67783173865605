import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ActionButton from '../Buttons/ActionButton';

import { BASE_APP_TRADEMARK } from 'constants/env';

import './ProfileCreationCard.scss';

const ProfileCreationCard = ({ children, className }) => {
    return (
        <Grid
            container
            className={`profile-creation-card${className ? ` ${className}` : ''}`}
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            {children}
        </Grid>
    );
};

const Header = () => {
    return (
        <div className="profile-creation-card-header">
            <div className="logo-wrapper-white">
                {BASE_APP_TRADEMARK.LOGO_WHITE}
            </div>
            <div className="logo-wrapper">
                {BASE_APP_TRADEMARK.LOGO}
            </div>
        </div>
    );
};

const ContentWrapper = ({ children }) => {
    return (
        <Paper
            square
            className="profile-creation-card-content-wrapper"
        >
            {children}
        </Paper>
    );
};

const ContentTitle = ({ children, className }) => {
    return (
        <Typography className={`profile-creation-card-content-title${className ? ` ${className}` : ''}`} variant="h1">{children}</Typography>
    );
};

const ContentDivider = () => <Divider className="profile-creation-card-content-divider" />

const ContentBody = ({ children, className }) => {
    return (
        <div className={`profile-creation-card-content-body${className ? ` ${className}` : ''}`}>{children}</div>
    );
};

const ContentAction = ({ text, handleClick }) => {
    return (
        <div className="profile-creation-card-content-action-wrapper">
            <ActionButton
                type="submit"
                text={text}
                handleClick={handleClick}
            />
        </div>
    );
};

ProfileCreationCard.Header = Header;
ProfileCreationCard.ContentWrapper = ContentWrapper;
ProfileCreationCard.ContentTitle = ContentTitle;
ProfileCreationCard.ContentDivider = ContentDivider;
ProfileCreationCard.ContentBody = ContentBody;
ProfileCreationCard.ContentAction = ContentAction;

export default ProfileCreationCard;
