import { ROLES } from 'constants/userConstants';

const ROLE_OPTIONS = [
    {
        title: 'Find a remote job',
        value: ROLES.CANDIDATE,
    },
    {
        title: 'Build a remote team',
        value: ROLES.UNASSIGNED_CLIENT,
    },
];

export const ROLE_SELECTION_OPTIONS = {
    test: ROLE_OPTIONS,
    development: ROLE_OPTIONS,
    qa: ROLE_OPTIONS,
    production: ROLE_OPTIONS,
    whoisavailable: ROLE_OPTIONS,
    staging: ROLE_OPTIONS,
};
