import React from 'react';

import FormInputField from 'components/Shared/FormInputField';
import FormValidationRequirement from 'components/Shared/FormValidationRequirement';
import FormControlCheckbox from 'components/Shared/FormControlCheckbox';
import ActionButton from 'components/Shared/Buttons/ActionButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { motionRedirectLinks } from 'components/Register/RegisterConstants';
import { PASSWORD_VALIDATION_MESSAGES } from 'constants/commonConstants';

import { getInviterInfoText } from '../PasswordSetUtils';

const PasswordSetFormView = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    role,
    inviterInfo,
    loginLoading,
}) => {
    const isPasswordRequirementMet = !errors.password && values.password;
    const isPasswordRequirementNotMet = errors.password && touched.password;
    const isConfirmPasswordRequirementNotMet = errors.confirmPassword && touched.confirmPassword;

    const isTCChecked = values.isTermsAndConditionsAccepted;
    const hasTCError = errors.isTermsAndConditionsAccepted;
    const isTCFieldTouched = touched.isTermsAndConditionsAccepted;

    return (
        <form autoComplete="off">
            {inviterInfo &&
                <Typography className="inviter-info">
                    {getInviterInfoText(role, inviterInfo?.name, inviterInfo?.agencyName, inviterInfo?.companyName)}
                </Typography>
            }

            <FormInputField
                className="smaller"
                placeholder="Password"
                name="password"
                value={values.password}
                isRequirementNotMet={isPasswordRequirementNotMet}
                handleChange={handleChange}
                handleBlur={handleBlur}
                withPasswordVisibility
            />
            <FormInputField
                className="smaller"
                placeholder="Confirm Password"
                name="confirmPassword"
                value={values.confirmPassword}
                isRequirementNotMet={isConfirmPasswordRequirementNotMet}
                handleChange={handleChange}
                handleBlur={handleBlur}
                withPasswordVisibility
            />

            <FormValidationRequirement
                requirementText={PASSWORD_VALIDATION_MESSAGES.ALL}
                isRequirementMet={isPasswordRequirementMet}
                isRequirementNotMet={isPasswordRequirementNotMet}
            />

            {(isPasswordRequirementNotMet || isConfirmPasswordRequirementNotMet) &&
                <FormValidationRequirement
                    requirementText={errors.confirmPassword || errors.password}
                    isRequirementNotMet={isPasswordRequirementNotMet || isConfirmPasswordRequirementNotMet}
                />
            }

            <FormControlCheckbox
                isTouched={isTCFieldTouched}
                hasError={hasTCError}
                name="isTermsAndConditionsAccepted"
                label={
                    <span>
                        I agree to the platform's <a href={motionRedirectLinks.privacy.link} target="_blank">{motionRedirectLinks.privacy.label}.</a>
                    </span>
                }
                checked={isTCChecked}
                handleChange={() => setFieldValue('isTermsAndConditionsAccepted', !isTCChecked)}
            />

            <div className="buttons-wrapper">
                <ActionButton
                    text={!loginLoading ? <CircularProgress thickness={5} className="white" /> : "Sign Up"}
                    handleClick={handleSubmit}
                />
            </div>
        </form>
    );
};

export default PasswordSetFormView;
