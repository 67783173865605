
import { createTheme } from '@material-ui/core/styles';

import styleConstants from 'style-constants.scss';

const themeCreator = () => {
    return createTheme({
        components: {
            MuiButton: {
                defaultProps: {
                    disableElevation: true
                }
            }
        },
        typography: {
            fontFamily: ['"Urbanist"', 'sans-serif'].join(','),
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    body: {
                        backgroundColor: styleConstants.appBackground,
                    },
                },
            },
        },
    });
}

export const theme = themeCreator();
