import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/performance';

import authService from 'services/authService';

import { FIREBASE_CONFIG } from 'constants/env';
import { REGISTRATION_TYPES, REFERRALS, ROLES } from 'constants/userConstants';

// Initialize Firebase
firebase.initializeApp(FIREBASE_CONFIG);

export default firebase;

export const auth = firebase.auth();
export const performance = firebase.performance();

const provider = new firebase.auth.GoogleAuthProvider();

provider.setCustomParameters({
    prompt: "select_account",
});

// parameters are not required on login
export const signInWithPopup = (jobOpportunityId, gotoUrl, referrer, campaignId, role, isLogin) => auth.signInWithPopup(provider).then(async (response) => {
    let userResponse;
    const isNew = response.additionalUserInfo.isNewUser;

    // if the user tries to login with unexsisting account, create an account with unassigned role
    if (isLogin && isNew) {
        const userData = {
            uid: response.user.uid,
            name: response.user.displayName,
            jobOpportunityId,
            referrer: campaignId && (!referrer || referrer === REFERRALS.WEBSITE_ORGANIC) ? REFERRALS.GOOGLE : referrer,
            campaignId,
            googleProfilePictureImgUrl: response.additionalUserInfo.profile?.picture,
        }

        userResponse = await authService.registerUnassigned(userData);
    } else if (isNew) {
        // register only if the user has not been registered yet
        const userData = {
            uid: response.user.uid,
            campaignId,
            email: response.user.email,
            gotoUrl,
            isTermsAndConditionsAccepted: true,
            jobOpportunityId,
            name: response.user.displayName,
            referrer: campaignId && (!referrer || referrer === REFERRALS.WEBSITE_ORGANIC) ? REFERRALS.GOOGLE : referrer,
            registrationType: REGISTRATION_TYPES.SELF_REGISTERED,
            googleProfilePictureImgUrl: response.additionalUserInfo.profile?.picture,
            role,
        }

        userResponse = await authService.registerCandidate(userData, true);
    } else {
        userResponse = {
            email: response.user.email,
        };
    }


    return {
        ...userResponse,
        credential: response.credential,
        isNew,
    }
});

export const signInAgencyWithPopup = () => auth.signInWithPopup(provider).then(async (response) => {
    let userResponse;
    const isNew = response.additionalUserInfo.isNewUser;

    // if the user tries to login with unexsisting account, create an account with unassigned role
    if (isNew) {
        const isSSO = true;
        const userData = {
            uid: response.user.uid,
            name: response.user.displayName,
            googleProfilePictureImgUrl: response.additionalUserInfo.profile?.picture,
        }

        userResponse = await authService.registerAsUnassignedAdmin(userData, isSSO);
    } else {
        userResponse = {
            email: response.user.email,
        };
    }

    return {
        ...userResponse,
        credential: response.credential,
        isNew,
    }
});

export const signInAgencyCandidateOrClientWithPopup = (agencyId, role) => auth.signInWithPopup(provider).then(async (response) => {
    let userResponse;
    const isNew = response.additionalUserInfo.isNewUser;

    if (isNew) {
        const isSSO = true;
        const userData = {
            uid: response.user.uid,
            email: response.user.email,
            name: response.user.displayName,
            registrationType: REGISTRATION_TYPES.SELF_REGISTERED,
            googleProfilePictureImgUrl: response.additionalUserInfo.profile?.picture,
            role,
            agencyId,
        }

        userResponse = role === ROLES.CANDIDATE ? await authService.registerCandidate(userData, isSSO) : await authService.registerClient(userData, isSSO);
    } else {
        userResponse = {
            email: response.user.email,
        };
    }

    return {
        ...userResponse,
        credential: response.credential,
        isNew,
    }
});

export const signInWithRedirect = () => auth.signInWithRedirect(provider).then(res => console.log(res));
