import React from 'react';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import SelectTechStack from 'components/CandidateProfileJourney/Steps/UploadResumeWrapper/DialogsCV/SelectTechStack';

import './TechStackDialog.scss';

const TechStackDialog = ({
    setFieldValue,
    handleSubmit,
    values,
    onClose,
    open,
    handleAddTechnology,
    handleRemoveTechnology,
}) => {
    const onFormSubmit = () => {
        handleSubmit();
    };

    const handleOnInputChangeTechnologies = (values, newValue) => {
        setFieldValue('technologies', [...values.technologies, newValue.title]);
    };

    return (
        <CommonFormikDialog
            className="tech-stack-dialog"
            open={open}
            onClose={onClose}
            dialogTitle="Edit Tech Stack"
            saveBtnText="Save"
            discardBtnText="Discard"
            onSave={onFormSubmit}
        >
            <form className="tech-stack-dialog-form" autoComplete="off">
                <div className="form-row">
                    <SelectTechStack
                        label="Stack"
                        handleInputChange={handleOnInputChangeTechnologies.bind(null, values)}
                        handleOnDeleteSkill={handleRemoveTechnology.bind(null, values, setFieldValue)}
                        addNewSkill={handleAddTechnology.bind(null, values, setFieldValue)}
                        technologies={values.technologies}
                    />
                </div>
            </form>
        </CommonFormikDialog>
    )
}

export default TechStackDialog;
