import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { updateCandidatesJourneyCompletedSteps } from 'actions/candidateActions';
import { getAgencyId, getCurrentJourneyStepId, getInitialJourneyCompletedStepsIds, getUserId, getAgencyRouteName } from 'reducers';

import { ReactComponent as FinishProfileImage } from 'assets/finish-profile.svg';
// import { ReactComponent as ExploreJobsImage } from 'assets/explore-jobs.svg';
import { ReactComponent as FinishProfileImageMobile } from 'assets/finish-profile-mobile.svg';
// import { ReactComponent as ExploreJobsImageMobile } from 'assets/explore-jobs-mobile.svg';

import { logEvent } from 'utils/amplitude';
import { parseQueryString } from 'utils/helpers';

import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { CALENDLY_DIALOG_STATES, substepIds } from 'components/CandidateProfileJourney/CandidateProfileJourneyConstants';

import './CandidatesJourneyNearlyThere.scss';

const Option = ({ imageComponent, text, buttonText, btnOnClick, heading, className }) => {
    return (
        <div className={`cjnt-option ${className ? className : ''}`}>
            <div className="option-image-wrapper">{imageComponent}</div>
            <Typography className="cjnt-option-heading subheading-xxl color-dark-grey">{heading}</Typography>
            <Typography className="cjnt-option-text subheading-m color-grey">{text}</Typography>
            <div className="btn-wrapper">
                <PrimaryButton className="purple" text={buttonText} handleClick={btnOnClick} />
            </div>
        </div>
    );
};

const CandidatesJourneyNearlyThere = ({
    handleGoToNextStep,
    stepId,
    completedStepsIds,
    updateCandidatesJourneyCompletedSteps,
    userId,
    agencyId,
}) => {
    const history = useHistory();
    const condition = !completedStepsIds.includes(stepId);
    const action = updateCandidatesJourneyCompletedSteps.bind(null, agencyId, userId);

    useEffect(() => {
        const queryString = history.location.search;
        const params = parseQueryString(queryString);
        const isChoiceSectionStepCompleted = completedStepsIds.includes(substepIds.choiseSection);

        if (params.calendlyDialogState === CALENDLY_DIALOG_STATES.OPEN && !isChoiceSectionStepCompleted) {
            goToNextStep();
        }

    }, [history.location.search]);

    const goToNextStep = () => {
        logEvent(AMPLITUDE_EVENT_TYPES.CANDIDATE_CLICK_FINISH_PROFILE);
        handleGoToNextStep(null, condition, action);
    }

    return (
        <div className="candidates-nearly-there-wrapper">
            <div className="candidates-nearly-there-container">
                <div className="text-container">
                    <Typography className="text-container-heading heading-m color-dark-grey">You're nearly there!</Typography>
                    <Typography className="subheading-xl color-grey">
                        Thanks for completing your profile! Before moving forward, we'd like to go the extra mile for knowing you. Book a call with our team!
                    </Typography>
                </div>
                <div className="cjnt-options-container">
                    <Option
                        className={'finish-profile-option'}
                        imageComponent={isMobile ? <FinishProfileImageMobile /> : <FinishProfileImage />}
                        buttonText={'Continue'}
                        btnOnClick={goToNextStep}
                        heading={'Finish Profile'}
                        text={`Thanks for completing your profile! Before moving forward, we'd like to go the extra mile for knowing you. Book a call with our team!`}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    stepId: getCurrentJourneyStepId(state),
    completedStepsIds: getInitialJourneyCompletedStepsIds(state),
    agencyId: getAgencyId(state),
    userId: getUserId(state),
    agencyRouteName: getAgencyRouteName(state),
});

const mapDispatchToProps = {
    updateCandidatesJourneyCompletedSteps,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidatesJourneyNearlyThere);
