export const SPECIALITY_ITEMS = [
    'Developer',
    'Full-stack Developer',
    'Backend Developer',
    'Frontend Developer',
    'Designer',
    'Product Manager',
    'QA Engineer',
    'DevOps Engineer',
    'Data Engineer',
    'Data Analyst',
    'Architect',
    'Team Lead',
    'Other'
];

export const LANGUAGES_ITEMS = ['English', 'French', 'Spanish', 'German'];

export const LOCATIONS_ITEMS = ['Bulgaria', 'Romania', 'Turkey', 'Ukraine'];

export const PROFILE_TYPES = ['Employee', 'Candidate', 'Partner'];

export const EXPERIENCE_ITEMS = ['< 1 year', '1 - 3 years', '3 - 5 years', '5 + years'];
export const FIVE_YEARS_PLUS_EXPERIENCE = '5+ years';

export const VETTED_SINCE_ITEMS = [
    { value: '99', name: 'Anytime' },
    { value: '1', name: '1 week ago' },
    { value: '2', name: '2 weeks ago' },
    { value: '4', name: '4 weeks ago' },
];

export const VETTED_SINCE_ANY_TIME = VETTED_SINCE_ITEMS[0].value

export const defaultSearchTerm = '';
export const defaultSpecialities = [];
export const defaultProfiles = [];
export const defaultExperience = [];
export const defaultLanguages = "";
export const defaultLocations = "";
export const defaultAvailability = false;
export const defaultShowVettedOnly = false;
export const defaultSearchInFullCV = false;
export const defaultMandatorySkills = [];
export const defaultPreferredStack = [];
export const defaultVettedSince = VETTED_SINCE_ITEMS[0];
export const defaultMaximumSalary = "";

export const initialFilters = {
    speciality: defaultSpecialities,
    profileType: defaultProfiles,
    experience: defaultExperience,
    languages: defaultLanguages,
    location: defaultLocations,
    mandatorySkills: defaultMandatorySkills,
    preferredTechStack: defaultPreferredStack,
    searchTerm: defaultSearchTerm,
    availableOnly: defaultShowVettedOnly,
    vettedOnly: defaultShowVettedOnly,
    vettedSince: defaultVettedSince,
    maximumSalary: defaultMaximumSalary,
    searchInFullCV: defaultSearchInFullCV,
};

export const DEFAULT_PAGE_VALUE = 0;

export const DEFAULT_SEARCH_QUERY = '?profileType=Candidate,Partner,Employee'
