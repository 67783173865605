import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import {
    getMatchingConsultants,
} from 'reducers';

import { updateQuery, parseQueryString } from 'utils/helpers';
import { mapQueryToFilters } from './MatchingConsultantsUtils';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import MatchingConsultantsRedux from './MatchingConsultantsRedux';
import { CircularProgress } from '@material-ui/core';
import SendProfilesDialog from 'components/Shared/SendProfilesDialog';

import { MATCHING_CONSULTANTS_FILTER_LIST_ITEMS, NOT_UNSELECTABLE, MULTISELECT_GROUPS, FILTER_DEFAULT_STATE } from 'constants/matchingConsultantsConstants';

import './MatchingConsultants.scss';

const MatchingConsultants = ({
    job,
    dataFetchState,
}) => {
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();

    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [isSendProfilesDialogOpen, setIsSendProfilesDialogOpen] = useState(false);

    const [filters, setFilters] = useState(FILTER_DEFAULT_STATE);

    useEffect(() => {
        const queryString = history.location.search;
        const parsedQuery = parseQueryString(queryString);

        if (Object.keys(parsedQuery).length > 0) {
            const newFilters = mapQueryToFilters(parsedQuery);
            setFilters({ ...newFilters });
        } else {
            setFilters(FILTER_DEFAULT_STATE);
        }
    }, [location.search]);

    const handleSendToClientClick = (employee) => {
        setSelectedEmployees([employee]);
        setIsSendProfilesDialogOpen(true);
    }

    const handleCloseSendProfilesDialog = () => {
        setIsSendProfilesDialogOpen(false);
    };

    const handleSingleSelectFilterChange = (filterName, value) => {
        let filterObj = {};
        if (MULTISELECT_GROUPS.includes(filterName)) {
            const queryString = history.location.search;
            const parsedQuery = parseQueryString(queryString);
            parsedQuery[filterName] = parsedQuery[filterName] ? parsedQuery[filterName] : [];
            parsedQuery[filterName] = typeof parsedQuery[filterName] === 'string' ? [parsedQuery[filterName]] : parsedQuery[filterName];
            filterObj = parsedQuery[filterName].includes(value)
                ? { [filterName]: parsedQuery[filterName].filter(x => x !== value) }
                : { [filterName]: [...parsedQuery[filterName], value] };
        } else if (NOT_UNSELECTABLE.includes(filterName)) {
            filterObj = { [filterName]: value };
        } else {
            filterObj = filters[filterName] !== value
                ? { [filterName]: value }
                : { [filterName]: '' };
        }

        const updatedQuery = updateQuery(history.location.search, filterObj);

        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    return (
        <div className="profile-fit-table-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle sectionTitle={intl.formatMessage({ id: "profiles-that-fit-your-search" })} />
                <SectionActionsWrapper.LeftAlignedActions>
                    <SectionActionsWrapper.LeftAlignedActions.GroupedSelectFilterListWrapper
                        title={intl.formatMessage({ id: "filter-by" })}
                        className="matching-consultants"
                        filters={filters}
                        groups={MATCHING_CONSULTANTS_FILTER_LIST_ITEMS}
                        handleChange={handleSingleSelectFilterChange}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        closeMenuOnSelect={true}
                    />
                </SectionActionsWrapper.LeftAlignedActions>
            </SectionActionsWrapper>

            {dataFetchState?.isDataFetching
                ?
                <CircularProgress thickness={5} size={25} className="matching-consultants-loader" />
                :
                <MatchingConsultantsRedux
                    dataSelector={getMatchingConsultants}
                    filters={filters}
                    handleSendToClientClick={handleSendToClientClick}
                />
            }

            {isSendProfilesDialogOpen &&
                <SendProfilesDialog
                    job={job}
                    isSelectDialogOpen={false}
                    isCommentsDialogOpen={isSendProfilesDialogOpen}
                    handleCloseDialog={handleCloseSendProfilesDialog}
                    initialSelectedEmployees={selectedEmployees}
                />
            }

        </div>
    )
};

export default MatchingConsultants;
