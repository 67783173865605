import React from 'react';
import { logEvent } from 'utils/amplitude';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import { ReactComponent as UploadCloudIcon } from 'assets/upload-cv-cloud-icon.svg';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { ROLES } from 'constants/userConstants';
import { useIntl } from 'react-intl';

const UploadCvFile = ({
    buttonRef,
    handleOnInputChange,
    inputFilesRef,
    getRootProps,
    uploads,
    setUploads,
    setInputFilesRefState,
}) => {
    const intl = useIntl();

    return (
        <div className="candidate-journey-upload-file" {...getRootProps()}>
            <input
                hidden
                type="file"
                ref={inputFilesRef}
                onChange={handleOnInputChange}
            />
            <div ref={buttonRef} className="import-pdf-container">
                <span>
                    <UploadCloudIcon className="cloud-icon" />
                </span>
                <span>{intl.formatMessage({ id: "drag-and-drop" })}</span>
                <span>{intl.formatMessage({ id: "or" })}<button
                    className='browse-button'
                    type="file"
                    onClick={() => {
                        logEvent(AMPLITUDE_EVENT_TYPES.CLICK_UPLOAD_RAW_CV, { rolesPermitted: [ROLES.ADMIN] });
                        inputFilesRef.current.click();
                        setInputFilesRefState(inputFilesRef);
                    }}
                >
                    {intl.formatMessage({ id: "browse" })}
                </button>
                </span>
            </div>
            <p className="allowed-formats-text">.doc, .docx, .pdf, .txt</p>
            {uploads?.type &&
                <div className="attached-files-container">
                    <List>
                        {Array.from(uploads.filesAttached).map((file, i) => {
                            return (
                                <ListItem key={i + file.name}>
                                    <ListItemAvatar>
                                        <DescriptionOutlinedIcon />
                                    </ListItemAvatar>
                                    <ListItemText primary={file.name} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge='end' aria-label='delete' onClick={() => setUploads({})}>
                                            <ClearIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
            }
        </div>
    );
};

export default UploadCvFile;
