import React from 'react';

import { ReactComponent as MyInterviewsIcon } from 'assets/my-interviews-icon.svg';
import { ReactComponent as MyProfileIcon } from 'assets/my-profile-icon.svg';

export const getCandidateMenuItems = agencyRouteName => [
    {
        TITLE: 'My Profile',
        ICON: <MyProfileIcon />,
        ROUTE: `/${agencyRouteName}/profile`,
    },
    {
        TITLE: 'My Interviews',
        ICON: <MyInterviewsIcon />,
        ROUTE: `/${agencyRouteName}/my-applications`,
    },
];
