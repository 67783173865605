import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { getCandidateApplicationProcessInformation, getPersonalInfo, getCandidatePreferences } from 'reducers';

import { Tooltip } from '@material-ui/core';

import { TOOLTIP_TEXT, APPLICATION_PROCESS_STEPS } from './ApplicationProcessBannerConstants';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { ReactComponent as InfoIcon } from 'assets/info-icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/right-arrow-icon.svg';

import { getWelcomeInformation } from './ApplicationProcessBannerUtils';
import { getStateAgencyName } from 'utils/helpers';

import './ApplicationProcessBanner.scss';

const ApplicationProcessBanner = ({
    candidateApplicationProcessInformation,
    personalInfo,
    preferences,
    showWelcomeBack = false,
}) => {
    const history = useHistory();

    const { text, showButton, buttonText } = getWelcomeInformation(personalInfo, preferences);

    const handleUpdateClick = () => history.push(`/${getStateAgencyName()}/profile`);

    return (
        <div className="application-process-banner-wrapper">
            {(showWelcomeBack && text) &&
                <div className="welcome-back-wrapper">
                    <h2 className="welcome-back-title">
                        Welcome back!
                    </h2>
                    <p className="welcome-back-text">
                        {text}
                    </p>
                    {showButton && <PrimaryButton className="welcome-back-update-btn" text={buttonText} handleClick={handleUpdateClick} />}
                </div>
            }

            <h2 className="application-process-banner-title heading-xs color-dark-grey">
                Our typical application process
                <Tooltip classes={{ tooltip: 'custom-tooltip' }} title={TOOLTIP_TEXT} placement="top-start">
                    <InfoIcon className="info-icon" />
                </Tooltip>
            </h2>
            <div className="application-process-banner-steps-container">
                {APPLICATION_PROCESS_STEPS.map((step, index) => {
                    return (
                        <Fragment key={step._id}>
                            <div className="step-container">
                                <p className="heading-m color-deep-purple">
                                    0{index+1}
                                </p>
                                <p className="subheading-l color-grey">{step.text}</p>
                            </div>
                            {index !== APPLICATION_PROCESS_STEPS.length - 1 &&
                                <ArrowIcon className="arrow-icon" />
                            }
                        </Fragment>
                    )
                })}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    candidateApplicationProcessInformation: getCandidateApplicationProcessInformation(state),
    personalInfo: getPersonalInfo(state),
    preferences: getCandidatePreferences(state),
});

export default connect(mapStateToProps)(ApplicationProcessBanner);
