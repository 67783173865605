import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { ReactComponent as CoderyLogo } from 'assets/codery-logo.svg';

import './AuthCard.scss';

const AuthCard = ({
    className,
    titleAsImage,
    titleImageComponent,
    footerImageComponent,
    mainTitle,
    mainSubtitle,
    title,
    children,
}) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            className={`auth-card-wrapper${className ? ` ${className}` : ''}`}
        >
            <Paper className="auth-info-content">
                <CoderyLogo />
                <div className="codery-info">
                    {titleAsImage ? titleImageComponent : <p className="heading-l">{mainTitle}</p>}
                    <p className="heading-s color-white">{mainSubtitle}</p>
                    {footerImageComponent && footerImageComponent}
                </div>
            </Paper>
            <Paper
                elevation={0}
                className="auth-card-content rounded-l"
            >
                <div className="auth-card-header">
                    <p className="heading-m color-dark-grey">{title}</p>
                </div>
                <div className="auth-card-form-wrapper">
                    {children}
                </div>
            </Paper>
        </Grid>
    );
};

export default AuthCard;
