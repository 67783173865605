import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { InlineWidget, CalendlyEventListener } from 'react-calendly';

import { bookFaceToFaceInterview } from 'actions/candidateActions';

import { 
    getCurrentJourneyStepId,
    getDisplayName,
    getEmail,
    getEmployeeEmail,
    getEmployeeName,
    getInitialJourneyCompletedStepsIds
} from 'reducers';

import { VideoIntroductionWrapperContext } from '../VideoIntroductionWrapper';

import CircularProgress from '@material-ui/core/CircularProgress';

const VideoIntroduction = ({
    agencyId,
    userId,
    continueJourney,
    bookFaceToFaceInterview,
    stepId,
    completedStepsIds,
    candidateEmail,
    candidateName
}) => {
    const { agencyCalendlyInfo } = useContext(VideoIntroductionWrapperContext);

    const calendlyFullUrl = agencyCalendlyInfo.calendlyUrl?.includes('https://calendly.com')
        ? agencyCalendlyInfo.calendlyUrl
        : `https://calendly.com/${agencyCalendlyInfo.calendlyUrl}`;

    const isUrlLoading = !Boolean(agencyCalendlyInfo.calendlyUrl);

    const widgetProps = {
        url: calendlyFullUrl || '',
        pageSettings: {
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: '#0A0A0A',
            primaryColorLevel4: '#0A0A0A',
            textColor: '#0A0A0A',
        },
        prefill: {
            email: candidateEmail,
            name: candidateName,
        },
    };

    const customStyles = { height: '660px', minWidth: '1000px' }

    const handleEventScheduling = (event) => {
        const eventUri = event.data.payload.event.uri;
        const inviteeUri = event.data.payload.invitee.uri;
        const condition = !completedStepsIds.includes(stepId);
        const action = bookFaceToFaceInterview.bind(null, agencyId, userId, { eventUri, inviteeUri }, false);
        continueJourney(null, condition, action, true);
    };

    return (
        <div className="step-wrapper">
                {isUrlLoading ?
                    <CircularProgress thickness={5} size={50} className="page-loader" /> :
                    <>
                        <InlineWidget styles={customStyles} {...widgetProps} />
                        <CalendlyEventListener onEventScheduled={handleEventScheduling} />
                    </>
                }
        </div>
    );
};

const mapStateToProps = (state, props) => ({
    stepId: getCurrentJourneyStepId(state),
    completedStepsIds: getInitialJourneyCompletedStepsIds(state),
    candidateEmail: props.isAdminMode ? getEmployeeEmail(state) : getEmail(state),
    candidateName: props.isAdminMode ? getEmployeeName(state) : getDisplayName(state),
});

const mapDispatchToProps = {
    bookFaceToFaceInterview,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoIntroduction);
