import React from 'react';
import { useIntl } from 'react-intl';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import { ErrorMessage } from 'formik';

import '../CompanyInformationBasicForm.scss';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const CompanyInformationBasicFormView = ({
    values,
    handleChange,
    handleShowHeaderButtons,
    errors,
    touched,
    countries,
    isEmailChangeDisabled,
    isClient,
}) => {
    const intl = useIntl();

    return (
        <form className={`company-basic-information-form ${isClient ? 'client' : ''}`} autoComplete="off">
            <div className="company-information-form-field">
                <TextField
                    required
                    id="name"
                    label={intl.formatMessage({ id: "company-name" })}
                    variant="outlined"
                    name="name"
                    value={values.name}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    error={errors.name && touched.name}
                    fullWidth
                />
                <ErrorMessage name="name" component="div" className="invalid-field-message" />
            </div>
            <div>
                <FormControl className={`company-information-form-field country ${isClient ? 'client' : ''}`} variant="outlined" fullWidth>
                    <InputLabel id="country-label">{intl.formatMessage({ id: "country" })}</InputLabel>
                    <Select
                        labelId="country-label"
                        id="country"
                        name="country"
                        value={values.country}
                        onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                        label={intl.formatMessage({ id: "country" })}
                        error={errors.country && touched.country}
                    >
                        {countries.map(country => (
                            <MenuItem key={country.name} value={country.name}>
                                {intl.formatMessage({ id: convertStringToLanguageKey(country.name) })}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {!isClient &&
                <div className={`company-information-form-field last`}>
                    <TextField
                        disabled={isEmailChangeDisabled}
                        id="email"
                        label={intl.formatMessage({ id: "email" }) }
                        variant="outlined"
                        name="email"
                        value={values.email}
                        onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                        error={errors.email && touched.email}
                        fullWidth
                    />
                    <ErrorMessage name="email" component="div" className="invalid-field-message" />
                </div>
            }
            {isClient &&
                <>
                    <div className="company-information-form-field hq-location">
                        <TextField
                            id="hqLocation"
                            label={intl.formatMessage({ id: "hq-location" }) }
                            variant="outlined"
                            name="hqLocation"
                            value={values.hqLocation}
                            onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                            fullWidth
                        />
                    </div>

                    <div className="company-information-form-field obfuscated-name">
                        <TextField
                            id="obfuscatedName"
                            label={intl.formatMessage({ id: "obfuscated-name" }) }
                            variant="outlined"
                            name="obfuscatedName"
                            value={values.obfuscatedName}
                            onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                            fullWidth
                        />
                    </div>

                    <div className="company-information-form-field company-website last">
                        <TextField
                            id="companyWebsite"
                            label={intl.formatMessage({ id: "company-website" }) }
                            variant="outlined"
                            name="companyWebsite"
                            value={values.companyWebsite}
                            onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                            fullWidth
                        />
                    </div>
                </>
            }
        </form>);
};

export default CompanyInformationBasicFormView;
