import React from 'react';
import { useIntl } from 'react-intl';

import MissingInformation from '../MissingInformation';

import { ReactComponent as EditCVIcon } from 'assets/edit-candidate-cv-icon.svg';

import { ROLES } from 'constants/userConstants';

import './CvProfileSummary.scss';

const CvProfileSummary = ({
    profileSummary,
    enterEditMode,
    enableEdit,
    displayMode,
}) => {
    const intl = useIntl();
    const isClient = displayMode === ROLES.CLIENT;

    return (
        <>
            <div className="profile-summary-wrapper">
                <div className="header">
                    <h1 className="title subheading-xxl color-grey-dark">
                        {intl.formatMessage({ id: "profile-summary" })}
                    </h1>
                    {enableEdit &&
                        <div className="item-edit-icon-wrapper" onClick={() => enterEditMode('profileSummary')}>
                            <EditCVIcon />
                        </div>
                    }
                </div>
                {profileSummary
                    ? <p className="summary subheading-m color-grey">{profileSummary}</p>
                    : isClient ? <div className="empty-category" /> : <MissingInformation />
                }
            </div>
        </>
    )
};

export default CvProfileSummary;
