import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchAccountManagerInformation } from 'actions/userActions';
import {
    getClientAccountManager,
    getRecruiterAccountManager,
    getSupplierAccountManager,
    getAccountManagerDataFetchState,
    getClientId
} from 'reducers';

import CircularProgress from '@material-ui/core/CircularProgress';
import YourAccountManagerInformation from './YourAccountManagerInformation';
import CustomerSupport from './CustomerSupport';
// import YourAccountManagerFeedback from './YourAccountManagerFeedback';

import { ROLES } from 'constants/userConstants';

import './YourAccountManager.scss';

const YourAccountManager = ({
    fetchAccountManagerInformation,
    agencyId,
    userId,
    accountManager,
    displayMode,
    dataFetchState,
    clientId,
}) => {
    useEffect(() => {
        fetchAccountManagerInformation(agencyId, clientId || userId, displayMode);
    }, []);

    return (
        <div className="your-account-manager-wrapper">
            {dataFetchState?.isDataFetching && accountManager.name === '' &&
                <CircularProgress thickness={5} size={50} disableShrink className="account-manager-loader" />
            }
            <YourAccountManagerInformation
                accountManager={accountManager.name}
                contactNumber={accountManager.contactNumber}
                contactEmail={accountManager.email}
                profilePicture={accountManager.profilePictureImgUrl}
            />
            <CustomerSupport
                accountManager={accountManager}
            />
            {/* The below code is commented due to task #581 */}
            {/* <YourAccountManagerFeedback />*/}
        </div>
    );
}

const GET_ACCOUNT_MANAGER_ROLE_MAPPER = {
    [ROLES.CLIENT]: getClientAccountManager,
    [ROLES.SUPPLIER_ADMIN]: getSupplierAccountManager,
    [ROLES.RECRUITER]: getRecruiterAccountManager,
}

const mapStateToProps = (state, ownProps) => ({
    accountManager: GET_ACCOUNT_MANAGER_ROLE_MAPPER[ownProps.displayMode](state),
    dataFetchState: getAccountManagerDataFetchState(state),
    clientId: getClientId(state),
});

const mapDispatchToProps = {
    fetchAccountManagerInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(YourAccountManager);
