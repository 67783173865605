export const ADMIN_USERS_PERMISSIONS = {
    DASHBOARD_VIEW: 'DASHBOARD_VIEW',
    TALENTS_VIEW: 'TALENTS_VIEW',
    TEAM_VIEW: 'TEAM_VIEW',
    CANDIDATES_VIEW: 'CANDIDATES_VIEW',
    REPORTS_VIEW: 'REPORTS_VIEW',
    CLIENTS_VIEW: 'CLIENTS_VIEW',
    PARTNERS_VIEW: 'PARTNERS_VIEW',
    TASKS_VIEW: 'TASKS_VIEW',
    SETTINGS_VIEW: 'SETTINGS_VIEW'
};

export const ADMIN_USERS_PERMISSIONS_LIST = Object.values(ADMIN_USERS_PERMISSIONS);

export const ADMIN_USERS_PERMISSIONS_TEXT_MAP = {
    [ADMIN_USERS_PERMISSIONS.DASHBOARD_VIEW]: 'Dashboard View',
    [ADMIN_USERS_PERMISSIONS.TALENTS_VIEW]: 'Talents View',
    [ADMIN_USERS_PERMISSIONS.TEAM_VIEW]: 'Team View',
    [ADMIN_USERS_PERMISSIONS.CANDIDATES_VIEW]: 'Candidates View',
    [ADMIN_USERS_PERMISSIONS.REPORTS_VIEW]: 'Reports View',
    [ADMIN_USERS_PERMISSIONS.CLIENTS_VIEW]: 'Clients View',
    [ADMIN_USERS_PERMISSIONS.PARTNERS_VIEW]: 'Partners View',
    [ADMIN_USERS_PERMISSIONS.TASKS_VIEW]: 'Tasks View',
    [ADMIN_USERS_PERMISSIONS.SETTINGS_VIEW]: 'Settings View'
}
